define('sokol-today/models/school', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        name: _emberData.default.attr('string'),
        rooms: _emberData.default.hasMany('room'),
        verified: _emberData.default.attr('boolean', { default: false }),
        totalPINS: _emberData.default.attr('number'),
        usedPINS: _emberData.default.attr('number'),
        curriculumList: _emberData.default.belongsTo('class-curriculum-list', { async: true }),
        studentGrade: _emberData.default.belongsTo('student-grade-list', { async: true }), //student grades
        termList: _emberData.default.belongsTo('term-list', { async: true }),
        yearList: _emberData.default.belongsTo('year-list', { async: true }),
        observationList: _emberData.default.belongsTo('observation-list', { asyn: true }),
        rubricList: _emberData.default.belongsTo('rubric-list', { async: true }),
        absenceList: _emberData.default.belongsTo('absence-list', { async: true }),
        assignmentList: _emberData.default.belongsTo('assignment-list', { async: true }),
        relationshipList: _emberData.default.belongsTo('relationship-list', { async: true }),
        roomList: _emberData.default.belongsTo('room-list', { async: true }),
        studentList: _emberData.default.belongsTo('student-list', { async: true }),
        subjectList: _emberData.default.belongsTo('subject-list', { async: true }),
        professionList: _emberData.default.belongsTo('profession-list', { async: true }), //list of professions available at school  
        staffList: _emberData.default.belongsTo('staff-list', { async: true }),
        classList: _emberData.default.belongsTo('class-list', { async: true }),
        calamityList: _emberData.default.belongsTo('calamity-list', { async: true }),
        courseList: _emberData.default.belongsTo('course-list', { async: true }),
        schoolGradeList: _emberData.default.belongsTo('school-grade-list', { async: true }), //list of grades available at school
        personal: _emberData.default.attr('boolean'), //Personal account this should be removed as now accounts handled by stripe
        addressStreet: _emberData.default.attr('string'),
        addressNumber: _emberData.default.attr('string'),
        addressState: _emberData.default.attr('string'),
        addressZip: _emberData.default.attr('string'),
        addressTown: _emberData.default.attr('string'),
        elementarySchool: _emberData.default.attr('boolean'),
        gradeSchool: _emberData.default.attr('boolean'),
        highSchool: _emberData.default.attr('boolean'),
        college: _emberData.default.attr('boolean')
    });
});