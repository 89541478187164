define('sokol-today/components/helper/dashboard-hamburger-button', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		connectedUser: Ember.inject.service(),
		hideDashboard: false,
		// click(){
		// 	this.toggleProperty('clicked');
		// }
		// 
		actions: {
			clickedMenu: function clickedMenu() {
				this.toggleProperty('hideDashboard');
			}
		}
	});
});