define('sokol-today/helpers/blurb-creation', ['exports'], function (exports) {
		'use strict';

		Object.defineProperty(exports, "__esModule", {
				value: true
		});
		exports.blurbCreation = blurbCreation;
		function blurbCreation(params /*, hash*/) {
				var string = params[0];
				var length = Number(params[1]);
				if (!string) {
						return '<red>No Course Description</red>';
				}
				if (string.length < length) {
						return string;
				}

				var i = 0;
				do {
						if (string.substring(0, length + i + 1).endsWith(' ')) {
								return string.substring(0, length + i) + '...';
						}
						i++;
				} while (!string.substring(0, length + i).endsWith(' '));
		}

		exports.default = Ember.Helper.helper(blurbCreation);
});