define('sokol-today/components/dashboard/observations/index-display', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        connectedUser: Ember.inject.service(),
        store: Ember.inject.service(),

        staff: {},

        getTeachers: function () {
            var self = this;
            var store = this.get('store');
            var connectedUser = this.get('connectedUser');

            // var user = this.get('connectedUser');
            var promises = {
                staff: connectedUser.get('user').then(function (theUser) {
                    var accountId = theUser.get('defaultAccount');
                    return store.findRecord('staff', accountId).then(function (theStaff) {
                        console.log(theStaff.get('school.id'));
                        return store.findRecord('school', theStaff.get('school.id')).then(function (theSchool) {
                            return theSchool.get('staffList').then(function (theStaff) {
                                return theStaff;
                            }).catch(function (error) {
                                console.log(error);
                            });
                        }).catch(function (error) {
                            console.log(error);
                        });
                    }).catch(function (error) {
                        console.log(error);
                    });
                })
            };
            Ember.RSVP.hash(promises).then(function (theStaff) {
                self.setProperties({ staff: theStaff.staff });
            });
        }.on('init')
    });
});