define('sokol-today/components/dashboard/display/main/main-admin', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        store: Ember.inject.service(),
        connectedUser: Ember.inject.service(),

        recentObservations: function () {
            var store = this.get('store');
            return this.get('user.school').then(function (school) {
                return store.findRecord('observationList', school.get('observationList.id')).then(function (observationList) {
                    console.log(observationList.get('observationBlocks').slice(0, 3));
                    return observationList.get('observationBlocks').slice(0, 3);
                });
            }, function (error) {
                console.log('Error : ' + error);
            });
        }.property('user.school.observationList.observationBlocks.[]'),

        todaysAbsences: Ember.computed(function () {
            var dateStart = moment().startOf('day').format();
            var dateEnd = moment().endOf('day').format();
            var school = this.get('connectedUser.school.id');

            var store = this.get('store');

            var absences = store.query('absence', {
                orderBy: 'date',
                startAt: dateStart,
                endAt: dateEnd
            });

            return absences;
        })
    });
});