define('sokol-today/components/helper/file-picker', ['exports', 'npm:exif-parser'], function (exports, _npmExifParser) {
		'use strict';

		Object.defineProperty(exports, "__esModule", {
				value: true
		});
		exports.default = Ember.Component.extend({
				store: Ember.inject.service(),
				firebaseApp: Ember.inject.service(),
				appEvents: Ember.inject.service(),
				// classNames: ['drop-area', 'margin-h-80'],
				dragStarted: false,
				// classNameBindings: ['dropArea:file:drop-area'],
				rawSize: '',

				// image: false,

				dropArea: Ember.computed('file', function () {
						var file = this.get('file');
						if (file) {
								return true;
						} else {
								return false;
						}
				}),

				mouseDown: function mouseDown() {
						console.log('drag started');
						this.set('dragStarted', true);
				},
				dragStop: function dragStop() {
						console.log('drag ended');
						this.set('dragStarted', false);
				},
				newImage: function newImage(input) {
						var _URL = window.URL || window.webkitURL;
						var self = this;
						var fileInfo = this.get('fileInfo');
						// let firebase = this.get('firebaseApp');
						var array = [];
						for (var i = input.files.length - 1; i >= 0; i--) {
								(function (file) {
										var fileReader = new FileReader();
										var imageReader = new FileReader();
										fileReader.onload = function (e) {
												console.log(file.name);
												var arrayBuffer = e.target.result;
												var arr = new Uint8Array(e.target.result).subarray(0, 8);
												var header = "";
												for (var i = 0; i < arr.length; i++) {
														header += arr[i].toString(16);
												}
												var type = null;
												console.log(header);
												switch (header) {
														case "89504e47":
																type = "image/png";
																break;
														case "47494638":
																type = "image/gif";
																break;
														case "ffd8ffe00104a46":
														case "ffd8ffe1":
														case "ffd8ffe2":
																type = "image/jpeg";
																break;
														case "3c3f786d6c207665":
																self.setProperties({ fileSVG: true, filePDF: false, fileXSL: false, fileDOC: false });
																type = "application/svg";
																break;
														case "255044462d312e33":
																self.setProperties({ fileSVG: false, filePDF: true, fileXSL: false, fileDOC: false });
																type = "application/pdf";
																break;
														case "38425053":
																type = "application/psd";
																break;
														case "504b34":
																type = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
																break;
														case "d0cf11e0a1b11ae1":
																self.setProperties({ fileSVG: false, filePDF: false, fileXSL: true, fileDOC: false });
																type = 'application/vnd.ms-excel';
																break;
														case "d0cf11e0":
																self.setProperties({ fileSVG: false, filePDF: false, fileXSL: false, fileDOC: true });
																type = "application/doc"; //or doc, xls, ppt, msg, and other
																break;
														case "49492A00":
																type = "image/tif";
																break;
														case "4D4D002A":
																type = "image/tiff";
																break;
														default:
																type = "unknown"; // Or you can use the blob.type as fallback
																break;
												}
												var exifParser = _npmExifParser.default.create(arrayBuffer);;
												var blob = new Blob([arrayBuffer], { type: type });
												var img = new Image();
												var _URL = window.URL || window.webkitURL;
												img.src = _URL.createObjectURL(blob);
												var exifResult = exifParser.parse();
												var exifRef = exifResult.tags;
												console.log(exifRef);
												var metadata = {
														type: type,
														make: exifRef.Make,
														model: exifRef.Model,
														lensMake: exifRef.LensMake,
														lensModel: exifRef.LensModel,
														gpsLat: exifRef.GPSLatitude,
														gpsLatRef: exifRef.GPSLatitudeRef,
														gpsLon: exifRef.GPSLongitude,
														gpsLonRef: exifRef.GPSLongitudeRef,
														iso: exifRef.ISO,
														exposure: exifRef.ExposureTime,
														aperture: exifRef.FNumber,
														origDate: exifRef.DateTimeOriginal,
														name: file.name,
														lastModified: file.lastModified,
														size: file.size,
														filePDF: self.get('filePDF'),
														fileJPG: self.get('fileJPG'),
														fileXSL: self.get('fileXSL'),
														fileDOC: self.get('fileDOC'),
														fileSVG: self.get('fileSVG')
												};
												console.log(metadata);
												fileInfo.addObject({ file: blob, data: metadata, preview: img });

												// if (fileInfo) {
												// 		console.log('array exists');
												// 		self.get('fileInfo').pushObject({ "file": blob, "metadata": metadata, "preview": img });
												// } else {
												// self.setProperties({ fileInfo: array });
												// }
												// let ref = firebase.storage().ref('teacherAssignments');
												// ref.put(blob).then(function(){
												//  console.log('saved successful');
												// }).catch(function(error){
												//  console.log('save failed: '+ error);
												// });
										};
										fileReader.readAsArrayBuffer(file);
								})(input.files[i]);
						}
				},


				click: function click() {
						Ember.$("input[type='file']").click();
				},

				didInsertElement: function didInsertElement() {
						var self = this;
						Ember.$("input[type='file']").click(function (event) {
								event.stopPropagation();
						});
						$("#fileInput").on('change', function (e) {
								e.preventDefault();
								e.stopPropagation();
								self.newImage(e.target);
						});
						$(document).on({
								'drop': function drop(e) {
										e.preventDefault();
										// e.stopPropagation();
										// console.log(e.dataTransfer);
										self.newImage(e.dataTransfer);
								},
								'dragover': function dragover(e) {
										e.preventDefault();
										$(this).addClass('dragging');
								}
						}, ".drop-area");
				}
		});
});