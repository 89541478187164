define('sokol-today/helpers/date-formatter', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.dateFormatter = dateFormatter;
    function dateFormatter(params) {
        var format = params[1];
        var expectedFormat = params[2];
        var momentFormat = params[3];
        // console.log(params);
        if (!params[0]) {
            return 'No Data';
        }

        if (params[0] === 'now') {
            switch (format) {
                case "postDate":
                    return moment().format('MMM Do YYYY');
                    break;
                case "timeline":
                    return moment().format('h:mm a, dddd');
                    break;
                case "moment":
                    return moment().format(momentFormat);
                    break;
                case "number":
                    return date.valueOf();
                    break;
                default:
                    return moment().format(momentformat);
            }
        } else {
            var date = params[0];
            if (!moment(date).isValid()) {
                return 'NA';
            }
            if (expectedFormat != "X") {
                switch (format) {
                    case "postDate":
                        return moment(date, expectedFormat).format('MMM Do YYYY');
                        break;
                    case "timeline":
                        return moment(date, expectedFormat).format('h:mm a');
                        break;
                    case "moment":
                        return moment(date, expectedFormat).format(momentFormat);
                        break;
                    case "number":
                        return date.valueOf();
                        break;
                    case "calendar":
                        return moment(date).calendar();
                        break;
                    case "fromNow":
                        return moment(date).fromNow();
                        break;
                    case "toNow":
                        return moment(date).toNow(true);
                        break;
                    default:
                        return moment(date, expectedFormat).format(format);
                }
            } else {
                // console.log(moment.unix(date));
                switch (format) {
                    case "postDate":
                        return moment.unix(date).format('MMM Do YYYY');
                        break;
                    case "timeline":
                        return moment.unix(date).format('h:mm a');
                        break;
                    case "moment":
                        return moment.unix(date).format(momentFormat);
                        break;
                    case "number":
                        return date.valueOf();
                        break;
                    case "calendar":
                        return moment.unix(date).calendar();
                        break;
                    case "fromNow":
                        return moment.unix(date).fromNow();
                        break;
                    case "toNow":
                        return moment.unix(date).toNow(true);
                        break;
                    default:
                        return moment.unix(date).format(format);
                }
            }
        }
        return params;
    }

    exports.default = Ember.Helper.helper(dateFormatter);
});