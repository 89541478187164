define('sokol-today/components/dashboard/display/top-menu/feedback-button', ['exports'], function (exports) {
		'use strict';

		Object.defineProperty(exports, "__esModule", {
				value: true
		});
		exports.default = Ember.Component.extend({
				firebaseApp: Ember.inject.service(),
				store: Ember.inject.service(),
				feedbackMenu: false,
				actions: {
						toggleFeedbackMenu: function toggleFeedbackMenu() {
								this.toggleProperty('feedbackMenu');
						},
						sendFeedback: function sendFeedback(feedBack, userAccount) {
								if (!feedBack) {
										return;
								}
								var _this = this;
								var feedRef = this.get('firebaseApp').database().ref('/null/').push();
								var store = this.get('store');
								var url = window.location.href;
								var newFeedRef = 'feedback' + feedRef.key;
								var newChatRef = 'feedback-chat' + feedRef.key;
								var chatModel = store.createRecord('chat-room', {
										id: newChatRef
								});
								chatModel.get('staff_members').addObject(userAccount);
								var feedbackModel = store.createRecord('feedback', {
										id: newFeedRef,
										account: userAccount,
										timestamp: new Date(),
										feedback: feedBack,
										url: url,
										chat_room: newChatRef
								});

								feedbackModel.save().then(function (savedFeedback) {
										chatModel.save().then(function (savedChat) {
												_this.setProperties({ successMessage: 'Saved', errorMessage: null });
												setTimeout(function () {
														_this.setProperties({ successMessage: null, errorMessage: null, feedbackMenu: false, notes: null });
												}, 3000);
										}).catch(function (error) {
												feedbackModel.rollBackAttributes();
												_this.setProperties({ errorMessage: error });
										});
								}).catch(function (error) {
										_this.setProperties({ errorMessage: error });
								});
						}
				}
		});
});