define('sokol-today/components/dashboard/students/edit/step-one', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    firebaseApp: Ember.inject.service(),
    raceCode: Ember.inject.service(),
    connectedUser: Ember.inject.service(),
    router: Ember.inject.service('-routing'),
    errorMessage: '',
    successMessage: '',
    studentRace: null,
    raceArray: [],
    classOfArray: [],

    classOf: Ember.computed('', function () {
      var myArray = this.get('classOfArray');
      // let year = moment().format('YYYY');
      for (var i = 14 - 1; i >= 0; i--) {
        var nextYear = moment().add(i, 'year').format('YYYY');
        myArray.pushObject(nextYear);
      }
    }),

    handleSaveModel: Ember.computed('saveModel', function () {
      console.log(this.get('saveModel'));
    }),

    didInsertElement: function didInsertElement() {
      var array = this.get('raceArray');
      array.clear();
      var self = this;
      var studentRaceRef = this.get('model.raceCode');
      studentRaceRef.then(function (theRaceCodeLoc) {
        if (!theRaceCodeLoc) {
          return;
        }
        var raceCode = theRaceCodeLoc.get('raceID');
        var service = self.get('raceCode');
        if (!raceCode) {
          return;
        }
        console.log(raceCode);
        var raceArray = self.getRaceArray(service, raceCode);
        console.log(raceArray);
        self.set('studentRace', raceCode);
        self.set('raceArray', raceArray);
        for (var i = raceArray.length - 1; i >= 0; i--) {
          switch (raceArray[i]) {
            case 1:
              self.set('isOne', true);
              break;
            case 2:
              self.set('isTwo', true);
              break;
            case 10:
              self.set('isHispanic', true);
              raceArray.removeObject(10);
              break;
            case 4:
              self.set('isFour', true);
              break;
            case 5:
              self.set('isFive', true);
              break;
            case 6:
              self.set('isSix', true);
              break;
            default:
              return;
              break;

          }
        }
      });
    },


    allergies: Ember.computed('', function () {
      return this.get('store').findAll('allergy');
    }),

    classes: Ember.computed('connectedUser.currentClass', function () {
      return this.get('connectedUser.currentClass');
    }),

    getRaceArray: function getRaceArray(raceService, raceCode) {
      var array = raceService.getArray(raceCode);
      return array;
    },
    getRaceCode: function getRaceCode(raceService, isHispanic, array) {
      var code = raceService.getCode(isHispanic, array);
      return code;
    },


    actions: {
      saveStudent: function saveStudent(model, image, imageLandscape, imagePortrait) {
        var self = this;
        var firebaseRef = this.get('firebaseApp').database().ref('/students');
        var connectedUser = this.get('connectedUser');
        var router = this.get('router');
        var raceCode = this.get('studentRace');
        var store = this.get('store');
        var avatarRef = firebaseRef.push();
        var newAvatarRef = 'avatar' + avatarRef.key;
        if (raceCode) {
          var raceModel = store.findRecord('race-code', raceCode);
          raceModel.then(function (foundRaceModel) {
            model.set('raceCode', foundRaceModel);
            if (image) {
              console.log('there is no model image and we have a image');
              if (imageLandscape) {
                var avatar = store.createRecord('avatar', {
                  id: newAvatarRef,
                  imageLandscape: true,
                  image: image
                });

                avatar.save().then(function (newAvatar) {
                  model.set('avatar', newAvatar);
                  model.save().then(function () {
                    self.setProperties({ successMessage: "Student Info Saved", errorMessage: '' });
                    // connectedUser.changeYear();
                    setTimeout(function () {
                      router.transitionTo('dashboard.students');
                    }, 1000);
                  }, function (error) {
                    self.setProperties({ successMessage: '', errorMessage: error });
                  });
                }, function (error) {
                  console.log('Error : ' + error);
                });
              } else if (imagePortrait) {
                var avatar = store.createRecord('avatar', {
                  id: newAvatarRef,
                  imagePortrait: true,
                  image: image
                });

                avatar.save().then(function (newAvatar) {
                  model.set('avatar', newAvatar);
                  model.save().then(function () {
                    self.setProperties({ successMessage: "Student Info Saved", errorMessage: '' });
                    // connectedUser.changeYear();
                    setTimeout(function () {
                      router.transitionTo('dashboard.students');
                    }, 1000);
                  }, function (error) {
                    self.setProperties({ successMessage: '', errorMessage: error });
                  });
                }, function (error) {
                  console.log('Error : ' + error);
                });
              } else {
                var avatar = store.createRecord('avatar', {
                  id: newAvatarRef,
                  image: image
                });
                avatar.save().then(function (newAvatar) {
                  model.set('avatar', newAvatar);
                  model.save().then(function () {
                    self.setProperties({ successMessage: "Student Info Saved", errorMessage: '' });
                    // connectedUser.changeYear();
                    setTimeout(function () {
                      router.transitionTo('dashboard.students');
                    }, 1000);
                  }, function (error) {
                    self.setProperties({ successMessage: '', errorMessage: error });
                  });
                }, function (error) {
                  console.log('Error : ' + error);
                });
              }
            } else if (image === null) {
              var _avatar = model.get('avatar');
              _avatar.then(function (foundAvatar) {
                // foundAvatar.setProperties({ image: model.avatar.image, imagePortrait: model.avatar.imagePortrait, imageLandscape: model.avatar.imageLandscape });
                foundAvatar.save().then(function () {
                  model.save().then(function () {
                    self.setProperties({ successMessage: "Student Info Saved", errorMessage: '' });
                    // connectedUser.changeYear();
                    setTimeout(function () {
                      router.transitionTo('dashboard.students');
                    }, 1000);
                  }, function (error) {
                    self.setProperties({ successMessage: '', errorMessage: error });
                  });
                }, function (error) {
                  console.log('Error : ' + error);
                });
              }, function (error) {
                console.log('Error : ' + error);
              });
            } else {
              model.save().then(function () {
                self.setProperties({ successMessage: "Student Info Saved", errorMessage: '' });
                // connectedUser.changeYear();
                setTimeout(function () {
                  router.transitionTo('dashboard.students');
                }, 1000);
              }, function (error) {
                self.setProperties({ successMessage: '', errorMessage: error });
              });
            }
          });
        } else {
          if (image) {
            console.log('there is no model image and we have a image');
            if (imageLandscape) {
              var avatar = store.createRecord('avatar', {
                id: newAvatarRef,
                imageLandscape: true,
                image: image
              });

              avatar.save().then(function (newAvatar) {
                model.set('avatar', newAvatar);
                model.save().then(function () {
                  self.setProperties({ successMessage: "Student Info Saved", errorMessage: '' });
                  // connectedUser.changeYear();
                  setTimeout(function () {
                    router.transitionTo('dashboard.students');
                  }, 1000);
                }, function (error) {
                  self.setProperties({ successMessage: '', errorMessage: error });
                });
              }, function (error) {
                console.log('Error : ' + error);
              });
            } else if (imagePortrait) {
              var avatar = store.createRecord('avatar', {
                id: newAvatarRef,
                imagePortrait: true,
                image: image
              });

              avatar.save().then(function (newAvatar) {
                model.set('avatar', newAvatar);
                model.save().then(function () {
                  self.setProperties({ successMessage: "Student Info Saved", errorMessage: '' });
                  // connectedUser.changeYear();
                  setTimeout(function () {
                    router.transitionTo('dashboard.students');
                  }, 1000);
                }, function (error) {
                  self.setProperties({ successMessage: '', errorMessage: error });
                });
              }, function (error) {
                console.log('Error : ' + error);
              });
            } else {
              var avatar = store.createRecord('avatar', {
                id: newAvatarRef,
                image: image
              });
              avatar.save().then(function (newAvatar) {
                model.set('avatar', newAvatar);
                model.save().then(function () {
                  self.setProperties({ successMessage: "Student Info Saved", errorMessage: '' });
                  // connectedUser.changeYear();
                  setTimeout(function () {
                    router.transitionTo('dashboard.students');
                  }, 1000);
                }, function (error) {
                  self.setProperties({ successMessage: '', errorMessage: error });
                });
              }, function (error) {
                console.log('Error : ' + error);
              });
            }
          } else if (image === null) {
            var _avatar2 = model.get('avatar');
            _avatar2.then(function (foundAvatar) {
              // foundAvatar.setProperties({ image: model.avatar.image, imagePortrait: model.avatar.imagePortrait, imageLandscape: model.avatar.imageLandscape });
              foundAvatar.save().then(function () {
                model.save().then(function () {
                  self.setProperties({ successMessage: "Student Info Saved", errorMessage: '' });
                  // connectedUser.changeYear();
                  setTimeout(function () {
                    router.transitionTo('dashboard.students');
                  }, 1000);
                }, function (error) {
                  self.setProperties({ successMessage: '', errorMessage: error });
                });
              }, function (error) {
                console.log('Error : ' + error);
              });
            }, function (error) {
              console.log('Error : ' + error);
            });
          } else {
            model.save().then(function () {
              self.setProperties({ successMessage: "Student Info Saved", errorMessage: '' });
              // connectedUser.changeYear();
              setTimeout(function () {
                router.transitionTo('dashboard.students');
              }, 1000);
            }, function (error) {
              self.setProperties({ successMessage: '', errorMessage: error });
            });
          }
        }
      },
      saveStudentGoGuardian: function saveStudentGoGuardian(model, image, imageLandscape, imagePortrait) {
        var self = this;
        var firebaseApp = this.get('firebaseApp').database().ref('/students');
        var connectedUser = this.get('connectedUser');
        var router = this.get('router');
        var raceCode = this.get('studentRace');
        var store = this.get('store');
        var avatarRef = firebaseRef.push();
        var newAvatarRef = 'avatar' + avatarRef.key;
        if (raceCode) {
          var raceModel = store.findRecord('race-code', raceCode);
          raceModel.then(function (foundRaceModel) {
            model.set('raceCode', foundRaceModel);
            if (image) {
              if (imageLandscape) {
                var avatar = store.createRecord('avatar', {
                  id: newAvatarRef,
                  imageLandscape: true,
                  image: image
                });

                avatar.save().then(function (newAvatar) {
                  self.set('successMessage', 'We are saving the data...please hold');
                  model.set('avatar', newAvatar);
                  model.save().then(function () {
                    self.setProperties({ successMessage: "Student Info Saved", errorMessage: '' });
                    // connectedUser.changeYear();
                    setTimeout(function () {
                      self.get('transitionToGuardian')(model);
                    }, 1000);
                  }, function (error) {
                    self.setProperties({ successMessage: '', errorMessage: error });
                  });
                }, function (error) {
                  console.log('Error : ' + error);
                });
              } else if (imagePortrait) {
                var avatar = store.createRecord('avatar', {
                  id: newAvatarRef,
                  imagePortrait: true,
                  image: image
                });

                avatar.save().then(function (newAvatar) {
                  self.set('successMessage', 'We are saving the data...please hold');
                  model.set('avatar', newAvatar);
                  model.save().then(function () {
                    self.get('transitionToGuardian')(model);
                  }, function (error) {
                    console.log('Error : ' + error);
                  });
                }, function (error) {
                  console.log('Error : ' + error);
                });
              } else {
                var avatar = store.createRecord('avatar', {
                  id: newAvatarRef,
                  image: image
                });
                avatar.save().then(function (newAvatar) {
                  self.set('successMessage', 'We are saving the data...please hold');
                  model.set('avatar', newAvatar);
                  model.save().then(function () {
                    self.setProperties({ successMessage: "Student Info Saved", errorMessage: '' });
                    // connectedUser.changeYear();
                    setTimeout(function () {
                      self.get('transitionToGuardian')(model);
                    }, 1000);
                  }, function (error) {
                    self.setProperties({ successMessage: '', errorMessage: error });
                  });
                }, function (error) {
                  console.log('Error : ' + error);
                });
              }
            } else if (image === null) {
              var _avatar3 = model.get('avatar');
              _avatar3.then(function (foundAvatar) {
                // foundAvatar.setProperties({ image: model.avatar.image, imagePortrait: model.avatar.imagePortrait, imageLandscape: model.avatar.imageLandscape });
                foundAvatar.save().then(function () {
                  model.save().then(function () {
                    self.setProperties({ successMessage: "Student Info Saved", errorMessage: '' });
                    // connectedUser.changeYear();
                    setTimeout(function () {
                      self.get('transitionToGuardian')(model);
                    }, 1000);
                  }, function (error) {
                    self.setProperties({ successMessage: '', errorMessage: error });
                  });
                }, function (error) {
                  console.log('Error : ' + error);
                });
              }, function (error) {
                console.log('Error : ' + error);
              });
            } else {
              model.save().then(function () {
                self.setProperties({ successMessage: "Student Info Saved", errorMessage: '' });
                // connectedUser.changeYear();
                setTimeout(function () {
                  self.get('transitionToGuardian')(model);
                }, 1000);
              }, function (error) {
                self.setProperties({ successMessage: '', errorMessage: error });
              });
            }
          });
        } else {
          if (image) {
            console.log('there is no model image and we have a image');
            if (imageLandscape) {
              var avatar = store.createRecord('avatar', {
                id: newAvatarRef,
                imageLandscape: true,
                image: image
              });

              avatar.save().then(function (newAvatar) {
                self.set('successMessage', 'We are saving the data...please hold');
                model.set('avatar', newAvatar);
                model.save().then(function () {
                  self.setProperties({ successMessage: "Student Info Saved", errorMessage: '' });
                  // connectedUser.changeYear();
                  setTimeout(function () {
                    self.get('transitionToGuardian')(model);
                  }, 1000);
                }, function (error) {
                  self.setProperties({ successMessage: '', errorMessage: error });
                });
              }, function (error) {
                console.log('Error : ' + error);
              });
            } else if (imagePortrait) {
              var avatar = store.createRecord('avatar', {
                id: newAvatarRef,
                imagePortrait: true,
                image: image
              });

              avatar.save().then(function (newAvatar) {
                self.set('successMessage', 'We are saving the data...please hold');
                model.set('avatar', newAvatar);
                model.save().then(function () {
                  self.setProperties({ successMessage: "Student Info Saved", errorMessage: '' });
                  // connectedUser.changeYear();
                  setTimeout(function () {
                    self.get('transitionToGuardian')(model);
                  }, 1000);
                }, function (error) {
                  self.setProperties({ successMessage: '', errorMessage: error });
                });
              }, function (error) {
                console.log('Error : ' + error);
              });
            } else {
              var avatar = store.createRecord('avatar', {
                id: newAvatarRef,
                image: image
              });
              avatar.save().then(function (newAvatar) {
                self.set('successMessage', 'We are saving the data...please hold');
                model.set('avatar', newAvatar);
                model.save().then(function () {
                  self.setProperties({ successMessage: "Student Info Saved", errorMessage: '' });
                  // connectedUser.changeYear();
                  setTimeout(function () {
                    self.get('transitionToGuardian')(model);
                  }, 1000);
                }, function (error) {
                  self.setProperties({ successMessage: '', errorMessage: error });
                });
              }, function (error) {
                console.log('Error : ' + error);
              });
            }
          } else if (image === null) {
            var _avatar4 = model.get('avatar');
            _avatar4.then(function (foundAvatar) {
              // foundAvatar.setProperties({ image: model.avatar.image, imagePortrait: model.avatar.imagePortrait, imageLandscape: model.avatar.imageLandscape });
              foundAvatar.save().then(function () {
                model.save().then(function () {
                  self.setProperties({ successMessage: "Student Info Saved", errorMessage: '' });
                  connectedUser.changeYear();
                  setTimeout(function () {
                    self.get('transitionToGuardian')(model);
                  }, 1000);
                }, function (error) {
                  self.setProperties({ successMessage: '', errorMessage: error });
                });
              }, function (error) {
                console.log('Error : ' + error);
              });
            }, function (error) {
              console.log('Error : ' + error);
            });
          } else {
            model.save().then(function () {
              self.setProperties({ successMessage: "Student Info Saved", errorMessage: '' });
              connectedUser.changeYear();
              setTimeout(function () {
                self.get('transitionToGuardian')(model);
              }, 1000);
            }, function (error) {
              self.setProperties({ successMessage: '', errorMessage: error });
            });
          }
        }
      },
      addRace: function addRace(race) {
        var raceService = this.get('raceCode');
        var getRaceCode = this.get('getRaceCode');
        var array = this.get('raceArray');
        switch (race) {
          case "isOne":
            array.pushObject(1);
            array.sort();
            break;
          case "isTwo":
            array.pushObject(2);
            array.sort();
            break;
          case "isFour":
            array.pushObject(4);
            array.sort();
            break;
          case "isFive":
            array.pushObject(5);
            array.sort();
            break;
          case "isSix":
            array.pushObject(6);
            array.sort();
            break;
          default:
            break;
        }

        this.toggleProperty(race);
        var isHispanic = this.get('isHispanic');
        var newRaceCode = getRaceCode(raceService, isHispanic, array);
        this.setProperties({ studentRace: newRaceCode });
      },
      removeRace: function removeRace(race) {
        var raceService = this.get('raceCode');
        var getRaceCode = this.get('getRaceCode');
        var array = this.get('raceArray');
        switch (race) {
          case "isOne":
            array.removeObject(1);
            array.sort();
            break;
          case "isTwo":
            array.removeObject(2);
            array.sort();
            break;
          case "isFour":
            array.removeObject(4);
            array.sort();
            break;
          case "isFive":
            array.removeObject(5);
            array.sort();
            break;
          case "isSix":
            array.removeObject(6);
            array.sort();
            break;
          default:
            break;
        }

        this.toggleProperty(race);
        var isHispanic = this.get('isHispanic');
        this.setProperties({ studentRace: getRaceCode(raceService, isHispanic, array) });
      },
      classChanged: function classChanged(record, highlighted) {
        var student = this.get('model');
        // console.log(student);
        if (record.length < highlighted.selected.length) {
          var myArray = [];
          myArray.pushObjects(highlighted.selected);
          myArray.removeObjects(record);
          highlighted.selected.removeObjects(myArray);
          var myClass = myArray.get('firstObject');
          myClass.get('students').removeObject(student);
          myClass.save().then(function (classRemoved) {
            student.get('classes').removeObject(classRemoved);
            student.save();
          });
        } else if (record.length > highlighted.selected.length) {
          var classAdded = highlighted.highlighted;
          console.log(classAdded);
          classAdded.get('students').addObject(student);
          student.save().then(function () {
            classAdded.save().then(function (classSaved) {
              highlighted.selected.addObject(classSaved);
            });
          });
        } else {
          console.log('hopefully nothing changed');
        }
      }
    }
  });
});