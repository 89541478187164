define('sokol-today/components/website/demo/observations/observation-main', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        store: Ember.inject.service(),

        hideSelect: false,
        timerOn: true,

        obsStart: moment(),

        // willDestroyElement() {
        //     if (this.hideSelect === true) {
        //         this.stopTimer();
        //     } else {
        //         this.get('model').destroy();
        //         return;
        //     }
        // },

        walkThrough: Ember.computed('selectedObserveType', function () {
            var observeType = this.get('selectedObserveType');
            if (typeof observeType == 'undefined') {
                return;
            }
            if (observeType.get('name') == 'Walk-though') {
                return true;
            }
        }),

        stopTimer: function stopTimer() {
            this.setProperties({ timerOn: false });
            var store = this.get('store');
            var currentObservation = this.get('model');
            var date = new Date();
            currentObservation.set('timestampEnd', date);
            // currentObservation.save();
        },

        currentTimeMetronome: function currentTimeMetronome() {
            var timerOn = this.get('timerOn');
            // this.setProperties({timerOn:true});
            if (timerOn) {
                var interval = 1000;
                Ember.run.later(this, function () {
                    this.notifyPropertyChange('currentTimePulse');
                    this.currentTimeMetronome();
                }, interval);
            }
        },

        keepTrack: function () {
            var timestart = this.get('obsStart');
            var now = moment();
            var duration = moment.duration(now.diff(timestart));
            return Math.floor(duration.asHours()) + moment.utc(duration.asMilliseconds()).format("[h]:mm[m]:ss[s]");
        }.property('currentTimePulse'),

        observeTypes: Ember.computed('observeType', function () {
            var store = this.get('store');
            return store.findAll('demo/observe-type');
        }),

        teachers: Ember.computed('demo/teacher', function () {
            var store = this.get('store');
            return store.findAll('demo/teacher');
        }),

        actions: {
            hideSelect: function hideSelect(selectedTeacher, selectedObserveType) {
                var store = this.get('store');
                var session = this.get('session');
                var teacher = selectedTeacher;
                console.log(teacher);
                var date = moment();
                this.set('obsStart', date);
                this.currentTimeMetronome();

                if (typeof teacher == 'undefined') {
                    alert('teacher undefined');
                } else {
                    var newObservation = this.get('model');

                    var user = store.findRecord('demo/user', 'sUm3BiDgDcfPo7oEutL1VluTRLQ2');

                    user.then(function (theObserver) {

                        var school = store.findRecord('demo/school', theObserver.get('school.id'));

                        school.then(function (theSchool) {
                            newObservation.set('observer', theObserver);
                            newObservation.set('teacher', teacher);
                            newObservation.set('school', theSchool);
                            newObservation.set('room', teacher.get('room'));
                            newObservation.set('observationType', selectedObserveType);
                            newObservation.set('timestampStart', date);

                            var posIndex = theSchool.get('observationList.id');
                            console.log(posIndex);
                            if (typeof posIndex == 'undefined') {
                                console.log('no ob-index!');
                                var theIndex = store.createRecord('demo/observation-list', {
                                    school: theSchool
                                });

                                theIndex.get('observationBlocks').addObject(newObservation);
                            } else {
                                var theIndex = store.findRecord('demo/observation-list', theSchool.get('observationList.id'));
                                theIndex.then(function (indexLink) {
                                    indexLink.get('observationBlocks').addObject(newObservation);
                                });
                            }
                        });
                    }, function (error) {
                        console.log('Error : ' + error);
                    });
                    this.toggleProperty('hideSelect');
                }
            },
            stopTimer: function stopTimer() {
                this.setProperties({ timerOn: false });
                var store = this.get('store');
                var currentObservation = this.get('model');
                var date = new Date();
                currentObservation.set('timestampEnd', date);
                currentObservation.save();
            }
        }
    });
});