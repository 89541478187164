define('sokol-today/components/dashboard/classes/display-page', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    store: Ember.inject.service(),
    router: Ember.inject.service('-routing'),

    didInsertElement: function didInsertElement() {
      var height = this.$(window).height() * 0.78;
      this.$('section').height(height);
    },


    sortedClasses: Ember.computed.sort('content', 'sortDefinition'),

    content: Ember.computed('connectedUser.currentYear', function () {
      var store = this.get('store');
      var year = this.get('connectedUser.currentYear');
      if (!year) {
        return;
      }
      return store.query('class', {
        orderBy: 'year',
        equalTo: year.get('id')
      });
    }),

    sortByCorrected: Ember.computed('sortBy', function () {
      var string = this.get('sortBy');
      switch (string) {
        case "name.name":
          return 'Class Name';
          break;
        case "staff.firstObject.firstName":
          return 'Staff Name';
          break;
        case "room.name":
          return 'Room';
          break;
        case 'startTime.time':
          return 'Start Time';
          break;
        default:
          return string;
          break;
      }
    }),

    sortBy: 'name.name',
    reverseSort: false,
    sortDefinition: Ember.computed('sortBy', 'reverseSort', function () {
      var sortOrder = this.get('reverseSort') ? 'desc' : 'asc';
      return [this.get('sortBy') + ':' + sortOrder];
    }),

    actions: {
      batchCopy: function batchCopy(classes) {
        var self = this;
        var message = confirm('This will copy all classes in current view to a new year or term of your choosing, do you want to continue?');
        if (message == true) {
          self.get('transitionToBatchCopy')(classes);
        } else {
          console.log('clicked cancel');
        }
      },
      toggleFilterMenu: function toggleFilterMenu() {
        this.toggleProperty('showFilterMenu');
      }
    }
  });
});