define('sokol-today/components/helper/class-day-toggle', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNameBindings: ['active'],
    active: false,

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      var dayID = this.get('day.id');
      var classDays = this.get('model.days').map(function (x) {
        return x.get('id');
      });
      console.log(classDays);
      if (classDays.includes(dayID)) {
        this.toggleProperty('active');
      } else {}
    },


    actions: {
      toggleDay: function toggleDay(day, model) {
        var active = this.get('active');
        if (!active) {
          model.get('days').addObject(day);
        } else {
          model.get('days').popObject(day);
        }
        this.toggleProperty('active');
      }
    }
  });
});