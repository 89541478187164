define('sokol-today/helpers/class-room-observation', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.classRoomObservation = classRoomObservation;
    function classRoomObservation(params) {
        var a = params[0]; //data
        var response = params[1]; //requested report

        var myArray = [];

        for (var i = a.get('length') - 1; i >= 0; i--) {
            switch (response) {
                case "upper-left":
                    if (a.objectAt(i).get('cy') < 140 && a.objectAt(i).get('cx') < 180) {
                        myArray.pushObject(a.objectAt(i));
                    }
                    break;
                case "upper-middle":
                    if (a.objectAt(i).get('cy') < 140 && a.objectAt(i).get('cx') > 180 && a.objectAt(i).get('cx') < 420) {
                        myArray.pushObject(a.objectAt(i));
                    }
                    break;
                case "upper-right":
                    if (a.objectAt(i).get('cy') < 140 && a.objectAt(i).get('cx') > 420) {
                        myArray.pushObject(a.objectAt(i));
                    }
                    break;
                case "middle-left":
                    if (a.objectAt(i).get('cy') > 140 && a.objectAt(i).get('cy') < 400 && a.objectAt(i).get('cx') < 180) {
                        myArray.pushObject(a.objectAt(i));
                    }
                    break;
                case "middle-middle":
                    if (a.objectAt(i).get('cy') > 140 && a.objectAt(i).get('cy') < 400 && a.objectAt(i).get('cx') > 180 && a.objectAt(i).get('cx') < 420) {
                        myArray.pushObject(a.objectAt(i));
                    }
                    break;
                case "middle-right":
                    if (a.objectAt(i).get('cy') > 140 && a.objectAt(i).get('cy') < 400 && a.objectAt(i).get('cx') > 420) {
                        myArray.pushObject(a.objectAt(i));
                    }
                    break;
                case "lower-left":
                    if (a.objectAt(i).get('cy') > 400 && a.objectAt(i).get('cx') < 180) {
                        myArray.pushObject(a.objectAt(i));
                    }
                    break;
                case "lower-middle":
                    if (a.objectAt(i).get('cy') > 400 && a.objectAt(i).get('cx') > 180 && a.objectAt(i).get('cx') < 420) {
                        myArray.pushObject(a.objectAt(i));
                    }
                    break;
                case "lower-right":
                    if (a.objectAt(i).get('cy') > 400 && a.objectAt(i).get('cx') > 420) {
                        myArray.pushObject(a.objectAt(i));
                    }
                    break;
                default:
                    break;
            }
        }
        return myArray.length;
    }

    exports.default = Ember.Helper.helper(classRoomObservation);
});