define('sokol-today/components/helper/card-observation', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        cardFlip: false,

        click: function click() {
            this.toggleProperty('cardFlip');
        }
    });
});