define("sokol-today/components/billing/subscriptions/index-page", ["exports", "accounting/format-money"], function (exports, _formatMoney) {
		"use strict";

		Object.defineProperty(exports, "__esModule", {
				value: true
		});
		exports.default = Ember.Component.extend({
				connectedUser: Ember.inject.service(),
				deleteUser: false,
				undoObjects: Array(),

				moneyFormat: {
						pos: "<green>+%s %v</green>",
						neg: "<red>-%s %v</red>",
						zero: "%s  0.00"
				},

				toggleCheck: Ember.computed('undoObjects', function () {
						console.log('undoObjects has changed');
						var undoObjects = this.get('undoObjects');
						if (undoObjects.length < 1) {
								this.set('showOpss', false);
						}
				}),

				willDestroyElement: function willDestroyElement() {
						var _this = this;
						var memberObjects = this.get('undoObjects');
						var sub = this.get('subscription');
						var sub_id = sub.get('id');
						var accountInfo_id = sub.get('customer.id');
						console.log(Object.keys(memberObjects).length);
						if (Object.keys(memberObjects).length < 1) {
								console.log('no members to update for');
								return;
						}
						$.get({
								url: "https://us-central1-scope-95d64.cloudfunctions.net/removeMemberFromStripeSubscription?sub_id=" + sub_id + '&members=' + JSON.stringify(memberObjects) + '&accountInfoId=' + accountInfo_id + '&cus_id=' + accountInfo_id,
								json: true,
								objectMode: true
						}).done(function (data) {
								memberObjects.clear();
								// $.get({
								//   url: "https://us-central1-scope-95d64.cloudfunctions.net/retrieveUpcomingStripeInvoice?cus_id=" + accountInfo_id + '&sub_id=' + sub_id,
								// }).done(function(data) {								  
								//   if (data.error) {
								//     console.log('error with upcoming Invoice');
								//   } else if (data.success) {
								//     console.log('received upcoming invoice');
								//   }
								// });
						}).fail(function (error) {
								console.log(error);
						});
				},


				actions: {
						unlinkStaff: function unlinkStaff(staff, subscription) {
								var _this = this;
								var undoObject = {};
								var cus_id = subscription.get('customer.id');
								var description = 'Re-link ' + staff.get('firstName') + ' ' + staff.get('lastName') + ' back to the school account?';
								undoObject = { 'staff_id': staff.get('id'), 'districtInfo': cus_id, 'subscription': subscription.get('id'), 'description': description, 'model': staff };
								_this.get('undoObjects').addObject(undoObject);
								subscription.get('users').removeObject(staff);
						},
						removeStaff: function removeStaff(staff, subscription) {
								var _this = this;
								var undoObject = {};
								var cus_id = subscription.get('customer.id');
								var description = 'Add ' + staff.get('firstName') + ' ' + staff.get('lastName') + ' back to the school?';
								undoObject = { 'staff_id': staff.get('id'), 'districtInfo': cus_id, 'subscription': subscription.get('id'), 'description': description, 'model': staff };
								_this.get('undoObjects').addObject(undoObject);
								subscription.get('users').removeObject(staff);
						},
						undo: function undo(object, subscription) {
								var undoObjects = this.get('undoObjects');
								undoObjects.removeObject(object);
								subscription.get('users').addObject(object.model);
								if (undoObjects.length < 1) {
										this.set('showOpps', false);
								}
						},
						toggleShowOpps: function toggleShowOpps() {
								this.toggleProperty('showOpps');
						}
				}
		});
});