define('sokol-today/helpers/tele-formatter', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.teleFormatter = teleFormatter;
	function teleFormatter(params) {
		// console.log(params);
		if (!params[0]) {
			return 'No Data';
		}
		switch (params[0].length) {
			case 7:
				return params[0].substr(0, 3) + "-" + params[0].substr(3, 4);
				break;
			case 10:
				return "(" + params[0].substr(0, 3) + ")" + ' ' + params[0].substr(3, 3) + "-" + params[0].substr(6, 4);
				break;
			case 11:
				return params[0].substr(0, 1) + ' ' + "(" + params[0].substr(1, 3) + ")" + ' ' + params[0].substr(4, 3) + "-" + params[0].substr(7, 4);
				break;
			default:
				return params[0];
		}
	}

	exports.default = Ember.Helper.helper(teleFormatter);
});