define('sokol-today/components/dashboard/observations/observation-edit', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        connectedUser: Ember.inject.service(),
        router: Ember.inject.service('-routing'),
        didInsertElement: function didInsertElement() {
            var height = this.$(window).height() * 0.67;
            this.$('section').height(height);
            this.setProperties({ showParticipation: true });
        },


        actions: {
            finalizeReport: function finalizeReport() {
                this.set('finalizeReport', true);
            },
            back: function back() {
                this.set('finalizeReport', false);
            },
            print: function print() {
                alert('Sorry, but this is coming real soon');
            },
            finalize: function finalize(model, observee, observeeEmail) {
                var router = this.get('router');
                var self = this;
                model.set('finalized', true);
                model.set('dateFinalized', moment());
                model.save().then(function () {
                    if (!observeeEmail) {
                        self.setProperties({ errorMessage: 'The observee member added does not have an email to send to.' });
                    }
                    var observer = self.get('connectedUser.staff');
                    $.get({
                        url: "https://us-central1-scope-95d64.cloudfunctions.net/sendEmailObservationFinal?observee=" + observee.get('id') + '&observeeEmail=' + observeeEmail + '&observer=' + observer.get('firstName')
                    }).done(function (data) {
                        console.log(data);
                        if (data.error) {
                            self.setProperties({ errorMessage: data.error });
                            model.rollbackAttributes();
                            model.save();
                        } else if (data.success) {
                            self.setProperties({ errorMessage: '', successMessage: 'Report finalized and ' + data.success });
                            setTimeout(function () {
                                router.transitionTo('dashboard.observations');
                            }, 1500);
                        }
                    });
                }, function (error) {
                    console.log('Error : ' + error);
                    self.setProperties({ successMessage: '', errorMessage: error });
                });
            }
        }
    });
});