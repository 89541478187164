define('sokol-today/components/account/user/sign-in', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        session: Ember.inject.service(),
        connectedUser: Ember.inject.service(),
        store: Ember.inject.service(),
        firebaseApp: Ember.inject.service(),
        routeHistory: Ember.inject.service(),
        router: Ember.inject.service(),

        willRender: function willRender() {
            var user = this.get('user.isNew');
            var router = this.get('router');
            if (user) {
                router.transitionTo(user);
            }
        },


        actions: {
            signIn: function signIn(email, password) {
                var torii = this.get('session');
                var store = this.get('store');
                var service = this.get('connectedUser');
                console.log(torii);
                store.peekAll('user').forEach(function (r) {
                    r.unloadRecord();
                });
                var route = this.get('router');
                var self = this;
                if (typeof email === 'undefined') {
                    this.setProperties({ errorMessage: 'email cannot be blank' });
                    return;
                }

                if (typeof password === 'undefined') {
                    this.setProperties({ errorMessage: 'password cannot be blank' });
                    return;
                }
                torii.open('firebase', {
                    provider: 'password',
                    email: email,
                    password: password
                }).then(function (d) {
                    // self.toggleProperty('modalSignInDisplay');
                    self.setProperties({ errorMessage: '' });

                    // if(!self.get('routeHistory.previousRoute')){
                    //     route.transitionTo('website.home');
                    // } else {
                    //     route.transitionTo(self.get('routeHistory.previousRoute'));
                    // }

                    var user = store.findRecord('user', d.uid);
                    route.transitionTo('website.home');
                    service.getSchool();
                    return user;
                }, function (error) {
                    self.setProperties({ errorMessage: error.message, login: true, displayChoice: false });
                });
            },
            passwordReset: function passwordReset(email) {
                if (typeof email === 'undefined') {
                    this.setProperties({ errorMessage: 'provide an email to account for password reset' });
                    return;
                }
                var ref = new Firebase("https://project-6125170587165092387.firebaseio.com/");
                ref.resetPassword({
                    email: email
                }, function (error) {
                    if (error === null) {
                        console.log("Password reset email sent successfully");
                    } else {
                        console.log("Error sending password reset email:", error);
                    }
                });
            }
        }
    });
});