define('sokol-today/components/helper/card-assignment', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        store: Ember.inject.service(),
        stepsArray: [],
        movedToClass: false,
        click: function click() {
            this.toggleProperty('cardFlip');
        },


        steps: Ember.computed('assignment.assignmentInfo.steps', function () {
            var self = this;
            var assignmentInfo = this.get('assignment.assignmentInfo');
            return assignmentInfo.then(function (theAssignment) {
                if (!theAssignment) {
                    return;
                }
                var steps = theAssignment.get('steps');
                if (!steps) {
                    return;
                }
                var stepsArray = [];
                for (var i = steps - 1; i >= 0; i--) {
                    stepsArray.push(i);
                }
                self.set('stepsArray', stepsArray.reverse());
            }, function (error) {
                console.log('Error : ' + error);
            });
        }),

        actions: {
            moveToClass: function moveToClass(theClass, assignment) {
                var _this = this;
                // activate button for visual que
                this.toggleProperty('assignmentAdded');
                // prevent card flip
                this.toggleProperty('cardFlip');
                var classAssignment = _this.get('movedToClass');
                if (classAssignment) {
                    theClass.get('classAssignments').removeObject(classAssignment);
                    theClass.save().then(function () {
                        classAssignment.destroyRecord();
                        _this.set('movedToClass', false);
                    }, function (error) {
                        console.log('Error : ' + error);
                    });
                    return;
                }
                var store = this.get('store');
                var myAssignment = store.findRecord('my-assignment', assignment.get('id'));
                myAssignment.then(function (foundAssignment) {
                    var newClassAssignment = store.createRecord('class-assignment', {
                        dueDate: new Date(),
                        myAssignment: foundAssignment,
                        class: theClass
                    });
                    theClass.get('classAssignments').addObject(newClassAssignment);
                    newClassAssignment.save().then(function (savedClassAssignment) {
                        // save new classAssignment model to movedToClass so we can remove it if they decide not to add assignment
                        _this.set('movedToClass', savedClassAssignment);
                        theClass.save();
                    });
                });
            },
            downloadFile: function downloadFile(file) {
                this.toggleProperty('cardFlip');
                window.location = file;
            },
            downloadFiles: function downloadFiles(files) {
                this.toggleProperty('cardFlip');
                var fileLength = files.get('length');
                for (var i = fileLength - 1; i >= 0; i--) {
                    var file = files.objectAt(i).get('filePath');
                    window.location = file;
                }
            }
        }
    });
});