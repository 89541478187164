define('sokol-today/components/dashboard/observations/observations-list', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        store: Ember.inject.service(),
        firebaseApp: Ember.inject.service(),
        showRubricCodes: false,
        showContextMenu: false,
        rightClickMenu: $("#context-menu"),

        codeList: Ember.computed('obs', function () {
            var store = this.get('store');
            var rubricDomains = this.get('obs.observee.profession.rubric.domains');
            console.log(rubricDomains);
            return rubricDomains;
        }),

        didInsertElement: function didInsertElement() {
            this.$('.ember-content-editable').focus();
        },
        focusOut: function focusOut() {
            var data = this.get('data');
            var notes = this.get('notes');
            var rubricCode = this.get('codeHere');
            var origNotes = data.get('notes');
            if (typeof origNotes === 'undefined' || !origNotes) {
                console.log('new note');
                data.set('notes', notes);
                data.save().then(function (savedData) {
                    console.log(savedData);
                    console.log('notes should be saved');
                }, function (error) {
                    console.log('Error : ' + error);
                });
            } else {
                console.log('old note edited');
                data.save().then(function (savedData) {
                    console.log(savedData);
                    console.log('notes should be saved');
                }, function (error) {
                    console.log('Error : ' + error);
                });
            }
            // if (typeof(rubricCode) != 'undefined') {
            //     data.get('rubricCodes').addObject(rubricCode);
            //     data.save().then(function() {
            //         console.log('notes should be saved');
            //     }, function(error) {
            //         console.log('Error : ' + error);
            //     });
            // }
        },
        positionMenu: function positionMenu(e) {
            var menuPosition = this.getPosition(e);
            var menuPositionX = menuPosition.x + "px";
            var menuPositionY = menuPosition.y + "px";

            // this.rightClickMenu.style.left = menuPositionX;
            //  this.rightClickMenu.style.top = menuPositionY;
        },
        getPosition: function getPosition(e) {
            var posx = 0;
            var posy = 0;

            if (!e) var e = window.event;

            if (e.pageX || e.pageY) {
                posx = e.pageX;
                posy = e.pageY;
            } else if (e.clientX || e.clientY) {
                posx = e.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
                posy = e.clientY + document.body.scrollTop + document.documentElement.scrollTop;
            }

            return {
                x: posx,
                y: posy
            };
        },
        contextMenu: function contextMenu(e) {
            this.toggleProperty('showContextMenu');
            e.preventDefault();
            var text = window.getSelection().toString();
            this.positionMenu(e);
            console.log(text);
            // alert('This Could work!');
        },


        keydownMap: {
            8: 'startBackspacing', // backspace
            13: 'selectOption', // return
            16: 'shiftKey',
            27: 'closeDropdown', // escape
            38: 'focusPrevious', // up key
            40: 'focusNext' // down key
        },

        keyDown: function keyDown(e) {
            var map = this.get('keydownMap');
            var code = event.keyCode;
            var method = map[code];
            var firebaseRef = this.get('firebaseApp').database();
            var that = this;
            var store = this.get('store');
            var model = this.get('obs');
            var date = new Date();
            if (method === 'focusNext' && e.shiftKey) {
                var obsRef = firebaseRef.push();
                var newObsRef = 'observation' + obsRef.key;
                var newObservation = store.createRecord('observation', {
                    id: newObsRef,
                    timestamp: date
                });

                var obsBlock = store.findRecord('observation-block', model.get('id'));

                obsBlock.then(function (result) {
                    result.get('observations').addObject(newObservation);
                    result.save().then(function () {}, function (error) {
                        console.log('Error : ' + error);
                    });
                }, function (error) {
                    console.log('Error : ' + error);
                });
                $(that).next('.ember-content-editable').focus();
            }
        }
    });
});