define('sokol-today/models/avatar', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    imageLandscape: _emberData.default.attr('boolean', { defaultValue: false }),
    imagePortrait: _emberData.default.attr('boolean', { defaultValue: false }),
    image: _emberData.default.attr('string')
  });
});