define('sokol-today/models/class', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    students: _emberData.default.hasMany('student', { async: true }),
    classAssignments: _emberData.default.hasMany('class-assignment', { async: true }),
    year: _emberData.default.belongsTo('year', { async: true }),
    photos: _emberData.default.hasMany('photos', { async: true }),
    name: _emberData.default.belongsTo('course-info', { async: true }),
    startTime: _emberData.default.belongsTo('time', { async: true }),
    room: _emberData.default.belongsTo('room', { async: true }),
    endTime: _emberData.default.belongsTo('time', { async: true }),
    school: _emberData.default.belongsTo('school', { async: true }),
    days: _emberData.default.hasMany('day-list', { async: true }),
    staff: _emberData.default.hasMany('staff', { async: true, inverse: 'classes' }),
    subject: _emberData.default.belongsTo('subject', { async: true }),
    periods: _emberData.default.hasMany('period', { async: true, inverse: 'classes' }),
    finalized: _emberData.default.attr('boolean', { defaultValue: false }),
    gradeWeight: _emberData.default.belongsTo('class-grade-weight'),
    isWeighted: _emberData.default.attr('boolean')
  });
});