define('sokol-today/components/dashboard/settings/index/school/school-display', ['exports'], function (exports) {
		'use strict';

		Object.defineProperty(exports, "__esModule", {
				value: true
		});
		exports.default = Ember.Component.extend({
				connectedUser: Ember.inject.service(),
				router: Ember.inject.service(),
				store: Ember.inject.service(),
				sortPeriods: ['order'],
				didInsertElement: function didInsertElement() {
						var _this = this;
						if (!this.settingsForClass) {
								_this.get('connectedUser.account.classes').then(function (classList) {
										_this.set('settingsForClass', classList.get('lastObject'));
								}).catch(function (error) {
										console.log(error);
								});
						}
				},


				isWeightingDirty: Ember.computed('settingsForClass.gradeWeight', function () {
						var changedAttr = this.get('settingsForClass').changedAttributes();
						console.log(changedAttr);
						return !!changedAttr.gradeWeight;
				}),

				times: Ember.computed('', function () {
						var store = this.get('store');
						return store.findAll('time');
				}),

				gradeWeightOptions: Ember.computed('', function () {
						var store = this.get('store');
						return store.findAll('class-grade-weight');
				}),

				handleSchoolTypeChange: Ember.computed('schoolType', function () {
						var schoolType = this.get('schoolType');
						var model = this.get('model');
						model.setProperties({ elementarySchool: false, gradeSchool: false, highSchool: false, college: false });
						switch (schoolType) {
								case 'elementarySchool':
										return model.set('elementarySchool', true);
								case 'gradeSchool':
										return model.set('gradeSchool', true);
								case 'highSchool':
										return model.set('highSchool', true);
								case 'college':
										return model.set('college', true);
								default:
										console.log('rut row');
										return;
						}
				}),

				schoolType: Ember.computed('model', function () {
						if (this.get('model.gradeSchool')) {
								return 'gradeSchool';
						}
						if (this.get('model.elementarySchool')) {
								return 'elementarySchool';
						}
						if (this.get('model.college')) {
								return 'college';
						}
						if (this.get('model.highSchool')) {
								return 'highSchool';
						}
				}),

				dayPeriods: Ember.computed.sort('connectedUser.currentYear.daySchedule', 'sortPeriods'),

				actions: {
						getSchool: function getSchool(staff) {
								var _this = this;
								var router = _this.get('router');
								var service = _this.get('connectedUser');
								service.getStaff(staff);
								router.transitionTo('dashboard.settings.user', staff);
						},
						moveUp: function moveUp(item) {
								var itemA = item;
								var itemB = this.get('dayPeriods').objectAt(item.get('order') - 1);
								if (!itemB) {
										return;
								}
								itemB.incrementProperty('order');
								itemA.decrementProperty('order');
								itemA.save();
								itemB.save();
						},
						moveDown: function moveDown(item) {
								var itemA = item;
								var itemB = this.get('dayPeriods').objectAt(item.get('order') + 1);
								if (!itemB) {
										return;
								}
								itemA.incrementProperty('order');
								itemB.decrementProperty('order');
								itemA.save();
								itemB.save();
						},
						save: function save(model) {
								var _this = this;
								model.save().then(function () {
										_this.setProperties({ successMessage: 'Save Successful', success: true });
										setTimeout(function () {
												_this.setProperties({ success: false });
										}, 3000);
								}, function (error) {
										console.log('Error : ' + error);
										_this.setProperties({ errorMessage: error, error: true });
										setTimeout(function () {
												_this.setProperties({ error: false });
										}, 3000);
								});
						},
						updateDays: function updateDays() {
								var service = this.get('connectedUser');
								service.currentYearDays(service.get('currentYear.firstDay'), service.get('currentYear.lastDay'));
						},
						toggleAddPeriod: function toggleAddPeriod() {
								this.toggleProperty('addPeriod');
								this.setProperties({ successMessage: false, errorMessage: false });
						},
						addPeriod: function addPeriod(year, daySchedule, start, end) {
								var _this = this;
								if (!start || !end) {
										_this.set('errorMessage', 'Most have a start and end time chosen');
										return;
								}
								var store = this.get('store');
								var startDate = moment(start.get('time'), 'h:mm a');
								var endDate = moment(end.get('time'), 'h:mm a');
								var order = daySchedule.get('length');

								if (!startDate.isBefore(endDate)) {
										console.log('woops');
										_this.setProperties({ errorMessage: 'Start time must be before end time' });
										return;
								} else {
										console.log('so far so good');
										var period = store.createRecord('day-time-breakdown', {
												begins: start,
												ends: end,
												order: order
										});
										console.log(period);
										period.save().then(function (savedPeriod) {
												daySchedule.addObject(savedPeriod);
												year.save().then(function () {
														_this.setProperties({ successMessage: 'Period Created', addPeriod: false });
														setTimeout(function () {
																_this.setProperties({ successMessage: false, addPeriod: false });
														}, 3000);
												}).catch(function (error) {
														_this.setProperties({ errorMessage: error });
												});
										}).catch(function (error) {
												_this.setProperties({ errorMessage: error });
										});
								}
						}
				}
		});
});