define('sokol-today/components/pricing/administrator-accounts', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        connectedUser: Ember.inject.service(),

        accountType: Ember.computed('connectedUser.staff.accountInfo.accountType', function () {
            var account = this.get('connectedUser.staff.accountInfo.accountType');

            return account.then(function (accountType) {
                if (accountType) {
                    switch (accountType) {
                        case "Personal Admin":
                            return { personalAdmin: true };
                            break;
                        case "Personal Teacher":
                            return { personalTeacher: true };
                            break;
                        default:
                            return { unknown: true };
                            break;
                    }
                }
            }, function (error) {
                console.log('Error : ' + error);
            });
        }),

        actions: {
            processStripeToken: function processStripeToken(plan, token) {
                console.log(token);
                console.log(plan);
            },
            toggleMonthly: function toggleMonthly() {
                this.toggleProperty('monthly');
            }
        }
    });
});