define('sokol-today/components/helper/card-year', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    cardFlip: false,
    // firebase: Ember.inject.service(),
    connectedUser: Ember.inject.service(),
    router: Ember.inject.service('-routing'),
    store: Ember.inject.service(),
    classNumber: null,
    students: [],

    classes: Ember.computed('year.periods.@each.classes', function () {
      var _this = this;
      var year = this.get('year');
      var store = this.get('store');
      console.log(year.get('id'));
      var classes = store.query('class', {
        orderBy: 'year',
        equalTo: year.get('id')
      }).catch(function (error) {
        console.log(error);
      });
      classes.then(function (response) {
        _this.set('classNumber', response);
      });
    }),

    studentsNew: Ember.computed('', function () {
      this.set('students', []);
      var _this = this,
          store = this.get('store'),
          year = this.get('year.id'),
          school = this.get('connectedUser.school.id'),
          studentsQuery = store.query('student', {
        orderBy: 'school',
        equalTo: school
      });

      studentsQuery.then(function (response) {
        response.forEach(function (student) {
          student.get('years').forEach(function (years) {
            if (years.get('id') === year) {
              _this.get('students').addObject(student);
              console.log(_this.get('students'));
            } else {
              console.log(false);
              return false;
            }
          });
        });
      });
    }),
    click: function click() {
      this.toggleProperty('cardFlip');
    },


    actions: {}
  });
});