define('sokol-today/components/dashboard/courses/add/step-four', ['exports'], function (exports) {
		'use strict';

		Object.defineProperty(exports, "__esModule", {
				value: true
		});
		exports.default = Ember.Component.extend({
				stepIndicator: Ember.inject.service(),
				connectedUser: Ember.inject.service(),
				store: Ember.inject.service(),
				router: Ember.inject.service('-routing'),

				didInsertElement: function didInsertElement() {
						var step = this.get('stepIndicator');
						step.setProperties({ stepOne: true, stepTwo: true, stepThree: true, stepFour: false });
				},


				actions: {
						save: function save(course, courseList, school) {
								var _this = this;
								var router = this.get('router');
								var store = this.get('store');
								var subject = course.get('subject');
								course.set('school', school);
								course.save().then(function (courseSaved) {
										store.find('course-list', courseList.get('id')).then(function (courseListModel) {
												courseListModel.get('courses').addObject(courseSaved);
												courseListModel.save().then(function () {
														store.find('subject', subject.get('id')).then(function (subjectModel) {
																subjectModel.get('courses').addObject(courseSaved);
																subjectModel.save().then(function () {
																		_this.setProperties({ errorMessage: '', successMessage: 'Course Saved' });
																		setTimeout(function () {
																				router.transitionTo('dashboard.courses');
																		}, 1000);
																}, function (error) {
																		_this.setProperties({ errorMessage: error });
																		console.log('Error : ' + error);
																});
														}, function (error) {
																_this.setProperties({ errorMessage: error });
																console.log('Error : ' + error);
														});
												}, function (error) {
														console.log('Error : ' + error);
														_this.setProperties({ errorMessage: error });
												});
										}, function (error) {
												console.log('Error : ' + error);
												_this.setProperties({ errorMessage: error });
										});
								});
						}
				}
		});
});