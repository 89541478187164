define('sokol-today/routes/dashboard/cloud-files/index', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		connectedUser: Ember.inject.service(),
		firebaseApp: Ember.inject.service(),

		model: function model() {
			var self = this;
			var store = this.get('store');
			var user = this.get('connectedUser.user');
			return user.then(function (theUser) {
				var storageId = theUser.get('cloudStorage.id');
				return store.findRecord('folder', storageId);
			}, function (error) {
				console.log('Error : ' + error);
			});
		}
	});
});