define('sokol-today/components/dashboard/staff/display-page', ['exports'], function (exports) {
		'use strict';

		Object.defineProperty(exports, "__esModule", {
				value: true
		});
		exports.default = Ember.Component.extend({
				connectedUser: Ember.inject.service(),
				store: Ember.inject.service(),

				didInsertElement: function didInsertElement() {
						var height = this.$(window).height() * 0.80;
						this.$('section').height(height);
				},


				sortByCorrected: Ember.computed('sortBy', function () {
						var string = this.get('sortBy');
						switch (string) {
								case "lastName":
										return 'Name';
										break;
								case "profession.name":
										return 'Profession';
										break;
								case "started":
										return "Start Date";
										break;
								default:
										return string;
										break;
						}
				}),
				sortedStaff: Ember.computed.sort('connectedUser.school.staffList.staff', 'sortDefinition'),
				sortBy: 'lastName',
				reverseSort: false,
				sortDefinition: Ember.computed('sortBy', 'reverseSort', function () {
						var sortOrder = this.get('reverseSort') ? 'desc' : 'asc';
						return [this.get('sortBy') + ':' + sortOrder];
				}),
				actions: {
						toggleSortMenu: function toggleSortMenu() {
								this.toggleProperty('sortMenu');
						},
						toggleFilterMenu: function toggleFilterMenu() {
								this.toggleProperty('showFilterMenu');
						}
				}
		});
});