define('sokol-today/models/user', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        avatar: _emberData.default.belongsTo('avatar', { async: true }),
        cloudStorage: _emberData.default.belongsTo('folder'),
        userSince: _emberData.default.attr('date', { defaultValue: new Date() }),
        staff: _emberData.default.hasMany('staff', { async: true }),
        students: _emberData.default.hasMany('student', { async: true }),
        accountInfo: _emberData.default.belongsTo('account-info'),
        paymentHistory: _emberData.default.hasMany('payment-history'), //Going to depricate this and move to account-info
        role: _emberData.default.attr('number'), //Going to depricate this and move to each teacher admin model
        firstName: _emberData.default.attr('string'),
        lastName: _emberData.default.attr('string'),
        photoLibrary: _emberData.default.belongsTo('photoLibrary', { async: true, inverse: 'user' }),
        betaTester: _emberData.default.attr('boolean'),
        activity: _emberData.default.attr('date'),
        defaultStaff: _emberData.default.belongsTo('staff', { async: true, inverse: 'user' }),
        defaultStudent: _emberData.default.belongsTo('student', { async: true, inverse: 'user' }),
        defaultAccount: _emberData.default.attr('string'),
        schools: _emberData.default.hasMany('school', { async: true }),
        isNew: _emberData.default.attr('string'),
        email: _emberData.default.attr('string'),
        interestFrom: _emberData.default.attr('string'),
        //pin to connect user to correct teacher/student/admin account
        pin: _emberData.default.attr('string'),
        //check student role privledges
        waiting: Ember.computed('role', function () {
            var role = this.get('role');
            switch (role) {
                case 0:
                    return true;
                default:
                    return false;
            }
        }).property('role'),
        student: Ember.computed('role', function () {
            var role = this.get('role');
            switch (role) {
                case 60:
                    return true;
                default:
                    return false;
            }
        }).property('role'),
        //check parent role privledges
        parent: Ember.computed('role', function () {
            var role = this.get('role');
            switch (role) {
                case 70:
                    return true;
                default:
                    return false;
            }
        }).property('role'),
        //check teacher role privledges
        teacher: Ember.computed('role', function () {
            var role = this.get('role');
            switch (role) {
                case 80:
                case 81:
                case 82:
                case 83:
                case 84:
                case 85:
                case 86:
                case 87:
                case 88:
                case 89:
                    return true;
                default:
                    return false;
            }
            this.notifyPropertyChange('role');
        }).property('role'),
        teacherPersonal: Ember.computed('role', function () {
            var role = this.get('role');
            switch (role) {
                case 89:
                    return true;
                default:
                    return false;
            }
            this.notifyPropertyChange('role');
        }).property('role'),
        admin: Ember.computed('role', function () {
            var role = this.get('role');
            switch (role) {
                case 89:
                case 90:
                case 91:
                case 92:
                case 93:
                case 94:
                case 95:
                case 96:
                case 97:
                case 98:
                case 99:
                    return true;
                default:
                    return false;
            }
            this.notifyPropertyChange('role');
        }).property('role'),
        adminPersonal: Ember.computed('role', function () {
            var role = this.get('role');
            switch (role) {
                case 90:
                    return true;
                default:
                    return false;
            }
            this.notifyPropertyChange('role');
        }).property('role'),
        superUser: Ember.computed('role', function () {
            var role = this.get('role');
            switch (role) {
                case 99:
                    return true;
                default:
                    return false;
            }
            this.notifyPropertyChange('role');
        }).property('role')
    });
});