define('sokol-today/routes/account/profile', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		model: function model(params) {
			var self = this;
			return this.store.findRecord('user', params.user_id).then(function (theUser) {
				return theUser;
			}, function (error) {
				self.transitionTo('website.home');
			});
		}
	});
});