define('sokol-today/models/period', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    classes: _emberData.default.hasMany('class', { async: true, inverse: 'periods' }),
    name: _emberData.default.attr('string')
    // searchName: Ember.computed('name', function(){
    // 	return this.get('name').replace(' ', '-');
    // }),
  });
});