define('sokol-today/controllers/dashboard/classes/add/step-one', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		actions: {
			transitionToStepTwo: function transitionToStepTwo(theClass) {
				this.transitionToRoute('dashboard.classes.add.step-two', theClass.id);
			}
		}
	});
});