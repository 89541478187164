define('sokol-today/components/dashboard/setup/step-one', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        connectedUser: Ember.inject.service(),
        firebaseApp: Ember.inject.service(),
        store: Ember.inject.service(),
        router: Ember.inject.service('-routing'),

        minDate: Ember.computed('firstDay', function () {
            var date = this.get('firstDay');
            if (date) {
                return moment(date, "string").add(1, "Y").subtract(2, "M").subtract(15, "d").toDate();
            } else {
                return "June 6";
            }
        }).property('firstDay'),

        actions: {
            saveInfo: function saveInfo(termNumber, termName, firstDay, lastDay) {
                this.get('connectedUser').getSchool();
                var firebaseRef = this.get('firebaseApp').database();
                var store = this.get('store');
                var school = this.get('connectedUser.staff.school');
                var user = this.get('connectedUser.user');
                var router = this.get('router');
                school.then(function (foundSchool) {
                    foundSchool.get('termList').then(function (termList) {
                        termList.get('terms').clear();
                        foundSchool.get('yearList').then(function (yearList) {
                            var yearRef = firebaseRef.push();
                            var newYearRef = 'year' + yearRef.key;
                            var year = store.createRecord('year', {
                                id: newYearRef,
                                year: moment(firstDay).year() + '-' + moment(lastDay).year(),
                                firstDay: firstDay,
                                lastDay: lastDay,
                                school: foundSchool
                            });
                            yearList.get('years').addObject(year);
                            yearList.save().then(function (savedList) {
                                year.save().then(function () {
                                    var _loop = function _loop() {
                                        var name = termName.capitalize() + ' ' + i;
                                        var termRef = firebaseRef.push();
                                        var newTermRef = 'term' + termRef.key;
                                        var term = store.createRecord('term', {
                                            id: newTermRef,
                                            name: name
                                        });
                                        termList.get('terms').addObject(term);
                                        termList.save().then(function () {
                                            term.save();
                                            var periodRef = firebaseRef.push();
                                            var newPeriodRef = 'period' + periodRef.key;
                                            var period = store.createRecord('period', {
                                                id: newPeriodRef,
                                                name: name
                                            });
                                            period.save().then(function (savedPeriod) {
                                                foundSchool.get('yearList').then(function (yearList) {
                                                    year.get('periods').addObject(savedPeriod);
                                                    year.save().then(function () {}, function (error) {
                                                        console.log('Error : ' + error);
                                                    });
                                                }, function (error) {
                                                    console.log('Error : ' + error);
                                                });
                                            }, function (error) {
                                                console.log('Error : ' + error);
                                            });
                                        }, function (error) {
                                            console.log('Error : ' + error);
                                        });
                                    };

                                    for (var i = termNumber; i >= 1; i--) {
                                        _loop();
                                    }
                                    user.then(function (foundUser) {
                                        foundUser.set('isNew', null);
                                        foundUser.save().then(function () {
                                            router.transitionTo('dashboard.index');
                                        }, function (error) {
                                            console.log('Error : ' + error);
                                        });
                                    }, function (error) {
                                        console.log('Error : ' + error);
                                    });
                                }, function (error) {
                                    console.log('Error : ' + error);
                                });
                            }, function (error) {
                                console.log('Error : ' + error);
                            });
                        }, function (error) {
                            console.log('Error : ' + error);
                        });
                    }, function (error) {
                        console.log('Error : ' + error);
                    });
                }, function (error) {
                    console.log('Error : ' + error);
                });
            }
        }
    });
});