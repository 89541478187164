define("sokol-today/helpers/date-compare-by", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.dateCompareBy = dateCompareBy;
    function dateCompareBy(params /*, hash*/) {
        var a = moment(params[0]).unix(),
            b = moment(params[2]).unix(),
            compareBy = params[1],
            count = params[3],
            dateArray = [];

        if (!b || b == "now") {
            b = moment().unix() - 172800;
        }
        if (!count || !a || !b) {
            switch (compareBy) {
                case "==":
                    if (a == b) {
                        // console.log('equal dates');
                        dateArray.pushObject(a);
                    }
                    break;
                case ">=":
                    if (a >= b) {
                        // console.log('greather than equal to');
                        dateArray.pushObject(a);
                    }
                    break;
                case "<=":
                    if (a <= b) {
                        // console.log('less than equal to');
                        dateArray.pushObject(a);
                    }
                    break;
                case "<":
                    if (a < b) {
                        // console.log('less than');
                        dateArray.pushObject(a);
                    }
                    break;
                case ">":
                    if (a > b) {
                        // console.log('greater than');
                        dateArray.pushObject(a);
                    }
                    break;
                default:
                    break;
            }
        }

        return dateArray;
    }

    exports.default = Ember.Helper.helper(dateCompareBy);
});