define('sokol-today/components/account/user/user-create/step-four/display-page', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    // newUser:Ember.inject.service(),

    // didInsertElement(){
    //        var newUser = this.get('newUser');
    //        newUser.setProperties({hasUser:true, hasType:true, hasAccountType:true});
    //    },
  });
});