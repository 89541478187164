define('sokol-today/components/dashboard/years/edit/step-one', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        connectedUser: Ember.inject.service(),
        store: Ember.inject.service(),
        changeYear: true,
        classNumber: null,

        classes: Ember.computed('model.periods.@each.classes', function () {
            var self = this,
                year = this.get('model');
            year.get('periods').then(function (periods) {
                var myArray = [];
                for (var i = periods.get('length') - 1; i >= 0; i--) {
                    var numberClasses = periods.objectAt(i).get('classes.length');
                    myArray.pushObject(numberClasses);
                }
                var totalClasses = myArray.compact().reduce(function (acc, val) {
                    return acc + val;
                }, 0);
                self.set('classNumber', totalClasses);
            });
        }),
        actions: {
            back: function back() {
                this.get('transitionToDashboard')();
            },
            deleteYear: function deleteYear() {
                this.set('changeYear', false);
            },
            submitChanges: function submitChanges(model) {
                var self = this;
                model.save().then(function () {
                    self.setProperties({ successMessage: 'Year info saved', errorMessage: null });
                    setTimeout(function () {
                        self.get('transitionToDashboard')();
                    }, 2000);
                }, function (error) {
                    console.log('Error : ' + error);
                });
            },
            confirmDelete: function confirmDelete(model) {
                var self = this,
                    periods = model.get('periods'),
                    yearList = model.get('school.yearList'),
                    store = this.get('store');

                yearList.then(function (foundYearList) {
                    foundYearList.get('years').removeObject(model);
                    foundYearList.save();
                    for (var i = periods.get('length') - 1; i >= 0; i--) {
                        var periodModel = store.peekRecord('period', periods.objectAt(i).get('id'));
                        periodModel.destroyRecord();
                    }
                    model.destroyRecord().then(function () {
                        self.setProperties({ successMessage: 'Year deleted', errorMessage: null });
                        self.get('connectedUser').changeYear();
                        setTimeout(function () {
                            self.get('transitionToDashboard')();
                        }, 2000);
                    });
                }, function (error) {
                    console.log('Error : ' + error);
                });
            },
            woops: function woops() {
                this.set('changeYear', true);
            }
        }
    });
});