define('sokol-today/routes/dashboard/settings/user', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      console.log(params);
      return this.get('store').findRecord('staff', params.staff_id);
    }
  });
});