define('sokol-today/models/demo/admin-list', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    school: _emberData.default.belongsTo('demo/school', { async: true }),
    admins: _emberData.default.hasMany('demo/admin', { async: true })
  });
});