define('sokol-today/models/demo/admin', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        firstName: _emberData.default.attr('string'),
        lastName: _emberData.default.attr('string'),
        birthday: _emberData.default.attr('date'),
        observed: _emberData.default.hasMany('demo/observation-block', { async: true }),
        started: _emberData.default.attr('date'),
        room: _emberData.default.belongsTo('demo/room'),
        pin: _emberData.default.attr('string'),
        school: _emberData.default.belongsTo('demo/school'),
        profession: _emberData.default.belongsTo('demo/profession', { async: true }),
        avatar: _emberData.default.belongsTo('demo/avatar', { async: true })
    });
});