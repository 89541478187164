define("sokol-today/components/dashboard/settings/billing/subscriptions/subscription/invoice-item", ["exports", "accounting/format-money"], function (exports, _formatMoney) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		connectedUser: Ember.inject.service(),
		showInvoiceItems: false,
		hasMore: false,

		moneyFormat: {
			pos: "<green>+%s %v</green>",
			neg: "<red>-%s %v</red>",
			zero: "%s  0.00"
		},

		actions: {
			toggleShowInvoiceItems: function toggleShowInvoiceItems() {
				this.toggleProperty('showInvoiceItems');
			},
			getInvoiceItems: function getInvoiceItems(model, invoiceId, start_after_item) {
				var _this = this;
				_this.set('fetching', true);
				$.get({ url: 'https://us-central1-scope-95d64.cloudfunctions.net/retrieveInvoiceItems?sub_id=' + invoiceId + '&last_object=' + start_after_item
				}).done(function (data) {
					_this.setProperties({ hasMore: false });
					if (data.has_more) {
						_this.setProperties({ hasMore: true });
					}
					var invoiceItems = model.get('lines.data');
					for (var i = data.data.length - 1; i >= 0; i--) {
						invoiceItems.addObject(data.data[i]);
					}
				}).fail(function (error) {
					console.log(error);
				}).always(function () {
					_this.set('fetching', false);
				});
			}
		}
	});
});