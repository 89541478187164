define('sokol-today/models/demo/assignment', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		dateAssigned: _emberData.default.attr('date'),
		dateDue: _emberData.default.attr('date'),
		subject: _emberData.default.attr('string'),
		grade: _emberData.default.attr('string'),
		completed: _emberData.default.attr('boolean'),
		//myAssignment is a directory for each teacher to keep/store/distribute assignments to students
		theAssignment: _emberData.default.belongsTo('demo/myAssignment'),
		//chats is for students to ask teachers in private questions
		chats: _emberData.default.hasMany('chat', { async: true })
	});
});