define('sokol-today/models/invoice', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        object: _emberData.default.attr('string'),
        amount_due: _emberData.default.attr('number'),
        application_fee: _emberData.default.attr('number'),
        attempt_count: _emberData.default.attr('number'),
        attempted: _emberData.default.attr('boolean'),
        charge: _emberData.default.belongsTo('charge', { async: true }),
        closed: _emberData.default.attr('boolean'),
        currency: _emberData.default.attr('string'),
        customer: _emberData.default.attr('string'),
        date: _emberData.default.attr('date'),
        description: _emberData.default.attr('string'),
        discount: _emberData.default.attr('string'),
        ending_balance: _emberData.default.attr('number'),
        forgiven: _emberData.default.attr('boolean'),
        lines: _emberData.default.attr(),
        livemode: _emberData.default.attr('boolean'),
        metadata: _emberData.default.attr(),
        next_payment_attempt: _emberData.default.attr('string'),
        paid: _emberData.default.attr('boolean'),
        period_end: _emberData.default.attr('date'),
        period_start: _emberData.default.attr('date'),
        receipt_number: _emberData.default.attr('string'),
        starting_balance: _emberData.default.attr('number'),
        statement_descriptor: _emberData.default.attr('string'),
        subscription: _emberData.default.belongsTo('subscription', { async: true }),
        subtotal: _emberData.default.attr('number'),
        tax: _emberData.default.attr('number'),
        tax_percent: _emberData.default.attr('number'),
        total: _emberData.default.attr('number')
    });
});