define('sokol-today/routes/account/user-create-with-pin/step-four', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		model: function model(params) {
			return this.store.findRecord('staff', params.staff_id);
		}
	});
});