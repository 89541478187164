define('sokol-today/controllers/account/user-create-with-pin/step-two', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		actions: {
			transitionToStepThree: function transitionToStepThree(theStaff) {
				this.transitionToRoute('account.user-create-with-pin.step-three', theStaff.id);
			}
		}
	});
});