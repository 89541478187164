define('sokol-today/routes/application', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    connectedUser: Ember.inject.service(),
    firebaseApp: Ember.inject.service(),
    beforeModel: function beforeModel() {
      // this._super();
      return this.get("session").fetch().catch(function () {});
    },
    afterModel: function afterModel() {
      var firebase = this.get('firebaseApp');
      var self = this;
      var session = this.get('session');
      if (!session || typeof session == 'undefined' || !session.content.isAuthenticated) {
        console.log('woops!!!');
        return;
      }
      var date = new Date();
      var user = this.store.findRecord('user', session.content.uid);
      user.then(function (foundUser) {
        var staff = foundUser.get('defaultAccount');
        if (!staff) {
          return;
        }
        // since I can connect from multiple devices or browser tabs, we store each connection instance separately
        // any time that connectionsRef's value is null (i.e. has no children) I am offline
        var myConnectionsRef = firebase.database().ref('connections/');
        var connectionHistoryRef = firebase.database().ref('connectionHistorys/');
        var myStaffRef = firebase.database().ref('staffs/' + staff);
        var newConnectionHistory = connectionHistoryRef.push();
        // stores the timestamp of my last disconnect (the last time I was seen online)
        var lastUserOnline = firebase.database().ref('users/' + foundUser.id);

        var connectedRef = firebase.database().ref('.info/connected');
        connectedRef.on('value', function (snap) {
          if (snap.val() === true) {
            // We're connected (or reconnected)! Do anything here that should happen only if online (or on reconnect)

            // add this device to my connections list
            // this value could contain info about the device or a timestamp too
            var ua = navigator.userAgent;
            console.log(ua);
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS|Phone/i.test(ua)) {
              //mobile device
              console.log('mobile device');
              var _date2 = new Date();
              var _staff = myStaffRef.child('connection').push(true);

              myConnectionsRef.child(_staff.key).update({
                device: 'mobile',
                time: _date2
              });
              newConnectionHistory.update({
                device: 'mobile',
                timeOn: _date2,
                user: foundUser.get('id'),
                account: myStaffRef.key
              });
              // when I disconnect, remove this device
              myConnectionsRef.child(_staff.key).onDisconnect().remove();
              _staff.onDisconnect().remove();
              newConnectionHistory.onDisconnect().update({
                timeOff: new Date()
              });
            } else if (/OS X|Windows|Linux/i.test(ua)) {
              //desktop
              console.log('signed in desktop');
              var _date3 = new Date();
              var _staff2 = myStaffRef.child('connection').push(true);
              console.log(_staff2.key);
              myConnectionsRef.child(_staff2.key).update({
                device: 'desktop',
                time: _date3
              });
              newConnectionHistory.update({
                device: 'desktop',
                timeOn: _date3,
                user: foundUser.get('id'),
                account: myStaffRef.key
              });
              // console.log(staff);
              // when I disconnect, remove this device
              myConnectionsRef.child(_staff2.key).onDisconnect().remove();
              _staff2.onDisconnect().remove();
              newConnectionHistory.onDisconnect().update({
                timeOff: new Date()
              });
            } else if (/tvOS|xbox/i.test(ua)) {
              //tv platforms
              var _date4 = new Date();
              var _staff3 = myStaffRef.child('connection').push(true);

              myConnectionsRef.child(_staff3.key).update({
                device: 'tv platforms',
                time: _date4
              });

              newConnectionHistory.update({
                device: 'tv platforms',
                timeOn: _date4,
                user: foundUser.get('id'),
                account: _staff3.key
              });
              // when I disconnect, remove this device
              myConnectionsRef.child(_staff3.key).onDisconnect().remove();
              _staff3.onDisconnect().remove();
              newConnectionHistory.onDisconnect().update({
                timeOff: new Date()
              });
            }

            var _date = new Date();
            // when I disconnect, update the last time I was seen online
            myStaffRef.onDisconnect().update({ lastOnline: _date });
            lastUserOnline.onDisconnect().update({ activity: _date });
          }
        }, function (error) {
          console.log('Error : ' + error);
        });
      }, function (error) {
        console.log('Error : ' + error);
      });
    }
  });
});