define('sokol-today/components/helper/card-staff', ['exports'], function (exports) {
		'use strict';

		Object.defineProperty(exports, "__esModule", {
				value: true
		});
		exports.default = Ember.Component.extend({
				connectedUser: Ember.inject.service(),
				router: Ember.inject.service(),
				cardFlip: false,
				studentsNumber: null,
				quoteText: null,

				click: function click() {
						this.toggleProperty('cardFlip');
				},


				quote: Ember.computed(function () {
						var _this = this;
						return $.get({
								url: "http://quotesondesign.com/wp-json/posts?filter[orderby]=rand&filter[posts_per_page]=1"
						}).done(function (data) {
								if (data) {
										var obj = {};
										obj['title'] = data[0].title;
										obj['content'] = data[0].content;
										_this.set('quoteText', obj);
								}

								console.log(_this.get('quoteText'));
						});
				}),

				classes: Ember.computed.filter('staff.classes.@each.year', function (classYear) {
						var year = this.get('connectedUser.currentYear');
						if (!year) {
								return;
						}
						return classYear.get('year.id') === year.get('id');
				}).property('connectedUser.currentYear'),

				studentCount: Ember.computed('classes.@each.students', function () {
						var self = this;
						var classes = this.get('classes');
						if (!classes) {
								return;
						}
						var studentArray = [];
						for (var i = classes.get('length') - 1; i >= 0; i--) {
								studentArray.pushObject(classes.objectAt(i).get('students.length'));
						}
						var totalStudents = studentArray.compact().reduce(function (acc, val) {
								return acc + val;
						}, 0);
						self.set('studentsNumber', totalStudents);
				}),

				// students: Ember.computed.sum('studentsNumber'),

				actions: {
						getClass: function getClass(theClass) {
								var classInfo = this.get('connectedUser');
								var router = this.get('router');
								classInfo.changeClass(theClass);
								this.set('selectedClass', theClass);
								// classInfo.setProperties({currentYear:theClass.year, currentPeriod: theClass.period, currentClass:theClass });
								router.transitionTo('dashboard.classes');
						}
				}
		});
});