define('sokol-today/components/dashboard/years/year-display', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		absenceArray: null,
		absences: Ember.computed('model.school.absenceList.absences', function () {
			var self = this;
			var model = this.get('model');
			var absences = model.get('school').then(function (result) {
				return result.get('absenceList');
			}).then(function (absenceList) {
				return absenceList.get('absences');
			});
			return absences.then(function (result) {
				var absences = result.get('length');
				var myArray = [];
				for (var i = absences - 1; i >= 0; i--) {
					myArray.pushObject(result.objectAt(i));
				}
				self.set('absenceArray', myArray);
			}, function (error) {
				console.log('Error : ' + error);
			});
		})
	});
});