define('sokol-today/components/account/user/user-create/step-one', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		store: Ember.inject.service(),
		firebaseApp: Ember.inject.service(),
		newUser: Ember.inject.service(),
		router: Ember.inject.service('-routing'),
		session: Ember.inject.service(),

		didInsertElement: function didInsertElement() {
			var newUser = this.get('newUser');
			newUser.setProperties({ hasUser: false, hasType: false, hasAccountType: false, hasAccountInfo: false, haveStudent: false, isAdmin: false, isTeacher: false, isStudent: false, isParent: false });
			$("input:password").focus(function () {
				this.type = "text";
			}).blur(function () {
				this.type = "password";
			});
			var emailInput = document.getElementById('email');
			emailInput.oninvalid = function (event) {
				event.target.setCustomValidity('Email should be a valid email address');
			};
		},

		notFilledOut: Ember.computed('email', 'password', 'passwordConfirm', function () {
			var email = this.get('email');
			var passphrase = this.get('password');
			var confirmed = this.get('passwordConfirm');

			if (email && passphrase && confirmed && passphrase === confirmed) {
				return false;
			} else {
				return true;
			}
		}),

		match: Ember.computed('password', 'passwordConfirm', function () {
			var passphrase = this.get('password');
			var confirmed = this.get('passwordConfirm');

			if (passphrase === confirmed || typeof confirmed === 'undefined' || confirmed === null || String(confirmed).length < 9) {
				return true;
			} else {
				return false;
			}
		}),

		shouldBeValidated: function shouldBeValidated(evt) {
			console.log(evt);
			return !(evt.getAttribute("readonly") || evt.readonly) && !(evt.getAttribute("disabled") || evt.disabled) && (evt.getAttribute("pattern") || evt.getAttribute("required"));
		},


		actions: {
			saveInfo: function saveInfo(emailCreate, passwordCreate, passwordCreateConfirm) {
				var torii = this.get('session');
				var self = this;
				var ref = this.get('firebaseApp').database();
				var newUser = this.get('newUser');
				if (typeof passwordCreate === 'undefined' || typeof passwordCreateConfirm === 'undefined') {
					self.setProperties({ passwordError: 'must have a password' });
					return;
				}
				if (passwordCreate !== passwordCreateConfirm) {
					self.setProperties({ passwordError: 'passwords do not match' });
					return;
				}
				if (typeof emailCreate === 'undefined') {
					self.setProperties({ emailError: 'most provide an email' });
					return;
				}
				var text = "";
				var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

				for (var i = 0; i < 10; i++) {
					text += possible.charAt(Math.floor(Math.random() * possible.length));
				}

				var auth = this.get('firebaseApp').auth();
				auth.createUserWithEmailAndPassword(emailCreate, passwordCreate).then(function (d) {
					var store = self.get('store');
					var newUser = store.createRecord('user', {
						id: d.uid,
						email: emailCreate,
						pin: text,
						isNew: 'account.user-create.step-two'
					});
					//create main storage folder
					var folderRef = ref.push();
					var newFolderRef = 'folder' + folderRef.key;
					var userStorage = store.createRecord('folder', {
						id: newFolderRef,
						user: newUser,
						folderName: 'Personal Files'
					});
					//create assignment folder
					var assignmentRef = ref.push();
					var newAssignmentRef = 'assignment_folder' + assignmentRef.key;
					var userAssignments = store.createRecord('folder', {
						id: newAssignmentRef,
						user: newUser,
						folderName: 'Assignments'
					});
					//add assignment to main folder tree
					userStorage.get('folders').addObject(userAssignments);
					//add storage location to user
					userAssignments.save().then(function () {
						userStorage.save().then(function (savedStorage) {
							newUser.set('cloudStorage', savedStorage);
							newUser.save().then(function () {
								torii.open('firebase', {
									provider: 'password',
									email: emailCreate,
									password: passwordCreate
								}).then(function (d) {
									var store = self.get('store');
									var user = store.findRecord('user', d.uid);
									newUser.setProperties({ hasUser: true });
									self.get('router').transitionTo('account.user-create.step-two');
									return user;
								}, function (error) {
									self.setProperties({ errorMessage: error.message, login: true, displayChoice: false });
								});
							}, function (error) {
								self.setProperties({ errorMessageCreate: error.message });
							});
						}, function (error) {
							self.setProperties({ errorMessageCreate: error.message });
							console.log('Error : ' + error);
						});
					}, function (error) {
						self.setProperties({ errorMessageCreate: error.message });
						console.log('Error : ' + error);
					});
				}, function (error) {
					self.setProperties({ errorMessageCreate: error.message });
				});
			},
			instantValidate: function instantValidate(field, evt) {
				if (this.shouldBeValidated(evt.target)) {
					console.log('lost focus');
					var invalid = evt.target.getAttribute("required") && !evt.target.value || evt.target.getAttribute("pattern") && evt.target.value && !new RegExp(evt.target.getAttribute("pattern")).test(evt.target.value);
					if (!invalid && evt.target.getAttribute("aria-invalid")) {
						console.log('valid');
						evt.target.removeAttribute("aria-invalid");
					} else if (invalid && !evt.target.getAttribute("aria-invalid")) {
						console.log('invalid');
						$(evt.target).parent().addClass('not-valid');
						evt.target.setAttribute("aria-invalid", "true");
					}
				}
			}
		}
	});
});