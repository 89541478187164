define('sokol-today/components/dashboard/display/left-side-menu/index-display', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		connectedUser: Ember.inject.service(),
		hideDashboard: true
	});
});