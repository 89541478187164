define('sokol-today/components/dashboard/courses/add/step-two', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    stepIndicator: Ember.inject.service(),
    connectedUser: Ember.inject.service(),
    store: Ember.inject.service(),
    router: Ember.inject.service('-routing'),
    includeGPA: true,

    didInsertElement: function didInsertElement() {
      var step = this.get('stepIndicator');
      step.setProperties({ stepOne: true, stepTwo: false, stepThree: false });
    },

    actions: {
      next: function next() {
        var router = this.get('router');
        router.transitionTo('dashboard.courses.add.step-three');
      },
      addPreReqOR: function addPreReqOR(reqClasses) {
        if (!reqClasses) {
          return;
        }
        var model = this.get('model');
        model.set('preReqOR', reqClasses);
      },
      addPreReqAND: function addPreReqAND(reqClasses) {
        if (!reqClasses) {
          return;
        }
        var model = this.get('model');
        model.set('preReqAND', reqClasses);
      }
    }
  });
});