define('sokol-today/components/dashboard/settings/billing/subscriptions/add-subscription/step-one', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		routeHistory: Ember.inject.service(),
		stepIndicator: Ember.inject.service(),

		didInsertElement: function didInsertElement() {
			var route = this.get('routeHistory');
			var stepIndicator = this.get('stepIndicator');
			route.set('nextRoute', 'dashboard.settings.billing.subscriptions.add-subscription.step-two');
			route.set('previousRoute', null);
			stepIndicator.setProperties({ stepOne: false, stepTwo: false, stepThree: false });
		}
	});
});