define('sokol-today/components/dashboard/courses/add/step-three', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    stepIndicator: Ember.inject.service(),
    connectedUser: Ember.inject.service(),
    store: Ember.inject.service(),
    router: Ember.inject.service('-routing'),

    didInsertElement: function didInsertElement() {
      var step = this.get('stepIndicator');
      step.setProperties({ stepOne: true, stepTwo: true, stepThree: false, stepFour: false });
    },

    actions: {
      next: function next() {
        var router = this.get('router');
        router.transitionTo('dashboard.courses.add.step-four');
      }
    }
  });
});