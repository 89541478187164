define('sokol-today/models/course-info', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'), //class name
    courseID: _emberData.default.attr('string'),
    priority: _emberData.default.attr('number'), //1 for required class and higher for less important classes
    curriculum: _emberData.default.hasMany('class-curriculum'), //For K thru 8 to show grades for specific areas MATH, SCIENCE, READING, WRITING, TECHNOLOGY
    subject: _emberData.default.belongsTo('subject', { inverse: null }), //class subject
    allowedGrades: _emberData.default.hasMany('school-grade', { inverse: null }), //student must be in one of the listed grades in order to take class
    preReqOR: _emberData.default.hasMany('course-info', { inverse: null }), //student must have taken one of the listed classes to take the class
    preReqAND: _emberData.default.hasMany('course-info', { inverse: null }), //student must have taken all the listed classes to take this class
    credit: _emberData.default.attr('number'), //number of credits earned by completing class
    courseLength: _emberData.default.belongsTo('course-length', { inverse: null }), //length of time class runs (Semester, Full Year, Quarter etc)
    courseDesc: _emberData.default.attr('string'), //Description of the class (usually shown in school handbook)
    status: _emberData.default.attr('string', { defaultValue: 'active' }), //Class active for school year
    preReqNotes: _emberData.default.attr('string'), //Any added notes to pre requisite field of class
    NCAA: _emberData.default.attr('boolean'), //approved course by NCAA
    AP: _emberData.default.attr('boolean'), //Advanced Placement Course
    PRS: _emberData.default.attr('boolean'), //Project Running Start Course
    fee: _emberData.default.attr('number'), //fee in pennies and is only for courses requiring additional funds
    GPA: _emberData.default.attr('boolean'), //include in GPA calculation
    courseRank: _emberData.default.attr('boolean'), //include in class rank
    honorRoll: _emberData.default.attr('boolean'), //include in class rank
    transcript: _emberData.default.attr('boolean'), //include in transcripts
    MATH: _emberData.default.attr('boolean'), //course meets state Math standards
    ENGLISH: _emberData.default.attr('boolean'), //course meets state English standards
    school: _emberData.default.belongsTo('school', { inverse: null }),
    years: _emberData.default.hasMany('year') //keep track of each year the class was active for
  });
});