define('sokol-today/components/dashboard/staff/add/step-one', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        firebaseApp: Ember.inject.service(),
        connectedUser: Ember.inject.service(),
        newUser: Ember.inject.service(),
        session: Ember.inject.service(),
        store: Ember.inject.service(),
        router: Ember.inject.service('-routing'),
        errorMessage: '',
        successMessage: '',

        didInsertElement: function didInsertElement() {
            var newUser = this.get('newUser');
            newUser.setProperties({ hasUser: false, hasRole: false });
        },


        allergies: Ember.computed('', function () {
            return this.get('store').findAll('allergy');
        }),

        professions: Ember.computed('', function () {
            return this.get('store').findAll('profession');
        }),

        actions: {
            createRoom: function createRoom(room) {
                var that = this;
                var store = this.get('store');
                var firebaseRef = this.get('firebaseApp').database().ref('null/');
                var roomRef = firebaseRef.push();
                var newRoomRef = 'room' + roomRef.key;
                var newRoom = store.createRecord('room', {
                    id: newRoomRef,
                    name: room
                });
                var school = this.get('connectedUser.account.school');

                return school.then(function (theSchool) {

                    return newRoom.save().then(function (theRoom) {
                        var roomList = store.findRecord('room-list', theSchool.get('roomList.id'));
                        roomList.then(function (theRoomList) {
                            console.log(theRoomList);
                            theRoomList.get('rooms').addObject(theRoom);
                            theRoomList.save();
                            that.set('selectedRoom', theRoom);
                        }, function (error) {
                            that.setProperties({ errorMessage: 'Error finding school room list to add new room too' });
                            console.log('Error : ' + error);
                        });
                    }, function (error) {
                        that.setProperties({ errorMessage: 'Error saving new room, check if you have permission' });
                        console.log('Error : ' + error);
                    });
                }, function (error) {
                    that.setProperties({ errorMessage: 'Error finding school information' });
                    console.log('Error : ' + error);
                });
            },
            createAllergy: function createAllergy(select, e) {
                console.log(select);
                if (e.keyCode === 13 && select.isOpen && !select.highlighted && !Ember.isBlank(select.searchText)) {
                    var selected = this.get('selected');
                    if (select.searchText) {
                        var firebaseRef = this.get('firebaseApp').database();
                        var allergyRef = firebaseRef.push();
                        var newAllergyRef = 'allergy' + allergyRef.key;
                        var newAllergy = this.get('store').createRecord('allergy', {
                            id: newAllergyRef,
                            name: select.searchText
                        });
                        newAllergy.save().then(function (savedAllergy) {});
                        select.actions.choose(select.searchText);
                    }
                }
            },
            saveExt: function saveExt(ext) {
                var room = this.get('selectedRoom');
                room.set('ext', ext);
                room.save();
            },
            createProfession: function createProfession(profession) {
                var firebaseRef = this.get('firebaseApp').database().ref('null/');
                var store = this.get('store');
                var session = this.get('session');
                var profRef = firebaseRef.push();
                var newProfRef = 'profession' + profRef.key;
                var newProfession = store.createRecord('profession', {
                    id: newProfRef,
                    name: profession
                });
                var staff = this.get('connectedUser.staff');
                staff.then(function (theUser) {
                    var school = store.findRecord('school', theUser.get('school.id'));
                    school.then(function (theSchool) {
                        newProfession.save().then(function (theProfession) {
                            var professionList = store.findRecord('profession-list', theSchool.get('professionList.id'));
                            professionList.then(function (theProfessionList) {
                                theProfessionList.get('professions').addObject(theProfession);
                                theProfessionList.save();
                            }, function (error) {
                                console.log('Error : ' + error);
                            });
                        });
                    });
                }, function (error) {
                    console.log('Error : ' + error);
                });
            },
            saveStaff: function saveStaff(first, last, job, room, bDay, sDay, email, allergies, classes) {
                var sDayRepair = sDay;
                if (!first || !last || !email) {
                    this.set('error', true);
                    return;
                }
                if (!sDay) {
                    sDayRepair = moment();
                }
                var firebaseRef = this.get('firebaseApp').database().ref('null/');
                var router = this.get('router');
                var store = this.get('store');
                var session = this.get('session');
                var school = this.get('connectedUser.account.school');
                var that = this;
                var imageLandscape = this.get('imageLandscape');
                var imagePortrait = this.get('imagePortrait');
                var image = this.get('image');
                var text = "";
                var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

                for (var i = 0; i < 10; i++) {
                    text += possible.charAt(Math.floor(Math.random() * possible.length));
                }
                var staffRef = firebaseRef.push();
                var newStaffRef = 'staff' + staffRef.key;
                var newStaff = store.createRecord('staff', {
                    id: newStaffRef,
                    firstName: first.capitalize(),
                    lastName: last.capitalize(),
                    room: room,
                    profession: job,
                    birthday: bDay,
                    started: sDayRepair,
                    pin: text,
                    email: email
                });

                if (classes) {
                    if (classes.length === 1) {
                        newStaff.get('classes').addObjects(classes);
                    } else {
                        newStaff.get('classes').addObjects(classes);
                    }
                }
                if (allergies) {
                    if (allergies.length === 1) {
                        newStaff.get('allergies').addObjects(allergies);
                    } else {
                        newStaff.get('allergies').addObjects(allergies);
                    }
                }

                school.then(function (schoolLink) {
                    newStaff.set('school', schoolLink);
                    // used for limiting account amount
                    // if (!theUser.adminPersonal || !theUser.teacherPersonal) {
                    //     var totalPINS = theUser.get('school.totalPINS');
                    //     var usedPINS = theUser.get('school.usedPINS');
                    //     if (usedPINS == totalPINS) {
                    //         that.setProperties({ errorMessage: 'All Active Slots Used!' });
                    //         return
                    //     } else {
                    //         schoolLink.incrementProperty('usedPINS');
                    //         schoolLink.save();
                    //     }
                    // }
                    newStaff.save().then(function (savedStaff) {
                        var avatarRef = firebaseRef.push();
                        var newAvatarRef = 'avatar' + avatarRef.key;
                        // that.get('onChange')();
                        if (imageLandscape) {
                            var avatar = store.createRecord('avatar', {
                                id: newAvatarRef,
                                imageLandscape: true,
                                image: imageLandscape
                            });

                            avatar.save().then(function (newAvatar) {
                                savedStaff.set('avatar', newAvatar);
                                savedStaff.set('school', schoolLink);
                                savedStaff.save();
                            });
                        } else if (imagePortrait) {
                            var avatar = store.createRecord('avatar', {
                                id: newAvatarRef,
                                imagePortrait: true,
                                image: imagePortrait
                            });

                            avatar.save().then(function (newAvatar) {
                                savedStaff.set('avatar', newAvatar);
                                savedStaff.set('school', schoolLink);
                                savedStaff.save();
                            });
                        } else if (image) {
                            var avatar = store.createRecord('avatar', {
                                id: newAvatarRef,
                                image: image
                            });

                            avatar.save().then(function (newAvatar) {
                                savedStaff.set('avatar', newAvatar);
                                savedStaff.set('school', schoolLink);
                                savedStaff.save();
                            });
                        } else {}
                        var staffList = store.findRecord('staff-list', schoolLink.get('staffList.id'));
                        staffList.then(function (theList) {
                            theList.get('staff').addObject(savedStaff);
                            theList.save().then(function () {
                                // $.get({url:'https://us-central1-scope-95d64.cloudfunctions.net/createStripeCustomerHttp?uid='+savedStaff.get('id')+'&email='+savedStaff.get('email')})
                                // .done(function(data){

                                // })
                                // .fail(function(error){

                                // });
                                that.setProperties({ successMessage: 'Saved Successful' });
                                that.setProperties({ firstName: null, lastName: null, selectedRoom: null, birthDate: null, startDate: null });
                                setTimeout(function () {
                                    that.get('transitionToStepTwo')(savedStaff);
                                }, 1000);
                            }, function (error) {
                                that.setProperties({ errorMessage: error });
                                console.log(error);
                            });
                        });
                    }, function (error) {
                        that.setProperties({ errorMessage: 'Error occured creating model, possibly a connection issue or permission issue' });
                        console.log(error);
                    });
                }, function (error) {
                    that.setProperties({ errorMessage: 'Error finding school information' });
                    console.log(error);
                });
            },
            cancel: function cancel() {
                var router = this.get('router');
                router.transitionTo('dashboard.staff.index');
            }
        }
    });
});