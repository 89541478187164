define('sokol-today/services/connection-status', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        firebaseApp: Ember.inject.service(),

        online: false,
        offline: Ember.computed.not('online'),
        firebaseConnection: false,

        issue: Ember.computed('online', 'firebaseConnection', function () {
            var offline = this.get('offline');
            var firebaseConnection = this.get('firebaseConnection');
            if (offline || !firebaseConnection) {
                return true;
            } else {
                return false;
            }
        }),

        startWatching: function () {
            var Firebase = this.get('firebaseApp').database();
            var _this = this;
            var updateStatus = function updateStatus() {
                _this.set('online', navigator.onLine);
                if (_this.get('online')) {
                    Ember.Logger.info('Connection status: User has gone online');
                } else {
                    Ember.Logger.info('Connection status: User has gone offline');
                }
            };
            this.set('online', navigator.onLine);

            var connectedRef = Firebase.ref(".info/connected");
            connectedRef.on('value', function (snap) {
                if (snap.val() === true) {
                    _this.set('firebaseConnection', true);
                } else {
                    _this.set('firebaseConnection', false);
                }
            });
            window.addEventListener('online', updateStatus);
            window.addEventListener('offline', updateStatus);
        }.on('init')
    });
});