define('sokol-today/components/dashboard/years/add/step-two', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        firebaseApp: Ember.inject.service(),
        newUser: Ember.inject.service(),
        connectedUser: Ember.inject.service(),
        store: Ember.inject.service(),
        router: Ember.inject.service('-routing'),
        didInsertElement: function didInsertElement() {
            var newUser = this.get('newUser');
            newUser.setProperties({ haveYearInfo: true });
        },


        actions: {
            createTerms: function createTerms() {
                var self = this;
                var firebaseRef = this.get('firebaseApp').database();
                var router = this.get('router');
                var store = this.get('store');
                var year = this.get('connectedUser.staff.school.yearList.years.lastObject');
                var termList = this.get('connectedUser.staff.school.termList.terms');
                termList.then(function (foundTerms) {
                    var length = foundTerms.get('length');

                    var _loop = function _loop() {
                        var periodRef = firebaseRef.push();
                        var newPeriodRef = 'period' + periodRef.key;
                        var period = store.createRecord('period', {
                            id: newPeriodRef,
                            name: foundTerms.objectAt(i).get('name')
                        });

                        year.get('periods').addObject(period);
                        year.save().then(function () {
                            period.save().then(function () {
                                self.setProperties({ successMessage: "Term Info Saved", errorMessage: '' });
                                setTimeout(function () {
                                    router.transitionTo('dashboard.years');
                                }, 1000);
                            }, function (error) {
                                console.log('Error : ' + error);
                            });
                        }, function (error) {
                            console.log('Error : ' + error);
                        });
                    };

                    for (var i = 0; i < length; i++) {
                        _loop();
                    }
                }, function (error) {
                    console.log('Error : ' + error);
                });
            }
        }

    });
});