define('sokol-today/components/website/demo/observations/display-clicked', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _defineProperty(obj, key, value) {
        if (key in obj) {
            Object.defineProperty(obj, key, {
                value: value,
                enumerable: true,
                configurable: true,
                writable: true
            });
        } else {
            obj[key] = value;
        }

        return obj;
    }

    exports.default = Ember.Component.extend({
        eventIO: null,
        store: Ember.inject.service(),
        tagName: 'g',
        //Manage click & double click desktop
        click: function click(evt) {
            // alert('ok');
            console.log(evt);
            var runLatter = this.get('eventIO');
            var data = this.get('data');
            var that = this;
            // console.log('in click event');
            //if this is the first click , schedule it for later after 500 ms
            if (runLatter === null) {
                runLatter = Ember.run.later(this, function () {
                    //do single click stuff
                    var teacher = that.get('teacher');
                    var handup = that.get('handup');
                    var calledOn = that.get('calledOn');
                    var offTask = that.get('offTask');
                    var store = that.get('store');
                    var date = new Date();
                    var e = evt.target;
                    var rect = e.nearestViewportElement.getBoundingClientRect();
                    var ratio = 600 / rect.height;
                    // var x = (evt.changedTouches[0].clientX - dim.left) * ratio;
                    // var y = (evt.changedTouches[0].clientY - dim.top) * ratio;

                    var x = (evt.originalEvent.clientX - rect.left) * ratio;
                    var y = (evt.originalEvent.clientY - rect.top) * ratio;

                    if (handup || calledOn) {
                        var points = store.createRecord('demo/point', {
                            cx: x,
                            cy: y,
                            teacher: teacher,
                            handup: handup,
                            calledOn: calledOn,
                            timeStamp: date
                        });

                        data.get('points').addObject(points);
                    } else if (offTask) {
                        var points = store.createRecord('demo/point', {
                            cx: x,
                            cy: y,
                            teacher: teacher,
                            offTask: true,
                            onTask: false,
                            timeStamp: date
                        });

                        data.get('points').addObject(points);
                    } else {
                        var _store$createRecord;

                        var points = store.createRecord('demo/point', (_store$createRecord = {
                            cx: x,
                            cy: y,
                            teacher: teacher
                        }, _defineProperty(_store$createRecord, 'teacher', teacher), _defineProperty(_store$createRecord, 'handup', handup), _defineProperty(_store$createRecord, 'calledOn', calledOn), _defineProperty(_store$createRecord, 'timeStamp', date), _store$createRecord));

                        data.get('points').addObject(points);
                    }

                    var eventIO = that.get('eventIO');

                    // clear additional events
                    if (eventIO !== null) {
                        console.log('click and event Not null');
                        Ember.run.cancel(runLatter);
                        that.set('eventIO', null);
                    }
                }, 500);

                //register event to the component
                that.set('eventIO', runLatter);
            } else {
                //check if there is any event for single click, disable it
                // console.log('double click');
                Ember.run.cancel(runLatter);
                that.set('eventIO', null);
                var teacher = that.get('teacher');
                var handup = that.get('handup');
                var offTask = that.get('offTask');
                var store = that.get('store');
                var store = that.get('store');
                var date = new Date();
                var e = evt.target;
                var rect = e.nearestViewportElement.getBoundingClientRect();
                var ratio = 600 / rect.height;
                // var x = (evt.changedTouches[0].clientX - dim.left) * ratio;
                // var y = (evt.changedTouches[0].clientY - dim.top) * ratio;

                var x = (evt.originalEvent.clientX - rect.left) * ratio;
                var y = (evt.originalEvent.clientY - rect.top) * ratio;

                if (handup) {

                    var points = store.createRecord('demo/point', {
                        cx: x,
                        cy: y,
                        handup: handup,
                        calledOn: true,
                        timeStamp: date
                    });

                    data.get('points').addObject(points);
                } else if (offTask) {

                    var points = store.createRecord('demo/point', {
                        cx: x,
                        cy: y,
                        offTask: false,
                        onTask: true,
                        timeStamp: date
                    });

                    data.get('points').addObject(points);
                }
            }
            return;
        },

        //manage tap and double tap mobile devices
        touchEnd: function touchEnd(evt) {
            // alert('ok');
            evt.preventDefault();
            console.log(evt);
            var runLatter = this.get('eventIO');
            var data = this.get('data');
            var that = this;
            // console.log('in click event');
            //if this is the first click , schedule it for later after 500 ms
            if (runLatter === null) {
                runLatter = Ember.run.later(this, function () {
                    //do single click stuff
                    var teacher = that.get('teacher');
                    var handup = that.get('handup');
                    var calledOn = that.get('calledOn');
                    var offTask = that.get('offTask');
                    var store = that.get('store');
                    var date = new Date();
                    var e = evt.target;
                    var rect = e.nearestViewportElement.getBoundingClientRect();
                    var ratio = 600 / rect.height;
                    var x = (evt.changedTouches[0].clientX - rect.left) * ratio;
                    var y = (evt.changedTouches[0].clientY - rect.top) * ratio;

                    if (handup || calledOn) {
                        var points = store.createRecord('demo/point', {
                            cx: x,
                            cy: y,
                            teacher: teacher,
                            handup: handup,
                            calledOn: calledOn,
                            timeStamp: date
                        });

                        data.get('points').addObject(points);
                    } else if (offTask) {
                        var points = store.createRecord('demo/point', {
                            cx: x,
                            cy: y,
                            teacher: teacher,
                            offTask: true,
                            onTask: false,
                            timeStamp: date
                        });

                        data.get('points').addObject(points);
                    } else {
                        var _store$createRecord2;

                        var points = store.createRecord('demo/point', (_store$createRecord2 = {
                            cx: x,
                            cy: y,
                            teacher: teacher
                        }, _defineProperty(_store$createRecord2, 'teacher', teacher), _defineProperty(_store$createRecord2, 'handup', handup), _defineProperty(_store$createRecord2, 'calledOn', calledOn), _defineProperty(_store$createRecord2, 'timeStamp', date), _store$createRecord2));

                        data.get('points').addObject(points);
                    }

                    var eventIO = that.get('eventIO');

                    // clear additional events
                    if (eventIO !== null) {
                        console.log('click and event Not null');
                        Ember.run.cancel(runLatter);
                        that.set('eventIO', null);
                    }
                }, 500);

                //register event to the component
                that.set('eventIO', runLatter);
            } else {
                //check if there is any event for single click, disable it
                // console.log('double click');
                Ember.run.cancel(runLatter);
                that.set('eventIO', null);
                var teacher = that.get('teacher');
                var handup = that.get('handup');
                var offTask = that.get('offTask');
                var store = that.get('store');
                var store = that.get('store');
                var date = new Date();
                var e = evt.target;
                var rect = e.nearestViewportElement.getBoundingClientRect();
                var ratio = 600 / rect.height;
                var x = (evt.changedTouches[0].clientX - rect.left) * ratio;
                var y = (evt.changedTouches[0].clientY - rect.top) * ratio;

                if (handup) {

                    var points = store.createRecord('demo/point', {
                        cx: x,
                        cy: y,
                        handup: handup,
                        calledOn: true,
                        timeStamp: date
                    });

                    data.get('points').addObject(points);
                } else if (offTask) {

                    var points = store.createRecord('demo/point', {
                        cx: x,
                        cy: y,
                        offTask: false,
                        onTask: true,
                        timeStamp: date
                    });

                    data.get('points').addObject(points);
                }
            }
            return;
        }
    });
});