define('sokol-today/models/observation-block', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        observations: _emberData.default.hasMany('observation', { async: true }),
        finalized: _emberData.default.attr('boolean', { defaultValue: false }),
        dateFinalized: _emberData.default.attr('string'),
        observee: _emberData.default.belongsTo('staff', { async: true, inverse: 'observed' }),
        school: _emberData.default.belongsTo('school', { async: true }),
        year: _emberData.default.belongsTo('year'),
        timestampStart: _emberData.default.attr('string'),
        timestampEnd: _emberData.default.attr('string'),
        room: _emberData.default.belongsTo('room'),
        walkthrough: _emberData.default.belongsTo('walk-through', { async: true }),
        observer: _emberData.default.belongsTo('staff', { async: true, inverse: null }),
        observationType: _emberData.default.belongsTo('observeType'),
        points: _emberData.default.hasMany('point'),
        class: _emberData.default.belongsTo('class'),
        momentElapsed: Ember.computed('timestampStart', 'timestampEnd', function () {
            var start = moment(this.get('timestampStart'));
            var end = moment(this.get('timestampEnd'));
            var time = end.diff(start, 'seconds', true);
            var duration = moment.duration(time, 'seconds').asMinutes();
            return duration;
        }),
        elapsedTime: Ember.computed('timestampStart', 'timestampEnd', function () {
            var start = moment(this.get('timestampStart'));
            var end = moment(this.get('timestampEnd'));
            var time = end.diff(start, 'seconds', true);
            var duration = moment.duration(time, 'seconds');
            return moment(duration._data).format("[h]:mm[m]:ss[s]");
        })
    });
});