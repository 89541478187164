define('sokol-today/components/dashboard/assignments/add/step-one', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        store: Ember.inject.service(),
        connectedUser: Ember.inject.service(),
        firebaseApp: Ember.inject.service(),
        router: Ember.inject.service('-routing'),
        subjectOptions: Ember.computed(function () {
            var store = this.get('store');
            return store.findAll('subject');
        }),
        typeOptions: ['homework', 'classwork', 'test', 'final'],

        actions: {
            removeFile: function removeFile(index) {
                var fileInfo = this.get('fileInfo');
                fileInfo.removeAt(index);
            },
            saveAssignment: function saveAssignment(name, subject, maxScore, minScore, steps, files, type) {
                if (!name) {
                    this.setProperties({ errorMessage: 'Assignment needs to have a name' });
                    return;
                }
                var user = this.get('connectedUser.user');
                var store = this.get('store');
                var storage = this.get('firebaseApp').storage();
                var self = this;
                var router = this.get('router');
                user.then(function (theUser) {
                    var cloudStorageLoc = theUser.get('cloudStorage');
                    cloudStorageLoc.then(function (theCloudStorageFolder) {
                        var assignmentLoc = theCloudStorageFolder.get('folders.firstObject');
                        var storageRef = storage.ref('users/' + theUser.id + '/Assignments/');
                        var assignmentFolder = store.createRecord('folder', {
                            folderName: name,
                            user: theUser
                        });
                        assignmentLoc.get('folders').addObject(assignmentFolder);
                        var assignmentModel = store.createRecord('my-assignment', {
                            name: name,
                            minScore: minScore,
                            maxScore: maxScore,
                            steps: steps,
                            subject: subject,
                            type: type
                        });
                        if (!files) {
                            assignmentModel.save().then(function (savedAssignment) {
                                assignmentFolder.save().then(function () {
                                    assignmentLoc.save().then(function () {
                                        self.setProperties({ errorMessage: '', successMessage: 'Assignment saved!' });
                                        setTimeout(function () {
                                            router.transitionTo('dashboard.assignments');
                                        }, 1000);
                                    }, function (error) {
                                        console.log('Error : ' + error);
                                    });
                                }, function (error) {
                                    console.log('Error : ' + error);
                                });
                            }, function (error) {
                                self.setProperties({ errorMessage: error });
                                assignmentModel.roleBackChanges();
                            });
                        } else {
                            for (var i = files.length - 1; i >= 0; i--) {
                                var file = files[i];
                                var metadata = {
                                    name: file.metadata.name,
                                    size: file.metadata.size
                                };
                                storageRef.child(file.metadata.name).put(file.file, metadata).then(function (fileShot) {
                                    var path = fileShot.metadata.downloadURLs[0];
                                    var fileModel = store.createRecord('file', {
                                        name: fileShot.metadata.name,
                                        filePath: path,
                                        saveDate: fileShot.metadata.updated,
                                        fileType: fileShot.metadata.contentType
                                    });
                                    assignmentFolder.set('assignmentInfo', assignmentModel);
                                    assignmentFolder.get('files').addObject(fileModel);
                                    assignmentModel.get('documents').addObject(fileModel);
                                    fileModel.get('assignments').addObject(assignmentModel);
                                    fileModel.save().then(function () {
                                        assignmentModel.save().then(function (savedAssignment) {
                                            assignmentFolder.save().then(function () {
                                                assignmentLoc.save().then(function () {
                                                    self.setProperties({ errorMessage: '', successMessage: 'Assignment saved!' });
                                                    setTimeout(function () {
                                                        router.transitionTo('dashboard.assignments');
                                                    }, 1000);
                                                }, function (error) {
                                                    console.log('Error : ' + error);
                                                });
                                            }, function (error) {
                                                console.log('Error : ' + error);
                                            });
                                        }, function (error) {
                                            self.setProperties({ errorMessage: error });
                                            assignmentModel.roleBackChanges();
                                        });
                                    }, function (error) {
                                        self.setProperties({ errorMessage: error });
                                        fileModel.roleBackChanges();
                                        console.log('Error : ' + error);
                                    });
                                }, function (error) {
                                    console.log('Error : ' + error);
                                });
                            }
                        }
                    }, function (error) {
                        console.log('Error : ' + error);
                    });
                }, function (error) {
                    console.log('Error : ' + error);
                });
            }
        }
    });
});