define('sokol-today/helpers/css-grade', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.cssGrade = cssGrade;
    function cssGrade(params, hash) {

        var grade = hash.grade;
        var min = hash.min;
        var max = hash.max;
        var steps = Number(hash.steps);
        var reverse = hash.reverse;
        var spectrum = hash.colors;
        var betweenRanges = Math.ceil((max - min) / steps * 10) / 10;
        var classReport = ['lightskyblue', 'aquamarine', 'lightgreen', 'lightpink', 'orangered'];
        var defaultColors = ['green', 'aquamarine', 'lightpink', 'red'];
        var defaultRev = ['red', 'lightyellow', 'aquamarine', 'green', 'grade-blue'];
        var sixSteps = ['eleven', 'nine', 'seven', 'five', 'three', 'one'];
        var fiveSteps = ['eleven', 'eight', 'five', 'three', 'one'];
        var fourSteps = ['eleven', 'eight', 'four', 'one'];
        var threeSteps = ['eleven', 'six', 'one'];
        var twoSteps = ['six', 'one'];

        if (reverse) {
            for (var i = steps - 1; i >= 0; i--) {
                if (grade >= Number(min) + betweenRanges * i) {
                    if (spectrum) {
                        return classReport[i];
                    } else {
                        switch (steps) {
                            case 6:
                                return sixSteps.reverse()[i];
                                break;
                            case 5:
                                return fiveSteps.reverse()[i];
                                break;
                            case 4:
                                return fourSteps.reverse()[i];
                                break;
                            case 3:
                                return threeSteps.reverse()[i];
                                break;
                            case 2:
                                return twoSteps.reverse()[i];
                                break;
                            default:
                                return 0;
                                break;
                        }
                    }
                }
            }
        } else {
            for (var i = steps - 1; i >= 0; i--) {
                // console.log(grade + ':' + min + ':' + (betweenRanges * i));
                if (grade >= Number(min) + betweenRanges * i) {
                    if (spectrum) {
                        switch (spectrum) {
                            case "classReport":
                                return classReport[i];
                                break;
                            default:
                                break;
                        }
                    } else {
                        switch (steps) {
                            case 6:
                                return sixSteps[i];
                                break;
                            case 5:
                                return fiveSteps[i];
                                break;
                            case 4:
                                return fourSteps[i];
                                break;
                            case 3:
                                return threeSteps[i];
                                break;
                            case 2:
                                return twoSteps[i];
                                break;
                            default:
                                return 0;
                                break;
                        }
                    }
                }
            }
        }
    }

    exports.default = Ember.Helper.helper(cssGrade);
});