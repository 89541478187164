define('sokol-today/components/dashboard/display/side-menu', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		connectedUser: Ember.inject.service(),
		store: Ember.inject.service(),
		hideDashboard: true,
		// didInsertElement(){
		// 	var height = $(window).height()-($('.dashboard-menu-user').height() + 16);
		// 	this.$('.well').height(height);
		// },

		actions: {
			menuToggle: function menuToggle() {
				this.toggleProperty('hideDashboard');
			}
		}
	});
});