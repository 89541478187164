define('sokol-today/components/dashboard/rubric/add/domain/custom-domain', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        store: Ember.inject.service(),

        focusOut: function focusOut() {
            var model = this.get('theDomain');

            if (typeof model == 'undefined') {
                return;
            } else {
                model.save();
            }
        },


        actions: {
            addComponent: function addComponent(theDomain) {
                var store = this.get('store');
                var unsatisfactory = store.createRecord('rubric-ranking', {
                    name: 'unsatisfactory',
                    description: 'Unsatisfactory Description Goes Here'
                });
                var basic = store.createRecord('rubric-ranking', {
                    name: 'basic',
                    description: 'Basic Description Goes Here'
                });
                var proficient = store.createRecord('rubric-ranking', {
                    name: 'proficient',
                    description: 'Proficient Description Goes Here'
                });
                var distinguished = store.createRecord('rubric-ranking', {
                    name: 'distinguished',
                    description: 'Distinguished Description Goes Here'
                });
                unsatisfactory.save().then(function (theUnsatisfactory) {
                    basic.save().then(function (theBasic) {
                        proficient.save().then(function (theProficient) {
                            distinguished.save().then(function (theDistinguished) {
                                var newComponent = store.createRecord('rubric-component', {
                                    name: 'New Component',
                                    unsatisfactory: theUnsatisfactory,
                                    basic: theBasic,
                                    proficient: theProficient,
                                    distinguished: theDistinguished
                                });

                                newComponent.save().then(function (theComponent) {
                                    theDomain.get('components').addObject(theComponent);
                                    theDomain.save().then(function () {}, function (error) {
                                        console.log('Error : ' + error);
                                    });
                                }, function (error) {
                                    console.log('Error : ' + error);
                                });
                            });
                        });
                    });
                });
            }
        }
    });
});