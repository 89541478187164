define('sokol-today/routes/dashboard/staff/index', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        // connectedUser: Ember.inject.service(),
        // model() {
        //     let user = this.get('connectedUser.staff');
        //     let self = this;
        //     return user.get('school').then(function(school) {
        //         return self.store.query('staff', {
        //             orderBy: 'school',
        //             equalTo: school.get('id')
        //         });
        //     });

        // }
    });
});