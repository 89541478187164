define('sokol-today/helpers/lnr-icon', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.lnrIcon = lnrIcon;
    function lnrIcon(params /*, hash*/) {
        // let iconHTML = '<svg class="lnr ' + params[0]  +'"><use xlink:href="#'+ params[0] +'"></use></svg>';
        var iconHTML = '<span class="lnr ' + params[0] + '"></span>';
        return iconHTML;
    }

    exports.default = Ember.Helper.helper(lnrIcon);
});