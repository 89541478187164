define('sokol-today/components/account/user/user-create/step-four/personal-account', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
        return typeof obj;
    } : function (obj) {
        return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };

    exports.default = Ember.Component.extend({
        firebaseApp: Ember.inject.service(),
        newUser: Ember.inject.service(),
        connectedUser: Ember.inject.service(),
        store: Ember.inject.service(),
        router: Ember.inject.service(),

        didInsertElement: function didInsertElement() {
            var newUser = this.get('newUser');
            newUser.setProperties({ hasUser: true, hasType: true, hasAccountType: true });
        },


        allergies: Ember.computed('', function () {
            return this.get('store').findAll('allergy');
        }),

        professions: Ember.computed('', function () {
            return this.get('store').findAll('profession');
        }),

        createPersonalSchool: function createPersonalSchool(name, state, district, room) {
            var firebaseRef = this.get('firebaseApp').database();
            var store = this.get('store');
            var schoolRef = firebaseRef.push();
            var newSchoolRef = 'school' + schoolRef.key;
            var newSchool = store.createRecord('school', {
                id: newSchoolRef,
                name: name,
                state: state,
                district: district,
                personal: true
            });
            return newSchool.save().then(function (savedSchool) {
                var calamityRef = firebaseRef.push();
                var newCalamityRef = 'calamity' + calamityRef.key;
                var calamityList = store.createRecord('calamity-list', {
                    id: newCalamityRef,
                    school: savedSchool
                });
                var classRef = firebaseRef.push();
                var newClassRef = 'class' + classRef.key;
                var classList = store.createRecord('class-list', {
                    id: newClassRef,
                    school: savedSchool
                });
                var courseRef = firebaseRef.push();
                var newCourseRef = 'course' + courseRef.key;
                var courseList = store.createRecord('course-list', {
                    id: newCourseRef,
                    school: savedSchool
                });
                var observationRef = firebaseRef.push();
                var newObservationRef = 'observation' + observationRef.key;
                var obsList = store.createRecord('observation-list', {
                    id: newObservationRef,
                    school: savedSchool
                });
                var assignmentRef = firebaseRef.push();
                var newAssignmentRef = 'assignment' + assignmentRef.key;
                var assignmentList = store.createRecord('assignment-list', {
                    id: newAssignmentRef,
                    school: savedSchool
                });
                var curriculumRef = firebaseRef.push();
                var newCurriculumRef = 'curriculum' + curriculumRef.key;
                var curriculumList = store.createRecord('curriculum-list', {
                    id: newCurriculumRef,
                    school: savedSchool
                });
                var absenceRef = firebaseRef.push();
                var newAbsenceRef = 'absence' + absenceRef.key;
                var absenceList = store.createRecord('absence-list', {
                    id: newAbsenceRef,
                    school: savedSchool
                });
                var yearRef = firebaseRef.push();
                var newYearRef = 'year' + yearRef.key;
                var yearList = store.createRecord('year-list', {
                    id: newYearRef,
                    school: savedSchool
                });

                var gradeRef = firebaseRef.push();
                var newGradeRef = 'grade' + gradeRef.key;
                var gradeList = store.createRecord('grade-list', {
                    id: newGradeRef,
                    school: savedSchool
                });
                var relationshipRef = firebaseRef.push();
                var newRelationshipRef = 'relationship' + relationshipRef.key;
                var relationshipList = store.createRecord('relationship-list', {
                    id: newRelationshipRef,
                    school: savedSchool
                });
                var studentRef = firebaseRef.push();
                var newStudentRef = 'student' + studentRef.key;
                var studentList = store.createRecord('student-list', {
                    id: newStudentRef,
                    school: savedSchool
                });
                var subjectRef = firebaseRef.push();
                var newSubjectRef = 'subject' + subjectRef.key;
                var subjectList = store.createRecord('subject-list', {
                    id: newSubjectRef,
                    school: savedSchool
                });
                var rubricRef = firebaseRef.push();
                var newRubricRef = 'rubric' + rubricRef.key;
                var rubList = store.createRecord('rubric-list', {
                    id: newRubricRef,
                    school: savedSchool
                });
                var staffRef = firebaseRef.push();
                var newStaffRef = 'staff' + staffRef.key;
                var staffList = store.createRecord('staff-list', {
                    id: newStaffRef,
                    school: savedSchool
                });
                var termRef = firebaseRef.push();
                var newTermRef = 'term' + termRef.key;
                var termList = store.createRecord('term-list', {
                    id: newTermRef,
                    school: savedSchool
                });
                var roomRef = firebaseRef.push();
                var newRoomRef = 'room' + roomRef.key;
                var roomList = store.createRecord('room-list', {
                    id: newRoomRef,
                    school: savedSchool
                });
                var professionRef = firebaseRef.push();
                var newProfessionRef = 'profession' + professionRef.key;
                var professionList = store.createRecord('profession-list', {
                    id: newProfessionRef,
                    school: savedSchool
                });

                staffList.save().then(function (theStaffList) {
                    savedSchool.set('staffList', theStaffList);
                    savedSchool.save();
                });
                gradeList.save().then(function (theGradeList) {
                    savedSchool.set('gradeList', theGradeList);
                    savedSchool.save();
                });
                curriculumList.save().then(function (theCurriculumList) {
                    savedSchool.set('curriculumList', theCurriculumList);
                    savedSchool.save();
                });
                termList.save().then(function (theTermList) {
                    savedSchool.set('termList', theTermList);
                    savedSchool.save();
                });
                // yearList.get('years').addObject(year);
                yearList.save().then(function (theYearList) {
                    // year.save();
                    savedSchool.set('yearList', theYearList);
                    savedSchool.save();
                });
                rubList.save().then(function (theRubList) {
                    savedSchool.set('rubricList', theRubList);
                    savedSchool.save();
                });
                obsList.save().then(function (theObsList) {
                    savedSchool.set('observationList', theObsList);
                    savedSchool.save();
                });
                if (room) {
                    roomList.get('rooms').addObject(room);
                }
                roomList.save().then(function (theRoomList) {
                    savedSchool.set('roomList', theRoomList);
                    savedSchool.save();
                });
                professionList.save().then(function (theProfessionList) {
                    savedSchool.set('professionList', theProfessionList);
                    savedSchool.save();
                });
                assignmentList.save().then(function (theAssignmentList) {
                    savedSchool.set('assignmentList', theAssignmentList);
                    savedSchool.save();
                });
                absenceList.save().then(function (theAbsenceList) {
                    savedSchool.set('absenceList', theAbsenceList);
                    savedSchool.save();
                });
                gradeList.save().then(function (theGradeList) {
                    savedSchool.set('gradeList', theGradeList);
                    savedSchool.save();
                });
                relationshipList.save().then(function (theRelationshipList) {
                    savedSchool.set('relationshipList', theRelationshipList);
                    savedSchool.save();
                });
                studentList.save().then(function (theStudentList) {
                    savedSchool.set('studentList', theStudentList);
                    savedSchool.save();
                });
                subjectList.save().then(function (theSubjectList) {
                    savedSchool.set('subjectList', theSubjectList);
                    savedSchool.save();
                });
                classList.save().then(function (theClassList) {
                    savedSchool.set('classList', theClassList);
                    savedSchool.save();
                });
                courseList.save().then(function (theCourseList) {
                    savedSchool.set('courseList', theCourseList);
                    savedSchool.save();
                });
                calamityList.save().then(function (theCalamityList) {
                    savedSchool.set('calamityList', theCalamityList);
                    savedSchool.save();
                });
                return savedSchool;
            }, function (error) {
                console.log('Error : ' + error);
            });
        },


        actions: {
            saveUser: function saveUser(first, last, job, room, bDay, sDay, email, allergies, className, state, district) {
                var firebaseRef = this.get('firebaseApp').database();
                var avatarRef = firebaseRef.push();
                var newAvatarRef = 'avatar' + avatarRef.key;
                if (!first || !last) {
                    this.setProperties({ errorMessage: 'We need your name' });
                } else if (!className || !state || !district) {
                    this.setProperties({ errorMessage: 'We need to have school information' });
                } else if (!sDay) {
                    this.setProperties({ errorMessage: 'We need a first day you started at the school' });
                } else {
                    this.setProperties({ errorMessage: null });
                }
                var store = this.get('store');
                var aStaff = store.findRecord('staff', this.get('connectedUser.account.id'));
                var aUser = store.findRecord('user', this.get('connectedUser.user.id'));
                var router = this.get('router');
                var that = this;
                var imageLandscape = this.get('imageLandscape');
                var imagePortrait = this.get('imagePortrait');
                var image = this.get('image');
                var text = "";
                var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

                for (var i = 0; i < 10; i++) {
                    text += possible.charAt(Math.floor(Math.random() * possible.length));
                }aUser.then(function (user) {
                    aStaff.then(function (staff) {
                        if (!staff) {
                            console.log('there is no account');
                        } else {
                            var account = store.findRecord('account-info', staff.get('accountInfo.id'));
                            account.then(function (staffAccount) {
                                var accountType = staffAccount.get('accountType');
                                switch (accountType) {
                                    case "cTeacher_m":
                                    case "cTeacher_y":
                                    case "pTeacher":
                                        staff.setProperties({
                                            firstName: first.capitalize(),
                                            lastName: last.capitalize(),
                                            room: room,
                                            profession: job,
                                            birthday: bDay,
                                            started: sDay,
                                            pin: text,
                                            email: email,
                                            teacher: true
                                        });
                                        if (imageLandscape) {
                                            var avatar = store.createRecord('avatar', {
                                                id: newAvatarRef,
                                                imageLandscape: true,
                                                image: image
                                            });

                                            avatar.save().then(function (newAvatar) {
                                                staff.set('avatar', newAvatar);
                                            });
                                        } else if (imagePortrait) {
                                            var avatar = store.createRecord('avatar', {
                                                id: newAvatarRef,
                                                imagePortrait: true,
                                                image: image
                                            });

                                            avatar.save().then(function (newAvatar) {
                                                staff.set('avatar', newAvatar);
                                            });
                                        } else if (image) {
                                            var avatar = store.createRecord('avatar', {
                                                id: newAvatarRef,
                                                image: image
                                            });

                                            avatar.save().then(function (newAvatar) {
                                                staff.set('avatar', newAvatar);
                                            });
                                        } else {}

                                        if (!(typeof allergies === 'undefined' ? 'undefined' : _typeof(allergies)) == 'undefined') {
                                            if (allergies.length === 1) {
                                                staff.get('allergies').addObject(allergies);
                                            } else {
                                                staff.get('allergies').addObjects(allergies);
                                            }
                                        }

                                        that.createPersonalSchool(className, state, district, room).then(function (school) {
                                            user.setProperties({ 'betaTester': true, 'firstName': first.capitalize(), 'lastName': last.capitalize(), isNew: "dashboard.setup.step-one" });
                                            user.get('schools').addObject(school);
                                            user.save().then(function () {
                                                staff.set('school', school);
                                                staff.save().then(function (savedStaff) {
                                                    var staffList = store.findRecord('staff-list', school.get('staffList.id'));
                                                    staffList.then(function (foundList) {
                                                        foundList.get('staff').addObject(savedStaff);
                                                        foundList.save();
                                                    });
                                                    that.setProperties({ successMessage: 'Nice, just a few more questions' });
                                                    setTimeout(function () {
                                                        router.transitionTo('dashboard.setup.step-one');
                                                    }, 1500);
                                                }, function (error) {
                                                    console.log('Error : ' + error);
                                                });
                                            }, function (error) {
                                                console.log('Error : ' + error);
                                            });
                                        }, function (error) {
                                            console.log('Error : ' + error);
                                        });
                                        break;
                                    case "cAdmin_m":
                                    case "cAdmin_y":
                                    case "pAdmin":
                                        staff.setProperties({
                                            firstName: first.capitalize(),
                                            lastName: last.capitalize(),
                                            room: room,
                                            profession: job,
                                            birthday: bDay,
                                            started: sDay,
                                            pin: text,
                                            email: email,
                                            admin: true
                                        });
                                        if (imageLandscape) {
                                            var avatar = store.createRecord('avatar', {
                                                id: newAvatarRef,
                                                imageLandscape: true,
                                                image: image
                                            });

                                            avatar.save().then(function (newAvatar) {
                                                staff.set('avatar', newAvatar);
                                            });
                                        } else if (imagePortrait) {
                                            var avatar = store.createRecord('avatar', {
                                                id: newAvatarRef,
                                                imagePortrait: true,
                                                image: image
                                            });

                                            avatar.save().then(function (newAvatar) {
                                                staff.set('avatar', newAvatar);
                                            });
                                        } else if (image) {
                                            var avatar = store.createRecord('avatar', {
                                                id: newAvatarRef,
                                                image: image
                                            });

                                            avatar.save().then(function (newAvatar) {
                                                staff.set('avatar', newAvatar);
                                            });
                                        } else {}

                                        if (allergies) {
                                            if (allergies.length === 1) {
                                                staff.get('allergies').addObjects(allergies);
                                            } else {
                                                staff.get('allergies').addObjects(allergies);
                                            }
                                        }

                                        that.createPersonalSchool(className, state, district, room).then(function (school) {
                                            user.setProperties({ 'betaTester': true, 'firstName': first.capitalize(), 'lastName': last.capitalize(), isNew: "dashboard.setup.step-one" });
                                            user.get('schools').addObject(school);
                                            user.get('staff').addObject(staff);
                                            user.save().then(function () {
                                                staff.set('school', school);
                                                staff.save().then(function (savedStaff) {
                                                    var staffList = store.findRecord('staff-list', school.get('staffList.id'));
                                                    staffList.then(function (foundList) {
                                                        foundList.get('staff').addObject(savedStaff);
                                                        foundList.save();
                                                    });
                                                    that.setProperties({ successMessage: 'Nice, just a few more questions' });
                                                    setTimeout(function () {
                                                        router.transitionTo('dashboard.setup.step-one');
                                                    }, 1500);
                                                }, function (error) {
                                                    console.log('Error : ' + error);
                                                });
                                            }, function (error) {
                                                console.log('Error : ' + error);
                                            });
                                        }, function (error) {
                                            console.log('Error : ' + error);
                                        });
                                        break;
                                    default:
                                        console.log('not accountType yet');
                                        break;
                                }
                            }, function (error) {
                                console.log('Error : ' + error);
                            });
                        }
                    }, function (error) {
                        console.log('Error : ' + error);
                    });
                }, function (error) {
                    console.log('Error : ' + error);
                });
            },
            saveExt: function saveExt(ext) {
                var room = this.get('selectedRoom');
                room.set('ext', ext);
                room.save();
            },
            createRoom: function createRoom(room) {
                var firebaseRef = this.get('firebaseApp').database();
                var roomRef = firebaseRef.push();
                var newRoomRef = 'room' + roomRef.key;
                var that = this;
                var store = this.get('store');
                var newRoom = store.createRecord('room', {
                    id: newRoomRef,
                    name: room
                });
                newRoom.save().then(function (theRoom) {
                    that.set('selectedRoom', theRoom);
                }, function (error) {
                    console.log('Error : ' + error);
                });
            },
            createAllergy: function createAllergy(select, e) {
                if (e.keyCode === 13 && select.isOpen && !select.highlighted && !Ember.isBlank(select.searchText)) {

                    var selected = this.get('selected');
                    if (select.searchText) {
                        var firebaseRef = this.get('firebaseApp').database();
                        var allergyRef = firebaseRef.push();
                        var newAllergyRef = 'allergy' + allergyRef.key;
                        var newAllergy = this.get('store').createRecord('allergy', {
                            id: newAllergyRef,
                            name: select.searchText
                        });
                        newAllergy.save().then(function (savedAllergy) {});

                        select.actions.choose(select.searchText);
                    }
                }
            }
        }
    });
});