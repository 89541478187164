define('sokol-today/routes/dashboard/courses/add', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		firebaseApp: Ember.inject.service(),
		model: function model() {
			var firebaseRef = this.get('firebaseApp').database().ref('courseInfo/');
			var courseRef = firebaseRef.push();
			var newCourseRef = 'course' + courseRef.key;
			return this.store.createRecord('course-info', {
				id: newCourseRef
			});
		}
	});
});