define('sokol-today/components/website/keep-posted', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        store: Ember.inject.service(),
        session: Ember.inject.service(),
        firebaseApp: Ember.inject.service(),
        router: Ember.inject.service('-routing'),
        // connectedUser: Ember.inject.service(),
        errorMessageCreate: '',
        success: false,
        fail: false,
        initial: true,
        navbar: false,

        didInsertElement: function didInsertElement() {
            $("input:password").focus(function () {
                this.type = "text";
            }).blur(function () {
                this.type = "password";
            });
        },


        // user: Ember.computed('session', 'user', function(){
        //     var store = this.get('store');
        //     var sessionID = this.get('session.content.uid');

        //     return store.findRecord('user', sessionID);
        // }),

        notFilledOut: Ember.computed('email', 'password', 'passConfirm', function () {
            var email = this.get('email');
            var passphrase = this.get('password');
            var confirmed = this.get('passConfirm');

            if (email && passphrase && confirmed && passphrase === confirmed) {
                return false;
            } else {
                return true;
            }
        }),

        match: Ember.computed('password', 'passConfirm', function () {
            var passphrase = this.get('password');
            var confirmed = this.get('passConfirm');

            if (passphrase === confirmed || typeof confirmed === 'undefined' || confirmed === null || String(confirmed).length < 9) {
                return true;
            } else {
                return false;
            }
        }),

        checkEmailUsed: function checkEmailUsed(email) {
            var store = this.get('store');

            return store.query('interested-party', {
                orderBy: 'email',
                equalTo: email
            });
        },


        actions: {
            saveInfo: function saveInfo(emailCreate, passwordCreate, passwordCreateConfirm, fName, lName, from) {
                var torii = this.get('session');
                var store = this.get('store');
                var self = this;
                var newUser = this.get('newUser');
                var imageLandscape = this.get('imageLandscape');
                var imagePortrait = this.get('imagePortrait');
                var image = this.get('image');
                if (typeof passwordCreate === 'undefined' || typeof passwordCreateConfirm === 'undefined') {
                    self.setProperties({ passwordError: 'must have a password' });
                    return;
                }
                if (passwordCreate !== passwordCreateConfirm) {
                    self.setProperties({ passwordError: 'passwords do not match' });
                    return;
                }
                if (typeof emailCreate === 'undefined') {
                    self.setProperties({ emailError: 'most provide an email' });
                    return;
                }
                var text = "";
                var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

                for (var i = 0; i < 10; i++) {
                    text += possible.charAt(Math.floor(Math.random() * possible.length));
                }

                var auth = this.get('firebaseApp').auth();
                auth.createUserWithEmailAndPassword(emailCreate, passwordCreate).then(function (d) {
                    var newUser = store.createRecord('user', {
                        id: d.uid,
                        firstName: fName,
                        lastName: lName,
                        email: emailCreate,
                        role: 0,
                        pin: text,
                        interestFrom: from,
                        isNew: 'dashboard.account.create.step-two'
                    });

                    newUser.save().then(function (savedUser) {
                        torii.open('firebase', {
                            provider: 'password',
                            email: emailCreate,
                            password: passwordCreate
                        }).then(function (d) {
                            self.setProperties({ initial: true });
                            if (imageLandscape) {
                                var avatar = store.createRecord('avatar', {
                                    imageLandscape: true,
                                    image: imageLandscape
                                });

                                avatar.save().then(function (newAvatar) {
                                    savedUser.set('avatar', newAvatar);
                                    savedUser.save();
                                }, function (error) {
                                    console.log('Error : ' + error);
                                });
                            } else if (imagePortrait) {
                                var avatar = store.createRecord('avatar', {
                                    imagePortrait: true,
                                    image: imagePortrait
                                });

                                avatar.save().then(function (newAvatar) {
                                    savedUser.set('avatar', newAvatar);
                                    savedUser.save();
                                }, function (error) {
                                    console.log('Error : ' + error);
                                });
                            } else if (image) {
                                var avatar = store.createRecord('avatar', {
                                    image: image
                                });

                                avatar.save().then(function (newAvatar) {
                                    savedUser.set('avatar', newAvatar);
                                    savedUser.save();
                                }, function (error) {
                                    console.log('Error : ' + error);
                                });
                            } else {
                                console.log('no info found');
                            }
                            // self.get('router').transitionTo('dashboard.account.create.step-two');

                            return savedUser;
                        }, function (error) {
                            self.setProperties({ errorMessage: error.message, login: true, displayChoice: false });
                        });
                    }, function (error) {
                        self.setProperties({ errorMessageCreate: error.message });
                    });
                }, function (error) {
                    self.setProperties({ errorMessageCreate: error.message });
                });
            },
            btnClick: function btnClick() {
                this.toggleProperty('initial');
            },
            toProfile: function toProfile(theUser) {
                var store = this.get('store');
                var model = store.findRecord('user', theUser.get('id'));
                var route = this.get('router');
                model.then(function (modelFound) {
                    console.log(modelFound);
                    route.transitionTo('account.profile');
                });
            }
        }
    });
});