define('sokol-today/models/demo/student', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        SASID: _emberData.default.attr('string'),
        avatar: _emberData.default.belongsTo('demo/avatar'),
        gender: _emberData.default.attr('string'),
        raceCode: _emberData.default.belongsTo('demo/race-code', { async: true }),
        birthPlace: _emberData.default.attr('string'),
        payDistrict: _emberData.default.belongsTo('demo/district', { async: true }),
        resident: _emberData.default.attr('boolean', { defaultValue: true }),
        tuition: _emberData.default.attr('boolean', { defaultValue: true }),
        active: _emberData.default.attr('boolean', { defaultValue: true }),
        firstName: _emberData.default.attr('string'),
        lastName: _emberData.default.attr('string'),
        birthday: _emberData.default.attr('date'),
        pin: _emberData.default.attr('string'),
        classes: _emberData.default.hasMany('demo/class', { inverse: null }),
        assignments: _emberData.default.hasMany('demo/assignment', { async: true }),
        parents: _emberData.default.hasMany('demo/parent', { async: true }),
        relationships: _emberData.default.hasMany('demo/relationships', { async: true }),
        absences: _emberData.default.hasMany('demo/absence', { async: true }),
        grades: _emberData.default.hasMany('demo/grade', { async: true }),
        user: _emberData.default.belongsTo('demo/user', { async: true })
    });
});