define('sokol-today/components/dashboard/students/student/add/class/step-one', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    store: Ember.inject.service(),
    daySort: ['numeric'],
    classSort: ['startTime.time'],

    schoolDays: Ember.computed('', function () {
      var store = this.get('store');
      return store.findAll('day-list');
    }),

    dayList: Ember.computed.sort('schoolDays', 'daySort'),

    //Organize scheduled classes by time
    organizedClasses: Ember.computed.sort('studentClasses', 'classSort').property('studentClasses'),

    //Get classes student may already be in and populate schedule
    studentClasses: Ember.computed.filter('student.classes.@each.year', function (theClass) {
      if (theClass.get('year.id') == this.get('connectedUser.currentYear.id')) {
        return true;
      } else {
        return false;
      }
    }).property('connectedUser.currentYear', 'student.classes'),

    //Options for POwer select classes
    classList: Ember.computed('connectedUser.currentYear', function () {
      var store = this.get('store');
      var year = this.get('connectedUser.currentYear.id');
      return store.query('class', {
        orderBy: 'year',
        equalTo: year
      });
    }),

    actions: {
      handleClassChange: function handleClassChange(record, highlighted) {
        var theStudent = this.get('student');
        var _this = this;
        if (record.length < highlighted.selected.length) {
          // A class was removed        
          var myArray = [];
          myArray.pushObjects(highlighted.selected);
          myArray.removeObjects(record);
          var theClass = myArray.get('firstObject');
          theClass.get('students').removeObject(theStudent);
          //remove student from class and save class
          theClass.save().then(function (classRemoved) {
            //remove class from student
            theStudent.get('classes').removeObject(classRemoved);
            theStudent.save().then(function () {
              highlighted.selected.removeObjects(myArray);
              _this.notifyPropertyChange('studentClasses');
            }).catch(function (error) {
              console.log(error);
            });
          }, function (error) {
            console.log('Error : ' + error);
          });
        } else if (record.length > highlighted.selected.length) {
          // class was added
          var classAdded = highlighted.highlighted;
          classAdded.get('students').addObject(theStudent);
          theStudent.get('classes').addObject(classAdded);
          theStudent.save().then(function () {
            classAdded.save().then(function (classSaved) {
              highlighted.selected.addObject(classAdded);
            }, function (error) {
              console.log('Error : ' + error);
            });
          }, function (error) {
            console.log('Error : ' + error);
          });
        } else {
          console.log('hopefully nothing changed');
        }
      }
    }

  });
});