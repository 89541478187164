define('sokol-today/components/dashboard/classes/classroom/grade-book', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    actions: {
      attendanceDateSelected: function attendanceDateSelected(attendanceDate) {
        var today = moment();
        var adjust = moment.duration(2, 'h');
        var adjustedToday = today.add(adjust);
        //add two hours onto old dates that start at 0hrs and compare
        if (moment(attendanceDate).isBefore(adjustedToday)) {
          var time = moment.duration(8, 'h');
          var correctedTime = moment(attendanceDate).add(time);
          this.setProperties({ attendanceDate: correctedTime });
        } else {
          this.setProperties({ attendanceDate: attendanceDate });
        }
      },
      toggleAssignmentAddDisplay: function toggleAssignmentAddDisplay() {
        this.toggleProperty('addAssignment');
      }
    }
  });
});