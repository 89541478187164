define('sokol-today/components/dashboard/rubric/add/custom-rubric', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        connectedUser: Ember.inject.service(),
        store: Ember.inject.service(),
        rubric: null,

        actions: {
            addDomain: function addDomain(name) {
                var store = this.get('store');
                var session = this.get('session');
                var self = this;
                var user = this.get('connectedUser.user');
                if (!this.get('rubric')) {
                    var rubric = store.createRecord('rubric', {
                        name: name
                    });
                    user.then(function (theUser) {
                        var school = theUser.get('school');
                        var rubricList = store.findRecord('rubric-list', school.get('rubricList.id'));

                        rubricList.then(function (theRubricList) {

                            rubric.save().then(function (theRubric) {
                                theRubricList.get('rubrics').addObject(theRubric);
                                theRubricList.save();
                                var domain = store.createRecord('rubric-domain', {
                                    name: 'Domain Name'
                                });
                                domain.save().then(function (theDomain) {
                                    theRubric.get('domains').addObject(theDomain);
                                    theRubric.save().then(function (savedRubric) {
                                        self.set('rubric', savedRubric);
                                    });
                                    console.log('you are here');
                                }, function (error) {
                                    console.log('Error : ' + error);
                                });
                            }, function (error) {
                                console.log('Error : ' + error);
                            });
                        }, function (error) {
                            console.log('Error : ' + error);
                        });
                    });
                } else {
                    var _rubric = self.get('rubric');
                    user.then(function (theUser) {
                        var school = theUser.get('school');

                        var domain = store.createRecord('rubric-domain', {
                            name: 'Domain Name'
                        });
                        domain.save().then(function (theDomain) {
                            _rubric.get('domains').addObject(theDomain);
                            _rubric.save().then(function (savedRubric) {
                                self.set('rubric', savedRubric);
                            });
                            console.log('you are here');
                        }, function (error) {
                            console.log('Error : ' + error);
                        });
                    }, function (error) {
                        console.log('Error : ' + error);
                    });
                }
            }
        }
    });
});