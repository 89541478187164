define('sokol-today/services/route-history', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Service.extend({
		previousRoute: null,
		nextRoute: null
	});
});