define('sokol-today/components/helper/card-class', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        connectedUser: Ember.inject.service(),
        router: Ember.inject.service('-routing'),
        store: Ember.inject.service(),
        cardFlip: false,

        click: function click() {
            this.toggleProperty('cardFlip');
        },


        actions: {
            getStaffInfo: function getStaffInfo(staff) {
                console.log(staff);
                var router = this.get('router');
                router.transitionTo('dashboard.staff.info');
            },
            fixTeacherIssue: function fixTeacherIssue() {
                // var store = this.get('store');
                // store.findAll('class').then(function(classes){
                // 	classes.get('teacher').then(function(theTeacher){
                // 		classes.get('staff').addObject(theTeacher).then(function(newStaff){
                // 			classes.save();
                // 		});
                // 	});
                // });
            }
        }

    });
});