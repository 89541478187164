define('sokol-today/models/charge', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        object: _emberData.default.attr('string'),
        amount: _emberData.default.attr('number'),
        amount_refunded: _emberData.default.attr('number'),
        application: _emberData.default.attr('string'),
        application_fee: _emberData.default.attr('number'),
        balance_transaction: _emberData.default.attr('string'),
        captured: _emberData.default.attr('boolean'),
        created: _emberData.default.attr('date'),
        currency: _emberData.default.attr('string'),
        customer: _emberData.default.belongsTo('account-info', { inverse: null }),
        description: _emberData.default.attr('string'),
        destination: _emberData.default.attr('string'),
        dispute: _emberData.default.attr('string'),
        failure_code: _emberData.default.attr('string'),
        failure_message: _emberData.default.attr('string'),
        fraud_details: _emberData.default.attr(),
        invoice: _emberData.default.belongsTo('invoice', { inverse: 'charge' }),
        livemode: _emberData.default.attr('boolean'),
        metadata: _emberData.default.attr(),
        on_behalf_of: _emberData.default.attr('string'),
        order: _emberData.default.attr('string'),
        outcome: _emberData.default.attr('string'),
        paid: _emberData.default.attr('boolean'),
        receipt_email: _emberData.default.attr('string'),
        receipt_number: _emberData.default.attr('string'),
        refunded: _emberData.default.attr('boolean'),
        refunds: _emberData.default.attr(),
        review: _emberData.default.attr('string'),
        shipping: _emberData.default.attr('string'),
        source: _emberData.default.attr(),
        source_transfer: _emberData.default.attr('string'),
        statement_descriptor: _emberData.default.attr('string'),
        status: _emberData.default.attr('string'),
        transfer_group: _emberData.default.attr('string')
    });
});