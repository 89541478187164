define('sokol-today/components/account/user/user-create/step-four/add-school', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    states: Ember.computed('states', function () {
      var store = this.get('store');
      return store.query('state', { filter: { verified: true } }).then(function (states) {
        return states;
      });
      // return ['New Hampshire', 'Maine'];
    }).property('states')
  });
});