define('sokol-today/models/photo', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    classes: _emberData.default.hasMany('class'), //Link to classroom(s) that are in picture to be shared
    favorites: _emberData.default.hasMany('user', { async: true, inverse: null }), //Keep track of likes
    filePath: _emberData.default.attr('string'), //File Storage Location
    fileType: _emberData.default.attr('string'),
    metadata: _emberData.default.attr(),
    date: _emberData.default.attr('date'), //Date from Photo Meta data, or day uploaded if no metadata present
    owner: _emberData.default.belongsTo('staff', { async: true, inverse: null }), //Who took the picture
    school: _emberData.default.belongsTo('school', { async: true, inverse: null }), //Find photos by school
    year: _emberData.default.belongsTo('year', { async: true, inverse: null }), //Find photos by year
    downloads: _emberData.default.attr('number'), //Keep track of number of downloads
    saves: _emberData.default.hasMany('user', { async: true, inverse: null }) //Keep track of saves to students pages
  });
});