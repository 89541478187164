define('sokol-today/components/account/user/profile-button', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        connectedUser: Ember.inject.service(),
        store: Ember.inject.service(),
        profileHidden: true,
        account: true,
        students: false,
        school: false,

        staff: Ember.computed('connectedUser.staff', function () {
            return this.get('connectedUser.staff');
        }),

        years: Ember.computed.mapBy('connectedUser.school.yearList.years', 'year'),

        studentsAll: Ember.computed.mapBy('connectedUser.school.studentList.students', 'student'),

        yearsAll: Ember.computed.mapBy('studentsAll.years', 'year'),

        // studentsPerYear: {
        //     chartOptions: {
        //         chart: {
        //             type: 'bar'
        //         },
        //         title: {
        //             text: 'Total Students Per Year'
        //         },
        //         xAxis: {
        //             categories: ['Apples', 'Bananas', 'Oranges']
        //         },
        //         yAxis: {
        //             title: {
        //                 text: 'Total Students'
        //             }
        //         }
        //     },

        //     chartData: [{
        //         name: 'Jane',
        //         data: [1, 0, 4]
        //     }, {
        //         name: 'John',
        //         data: [5, 7, 3]
        //     }],

        //     theme: defaultTheme
        // },
        activeStudents: Ember.computed.filter('connectedUser.school.studentList.students', function (student, index, array) {
            var result = student.get('active');
            return result === true;
        }),
        actions: {
            toggleUserProfile: function toggleUserProfile() {
                this.toggleProperty('profileHidden');
            },
            toggleDisplay: function toggleDisplay(display) {
                this.setProperties({ account: false, students: false, school: false });
                this.toggleProperty(display);
            }
        }
    });
});