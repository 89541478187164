define('sokol-today/models/staff-list', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    school: _emberData.default.belongsTo('school', { async: true, inverse: 'staffList' }),
    staff: _emberData.default.hasMany('staff', { async: true, inverse: null })
  });
});