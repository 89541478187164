define('sokol-today/services/app-events', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Service.extend({
		eventDragOverApp: null,
		eventDrag: null,
		eventMouseDown: null
	});
});