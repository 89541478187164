define('sokol-today/components/account/user/user-create-with-pin/step-three', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    newUser: Ember.inject.service(),
    connectedUser: Ember.inject.service(),
    store: Ember.inject.service(),
    router: Ember.inject.service('-routing'),
    error: false,
    errorMessage: '',
    didInsertElement: function didInsertElement() {
      var newUser = this.get('newUser');
      newUser.setProperties({ hasUser: true, haveAccountType: true, havePin: false, haveStaffConfirm: false });
      var user = this.get('connectedUser.user');
      var router = this.get('router');

      // if (!user) {
      //   router.transitionTo('account.user-create-with-pin.step-one');
      // }
    },
    keyDown: function keyDown() {
      $(".new-user").keyup(function () {
        if (this.value.length == this.maxLength) {
          var $next = $(this).parent().next().find('.new-user');
          if ($next.length) {
            $next.focus();
          } else {
            $(this).blur();
          }
        }
      });
    },

    actions: {
      findStaff: function findStaff(pin, pin1, pin2, pin3, pin4, pin5, pin6, pin7, pin8, pin9) {
        console.log('in find staff');
        if (!pin || !pin1 || !pin2 || !pin3 || !pin4 || !pin5 || !pin6 || !pin7 || !pin8 || !pin9) {
          this.set('error', true);
          console.log('error with PINS');
          return;
        }
        var self = this;
        var key = pin + pin1 + pin2 + pin3 + pin4 + pin5 + pin6 + pin7 + pin8 + pin9;
        var store = this.get('store');
        var router = this.get('router');
        var teacher = store.query('staff', {
          orderBy: 'pin',
          equalTo: key
        });
        teacher.then(function (res) {
          console.log(res);
          if (res.content.length == 0) {
            self.set('errorMessage', 'We could find no matching staff account with that pin');
            return;
          }
          if (res.content.length > 1) {
            self.set('errorMessage', 'There is an error with your pin, please have your school admin create another one');
            return;
          } else {
            self.setProperties({ successMessage: 'Staff file found', errorMessage: '' });
            setTimeout(function () {
              self.get('transitionToStepFour')(res.content.objectAt(0));
            }, 1000);
          }
        }, function (error) {
          self.setProperties({ errorMessage: 'There was an error we did not forsee, please notify us at help@sokool.today' });
          console.log('Error : ' + error);
        });
      }
    }
  });
});