define('sokol-today/components/account/user/user-create/step-two', ['exports'], function (exports) {
		'use strict';

		Object.defineProperty(exports, "__esModule", {
				value: true
		});
		exports.default = Ember.Component.extend({
				firebaseApp: Ember.inject.service(),
				newUser: Ember.inject.service(),
				session: Ember.inject.service(),
				store: Ember.inject.service(),
				router: Ember.inject.service('-routing'),

				didInsertElement: function didInsertElement() {
						var newUser = this.get('newUser');
						newUser.setProperties({ hasUser: true });
				},


				actions: {
						saveRole: function saveRole(role) {
								var router = this.get('router');
								var newUser = this.get('newUser');
								var route = 'account.user-create.step-three';
								var session = this.get('session.content.uid');
								var store = this.get('store');
								var user = store.findRecord('user', session);
								var firebaseRef = this.get('firebaseApp').database();

								user.then(function (theUser) {
										var account = firebaseRef.push();
										switch (role) {
												case "student":
														var studentRef = 'student' + account.key;
														console.log('student');
														var student = store.createRecord('student', {
																id: studentRef,
																user: theUser
														});
														student.set('role', 30);
														theUser.set('isNew', route);
														student.save().then(function (savedStudent) {
																theUser.get('student').addObject(savedStudent);
																theUser.set('defaultAccount', savedStudent);
																theUser.save().then(function () {
																		newUser.setProperties({ hasType: true });
																		router.transitionTo(route);
																}, function (error) {
																		console.log('Error : ' + error);
																});
														}, function (error) {
																console.log('Error : ' + error);
														});
														break;
												case "parent":
														var guardianRef = 'guardian' + account;
														var guardian = store.createRecord('guardian', {
																id: guardianRef,
																user: theUser
														});
														guardian.set('role', 40);
														theUser.set('isNew', route);
														guardian.save().then(function (savedGuardian) {
																theUser.get('guardian').addObject(savedGuardian);
																theUser.set('defaultAccount', savedGuardian);
																theUser.save().then(function () {
																		newUser.setProperties({ hasType: true });
																		router.transitionTo(route);
																}, function (error) {
																		console.log('Error : ' + error);
																});
														}, function (error) {
																console.log('Error : ' + error);
														});
														break;
												case "teacher":
														var teacherRef = 'staff' + account;
														var staff = store.createRecord('staff', {
																id: teacherRef,
																user: theUser
														});
														staff.set('role', 89);
														theUser.set('isNew', route);
														staff.save().then(function (savedStaff) {
																theUser.get('staff').addObject(savedStaff);
																theUser.set('defaultAccount', savedStaff);
																theUser.save().then(function () {
																		newUser.setProperties({ hasType: true });
																		router.transitionTo(route);
																}, function (error) {
																		console.log('Error : ' + error);
																});
														}, function (error) {
																console.log('Error : ' + error);
														});
														break;
												case "administrator":
														var adminRef = 'staff' + account;
														var staff = store.createRecord('staff', {
																id: adminRef,
																user: theUser
														});
														staff.set('role', 90);
														theUser.set('isNew', route);
														staff.save().then(function (savedStaff) {
																theUser.get('staff').addObject(savedStaff);
																theUser.set('defaultStaff', savedStaff);
																theUser.save().then(function () {
																		newUser.setProperties({ hasType: true });
																		router.transitionTo(route);
																}, function (error) {
																		console.log('Error : ' + error);
																});
														}, function (error) {
																console.log('Error : ' + error);
														});
														break;
												default:
														return;
										}
								});
						}
				}
		});
});