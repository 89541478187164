define('sokol-today/components/pricing/teacher-accounts', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        connectedUser: Ember.inject.service(),
        store: Ember.inject.service(),
        router: Ember.inject.service('-routing'),

        actions: {
            processStripeToken: function processStripeToken(plan, token) {
                var self = this;
                var router = this.get('router');
                var staff = this.get('connectedUser.staff');
                var store = this.get('store');

                if (!staff) {
                    console.log('no staff id yet');
                    return;
                }
                switch (plan) {
                    case "cTeacher_m":
                    case "cTeacher_y":
                        var hasAccountAlready = staff.get('accountInfo');
                        if (typeof hasAccountAlready === 'undefined') {
                            console.log('no accountInfo yet');
                        } else {
                            console.log('have account info');
                            $.get({
                                url: "https://us-central1-scope-95d64.cloudfunctions.net/createStripeSubscriptionCharge?token=" + JSON.stringify(token) + '&staffID=' + staff.get('id') + '&plan=' + plan,
                                json: true
                            }).done(function (data) {
                                console.log(data);
                                if (data.error) {
                                    self.setProperties({ errorMessage: data.error });
                                } else if (data.success) {
                                    store.findRecord('user', staff.get('user.id')).then(function (theUser) {
                                        theUser.set('isNew', 'account.user-create.step-four.personal');
                                        theUser.save().then(function () {
                                            self.setProperties({ errorMessage: '', successMessage: data.success });
                                            setTimeout(function () {
                                                router.transitionTo('account.user-create.step-four.personal');
                                                self.setProperties({ successMessage: '' });
                                            }, 1500);
                                        }, function (error) {
                                            console.log('Error : ' + error);
                                        });
                                    });
                                }
                            });
                        }
                        return;
                        break;
                    case "dTeacher":
                        user.set('isNew', 'account.user-create.step-four.group');
                        user.save().then(function () {
                            route.transitionTo('account.user-create.step-four.group');
                        }, function (error) {
                            console.log('Error : ' + error);
                        });
                        return;
                        break;
                    default:
                        console.log('default...?');
                        break;
                }
            },
            toggleMonthly: function toggleMonthly() {
                this.toggleProperty('monthly');
            },
            personalPlan: function personalPlan() {},
            createStripeAccount: function createStripeAccount(email, uid) {
                var self = this;
                if (!email && !uid) {
                    self.setProperties({ errorMessage: 'need email and staff id to proceed' });
                    return;
                }
                $.get({
                    url: "https://us-central1-scope-95d64.cloudfunctions.net/createStripeCustomerHttp?email=" + email + '&uid=' + uid
                }).done(function (data) {
                    console.log(data);
                    if (data.error) {
                        self.setProperties({ errorMessage: data.error });
                    } else if (data.success) {
                        self.setProperties({ errorMessage: '', successMessage: data.success });

                        setTimeout(function () {
                            self.setProperties({ successMessage: '' });
                        }, 1500);
                    }
                });
            }
        }
    });
});