define("sokol-today/routes/website", ["exports"], function (exports) {
       "use strict";

       Object.defineProperty(exports, "__esModule", {
              value: true
       });
       exports.default = Ember.Route.extend({
              activate: function activate() {
                     // this._super();
                     return this.get("session").fetch().catch(function () {});
              }
       });
});