define('sokol-today/components/nav/site-navbar', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		connectedUser: Ember.inject.service(),

		actions: {
			clickedMenu: function clickedMenu() {
				this.toggleProperty('hideNavBar');
			}
		}
	});
});