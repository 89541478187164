define('sokol-today/models/interested-party', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		email: _emberData.default.attr('string'),
		info: _emberData.default.attr('string')
	});
});