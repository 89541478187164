define('sokol-today/models/student', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        SASID: _emberData.default.attr('string'),
        avatar: _emberData.default.belongsTo('avatar'),
        started: _emberData.default.attr('date'),
        gender: _emberData.default.attr('string'),
        classOf: _emberData.default.attr('string'),
        allergies: _emberData.default.hasMany('allergy', { async: true }),
        raceCode: _emberData.default.belongsTo('race-code', { async: true }),
        birthPlace: _emberData.default.attr('string'),
        payDistrict: _emberData.default.belongsTo('district', { async: true }),
        resident: _emberData.default.attr('boolean', { defaultValue: true }),
        tuition: _emberData.default.attr('boolean', { defaultValue: true }),
        active: _emberData.default.attr('boolean', { defaultValue: true }),
        deactivated: _emberData.default.attr('date'),
        firstName: _emberData.default.attr('string'),
        lastName: _emberData.default.attr('string'),
        birthday: _emberData.default.attr('date'),
        pin: _emberData.default.attr('string'),
        photos: _emberData.default.hasMany('photo'),
        years: _emberData.default.hasMany('year', { async: true }),
        classes: _emberData.default.hasMany('class', { inverse: 'students' }),
        assignments: _emberData.default.hasMany('assignment', { async: true, inverse: 'student' }),
        relationships: _emberData.default.hasMany('relationship', { async: true }),
        absences: _emberData.default.hasMany('absence', { async: true }),
        diciplinary: _emberData.default.hasMany('diciplinary', { async: true }),
        grades: _emberData.default.hasMany('student-grade', { async: true }),
        school: _emberData.default.belongsTo('school', { async: true }), //Need to make this a has many, see history of schools for student
        user: _emberData.default.belongsTo('user', { async: true }),
        student: Ember.computed('', function () {
            return true;
        })
    });
});