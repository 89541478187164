define('sokol-today/routes/dashboard/classes/add', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		firebaseApp: Ember.inject.service(),
		model: function model() {
			var ref = this.get('firebaseApp').database().ref('classes/');
			var newRef = ref.push();
			var realRef = "class" + newRef.key;
			return this.store.createRecord('class', {
				id: realRef
			});
		}
	});
});