define('sokol-today/routes/website/home', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        activate: function activate() {
            this._super();
            window.scrollTo(0, 0);
        },

        model: function model() {
            var date = new Date();
            return this.store.createRecord('demo/observation-block', {
                // timestampStart:date
            });
        }
    });
});