define('sokol-today/components/dashboard/students/add/step-two', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        firebaseApp: Ember.inject.service(),
        newUser: Ember.inject.service(),
        connectedUser: Ember.inject.service(),
        store: Ember.inject.service(),
        didInsertElement: function didInsertElement() {
            var newUser = this.get('newUser');
            newUser.setProperties({ haveStudent: true });
        },

        relations: Ember.computed('store', function () {
            return this.get('store').findAll('relationList');
        }),
        actions: {
            willDestroyElement: function willDestroyElement() {
                alert('will be destroyed');
            },
            removeTime: function removeTime() {
                this.setProperties({ isFull: false, isPart: false });
            },
            addTime: function addTime(button) {
                switch (button) {
                    case "Full":
                        this.setProperties({ isFull: true, isPart: false });
                        break;
                    case "Part":
                        this.setProperties({ isFull: false, isPart: true });
                }
            },
            saveGuardian: function saveGuardian(first, last, email, cPhone, hPhone, sNumber, sName, townName, stateName, zipCode, relation, student, stayFull, stayPart, existingRelationship) {
                var store = this.get('store');
                var firebaseRef = this.get('firebaseApp').database();
                var school = this.get('connectedUser.staff.school');
                var that = this;
                if (!existingRelationship) {
                    // Check for bare minimum data required to save a relationship
                    if (!first && !last) {
                        this.setProperties({ errorMessage: 'We need both first and last name information' });
                        return;
                    } else if ((!cPhone || !hPhone) && !email) {
                        this.setProperties({ errorMessage: 'We need some form of contact information' });
                        return;
                    } else if (!relation) {
                        this.setProperties({ errorMessage: 'How are they related?' });
                        return;
                    } else if ((!sNumber || !sName || !townName || !stateName) && (stayFull || stayPart)) {
                        this.setProperties({ errorMessage: 'The location is needed if the student stays with them' });
                        return;
                    } else {
                        this.setProperties({ errorMessage: '' });
                    }

                    var imageLandscape = this.get('imageLandscape');
                    var imagePortrait = this.get('imagePortrait');
                    var image = this.get('image');

                    var text = "";
                    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_";

                    for (var i = 0; i < 10; i++) {
                        text += possible.charAt(Math.floor(Math.random() * possible.length));
                    }

                    var guardianRef = firebaseRef.push();
                    var newGuardianRef = 'guardian' + guardianRef.key;
                    var newGuardian = store.createRecord('guardian', {
                        id: newGuardianRef,
                        firstName: first.capitalize(),
                        lastName: last.capitalize(),
                        cellPhone: cPhone,
                        homePhone: hPhone,
                        email: email,
                        addressStreet: sName,
                        addressStreetNum: sNumber,
                        addressState: stateName,
                        addressZip: zipCode,
                        addressTown: townName,
                        pin: text
                    });

                    var relationshipRef = firebaseRef.push();
                    var _newRelationshipRef = 'relation' + relationshipRef.key;
                    var newRelationship = store.createRecord('relationship', {
                        id: _newRelationshipRef,
                        relation: relation,
                        student: student,
                        homeFull: stayFull,
                        homePart: stayPart
                    });

                    newGuardian.get('students').addObject(student);

                    school.then(function (schoolLink) {
                        newGuardian.save().then(function (savedGuardian) {
                            newRelationship.set('guardian', savedGuardian);
                            var avatarRef = firebaseRef.push();
                            var newAvatarRef = 'avatar' + avatarRef.key;
                            if (imageLandscape) {
                                var avatar = store.createRecord('avatar', {
                                    id: newAvatarRef,
                                    imageLandscape: true,
                                    image: image
                                });

                                avatar.save().then(function (newAvatar) {
                                    savedGuardian.set('avatar', newAvatar);
                                    savedGuardian.save();
                                });
                            } else if (imagePortrait) {
                                var avatar = store.createRecord('avatar', {
                                    id: newAvatarRef,
                                    imagePortrait: true,
                                    image: image
                                });

                                avatar.save().then(function (newAvatar) {
                                    savedGuardian.set('avatar', newAvatar);
                                    savedGuardian.save();
                                });
                            } else if (image) {
                                var avatar = store.createRecord('avatar', {
                                    id: newAvatarRef,
                                    image: image
                                });

                                avatar.save().then(function (newAvatar) {
                                    savedGuardian.set('avatar', newAvatar);
                                    savedGuardian.save();
                                });
                            } else {}

                            var relationshipList = store.findRecord('relationship-list', schoolLink.get('relationshipList.id'));
                            relationshipList.then(function (theList) {
                                newRelationship.save().then(function (savedRelationship) {
                                    student.get('relationships').addObject(savedRelationship);
                                    student.save();
                                    theList.get('relationships').addObject(savedRelationship);
                                    theList.save().then(function () {
                                        that.setProperties({ successMessage: 'Saved Successful' });
                                        that.setProperties({ firstName: null, lastName: null, selectedStudent: null, email: null, cellPhone: null, homePhone: null, streetNumber: null, streetName: null, townName: null, stateName: null, zipCode: null });
                                    }, function (error) {
                                        console.log('Error : ' + error);
                                    });
                                }, function (error) {
                                    console.log('Error : ' + error);
                                });
                            }, function (error) {
                                console.log('Error : ' + error);
                            });
                        }, function (error) {
                            console.log('Error : ' + error);
                        });
                    }, function (error) {
                        console.log('Error : ' + error);
                    });
                } else {
                    var guardian = existingRelationship.get('guardian');
                    guardian.then(function (foundGuardian) {
                        var relationRef = firebaseRef.push();
                        var newRelationRef = 'relationship' + relationRef.key;
                        var newRelationship = store.createRecord('relationship', {
                            id: newRelationshipRef,
                            relation: relation,
                            guardian: foundGuardian,
                            student: student,
                            homeFull: stayFull,
                            homePart: stayPart
                        });

                        foundGuardian.get('students').addObject(student);

                        var relationshipList = store.findRecord('relationship-list', school.get('relationshipList.id'));
                        relationshipList.then(function (theList) {
                            newRelationship.save().then(function (savedRelationship) {
                                student.get('relationships').addObject(savedRelationship);
                                foundGuardian.save();
                                student.save();
                                theList.get('relationships').addObject(savedRelationship);
                                theList.save().then(function () {
                                    that.setProperties({ successMessage: 'Saved Successful' });
                                    that.setProperties({ firstName: null, lastName: null, selectedStudent: null, email: null, cellPhone: null, homePhone: null, streetNumber: null, streetName: null, townName: null, stateName: null, zipCode: null });
                                }, function (error) {
                                    console.log('Error : ' + error);
                                });
                            }, function (error) {
                                console.log('Error : ' + error);
                            });
                        }, function (error) {
                            console.log('Error : ' + error);
                        });
                    }, function (error) {
                        console.log('Error : ' + error);
                    });
                }
            }
        }
    });
});