define('sokol-today/routes/dashboard/assignments/add/step-one', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		didInsertElement: function didInsertElement() {
			this.setProperties({ assignmentUpload: true, assignmentInformation: false });
		}
	});
});