define('sokol-today/models/demo/school', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        name: _emberData.default.attr('string'),
        rooms: _emberData.default.hasMany('demo/room'),
        verified: _emberData.default.attr('boolean', { default: false }),
        totalPINS: _emberData.default.attr('number'),
        usedPINS: _emberData.default.attr('number'),
        teacherList: _emberData.default.belongsTo('demo/teacher-list', { async: true }),
        yearList: _emberData.default.belongsTo('demo/year-list', { async: true }),
        observationList: _emberData.default.belongsTo('demo/observation-list', { asyn: true }),
        rubricList: _emberData.default.belongsTo('demo/rubric-list', { async: true }),
        absenceList: _emberData.default.belongsTo('demo/absence-list', { async: true }),
        assignmentList: _emberData.default.belongsTo('demo/assignment-list', { async: true }),
        roomList: _emberData.default.belongsTo('demo/room-list', { async: true }),
        studentList: _emberData.default.belongsTo('demo/student-list', { async: true }),
        parentList: _emberData.default.belongsTo('demo/parent-list', { async: true }),
        professionList: _emberData.default.belongsTo('demo/profession-list', { async: true }),
        adminList: _emberData.default.belongsTo('demo/admin-list', { async: true }),
        classList: _emberData.default.belongsTo('demo/class-list', { async: true }),
        classNameList: _emberData.default.belongsTo('demo/class-name-list', { async: true })
    });
});