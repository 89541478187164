define('sokol-today/components/dashboard/classes/classroom/grades/grade-book', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        store: Ember.inject.service(),
        assignment: null,
        stepsArray: [],
        steps: Ember.computed('assignment.myAssignment.steps', function () {
            var self = this;
            var assignmentInfo = this.get('assignment.myAssignment');
            if (!assignmentInfo) {
                return;
            } else {
                console.log('have assignment');
                var steps = assignmentInfo.get('steps');
                if (!steps) {
                    return;
                }
                var stepsArray = [];
                for (var i = steps - 1; i >= 0; i--) {
                    stepsArray.push(i);
                }
                self.set('stepsArray', stepsArray.reverse());
                return true;
            }
        }),
        actions: {
            showAssignmentToggle: function showAssignmentToggle(assignment) {
                this.set('assignment', assignment);
                this.toggleProperty('showAssignment');
                this.set('editAssignment', false);
            },
            deleteAssignment: function deleteAssignment(assignment, classroom) {
                var _this = this;
                console.log(classroom);
                if (assignment.get('assignDate')) {
                    console.log('assignment already assigned to students');
                    return;
                }
                classroom.get('classAssignments').removeObject(assignment);
                assignment.destroyRecord().then(function () {
                    _this.setProperties({ editAssignment: false, successMessage: 'assignment deleted from class' });
                    setTimeout(function () {
                        _this.setProperties({ showAssignment: false, successMessage: '' });
                    }, 1000);
                });
            },
            saveAssignment: function saveAssignment(assignment) {
                var self = this;
                assignment.save().then(function () {
                    self.setProperties({ editAssignment: false, successMessage: 'assignment changes saved!' });
                    setTimeout(function () {
                        self.setProperties({ showAssignment: false, successMessage: '' });
                    }, 1000);
                }, function (error) {
                    self.setProperties({ errorMessage: error });
                    console.log('Error : ' + error);
                });
            },
            updateDueDateTime: function updateDueDateTime(evt) {
                console.log(this.get('assignment.dueDate'));
                var assignment = this.get('assignment');
                assignment.save().then(function () {}, function (error) {
                    console.log('Error : ' + error);
                });
            },
            toggleGradeBook: function toggleGradeBook() {
                this.toggleProperty('showGrade');
            },
            toggleEditMode: function toggleEditMode() {
                this.toggleProperty('editMode');
            },
            editAssignment: function editAssignment() {
                this.toggleProperty('editAssignment');
            },
            cancelAssignmentEdits: function cancelAssignmentEdits(assignment) {
                assignment.rollbackAttributes();
                this.set('editAssignment', false);
            },
            showAddedMenu: function showAddedMenu() {
                this.toggleProperty('showAddMenu');
            },
            addAssignment: function addAssignment() {
                this.get('addAssignment')();
            },
            assignToStudents: function assignToStudents(assignment, classRoom) {
                var store = this.get('store');
                var self = this;
                assignment.set('assignDate', new Date());
                assignment.save();
                var students = classRoom.get('students');
                var myAssignmentType = assignment.get('myAssignment.type');

                students.then(function (classStudents) {
                    var _loop = function _loop() {
                        var currentStudent = classStudents.objectAt(i);
                        var class_id = classRoom.get('id');
                        var student_id = currentStudent.get('id');
                        var uniq_id = student_id + class_id;
                        var newAssignment = store.createRecord('assignment', {
                            classAssignment: assignment,
                            student: currentStudent,
                            class: classRoom,
                            report_card_id: uniq_id,
                            type: myAssignmentType
                        });
                        newAssignment.save().then(function (savedNewAssignment) {
                            currentStudent.get('assignments').addObject(savedNewAssignment);
                            currentStudent.save();
                        }, function (error) {
                            self.setProperties({ errorMessage: error });
                            console.log('Error : ' + error);
                        });
                    };

                    for (var i = classStudents.length - 1; i >= 0; i--) {
                        _loop();
                    }
                }, function (error) {
                    self.setProperties({ errorMessage: error });
                    console.log('Error : ' + error);
                });
            }
        }
    });
});