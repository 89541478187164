define('sokol-today/helpers/report-card-grade', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.reportCardGrade = reportCardGrade;
  function reportCardGrade(params /*, hash*/) {
    var assignments = params[0];
    var grades = [];
    var weights = [];

    for (var i = assignments.get('length') - 1; i >= 0; i--) {
      var assignment = assignments.objectAt(i);
      var assign_type = assignment.get('type');

      var maxScore = assignment.get('maxScore');
      var factor = 0;
      switch (assign_type) {
        case "homework":
          factor = 2;
          break;
        case "classwork":
          factor = 1;
          break;
        case "test":
          factor = 3;
          break;
        case "final":
          factor = 3;
          break;
      }
      var weight = Math.pow(factor, Math.sqrt(i + 1) * factor);
      var grade = assignment.get('grade') / maxScore * 100 * weight;
      grades.push(grade);
      weights.push(weight);
    }
    console.log('done for loop');
    return Math.round(grades.reduce(function (sum, value) {
      return sum + value;
    }, 1) / weights.reduce(function (sum, value) {
      return sum + value;
    }, 1));
  }

  exports.default = Ember.Helper.helper(reportCardGrade);
});