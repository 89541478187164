define('sokol-today/components/dashboard/classes/add/step-one', ['exports'], function (exports) {
		'use strict';

		Object.defineProperty(exports, "__esModule", {
				value: true
		});
		exports.default = Ember.Component.extend({
				newUser: Ember.inject.service(),
				connectedUser: Ember.inject.service(),
				session: Ember.inject.service(),
				store: Ember.inject.service(),
				// class: Ember.inject.service(),
				router: Ember.inject.service(),
				classFrequency: null,
				isOne: true,
				daysArray: [0],
				courseLengthPass: null,
				isFullYear: false,
				isSemester: false,

				courseLengthCorrect: Ember.computed('model.name', 'model.periods', function () {
						var courseLength = this.get('model.name.courseLength');
						var periods = this.get('connectedUser.currentYear.periods');
						var _this = this;
						return courseLength.then(function (requiredLength) {
								var classLength = _this.get('model.periods');
								//Nothing selected - no need to continue			
								if (classLength == null) {
										console.log('false');
										return false;
								}
								var selectedTerms = classLength.getEach('id').sort();
								return new Promise(function (resolve, reject) {
										_this.get('connectedUser.currentYear.periods').then(function (yearPeriods) {
												var count = yearPeriods.get('length');
												var indexSplit = count / 2;
												var test1 = yearPeriods.getEach('id').slice(0, indexSplit);
												var test2 = yearPeriods.getEach('id').slice(indexSplit);
												switch (requiredLength.get('name')) {
														case "Full Year":
																if (selectedTerms.length !== count) {
																		_this.set('courseLengthPass', false);
																} else {
																		_this.set('courseLengthPass', true);
																}
																break;
														case "Semester (Either)":
																if (selectedTerms.length !== test1.length && selectedTerms.length !== test2.length) {
																		_this.set('courseLengthPass', false);
																}
																for (var i = selectedTerms.length; i--;) {
																		if (test1.contains(selectedTerms[i])) {
																				test1.popObject(selectedTerms[i]);
																		} else if (test2.contains(selectedTerms[i])) {
																				test2.popObject(selectedTerms[i]);
																		}
																}
																if (test2.length === 0 && test1.length === indexSplit) {
																		_this.set('courseLengthPass', true);
																} else if (test1.length === 0 && test2.length === indexSplit) {
																		_this.set('courseLengthPass', true);
																} else {
																		_this.set('courseLengthPass', false);
																}
																break;
														case "Semester (Fall)":
																if (selectedTerms.length !== test2.length) {
																		_this.set('courseLengthPass', false);
																}
																for (var i = selectedTerms.length; i--;) {
																		if (selectedTerms[i] == test2[i]) {
																				test2.popObject(test2[i]);
																		}
																}
																if (test2.length == 0) {
																		_this.set('courseLengthPass', true);
																} else {
																		_this.set('courseLengthPass', false);
																}
																break;
														case "Semester (Spring)":
																if (selectedTerms.length !== test1.length) {
																		_this.set('courseLengthPass', false);
																}
																for (var i = selectedTerms.length; i--;) {
																		if (selectedTerms[i] == test1[i]) {
																				test1.popObject(test1[i]);
																		}
																}
																if (test1.length == 0) {
																		_this.set('courseLengthPass', true);
																} else {
																		_this.set('courseLengthPass', false);
																}
																break;
														default:
																_this.set('courseLengthPass', false);
																break;
												}
												return true;
										});
								});
						});
				}),

				times: Ember.computed(function () {
						var store = this.get('store');
						return store.findAll('time');
				}),

				days: Ember.computed(function () {
						var store = this.get('store');
						return store.findAll('day-list');
				}),

				actions: {
						courseSelected: function courseSelected(selected) {
								var _this = this;
								var periods = this.get('connectedUser.currentYear.periods');
								var model = this.get('model');
								model.set('name', selected);
								var modelPeriods = model.get('periods');
								var courseLength = selected.get('courseLength');

								courseLength.then(function (requiredLength) {
										switch (requiredLength.get('name')) {
												case "Full Year":
												case "Semester (Either)":
														modelPeriods.clear();
														modelPeriods.addObjects(periods);
														break;
												case "Semester (Spring)":
														modelPeriods.clear();
														modelPeriods.addObjects(periods.slice(0, periods.get('length') / 2));
														break;
												case "Semester (Fall)":
														modelPeriods.clear();
														modelPeriods.addObjects(periods.slice(periods.get('length') / 2));
														break;
												default:
														console.log(requiredLength.get('name'));
														break;
										}
								});
						},
						createClass: function createClass(year, period, room) {
								//staff relationship to class is handled with onchange event at input
								var store = this.get('store');
								var newUser = this.get('newUser');
								var router = this.get('router');
								var newClass = this.get('model');
								var userSchool = this.get('connectedUser.staff.school');
								var that = this;

								userSchool.then(function (theSchool) {
										newClass.setProperties({
												'school': theSchool,
												'year': year
										});
										var getClassList = theSchool.get('classList');
										getClassList.then(function (theClassListId) {
												var classList = store.find('classList', theClassListId.id);
												classList.then(function (theClassList) {
														newClass.save().then(function (theClass) {
																theClassList.get('classes').addObject(theClass);
																theClassList.save().then(function () {}, function (error) {
																		console.log('Error : ' + error);
																});
																var periods = newClass.get('periods');
																var periodCount = periods.get('length');
																console.log(periodCount);
																for (var i = periodCount - 1; i >= 0; i--) {
																		store.findRecord('period', periods.objectAt(i).get('id')).then(function (periodi) {
																				periodi.get('classes').addObject(theClass);
																				periodi.save();
																		}, function (error) {
																				console.log('Error : ' + error);
																		});
																}
																if (room) {
																		store.findRecord('room', room.get('id')).then(function (theRoom) {
																				theRoom.get('classes').addObject(theClass);
																				theRoom.save().then(function () {
																						that.setProperties({ selectedYear: null, selectedPeriod: null, selectedCourse: null, selectedTeacher: null, selectedRoom: null, selectedStartTime: null, selectedEndTime: null });
																						that.get('connectedUser').changeYear(year);
																						newUser.set('hasClass', true);
																						that.get('transitionToStepTwo')(theClass);
																				}, function (error) {
																						console.log('Error : ' + error);
																				});
																		}, function (error) {
																				console.log('Error : ' + error);
																		});
																} else {
																		that.setProperties({ selectedYear: null, selectedPeriod: null, selectedCourse: null, selectedTeacher: null, selectedRoom: null, selectedStartTime: null, selectedEndTime: null });
																		that.get('connectedUser').changeYear(year);
																		newUser.set('hasClass', true);
																		that.get('transitionToStepTwo')(theClass);
																}
														}, function (error) {
																console.log('Error : ' + error);
														});
												}, function (error) {
														console.log('Error : ' + error);
												});
										}, function (error) {
												console.log('Error : ' + error);
										});
								}, function (error) {
										console.log('Error : ' + error);
								});
						},
						resetClassName: function resetClassName(pickedSubject) {
								this.setProperties({ selectedClassName: null });
						},
						handleStaff: function handleStaff(record, highlighted) {
								var theClass = this.get('model');
								if (record.length < highlighted.selected.length) {
										// someone was removed
										// use mut when adding removing staff and wait till saving class to add classes to staff and staff to class
										var myArray = [];
										myArray.pushObjects(highlighted.selected);
										myArray.removeObjects(record);
										highlighted.selected.removeObjects(myArray);
										var staff = myArray.get('firstObject');
										staff.get('classes').removeObject(theClass);
										staff.save().then(function (staffRemoved) {
												theClass.get('staff').removeObject(staffRemoved);
												theClass.save();
										}, function (error) {
												console.log('Error : ' + error);
										});
								} else if (record.length > highlighted.selected.length) {
										// someone was added
										var staffAdded = highlighted.highlighted;
										staffAdded.get('classes').addObject(theClass);
										theClass.get('staff').addObject(staffAdded);
										theClass.save().then(function () {
												staffAdded.save().then(function (staffSaved) {
														highlighted.selected.addObject(staffAdded);
												}, function (error) {
														console.log('Error : ' + error);
												});
										}, function (error) {
												console.log('Error : ' + error);
										});
								} else {
										console.log('hopefully nothing changed');
								}
						},
						goingBack: function goingBack(model) {
								var staff = model.get('staff');
								var store = this.get('store');
								for (var i = staff.get('length') - 1; i >= 0; i--) {
										store.findRecord('staff', staff.objectAt(i).get('id')).then(function (theStaff) {
												theStaff.get('classes').removeObject(model);
												theStaff.save();
										}, function (error) {
												console.log('Error : ' + error);
										});
								}
								model.destroyRecord();
						}
				}
		});
});