define('sokol-today/models/demo/user', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        userSince: _emberData.default.attr('date', { default: new Date() }),
        isTeacher: _emberData.default.belongsTo('demo/teacher', { async: true }), //Going to depricate this and move to each teacher admin model
        isAdmin: _emberData.default.belongsTo('demo/admin'), //Going to depricate this and move to each teacher admin model
        isParent: _emberData.default.belongsTo('demo/parent'), //Going to depricate this and move to each teacher admin model
        isStudent: _emberData.default.belongsTo('demo/student'), //Going to depricate this and move to each teacher admin model
        accountInfo: _emberData.default.belongsTo('demo/account-info'),
        paymentHistory: _emberData.default.hasMany('demo/payment-history'), //Going to depricate this and move to account-info
        role: _emberData.default.attr('number'), //Going to depricate this and move to each teacher admin model
        firstName: _emberData.default.attr('string'),
        lastName: _emberData.default.attr('string'),
        // roles: DS.hasMany('role'), //Plan is to have this be teachers, students, parents, admin info
        // connectedRoles: DS.hasMany
        state: _emberData.default.belongsTo('demo/state'), //Going to depricate this and move to each teacher admin model
        district: _emberData.default.belongsTo('demo/district'), //Going to depricate this and move to each teacher admin model
        school: _emberData.default.belongsTo('demo/school', { async: true }), //Going to depricate this and move to each teacher admin model
        isNew: _emberData.default.attr('string'),
        email: _emberData.default.attr('string'),
        //pin to connect user to correct teacher/student/admin account
        pin: _emberData.default.attr('string'),
        //check student role privledges
        student: Ember.computed('role', function () {
            var role = this.get('role');
            switch (role) {
                case 60:
                    return true;
                default:
                    return false;
            }
        }).property('role'),
        //check parent role privledges
        parent: Ember.computed('role', function () {
            var role = this.get('role');
            switch (role) {
                case 70:
                    return true;
                default:
                    return false;
            }
        }).property('role'),
        //check teacher role privledges
        teacher: Ember.computed('role', function () {
            var role = this.get('role');
            switch (role) {
                case 80:
                case 81:
                case 82:
                case 83:
                case 84:
                case 85:
                case 86:
                case 87:
                case 88:
                case 89:
                    return true;
                default:
                    return false;
            }
        }).property('role'),

        admin: Ember.computed('role', function () {
            var role = this.get('role');
            switch (role) {
                case 89:
                case 90:
                case 91:
                case 92:
                case 93:
                case 94:
                case 95:
                case 96:
                case 97:
                case 98:
                case 99:
                    return true;
                default:
                    return false;
            }
        }).property('role'),

        superUser: Ember.computed('role', function () {
            var role = this.get('role');
            switch (role) {
                case 99:
                    return true;
                default:
                    return false;
            }
        }).property('role')
    });
});