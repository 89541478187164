define('sokol-today/models/observation', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    rubricCodes: _emberData.default.hasMany('rubric-component'),
    walkthrough: _emberData.default.belongsTo('walk-through', { async: true }),
    notes: _emberData.default.attr('string'),
    timestamp: _emberData.default.attr('date')
  });
});