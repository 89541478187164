define('sokol-today/services/connected-user', ['exports'], function (exports) {
		'use strict';

		Object.defineProperty(exports, "__esModule", {
				value: true
		});
		exports.default = Ember.Service.extend({
				firebaseApp: Ember.inject.service(),
				store: Ember.inject.service(),
				session: Ember.inject.service(),
				currentStudents: [],
				currentClass: null,
				currentYear: null,
				currentPeriod: null,
				singlePeriod: false,
				singleClass: false,
				totalDays: null,
				schoolDaysLeft: null,
				school: null,
				staff: null,
				account: null,
				student: null,

				// theSchool:Ember.computed('', function(){
				// 	return this.getSchool(null);
				// }),

				studentList: Ember.computed('school', function () {
						var school = this.get('school');
						if (!school) {
								return;
						}
						console.log(school.get('id'));
						var store = this.get('store');
						return store.query('student', {
								orderBy: 'school',
								equalTo: school.get('id')
						}).then(function (theList) {
								return theList;
						});
				}),

				user: Ember.computed('session', 'user', function () {
						console.log('user changed');
						var store = this.get('store');
						var session = this.get('session.content.uid');

						if (!session) {
								return null;
						}
						return store.findRecord('user', session);
				}).property('user', 'session'),

				init: function init() {
						this._super.apply(this, arguments);
						this.getSchool(null);
				},
				getStaff: function getStaff(staff) {
						var firebase = this.get('firebaseApp');
						var _this = this;
						var user = this.get('user');
						var store = this.get('store');
						_this.set('currentYear', null);
						_this.set('staff', staff);
						staff.get('school').then(function (theSchool) {
								_this.set('school', theSchool);
								_this.changeYear(null);
						});
				},
				getSchool: function getSchool(school) {
						var firebase = this.get('firebaseApp');
						var self = this;
						var accountInfo = null;
						var user = this.get('user');
						var store = this.get('store');
						self.set('currentYear', null);
						if (!user) {
								return;
						}
						if (school) {
								self.set('school', school);
								self.changeYear(null);
								return school;
						} else {
								return user.then(function (theUser) {
										//Check if default account	
										var defaultAccount = theUser.get('defaultAccount');
										//check if default account existed
										if (!defaultAccount) {
												return;
										} else {
												var id_type = defaultAccount.substring(0, defaultAccount.indexOf('-') - 1);
												//check which type account default is (Staff, student, or guardian)
												switch (id_type) {
														case "student":
																console.log('studentId : ' + defaultAccount);
																accountInfo = store.findRecord('student', defaultAccount);
																break;
														case "staff":
																console.log('StaffId : ' + defaultAccount);
																accountInfo = store.findRecord('staff', defaultAccount);
																break;
														default:
																console.log('defaultId : ' + defaultAccount);
																accountInfo = store.findRecord('staff', defaultAccount);
																break;
												}
												return accountInfo.then(function (theAccount) {
														self.set('account', theAccount);
														var schoolModel = store.findRecord('school', theAccount.get('school.id'));
														schoolModel.then(function (theSchool) {
																self.set('school', theSchool);
																self.changeYear(null);
														}, function (error) {
																console.log('Error : ' + error);
														});
														return theAccount;
												}, function (error) {
														console.log('Error : ' + error);
												});
										}
								});
						}
				},
				changeClass: function changeClass(theClass) {
						console.log('running changeClass');
						if (theClass) {
								this.set('currentStudents', null);
								var theStudents = [];
								var that = this;
								// console.log(theClass);
								var studentList = theClass.get('students');
								this.setProperties({ currentClass: { classes: [theClass] }, singleClass: true });
								studentList.then(function (theStudentList) {
										// that.setProperties({ currentClass: theStudentList });
										for (var j = 0; j < theStudentList.get('length'); j++) {
												// var studentList = theStudentList.objectAt(j).get('students');
												theStudents.addObject(theStudentList.objectAt(j));
												that.setProperties({ currentStudents: { students: theStudents } });
										}
								}, function (error) {
										console.log('Error : ' + error);
								});
						} else {
								console.log('no class given');
								// this.setProperties({ currentClass: { classes: theClass }, currentStudents: null });
						}
				},
				changePeriod: function changePeriod(thePeriod) {
						console.log('running changePeriod');
						if (thePeriod) {
								console.log('period sent');
								var theStudents = [];
								var theClasses = [];
								var user = this.get('staff');
								var store = this.get('store');
								var that = this;
								var classList = thePeriod.get('classes');
								store.query('student', {
										orderBy: 'school',
										equalTo: user.get('school.id')
								}).then(function (result) {
										// console.log(result);
										that.setProperties({
												currentStudents: {
														students: result.filter(function (b) {
																if (b.get('active')) {
																		return true;
																}
														})
												}
										});
								}, function (error) {
										console.log('Error : ' + error);
								});
								this.setProperties({ currentPeriod: thePeriod, singlePeriod: true, singleClass: false });

								classList.then(function (theClassList) {
										var classCount = theClassList.get('length');
										if (classCount <= 0) {
												that.setProperties({ currentClass: { classes: null } });
										} else {
												for (var j = 0; j < theClassList.get('length'); j++) {
														// var studentList = theClassList.objectAt(j).get('students');
														theClasses.addObject(theClassList.objectAt(j));
														that.setProperties({ currentClass: { classes: theClasses } });
														// studentList.then(function(theStudentList) {
														//     for (var i = 0; i < theStudentList.get('length'); i++) {
														//         theStudents.addObject(theStudentList.objectAt(i));

														//         that.setProperties({ currentStudents: { students: theStudents } });
														//     }
														// });
												}
										}
								}, function (error) {
										console.log('Error : ' + error);
								});
						} else {
								this.setProperties({ currentPeriod: thePeriod });
						}
				},
				changeYear: function changeYear(theYear) {
						var _this = this;
						this.set('currentStudents', []);
						console.log('Year changed');
						var currentYear = this.get('currentYear');
						if (currentYear && !theYear) {
								return;
						}
						var theStudents = [];
						var theClasses = [];
						var user = this.get('user');
						var that = this;
						var store = this.get('store');
						var schoolID = this.get('school.id');
						var school = store.findRecord('school', schoolID);
						if (theYear == null && user) {
								console.log('init set');
								user.then(function (theUser) {
										if (theUser.get('isNew')) {} else {
												// Get active students										
												school.then(function (theSchool) {
														if (!theSchool) {} else {
																theSchool.get('yearList').then(function (theYearList) {
																		theYearList.get('years').then(function (theYears) {
																				var theYear = theYears.get('lastObject');
																				theYear.get('periods').then(function (thePeriodList) {
																						if (thePeriodList) {
																								for (var i = 0; i < thePeriodList.get('length'); i++) {
																										var classList = thePeriodList.objectAt(i).get('classes');
																										classList.then(function (theClassList) {
																												for (var j = 0; j < theClassList.get('length'); j++) {
																														var studentList = theClassList.objectAt(j).get('students');
																														theClasses.addObject(theClassList.objectAt(j));
																														that.setProperties({ currentClass: { classes: theClasses } });
																												}
																										}, function (error) {
																												console.log('Error : ' + error);
																										});
																								}

																								store.query('student', {
																										orderBy: 'school',
																										equalTo: schoolID
																								}).then(function (result) {
																										result.forEach(function (student) {
																												student.get('years').forEach(function (years) {
																														// console.log(theYear);
																														if (years.get('id') == theYear.get('id')) {
																																_this.get('currentStudents').addObject(student);
																																// console.log(true);
																														} else {
																																// console.log(false);
																																return false;
																														}
																												});
																										});
																								}, function (error) {
																										console.log('Error : ' + error);
																								});

																								that.currentYearDays(start, end);
																								that.daysLeft(start, end);
																								that.setProperties({ currentYear: theYear, currentPeriod: null, singlePeriod: false, singleClass: false });
																						} else {
																								store.query('student', {
																										orderBy: 'school',
																										equalTo: schoolID
																								}).then(function (result) {
																										result.forEach(function (student) {
																												student.get('years').forEach(function (years) {
																														// console.log(theYear);
																														if (years.get('id') == theYear.get('id')) {
																																_this.get('currentStudents').addObject(student);
																																// console.log(true);
																														} else {
																																// console.log(false);
																																return false;
																														}
																												});
																										});
																								}, function (error) {
																										console.log('Error : ' + error);
																								});
																								that.currentYearDays(start, end);
																								that.daysLeft(start, end);
																								that.setProperties({ currentYear: theYear, currentPeriod: null, singlePeriod: false, singleClass: false });
																						}
																				}, function (error) {
																						console.log('Error : ' + error);
																				});
																				var start = theYear.get('firstDay');
																				var end = theYear.get('lastDay');
																		}, function (error) {
																				console.log('Error : ' + error);
																		});
																}, function (error) {
																		console.log('Error : ' + error);
																});
														}
												}, function (error) {
														console.log('Error : ' + error);
												});
										}
								}, function (error) {
										console.log('Error : ' + error);
								});
						} else if (theYear) {
								console.log('is teacher or admin');
								var periodList = theYear.get('periods');
								var start = theYear.get('firstDay');
								var end = theYear.get('lastDay');
								user.then(function (theUser) {
										store.query('student', {
												orderBy: 'school',
												equalTo: schoolID
										}).then(function (result) {
												result.forEach(function (student) {
														student.get('years').forEach(function (years) {
																// console.log(theYear);
																if (years.get('id') == theYear.get('id')) {
																		_this.get('currentStudents').addObject(student);
																		// console.log(true);
																} else {
																		// console.log(false);
																		return false;
																}
														});
												});
										}, function (error) {
												console.log('Error : ' + error);
										});
										if (periodList) {
												periodList.then(function (thePeriodList) {
														// that.setProperties({ currentPeriod: thePeriodList.get('lastObject') });
														for (var i = 0; i < thePeriodList.get('length'); i++) {
																var classList = thePeriodList.objectAt(i).get('classes');
																classList.then(function (theClassList) {
																		for (var j = 0; j < theClassList.get('length'); j++) {
																				var studentList = theClassList.objectAt(j).get('students');
																				theClasses.addObject(theClassList.objectAt(j));
																				that.setProperties({ currentClass: { classes: theClasses } });
																				studentList.then(function (theStudentList) {
																						for (var i = 0; i < theStudentList.get('length'); i++) {
																								theStudents.addObject(theStudentList.objectAt(i));
																								// that.setProperties({ currentStudents: { students: theStudents } });
																						}
																				}, function (error) {
																						console.log('Error : ' + error);
																				});
																		}
																}, function (error) {
																		console.log('Error : ' + error);
																});
														}
												}, function (error) {
														console.log('Error : ' + error);
												});
												that.currentYearDays(start, end);
												that.daysLeft(start, end);
												that.setProperties({ currentYear: theYear, currentPeriod: null, singlePeriod: false, singleClass: false });
										} else {
												console.log('no year or period...wtf');
												store.query('student', {
														filter: {
																active: true
														}
												}).then(function (result) {
														console.log(result);
														that.setProperties({ currentStudents: { students: result } });
												}, function (error) {
														console.log('Error : ' + error);
												});
												that.currentYearDays(start, end);
												that.daysLeft(start, end);
												that.setProperties({ currentYear: theYear, currentPeriod: null, singlePeriod: false, singleClass: false });
										}
								}, function (error) {
										console.log('Error : ' + error);
								});
						} else {
								console.log('no user if no year');
						}
				},
				currentYearDays: function currentYearDays(startDate, endDate) {
						var start = moment(startDate);
						var end = moment(endDate);

						var first = start.clone().endOf('week'); // end of first week
						var last = end.clone().startOf('week'); // start of last week
						var days = last.diff(first, 'days') * 5 / 7; // this will always multiply of 7
						var wfirst = first.day() - start.day(); // check first week
						if (start.day() == 0) --wfirst; // -1 if start with sunday 
						var wlast = end.day() - last.day(); // check last week
						if (end.day() == 6) --wlast; // -1 if end with saturday
						var daysTotal = Math.floor(wfirst + days + wlast); // get the total

						this.setProperties({ totalDays: daysTotal });
				},
				daysLeft: function daysLeft(startDate, endDate) {
						var start = moment(startDate);
						var today = moment();
						var end = moment(endDate);

						if (moment().isBefore(startDate)) {
								var first = start.clone().endOf('week'); // end of first week
								var last = end.clone().startOf('week'); // start of last week
								var days = last.diff(first, 'days') * 5 / 7; // this will always multiply of 7
								var wfirst = first.day() - start.day(); // check first week
								if (start.day() == 0) --wfirst; // -1 if start with sunday 
								var wlast = end.day() - last.day(); // check last week
								if (end.day() == 6) --wlast; // -1 if end with saturday
								var daysLeft = Math.floor(wfirst + days + wlast); // get the total

								this.setProperties({ schoolDaysLeft: daysLeft });
						} else {
								var first = today.clone().endOf('week'); // end of first week
								var last = end.clone().startOf('week'); // start of last week
								var days = last.diff(first, 'days') * 5 / 7; // this will always multiply of 7
								var wfirst = first.day() - today.day(); // check first week
								if (today.day() == 0) --wfirst; // -1 if start with sunday 
								var wlast = end.day() - last.day(); // check last week
								if (end.day() == 6) --wlast; // -1 if end with saturday
								var daysLeft = Math.floor(wfirst + days + wlast); // get the total

								this.setProperties({ schoolDaysLeft: daysLeft });
						}
				}
		});
});