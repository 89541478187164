define('sokol-today/components/dashboard/observations/observation-main', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        store: Ember.inject.service(),
        connectedUser: Ember.inject.service(),

        hideSelect: false,
        timerOn: true,

        obsStart: moment(),

        didInsertElement: function didInsertElement() {
            var height = this.$(window).height() * 0.67;
            this.$('section').height(height);
        },
        willDestroyElement: function willDestroyElement() {
            if (this.hideSelect === true) {
                this.stopTimer();
            } else {
                this.get('model').destroy();
                return;
            }
        },


        walkThrough: Ember.computed('selectedObserveType', function () {
            var observeType = this.get('selectedObserveType');
            if (typeof observeType === 'undefined') {
                return;
            }
            if (observeType.get('name') === 'Walk-Through') {
                return true;
            }
        }),

        stopTimer: function stopTimer() {
            this.setProperties({ timerOn: false });
            var store = this.get('store');
            var currentObservation = this.get('model');
            var date = new Date();
            currentObservation.set('timestampEnd', date);
            currentObservation.save();
        },

        currentTimeMetronome: function currentTimeMetronome() {
            var timerOn = this.get('timerOn');
            // this.setProperties({timerOn:true});
            if (timerOn) {
                var interval = 1000;
                Ember.run.later(this, function () {
                    this.notifyPropertyChange('currentTimePulse');
                    this.currentTimeMetronome();
                }, interval);
            }
        },

        keepTrack: function () {
            var timestart = this.get('obsStart');
            var now = moment();
            var duration = moment.duration(now.diff(timestart));
            return Math.floor(duration.asHours()) + moment.utc(duration.asMilliseconds()).format("[h]:mm[m]:ss[s]");
        }.property('currentTimePulse'),

        observeTypes: Ember.computed('observeType', function () {
            var store = this.get('store');
            return store.findAll('observe-type');
        }),

        staffList: Ember.computed.filterBy('connectedUser.school.staffList.staff', 'deactivated', false),

        staffClasses: Ember.computed.filterBy('selectedStaff.classes', 'finalized', false),

        actions: {
            hideSelect: function hideSelect(selectedStaff, selectedObserveType, selectedStaffClass) {
                var store = this.get('store');
                var session = this.get('session');
                var staff = selectedStaff;
                var year = this.get('connectedUser.currentYear');
                var theObserver = this.get('connectedUser.account');
                var date = moment();
                this.set('obsStart', date);
                this.currentTimeMetronome();

                if (typeof staff === 'undefined') {
                    alert('staff undefined');
                } else {
                    var newObservation = this.get('model');
                    // user.then(function(theObserver) {

                    var school = store.findRecord('school', theObserver.get('school.id'));
                    school.then(function (theSchool) {
                        newObservation.set('observer', theObserver);
                        newObservation.set('observee', staff);
                        newObservation.set('school', theSchool);
                        newObservation.set('room', staff.get('room'));
                        newObservation.set('observationType', selectedObserveType);
                        newObservation.set('timestampStart', date);
                        newObservation.set('year', year);
                        newObservation.set('class', selectedStaffClass);

                        newObservation.save().then(function (newObsBlock) {
                            var posIndex = theSchool.get('observationList.id');
                            console.log(posIndex);
                            if (typeof posIndex == 'undefined') {
                                console.log('no ob-index!');
                                var _theIndex = store.createRecord('observation-list', {
                                    school: theSchool
                                });

                                _theIndex.save().then(function (newIndex) {
                                    theSchool.save().then(function () {
                                        newIndex.get('observationBlocks').addObject(newObsBlock);
                                        newIndex.save();
                                    });
                                });
                                staff.save();
                            } else {
                                var theIndex = store.findRecord('observation-list', theSchool.get('observationList.id'));
                                theIndex.then(function (indexLink) {
                                    indexLink.get('observationBlocks').addObject(newObsBlock);
                                    indexLink.save().then(function () {
                                        staff.save();
                                    });
                                });
                            }
                        });
                    });
                    // },
                    // function(error) {
                    //     console.log('Error : ' + error);
                    // });
                    this.toggleProperty('hideSelect');
                }
            },
            stopTimer: function stopTimer() {
                this.setProperties({ timerOn: false });
                var store = this.get('store');
                var currentObservation = this.get('model');
                var date = new Date();
                currentObservation.set('timestampEnd', date);
                currentObservation.save();
            }
        }
    });
});