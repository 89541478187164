define('sokol-today/components/dashboard/relationships/display-relationship', ['exports'], function (exports) {
		'use strict';

		Object.defineProperty(exports, "__esModule", {
				value: true
		});
		exports.default = Ember.Component.extend({
				store: Ember.inject.service(),
				connectedUser: Ember.inject.service(),

				interests: Ember.computed('', function () {
						return this.get('store').findAll('interest');
				}),

				actions: {
						toggleEditAddress: function toggleEditAddress() {
								this.toggleProperty('editAddress');
						},
						toggleEditPhone: function toggleEditPhone() {
								this.toggleProperty('editPhone');
						},
						toggleEditEmail: function toggleEditEmail() {
								this.toggleProperty('editEmail');
						},
						toggleAddInterest: function toggleAddInterest() {
								this.toggleProperty('addInterest');
						},
						cancelEdit: function cancelEdit(model, toggle) {
								var store = this.get('store');
								var _this = this;
								if (model.get('hasDirtyAttributes')) {
										store.find('guardian', model.get('id')).then(function (theGuardian) {
												console.log('model was dirty');
												theGuardian.rollbackAttributes();
												theGuardian.save();
												_this.toggleProperty(toggle);
										}).catch(function (reason) {
												console.log(reason);
										});
								} else {
										this.toggleProperty(toggle);
								}
						},
						saveEdit: function saveEdit(model, toggle) {
								var store = this.get('store');
								var _this = this;
								var errorString = 'errorSave' + toggle;
								var toggleString = 'edit' + toggle;
								var saveString = 'successSave' + toggle;
								store.find('guardian', model.get('id')).then(function (theGuardian) {
										theGuardian.save().then(function () {
												_this.toggleProperty(toggleString);
												_this.toggleProperty(saveString);
												_this.set(errorString, false);
												setTimeout(function () {
														_this.toggleProperty(saveString);
												}, 3000);
										}).catch(function (error) {
												_this.set(errorString, error);
										});
								}).catch(function (reason) {
										_this.set(errorString, error);
										console.log(reason);
								});
						},
						saveInterests: function saveInterests(selectedInterest, model) {
								var _this = this;
								var guardian = this.get('store').findRecord('guardian', model.get('id'));
								guardian.then(function (theGuardian) {
										var interests = theGuardian.get('interests');
										interests.then(function (interestList) {
												interestList.addObjects(selectedInterest);
												theGuardian.save().then(function () {
														_this.setProperties({ successSaveInterest: true, errorSaveInterest: false, addInterest: false, selectedInterest: null });
														setTimeout(function () {
																_this.set('successSaveInterest', false);
														}, 3000);
												}).catch(function (error) {
														_this.setProperties({ errorSaveInterest: error });
												});
										}).catch(function (error) {
												console.log(error);
												_this.setProperties({ errorSaveInterest: error });
										});
								}).catch(function (error) {
										console.log(error);
										_this.setProperties({ errorSaveInterest: error });
								});
						},
						deleteInterest: function deleteInterest(interest, guardian) {
								var _this = this;
								if (confirm('Delete ' + interest.get('name').capitalize() + ' from interests?') == true) {
										var store = this.get('store');
										var model = store.findRecord('guardian', guardian.get('id'));
										model.then(function (theGuardian) {
												theGuardian.get('interests').then(function (interestList) {
														interestList.removeObject(interest);
														theGuardian.save().then(function () {
																var errorMessage = interest.get('name') + ' was deleted';
																_this.set('errorSaveInterest', errorMessage);
																setTimeout(function () {
																		_this.set('errorSaveInterest', false);
																}, 3000);
														}).catch(function (error) {
																_this.set('errorSaveInterest', error);
														});
												}).catch(function (error) {
														_this.set('errorSaveInterest', error);
												});
										}).catch(function (error) {
												_this.set('errorSaveInterest', error);
										});
								} else {
										return;
								}
						}
				}
		});
});