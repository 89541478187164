define("sokol-today/helpers/eq-date", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.eqDate = eqDate;

    // variables: firstDate, formatExpected, secondDate, secondFormat(optional), returnCount(optional), dateLocation(optional)
    // if no second format assumed formatExpected is same
    // if returnCount will return count of dates that are same otherwise will return objects
    ///param 0 date
    ///param 1 param 0 date format
    ///param 2 date
    ///param 3 param 2 date format
    ///param 4 returnCount - bool
    ///param 5 dateLocation
    function eqDate(params, hash) {

        var firstDate = hash.firstDate;
        var secondDate = hash.secondDate;
        var formatDate = hash.formatDate;
        var secondFormat = hash.secondFormat;
        var returnCount = hash.returnCount;
        var dateLocation = hash.dateLocation;
        var compareMethode = hash.compareBy;

        if (!params[4] && !returnCount) {
            if (!params[2]) {
                if (!compareMethode) {
                    return moment(params[0], params[1]).isSame(moment(moment(), params[3]), "year");
                } else {
                    return moment(params[0], params[1]).isSame(moment(moment(), params[3]), compareMethode);
                }
            } else {
                if (!compareMethode) {
                    return moment(params[0], params[1]).isSame(moment(params[2], params[3]), "year");
                } else {
                    return moment(params[0], params[1]).isSame(moment(params[2], params[3]), compareMethode);
                }
            }
        } else if (returnCount) {
            var myArray = [];
            if (dateLocation) {
                if (!firstDate) {
                    return;
                }
                for (var i = firstDate.get('length') - 1; i >= 0; i--) {
                    if (firstDate.objectAt(i).get(dateLocation) === secondDate) {
                        myArray.pushObject(firstDate.objectAt(i));
                    } else {}
                }
                return myArray.length;
            } else {
                // for (var i = firstDate.length - 1; i >= 0; i--) {
                //     if (moment(params[0][i], params[1]).isSame(moment(params[2], params[3]), "year")) {
                //         myArray.pushObject(params[0][i]);
                //     }
                // }
                // return myArray
            }
        }
    }

    exports.default = Ember.Helper.helper(eqDate);
});