define('sokol-today/components/website/demo/observations/observations-list', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        store: Ember.inject.service(),
        showRubricCodes: false,
        showContextMenu: false,
        rightClickMenu: $("#context-menu"),

        codeList: Ember.computed('obs', function () {
            var store = this.get('store');
            var rubricDomains = this.get('obs.teacher.profession.rubric.domains');
            console.log(rubricDomains);
            return rubricDomains;
        }),

        didInsertElement: function didInsertElement() {
            this.$('.ember-content-editable').focus();
        },
        focusOut: function focusOut() {
            var data = this.get('data');
            var notes = this.get('notes');
            var rubricCode = this.get('codeHere');
            var origNotes = data.get('notes');
            if (typeof origNotes == 'undefined') {
                data.set('notes', notes);
            } else {}
            if (typeof rubricCode != 'undefined') {
                data.get('rubricCodes').addObject(rubricCode);
            }
        },
        positionMenu: function positionMenu(e) {
            var menuPosition = this.getPosition(e);
            var menuPositionX = menuPosition.x + "px";
            var menuPositionY = menuPosition.y + "px";

            // this.rightClickMenu.style.left = menuPositionX;
            //  this.rightClickMenu.style.top = menuPositionY;
        },
        getPosition: function getPosition(e) {
            var posx = 0;
            var posy = 0;

            if (!e) var e = window.event;

            if (e.pageX || e.pageY) {
                posx = e.pageX;
                posy = e.pageY;
            } else if (e.clientX || e.clientY) {
                posx = e.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
                posy = e.clientY + document.body.scrollTop + document.documentElement.scrollTop;
            }

            return {
                x: posx,
                y: posy
            };
        },
        contextMenu: function contextMenu(e) {
            this.toggleProperty('showContextMenu');
            e.preventDefault();
            var text = window.getSelection().toString();
            this.positionMenu(e);
            console.log(text);
            // alert('This Could work!');
        },


        keydownMap: {
            8: 'startBackspacing', // backspace
            13: 'selectOption', // return
            16: 'shiftKey',
            27: 'closeDropdown', // escape
            38: 'focusPrevious', // up key
            40: 'focusNext' // down key
        },

        keyDown: function keyDown(e) {
            var map = this.get('keydownMap');
            var code = event.keyCode;
            console.log(e);
            var method = map[code];
            var that = this;
            var store = this.get('store');
            var model = this.get('obs');
            var date = new Date();
            console.log(method);
            if (method === 'focusNext' && e.shiftKey) {
                var newObservation = store.createRecord('demo/observation', {
                    timestamp: date
                });

                var obsBlock = store.findRecord('demo/observation-block', model.get('id'));

                obsBlock.then(function (result) {
                    result.get('observations').addObject(newObservation);
                }, function (error) {
                    console.log('Error : ' + error);
                });
                $(that).next('.ember-content-editable').focus();
            }
        }
    });
});