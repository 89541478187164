define('sokol-today/components/dashboard/staff/staff-display', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		connectedUser: Ember.inject.service(),
		router: Ember.inject.service(),
		actions: {
			getClass: function getClass(classroom) {
				this.get('goToClass')(classroom);
			}
		}
	});
});