define('sokol-today/routes/dashboard/observations/edit', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		model: function model(params) {
			return this.store.find('observation-block', params.observationBlock_id);
		},
		afterModel: function afterModel(model) {
			console.log(model);
			if (!model.get('finalized')) {
				return;
			} else {
				this.transitionTo('dashboard.observations.index');
			}
		}
	});
});