define('sokol-today/models/subject-list', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    subjects: _emberData.default.hasMany('subject'),
    school: _emberData.default.belongsTo('school')
  });
});