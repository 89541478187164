define('sokol-today/components/dashboard/rubric/add/domain/step-two', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),

    focusOut: function focusOut() {
      var store = this.get('store');
      var model = this.get('theComponent');
      var unsatisfactory = this.get('unsatisfactory');
      var unsatisfactoryModel = store.findRecord('rubric-ranking', unsatisfactory.get('id'));
      var basic = this.get('basic');
      var basicModel = store.findRecord('rubric-ranking', basic.get('id'));
      var proficient = this.get('proficient');
      var proficientModel = store.findRecord('rubric-ranking', proficient.get('id'));
      var distinguished = this.get('distinguished');
      var distinguishedModel = store.findRecord('rubric-ranking', distinguished.get('id'));
      if (typeof model == 'undefined') {
        console.log('not defined');
        return;
      } else {

        unsatisfactoryModel.then(function (theUnsatisfactory) {
          theUnsatisfactory.save();
        });

        basicModel.then(function (theBasicModel) {
          theBasicModel.save();
        });

        proficientModel.then(function (theProficientModel) {
          theProficientModel.save();
        });

        distinguishedModel.then(function (theDistinguishedModel) {
          theDistinguishedModel.save();
        });

        model.save();
      }
    }
  });
});