define('sokol-today/components/website/demo/observations/observation-icon', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['cd-timeline-img-demo cd-notes draggableItem'],
        attributeBindings: ['draggable'],
        draggable: 'true',

        dragStart: function dragStart(event) {},
        touchStart: function touchStart(event) {
            this.setProperties({ wasDragged: false });
        },
        touchMove: function touchMove(event) {
            this.setProperties({ wasDragged: true });
        },
        touchEnd: function touchEnd(event) {
            if (this.get('wasDragged')) {
                var data = this.get('data');
                data.destroyRecord();
            }
        },
        dragEnd: function dragEnd(event) {
            var data = this.get('data');
            data.destroyRecord();
        },
        click: function click(data) {
            this.toggleProperty('showRubricCodes');
            // var data = this.get('data');
            // console.log(data.get('observation'));
            // if (data.get('observation') == false) {
            //     data.set('observation', true);
            //     data.save();
            //     return
            // } else {
            //     data.set('observation', false);
            //     data.save();
            //     return
            // }
        }
    });
});