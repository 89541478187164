define('sokol-today/services/new-user', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Service.extend({
		hasUser: false,
		hasType: false,
		hasAccountType: false,
		hasAccountInfo: false,
		haveStudent: false,
		haveYearInfo: false,
		hasClass: false,
		hasClassStudents: false,
		isAdmin: false,
		isTeacher: false,
		isStudent: false,
		isParent: false
	});
});