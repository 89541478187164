define('sokol-today/components/dashboard/staff/add/step-three', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    newUser: Ember.inject.service(),
    connectedUser: Ember.inject.service(),
    router: Ember.inject.service(),
    isSending: false,
    didInsertElement: function didInsertElement() {
      var newUser = this.get('newUser');
      newUser.setProperties({ hasUser: true, hasRole: true });
    },


    actions: {
      printCard: function printCard(staff, schoolName) {
        this.toggleProperty('print');
      },
      print: function print() {
        window.print();
      },
      addAnotherStaff: function addAnotherStaff() {},
      sendEmail: function sendEmail(staff, staffEmail, school, schoolName) {
        var self = this;
        this.set('isSending', true);
        if (!staffEmail) {
          this.setProperties({ errorMessage: 'The staff member added does not have an email to send to.' });
        }
        var user = this.get('connectedUser.user');

        user.then(function (foundUser) {
          $.get({
            url: "https://us-central1-scope-95d64.cloudfunctions.net/sendEmailNewStaff?staff=" + staff.id + '&staffEmail=' + staffEmail + '&school=' + school + '&schoolName=' + schoolName + '&reqBy=' + foundUser.get('firstName')
          }).done(function (data) {
            self.setProperties({ errorMessage: '', successMessage: 'Email sent' });
            setTimeout(function () {
              self.get('transitionToStaff')();
            }, 1000);
          }).fail(function (reason) {
            self.setProperties({ isSending: false, errorMessage: reason });
          });
        });
      },
      back: function back() {
        this.toggleProperty('print');
      }
    }
  });
});