define('sokol-today/components/dashboard/students/display-page', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    sortByCorrected: Ember.computed('sortBy', function () {
      var string = this.get('sortBy');
      switch (string) {
        case "lastName":
          return 'Name';
          break;
        case "classOf":
          return 'Class Of';
          break;
        default:
          return string;
          break;
      }
    }),
    sortedStudents: Ember.computed.sort('connectedUser.currentStudents', 'sortDefinition'),
    sortBy: 'lastName',
    reverseSort: false,
    sortDefinition: Ember.computed('sortBy', 'reverseSort', function () {
      var sortOrder = this.get('reverseSort') ? 'desc' : 'asc';
      return [this.get('sortBy') + ':' + sortOrder];
    }),
    actions: {
      toggleFilterMenu: function toggleFilterMenu() {
        this.toggleProperty('showFilterMenu');
      }
    }
  });
});