define('sokol-today/components/dashboard/settings/billing/invoice-all', ['exports', 'accounting/format-money'], function (exports, _formatMoney) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		didReceiveAttr: function didReceiveAttr() {
			console.log(this.get('model'));
		}
	});
});