define('sokol-today/controllers/dashboard/classes/index', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		connectedUser: Ember.inject.service(),
		actions: {
			transitionToBatchCopy: function transitionToBatchCopy(classes) {
				this.transitionToRoute('dashboard.classes.copy.batch', classes);
			}
		}
	});
});