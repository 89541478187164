define('sokol-today/components/dashboard/staff/edit/step-one', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        connectedUser: Ember.inject.service(),
        newUser: Ember.inject.service(),
        store: Ember.inject.service(),
        firebaseApp: Ember.inject.service(),
        router: Ember.inject.service(),
        errorMessage: '',
        successMessage: '',

        allergies: Ember.computed('', function () {
            return this.get('store').findAll('allergy');
        }),
        professions: Ember.computed('', function () {
            return this.get('store').findAll('profession');
        }),
        didInsertElement: function didInsertElement() {
            var newUser = this.get('newUser');
            newUser.setProperties({ hasUser: false, hasRole: false });
        },

        actions: {
            createRoom: function createRoom(room) {
                var firebaseRef = this.get('firebaseApp').database();
                var self = this;
                var staff = this.get('model');
                var store = this.get('store');
                var roomRef = firebaseRef.push();
                var newRoomRef = 'room' + roomRef.key;
                var newRoom = store.createRecord('room', {
                    id: newRoomRef,
                    name: room
                });
                var user = this.get('connectedUser.staff');
                var school = store.findRecord('school', staff.get('school.id'));
                return school.then(function (theSchool) {
                    return newRoom.save().then(function (theRoom) {
                        var roomList = store.findRecord('room-list', theSchool.get('roomList.id'));
                        roomList.then(function (theRoomList) {
                            theRoomList.get('rooms').addObject(theRoom);
                            theRoomList.save().then(function () {
                                staff.set('room', theRoom);
                            }, function (error) {
                                console.log('Error : ' + error);
                            });
                        }, function (error) {
                            console.log('Error : ' + error);
                        });
                    }, function (error) {
                        console.log('Error : ' + error);
                    });
                }, function (error) {
                    console.log('Error : ' + error);
                });
            },
            createAllergy: function createAllergy(select, e) {
                var firebaseRef = this.get('firebaseApp').database();
                if (e.keyCode === 13 && select.isOpen && !select.highlighted && !Ember.isBlank(select.searchText)) {

                    var selected = this.get('selected');
                    if (select.searchText) {
                        var allergyRef = firebaseRef.push();
                        var newAllergyRef = 'allergy' + allergyRef.key;
                        var newAllergy = this.get('store').createRecord('allergy', {
                            id: newAllergyRef,
                            name: select.searchText
                        });
                        newAllergy.save().then(function (savedAllergy) {});

                        select.actions.choose(select.searchText);
                    }
                }
            },
            saveExt: function saveExt(ext) {
                var store = this.get('store');
                var classRoomID = this.get('model.room.id');
                var classRoom = store.findRecord('room', classRoomID);

                classRoom.then(function (foundClass) {
                    foundClass.set('ext', ext);
                    foundClass.save();
                }, function (error) {
                    console.log('Error : ' + error);
                });
            },
            createProfession: function createProfession(profession) {
                var firebaseRef = this.get('firebaseApp').database();
                var store = this.get('store');
                var session = this.get('session');
                var profRef = firebaseRef.push();
                var newProfRef = 'profession' + profRef.key;
                var newProfession = store.createRecord('profession', {
                    id: newProfRef,
                    name: profession
                });
                var staff = this.get('connectedUser.staff');

                staff.then(function (theUser) {

                    var school = store.findRecord('school', theUser.get('school.id'));
                    school.then(function (theSchool) {
                        newProfession.save().then(function (theProfession) {
                            var professionList = store.findRecord('profession-list', theSchool.get('professionList.id'));
                            professionList.then(function (theProfessionList) {
                                theProfessionList.get('professions').addObject(theProfession);
                                theProfessionList.save();
                            }, function (error) {
                                console.log('Error : ' + error);
                            });
                        });
                    });
                }, function (error) {
                    console.log('Error : ' + error);
                });
            },
            classChanged: function classChanged(record, highlighted) {
                var staff = this.get('model');
                if (record.length < highlighted.selected.length) {
                    var myArray = [];
                    myArray.pushObjects(highlighted.selected);
                    myArray.removeObjects(record);
                    highlighted.selected.removeObjects(myArray);
                    var myClass = myArray.get('firstObject');
                    myClass.get('staff').removeObject(staff);
                    myClass.save().then(function (classRemoved) {
                        staff.get('classes').removeObject(classRemoved);
                        staff.save();
                    });
                } else if (record.length > highlighted.selected.length) {
                    var classAdded = highlighted.highlighted;
                    classAdded.get('staff').addObject(staff);
                    staff.save().then(function () {
                        classAdded.save().then(function (classSaved) {
                            highlighted.selected.addObject(classSaved);
                        });
                    });
                } else {
                    console.log('hopefully nothing changed');
                }
                // console.log(highlighted.selected.removeObjects(record));
                // return record.classes;
            },
            saveTeacher: function saveTeacher(model, image, imageLandscape, imagePortrait) {
                var store = this.get('store');
                var self = this;
                var firebaseRef = this.get('firebaseApp').database().ref('null/');
                // var imageLandscape = this.get('imageLandscape');
                // var imagePortrait = this.get('imagePortrait');
                // var image = this.get('image');
                var router = this.get('router');
                var avatarRef = firebaseRef.push();
                var newAvatarRef = 'avatar' + avatarRef.key;
                if (image) {
                    // console.log('there is no model image and we have a image');
                    if (imageLandscape) {
                        var avatar = store.createRecord('avatar', {
                            id: newAvatarRef,
                            imageLandscape: true,
                            image: image
                        });

                        avatar.save().then(function (newAvatar) {
                            self.set('successMessage', 'We are saving the data...please hold');
                            model.set('avatar', newAvatar);
                            model.save().then(function () {
                                self.setProperties({ successMessage: 'Staff info saved', errorMessage: '' });
                                setTimeout(function () {
                                    router.transitionTo('dashboard.staff');
                                }, 1000);
                            }, function (error) {
                                self.setProperties({ successMessage: '', errorMessage: error });
                                console.log('Error : ' + error);
                            });
                        }, function (error) {
                            self.setProperties({ successMessage: '', errorMessage: error });
                            console.log('Error : ' + error);
                        });
                    } else if (imagePortrait) {
                        var avatar = store.createRecord('avatar', {
                            id: newAvatarRef,
                            imagePortrait: true,
                            image: image
                        });

                        avatar.save().then(function (newAvatar) {
                            self.set('successMessage', 'We are saving the data...please hold');
                            model.set('avatar', newAvatar);
                            model.save().then(function () {
                                self.setProperties({ successMessage: 'Staff info saved', errorMessage: '' });
                                setTimeout(function () {
                                    router.transitionTo('dashboard.staff');
                                }, 1000);
                            }, function (error) {
                                self.setProperties({ successMessage: '', errorMessage: error });
                                console.log('Error : ' + error);
                            });
                        }, function (error) {
                            self.setProperties({ successMessage: '', errorMessage: error });
                            console.log('Error : ' + error);
                        });
                    } else {
                        var avatar = store.createRecord('avatar', {
                            id: newAvatarRef,
                            image: image
                        });
                        avatar.save().then(function (newAvatar) {
                            self.set('successMessage', 'We are saving the data...please hold');
                            model.set('avatar', newAvatar);
                            model.save().then(function () {
                                self.setProperties({ successMessage: 'Staff info saved', errorMessage: '' });
                                setTimeout(function () {
                                    router.transitionTo('dashboard.staff');
                                }, 1000);
                            }, function (error) {
                                self.setProperties({ successMessage: '', errorMessage: error });
                                console.log('Error : ' + error);
                            });
                        }, function (error) {
                            self.setProperties({ successMessage: '', errorMessage: error });
                            console.log('Error : ' + error);
                        });
                    }
                } else {
                    model.save().then(function () {
                        self.setProperties({ successMessage: 'Staff info saved', errorMessage: '' });
                        setTimeout(function () {
                            router.transitionTo('dashboard.staff');
                        }, 1000);
                    }, function (error) {
                        self.setProperties({ successMessage: '', errorMessage: error });
                        console.log('Error : ' + error);
                    });
                }
            },
            saveEditRole: function saveEditRole(model, image, imageLandscape, imagePortrait, editRole) {
                var firebaseRef = this.get('firebaseApp').database();
                var store = this.get('store');
                var self = this;
                // var imageLandscape = this.get('imageLandscape');
                // var imagePortrait = this.get('imagePortrait');
                // var image = this.get('image');
                var router = this.get('router');
                var avatarRef = firebaseRef.push();
                var newAvatarRef = 'avatar' + avatarRef.key;
                if (image) {
                    // console.log('there is no model image and we have a image');
                    if (imageLandscape) {
                        var avatar = store.createRecord('avatar', {
                            id: newAvatarRef,
                            imageLandscape: true,
                            image: image
                        });

                        avatar.save().then(function (newAvatar) {
                            self.set('successMessage', 'We are saving the data...please hold');
                            model.set('avatar', newAvatar);
                            model.save().then(function (savedStaff) {
                                self.setProperties({ successMessage: 'Staff info saved', errorMessage: '' });
                                setTimeout(function () {
                                    if (editRole) {
                                        self.get('transitionToStepTwo')(savedStaff);
                                    } else {
                                        router.transitionTo('dashboard.staff');
                                    }
                                }, 1000);
                            }, function (error) {
                                self.setProperties({ successMessage: '', errorMessage: error });
                                console.log('Error : ' + error);
                            });
                        }, function (error) {
                            self.setProperties({ successMessage: '', errorMessage: error });
                            console.log('Error : ' + error);
                        });
                    } else if (imagePortrait) {
                        var avatar = store.createRecord('avatar', {
                            id: newAvatarRef,
                            imagePortrait: true,
                            image: image
                        });

                        avatar.save().then(function (newAvatar) {
                            self.set('successMessage', 'We are saving the data...please hold');
                            model.set('avatar', newAvatar);
                            model.save().then(function (savedStaff) {
                                self.setProperties({ successMessage: 'Staff info saved', errorMessage: '' });
                                setTimeout(function () {
                                    if (editRole) {
                                        self.get('transitionToStepTwo')(savedStaff);
                                    } else {
                                        router.transitionTo('dashboard.staff');
                                    }
                                }, 1000);
                            }, function (error) {
                                self.setProperties({ successMessage: '', errorMessage: error });
                                console.log('Error : ' + error);
                            });
                        }, function (error) {
                            self.setProperties({ successMessage: '', errorMessage: error });
                            console.log('Error : ' + error);
                        });
                    } else {
                        var avatar = store.createRecord('avatar', {
                            id: newAvatarRef,
                            image: image
                        });
                        avatar.save().then(function (newAvatar) {
                            self.set('successMessage', 'We are saving the data...please hold');
                            model.set('avatar', newAvatar);
                            model.save().then(function (savedStaff) {
                                self.setProperties({ successMessage: 'Staff info saved', errorMessage: '' });
                                setTimeout(function () {
                                    if (editRole) {
                                        self.get('transitionToStepTwo')(savedStaff);
                                    } else {
                                        router.transitionTo('dashboard.staff');
                                    }
                                }, 1000);
                            }, function (error) {
                                self.setProperties({ successMessage: '', errorMessage: error });
                                console.log('Error : ' + error);
                            });
                        }, function (error) {
                            self.setProperties({ successMessage: '', errorMessage: error });
                            console.log('Error : ' + error);
                        });
                    }
                } else {
                    model.save().then(function (savedStaff) {
                        self.setProperties({ successMessage: 'Staff info saved', errorMessage: '' });
                        setTimeout(function () {
                            if (editRole) {
                                self.get('transitionToStepTwo')(savedStaff);
                            } else {
                                router.transitionTo('dashboard.staff');
                            }
                        }, 1000);
                    }, function (error) {
                        self.setProperties({ successMessage: '', errorMessage: error });
                        console.log('Error : ' + error);
                    });
                }
            },
            deactivate: function deactivate() {
                this.toggleProperty('deactivate');
            },
            confirmDeactivate: function confirmDeactivate(model) {
                var self = this;
                var router = this.get('router');
                model.save().then(function (savedModel) {
                    var name = savedModel.get('firstName') + ' ' + savedModel.get('lastName') + ' is deactivated';
                    self.setProperties({ successMessage: name, errorMessage: '' });
                    setTimeout(function () {
                        router.transitionTo('dashboard.staff.index');
                    }, 1000);
                }, function (error) {
                    model.roleBackAttributes();
                    console.log('Error : ' + error);
                    self.setProperties({ errorMessage: error });
                });
            },
            back: function back() {
                var router = this.get('router');
                router.transitionTo('dashboard.staff');
            }
        }
    });
});