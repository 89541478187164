define('sokol-today/components/dashboard/classes/edit/display-page', ['exports'], function (exports) {
		'use strict';

		Object.defineProperty(exports, "__esModule", {
				value: true
		});
		exports.default = Ember.Component.extend({
				store: Ember.inject.service(),
				connectedUser: Ember.inject.service(),
				router: Ember.inject.service('-routing'),
				isOne: true,
				daysArray: [0],
				courseLengthPass: null,
				isFullYear: false,
				isSemester: false,

				didRender: function didRender() {
						this._super.apply(this, arguments);
						this.get('courseLengthCorrect');
				},


				courseLengthCorrect: Ember.computed('model.name', 'model.periods', function () {
						var courseLength = this.get('model.name.courseLength');
						var periods = this.get('model.year.periods');
						var _this = this;
						return courseLength.then(function (requiredLength) {
								var classLength = _this.get('model.periods');
								//Nothing selected - no need to continue            
								if (classLength == null) {
										console.log('false');
										return false;
								}
								var selectedTerms = classLength.getEach('id').sort();
								return new Promise(function (resolve, reject) {
										console.log(periods);
										periods.then(function (yearPeriods) {
												var count = yearPeriods.get('length');
												var indexSplit = count / 2;
												var test1 = yearPeriods.getEach('id').slice(0, indexSplit);
												var test2 = yearPeriods.getEach('id').slice(indexSplit);
												switch (requiredLength.get('name')) {
														case "Full Year":
																if (selectedTerms.length !== count) {
																		_this.set('courseLengthPass', false);
																} else {
																		_this.set('courseLengthPass', true);
																}
																break;
														case "Semester (Either)":
																if (selectedTerms.length !== test1.length && selectedTerms.length !== test2.length) {
																		_this.set('courseLengthPass', false);
																}
																for (var i = selectedTerms.length; i--;) {
																		if (test1.contains(selectedTerms[i])) {
																				test1.popObject(selectedTerms[i]);
																		} else if (test2.contains(selectedTerms[i])) {
																				test2.popObject(selectedTerms[i]);
																		}
																}
																if (test2.length === 0 && test1.length === indexSplit) {
																		_this.set('courseLengthPass', true);
																} else if (test1.length === 0 && test2.length === indexSplit) {
																		_this.set('courseLengthPass', true);
																} else {
																		_this.set('courseLengthPass', false);
																}
																break;
														case "Semester (Fall)":
																if (selectedTerms.length !== test2.length) {
																		_this.set('courseLengthPass', false);
																}
																for (var i = selectedTerms.length; i--;) {
																		if (selectedTerms[i] == test2[i]) {
																				test2.popObject(test2[i]);
																		}
																}
																if (test2.length == 0) {
																		_this.set('courseLengthPass', true);
																} else {
																		_this.set('courseLengthPass', false);
																}
																break;
														case "Semester (Spring)":
																if (selectedTerms.length !== test1.length) {
																		_this.set('courseLengthPass', false);
																}
																for (var i = selectedTerms.length; i--;) {
																		if (selectedTerms[i] == test1[i]) {
																				test1.popObject(test1[i]);
																		}
																}
																if (test1.length == 0) {
																		_this.set('courseLengthPass', true);
																} else {
																		_this.set('courseLengthPass', false);
																}
																break;
														default:
																_this.set('courseLengthPass', false);
																break;
												}
												return true;
										});
								});
						});
				}),

				times: Ember.computed(function () {
						var store = this.get('store');
						return store.findAll('time');
				}),

				days: Ember.computed(function () {
						var store = this.get('store');
						var days = store.findAll('day-list');
						return days;
						// return days.then(function(dayList){
						// 	console.log(dayList.sortBy('numeric'));
						// 	return dayList;
						// });
				}),

				actions: {
						///Need to create action for each power select onclose to pop old 
						///object and add new from model and relationships then save
						///can just be save
						yearChanged: function yearChanged(selected, object) {
								var that = this;
								var model = this.get('model');
								var store = this.get('store');
								this.set('model.year', object.highlighted);

								var periodID = this.get('model.period.id');

								var period = store.findRecord('period', periodID);

								period.then(function (result) {
										result.get('classes').removeObject(model);
										result.save();
										that.get('class').changeYear(object.highlighted);

										// period.removeObject(model);

										that.set('model.period', null);

										// console.log(object.highlighted);
										// console.log(object.selected);
								});
						},
						periodChanged: function periodChanged(selected, object) {
								var that = this;
								var model = this.get('model');
								this.set('model.period', object.highlighted);

								var year = this.get('model.year');

								var previousPeriod = object.selected;
								var newPeriod = object.highlighted;

								if (!previousPeriod) {
										newPeriod.get('classes').addObject(model);
										newPeriod.save();
										model.save();
								} else {
										previousPeriod.get('classes').removeObject(model);
										previousPeriod.save();
										newPeriod.get('classes').addObject(model);
										newPeriod.save();
										model.save();
								}

								that.get('connectedUser').changeYear(year);
								that.set('selectedYear', year);
						},
						createClassName: function createClassName(suggestedClass) {
								var that = this;
								var store = this.get('store');
								var classNameListID = this.get('connectedUser.staff.school.classNameList');

								classNameListID.then(function (theClassNameList) {
										var classNameListModel = store.findRecord('class-name-list', theClassNameList.id);
										classNameListModel.then(function (classNameList) {
												console.log(classNameList);
												var newClassName = store.createRecord('class-name', {
														name: suggestedClass
												});

												newClassName.save().then(function (savedNewClassName) {
														console.log(savedNewClassName);
														classNameList.get('classNames').addObject(savedNewClassName);
														classNameList.save().then(function () {
																that.set('model.name', savedNewClassName);
														}, function (error) {
																console.log(error);
														});
												}, function (error) {
														console.log(error);
												});
										}, function (error) {
												console.log(error);
										});
								}, function (error) {
										console.log(error);
								});
						},
						handleStaff: function handleStaff(record, highlighted) {
								var theClass = this.get('model');
								if (record.length < highlighted.selected.length) {
										// someone was removed
										var myArray = [];
										myArray.pushObjects(highlighted.selected);
										myArray.removeObjects(record);
										highlighted.selected.removeObjects(myArray);
										var staff = myArray.get('firstObject');
										staff.get('classes').removeObject(theClass);
										staff.save().then(function (staffRemoved) {
												theClass.get('staff').removeObject(staffRemoved);
												theClass.save();
										});
								} else if (record.length > highlighted.selected.length) {
										var staffAdded = highlighted.highlighted;
										staffAdded.get('classes').addObject(theClass);
										theClass.get('staff').addObject(staffAdded);
										theClass.save().then(function () {
												staffAdded.save().then(function (staffSaved) {
														highlighted.selected.addObject(staffSaved);
												});
										});
								} else {
										console.log('hopefully nothing changed');
								}
						},
						handleStudents: function handleStudents(record, highlighted) {
								var theClass = this.get('model');
								if (record.length < highlighted.selected.length) {
										// someone was removed
										var myArray = [];
										myArray.pushObjects(highlighted.selected);
										myArray.removeObjects(record);
										highlighted.selected.removeObjects(myArray);
										var student = myArray.get('firstObject');
										student.get('classes').removeObject(theClass);
										student.save().then(function (studentRemoved) {
												theClass.get('students').removeObject(studentRemoved);
												theClass.save();
										});
								} else if (record.length > highlighted.selected.length) {
										//someone was added
										var studentAdded = highlighted.highlighted;
										studentAdded.get('classes').addObject(theClass);
										theClass.get('year').then(function (yearObject) {
												studentAdded.get('years').addObject(yearObject);

												studentAdded.save().then(function (studentSaved) {
														theClass.get('students').addObject(studentSaved);
														theClass.save();
														highlighted.selected.addObject(studentSaved);
												}, function (error) {
														console.log('Error : ' + error);
												});
										}, function (error) {
												console.log('Error : ' + error);
										});
								} else {
										console.log('hopefully nothing changed');
								}
						},

						// save class and corresponding relationships
						saveClass: function saveClass(theClass) {
								var router = this.get('router');
								var that = this;
								theClass.save().then(function (savedClass) {
										that.setProperties({ successMessage: 'Class updated', errorMessage: '' });
										setTimeout(function () {
												router.transitionTo('dashboard.classes');
										}, 1000);
								}, function (error) {
										that.setProperties({ successMessage: '', errorMessage: 'There was an error saving your edits, possibly because you dont have permission' });
								});
						},
						deleteClass: function deleteClass(theClass) {
								var router = this.get('router');
								var store = this.get('store');
								var connectedUser = this.get('connectedUser');
								var self = this;
								//This deletes class from classList and Teachers class list
								//still need to check class with students
								var classList = theClass.get('school.classList');
								classList.then(function (theClassList) {
										theClass.destroyRecord().then(function (savedClass) {
												var staff = theClass.get('staff');

												var _loop = function _loop() {
														var foundStaff = store.peekRecord('staff', staff.objectAt(i).get('id'));
														foundStaff.get('classes').removeObject(theClass);
														foundStaff.save().then(function () {}, function (error) {
																foundStaff.rollbackChanges();
																console.log('Error : ' + error);
																return;
														});
												};

												for (var i = staff.get('length') - 1; i >= 0; i--) {
														_loop();
												}
												theClassList.get('classes').removeObject(theClass);
												theClassList.save();
												self.setProperties({ successMessage: 'Class deleted' });
												connectedUser.changeYear(connectedUser.get('currentYear'));
												setTimeout(function () {
														router.transitionTo('dashboard.classes');
												}, 1000);
										}, function (error) {
												theClass.rollbackAttributes();
												theClassList.rollbackAttributes();
												classList.rollbackAttributes();
												console.log('Error : ' + error);
										});
								}, function (error) {
										classList.rollbackAttributes();
										console.log('Error : ' + error);
								});
						},
						createRoom: function createRoom(room) {
								var that = this;
								var store = this.get('store');
								var newRoom = store.createRecord('room', {
										name: room
								});
								newRoom.save().then(function (theRoom) {
										that.set('model.room', theRoom);
								}, function (error) {
										console.log('Error : ' + error);
								});
						}
				}
		});
});