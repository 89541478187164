define('sokol-today/controllers/dashboard/courses/add', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        stepIndicator: Ember.inject.service(),

        priorityCheck: function () {
            console.log('controller priority check');
            var _this = this;
            var model = this.get('model');
            var priority = model.get('priority');
            var subject = model.get('subject.name');
            var count = model.get('subject.courses.length');
            if (!priority) {
                return;
            }
            switch (priority) {
                case "1":
                    _this.updateCourseID(subject, priority, count);
                    model.set('GPA', true);
                    model.set('honorRoll', true);
                    return "State Required";
                    break;
                case "2":
                    _this.updateCourseID(subject, priority, count);
                    model.set('GPA', true);
                    model.set('honorRoll', true);
                    return "State Required Second Level";
                    break;
                case "3":
                    _this.updateCourseID(subject, priority, count);
                    model.set('GPA', true);
                    model.set('honorRoll', true);
                    return "Higher Learning";
                    break;
                case "4":
                    _this.updateCourseID(subject, priority, count);
                    model.setProperties({ GPA: false, honorRoll: false });
                    return "Continued Learning";
                    break;
                case "5":
                    _this.updateCourseID(subject, priority, count);
                    model.setProperties({ GPA: false, honorRoll: false });
                    return "Non-Essential Learning";
                    break;
                case "6":
                    _this.updateCourseID(subject, priority, count);
                    model.setProperties({ GPA: false, honorRoll: false });
                    return "Basket Weaving...";
                    break;
                default:
                    _this.updateCourseID(subject, priority, count);
                    return "";
                    break;
            }
        }.observes('model.priority'),

        updateCourseID: function updateCourseID(subject, priority, count) {
            if (typeof count === 'undefined') {
                console.log('count was undefined: ' + count);
                count = 0;
            } else {
                console.log('count was present: ' + count);
            }
            console.log(subject + ' ' + priority + ' ' + count);
            var idString = null;
            var _this = this;
            switch (subject) {
                case 'Mathematics':
                    idString = 'MA' + (1000 * priority + count);
                    _this.set('model.courseID', idString);
                    _this.set('exampleID', idString);
                    break;
                case 'English':
                    idString = 'EN' + (1000 * priority + count);
                    _this.set('model.courseID', idString);
                    _this.set('exampleID', idString);
                    break;
                case 'Life Studies':
                    idString = 'LS' + (1000 * priority + count);
                    _this.set('model.courseID', idString);
                    _this.set('exampleID', idString);
                    break;
                case 'Science':
                    idString = 'SC' + (1000 * priority + count);
                    _this.set('model.courseID', idString);
                    _this.set('exampleID', idString);
                    break;
                case 'Social Studies':
                    idString = 'SS' + (1000 * priority + count);
                    _this.set('model.courseID', idString);
                    _this.set('exampleID', idString);
                    break;
                case 'Technology':
                    idString = 'TE' + (1000 * priority + count);
                    _this.set('model.courseID', idString);
                    _this.set('exampleID', idString);
                    break;
                case 'Physical & Health':
                    idString = 'PE' + (1000 * priority + count);
                    _this.set('model.courseID', idString);
                    _this.set('exampleID', idString);
                    break;
                case 'World Languages':
                    idString = 'WL' + (1000 * priority + count);
                    _this.set('model.courseID', idString);
                    _this.set('exampleID', idString);
                    break;
                case 'Miscellaneous Offering':
                    idString = 'MO' + (1000 * priority + count);
                    _this.set('model.courseID', idString);
                    _this.set('exampleID', idString);
                    break;
                case 'Business':
                    idString = 'BU' + (1000 * priority + count);
                    _this.set('model.courseID', idString);
                    _this.set('exampleID', idString);
                    break;
                case 'The Arts':
                    idString = 'AR' + (1000 * priority + count);
                    _this.set('model.courseID', idString);
                    _this.set('exampleID', idString);
                    break;
                case 'Performing Arts':
                    idString = 'MU' + (1000 * priority + count);
                    _this.set('model.courseID', idString);
                    _this.set('exampleID', idString);
                    break;
                case 'Digital Education':
                    idString = 'DE' + (1000 * priority + count);
                    _this.set('model.courseID', idString);
                    _this.set('exampleID', idString);
                    break;
                case 'Wellness':
                    idString = 'WE' + (1000 * priority + count);
                    _this.set('model.courseID', idString);
                    _this.set('exampleID', idString);
                    break;
                default:
                    return;
            }
        }
    });
});