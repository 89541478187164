define('sokol-today/components/dashboard/display-main', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		connectedUser: Ember.inject.service(),
		connectionStatus: Ember.inject.service(),
		appEvents: Ember.inject.service(),

		dragOver: function dragOver(event) {
			console.log(event);
			this.get('appEvents').set('eventDragOverApp', event);
		},
		dragEnd: function dragEnd() {
			console.log(event);
			this.get('appEvents').setProperties({ eventDragOverApp: null });
		},
		dragLeave: function dragLeave() {
			console.log(event);
			this.get('appEvents').setProperties({ eventDragOverApp: null });
		}
	});
});