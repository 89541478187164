define('sokol-today/routes/website/story', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        activate: function activate() {
            this._super();
            window.scrollTo(0, 0);
        }
    });
});