define('sokol-today/components/dashboard/years/add/progress-bar', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        newUser: Ember.inject.service(),
        didInsertElement: function didInsertElement() {
            var newUser = this.get('newUser');
            newUser.setProperties({ haveYearInfo: true });
        }
    });
});