define('sokol-today/components/website/demo/observations/new-observation', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        store: Ember.inject.service(),

        didInsertElement: function didInsertElement() {
            var menuHeight = $('#obs-demo1').height();
            var height = menuHeight;
            console.log(height);
            $('.demo-obs').height(height);
        },
        click: function click() {
            var store = this.get('store');
            var model = this.get('model');
            var date = new Date();
            var newObservation = store.createRecord('demo/observation', {
                timestamp: date
            });

            var obsBlock = store.findRecord('demo/observation-block', model.get('id'));

            obsBlock.then(function (result) {
                result.get('observations').addObject(newObservation);
            }, function (error) {
                console.log('Error : ' + error);
            });
        },


        actions: {}
    });
});