define('sokol-today/models/payment-history', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        cancel_at_period_end: _emberData.default.attr('boolean'),
        created: _emberData.default.attr('date'), //Unix Epoch Seconds
        current_period_end: _emberData.default.attr('date'), //Unix Epoch Seconds
        current_period_start: _emberData.default.attr('date'), //Unix Epoch Seconds
        customer: _emberData.default.attr('string'),
        // id: DS.attr('string'), //Subscription id
        items: _emberData.default.attr(),
        livemode: _emberData.default.attr('boolean'),
        object: _emberData.default.attr('string'),
        plan: _emberData.default.attr(),
        quantity: _emberData.default.attr('number'),
        start: _emberData.default.attr('date'), //Unix Epoch Seconds
        status: _emberData.default.attr('string'),
        trial_end: _emberData.default.attr('date'), //Unix Epoch Seconds
        trial_start: _emberData.default.attr('date') //Unic Epoch Seconds
    });
});