define('sokol-today/components/dashboard/years/add/step-one', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        connectedUser: Ember.inject.service(),
        newUser: Ember.inject.service(),
        store: Ember.inject.service(),
        router: Ember.inject.service(),
        firebaseApp: Ember.inject.service(),

        minDate: Ember.computed('firstDay', function () {
            var date = this.get('firstDay');
            if (date) {
                return moment(date, "string").add(1, "Y").subtract(2, "M").subtract(15, "d").toDate();
            } else {
                return "June 6";
            }
        }).property('firstDay'),

        actions: {
            saveInfo: function saveInfo(firstDay, lastDay) {
                var firebaseRef = this.get('firebaseApp').database();
                if (!firstDay) {
                    this.setProperties({ errorMessage: 'Please pick a first day' });
                    return;
                }
                if (!lastDay) {
                    this.setProperties({ errorMessage: 'Please pick a last day' });
                    return;
                }
                var self = this;
                var store = this.get('store');
                var school = this.get('connectedUser.staff.school');
                var router = this.get('router');
                var yearRef = firebaseRef.push();
                var newYearRef = 'year' + yearRef;
                school.then(function (foundSchool) {
                    var year = store.createRecord('year', {
                        id: newYearRef,
                        firstDay: firstDay,
                        lastDay: lastDay,
                        school: foundSchool
                    });
                    year.set('year', moment(firstDay).year() + '-' + moment(lastDay).year());

                    year.save().then(function (savedYear) {
                        foundSchool.get('yearList').then(function (yearList) {
                            yearList.get('years').addObject(savedYear);
                            yearList.save().then(function () {
                                self.get('newUser').set('haveYearInfo', true);
                                self.setProperties({ successMessage: "Year Info Saved", errorMessage: '' });
                                setTimeout(function () {
                                    router.transitionTo('dashboard.years.add.step-two');
                                }, 1000);
                            }, function (error) {
                                console.log('Error : ' + error);
                            });
                        }, function (error) {
                            console.log('Error : ' + error);
                        });
                    }, function (error) {
                        console.log('Error : ' + error);
                    });
                }, function (error) {
                    console.log('Error : ' + error);
                });
            },
            cancel: function cancel() {
                var router = this.get('router');
                router.transitionTo('dashboard.years');
            }
        }
    });
});