define('sokol-today/routes/dashboard/classes/classroom/photos', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    // model(params){
    //        return this.store.find('class', params.class_id);
    //    },
  });
});