define('sokol-today/models/guardian', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        avatar: _emberData.default.belongsTo('avatar', { async: true }),
        firstName: _emberData.default.attr('string'),
        lastName: _emberData.default.attr('string'),
        pin: _emberData.default.attr('string'),
        relationships: _emberData.default.hasMany('relationship'),
        students: _emberData.default.hasMany('student'),
        cellPhone: _emberData.default.attr('string'),
        homePhone: _emberData.default.attr('string'),
        workPhone: _emberData.default.attr('string'),
        emailPersonal: _emberData.default.attr('string'),
        emailWork: _emberData.default.attr('string'),
        addressStreet: _emberData.default.attr('string'),
        addressStreetNum: _emberData.default.attr('string'),
        addressState: _emberData.default.attr('string'),
        addressZip: _emberData.default.attr('string'),
        addressTown: _emberData.default.attr('string'),
        interests: _emberData.default.hasMany('interest', { async: true }),
        fullAddress: Ember.computed('addressStreetNum', 'addressStreet', 'zipCode', function () {
            return this.get('addressStreetNum') + ' ' + this.get('addressStreet') + ' ' + this.get('zipCode');
        })
    });
});