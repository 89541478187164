define('sokol-today/models/class-grade-weight', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		name: _emberData.default.attr('string'),
		test: _emberData.default.attr('number'),
		classwork: _emberData.default.attr('number'),
		homework: _emberData.default.attr('number'),
		final: _emberData.default.attr('number')
	});
});