define('sokol-today/models/photo-library', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		user: _emberData.default.belongsTo('user', { async: true, inverse: 'photoLibrary' }),
		favorites: _emberData.default.hasMany('photo'),
		saves: _emberData.default.hasMany('student'),
		uploades: _emberData.default.hasMany('photo')
	});
});