define('sokol-today/models/student-grade', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		grade: _emberData.default.attr('string'),
		//duration is to correspound to how the school hands out grades, quarterly, semester, yearly, by class etc
		duration: _emberData.default.attr('string'),
		year: _emberData.default.belongsTo('year'),
		//class the student received the grade for
		class: _emberData.default.belongsTo('class', { async: true, inverse: null }),
		teacher: _emberData.default.attr('string'),
		student: _emberData.default.belongsTo('student', { async: true, inverse: null }),
		school: _emberData.default.belongsTo('string'),
		district: _emberData.default.attr('string'),
		student_school_id: _emberData.default.attr('string'), //id created using student and school id for query
		student_class_id: _emberData.default.attr('string') //id created using student and class id for query
	});
});