define('sokol-today/initializers/init', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(container, app) {
    app.inject('router:main', 'new-user', 'service:new-user');
  }

  exports.default = {
    name: 'init',
    initialize: initialize
  };
});