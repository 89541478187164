define('sokol-today/components/dashboard/statistics/index-page', ['exports', 'd3-collection', 'd3-array'], function (exports, _d3Collection, _d3Array) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    period: 'byYear',
    absenceOptions: ['absent', 'dismissal', 'tardy'],

    absencesData: Ember.computed('model', function () {
      var store = this.get('store');
      var staff = this.get('model');
      if (!staff) {
        return;
      }
      return store.query('absence', {
        orderBy: 'staff',
        equalTo: staff.get('id')
      }).then(function (results) {
        return results.toArray();
      });
    }),

    absenceDataNested: Ember.computed('absencesData', 'period', function () {
      var period = this.get('period');
      console.log(period);
      return this.get('absencesData').then(function (absences) {
        // console.log(absences);
        return (0, _d3Collection.nest)().key(function (d) {
          console.log(d);
          return d.get(period);
        }).key(function (d) {
          return d.get('type');
        }).rollup(function (v) {
          return v.length;
        })
        // .sortKeys(ascending)
        .entries(absences);
      });
    }),

    allergies: Ember.computed('', function () {
      var store = this.get('store');
      return store.findAll('allergy');
    }),

    didInsertElement: function didInsertElement() {
      var _this = this;
      var model = _this.get('model');
      var service = _this.get('connectedUser');
      service.getStaff(model);
      this.set('byYear', true);
    },


    actions: {
      getSchool: function getSchool(staff) {
        var _this = this;
        var router = _this.get('router');
        var service = _this.get('connectedUser');
        service.getStaff(staff);
        router.transitionTo('dashboard.settings.user', staff);
      },
      rollbackAttributes: function rollbackAttributes(staffInfo, user) {
        staffInfo.rollbackAttributes();
        staffInfo.get('allergies').forEach(function (color) {
          return color.rollbackAttributes();
        });
        user.rollbackAttributes();
      },
      toggleStatPeriod: function toggleStatPeriod(period) {
        this.setProperties({ byWeek: false, byMonth: false, byYear: false });
        this.set(period, true);
        this.set('period', period);
      },
      save: function save(model) {
        var _this = this;
        model.save().then(function () {
          _this.setProperties({ successMessage: 'Save Successful', success: true });
          setTimeout(function () {
            _this.setProperties({ success: false });
          }, 3000);
        }, function (error) {
          console.log('Error : ' + error);
          _this.setProperties({ errorMessage: error, error: true });
          setTimeout(function () {
            _this.setProperties({ error: false });
          }, 3000);
        });
      }
    }
  });
});