define('sokol-today/models/year', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    coursesConfirmed: _emberData.default.attr('boolean'),
    courses: _emberData.default.hasMany('course-info', { async: true, inverse: null }),
    periods: _emberData.default.hasMany('period', { async: true }),
    year: _emberData.default.attr('string'),
    firstDay: _emberData.default.attr('date'),
    lastDay: _emberData.default.attr('date'),
    school: _emberData.default.belongsTo('school', { inverse: null }),
    daySchedule: _emberData.default.hasMany('dayTimeBreakdown')
  });
});