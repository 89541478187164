define('sokol-today/components/dashboard/display/top-menu/index-display', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    classNames: [],
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    roleDecode: Ember.inject.service(),

    didReceiveAttrs: function didReceiveAttrs() {
      this.set('isLoaded', true);
    },


    studentYears: Ember.computed('connectedUser.account.classes', function () {
      var yearArray = [];
      var yearObjects = [];
      var that = this;
      var classes = this.get('connectedUser.account.classes');
      return classes.then(function (classList) {
        for (var i = classList.get('length') - 1; i >= 0; i--) {
          return classList.objectAt(i).get('year').then(function (yearResult) {

            if (yearArray.includes(yearResult.get('year'))) {
              return;
            } else {
              yearArray.push(yearResult.get('year'));
              yearObjects.push(classList.objectAt(i).get('year'));
            }
            return yearObjects;
          });
        }
      });
    }),

    roleList: [{
      id: 1,
      roleName: 'Teacher Personal'
    }, {
      id: 2,
      roleName: 'Teacher'
    }, {
      id: 3,
      roleName: 'Administrator'
    }, {
      id: 4,
      roleName: 'Parent'
    }, {
      id: 5,
      roleName: 'Student'
    }, {
      id: 6,
      roleName: 'Administrator Personal'
    }],

    userList: [{
      id: 1,
      userName: 'Teacher Personal',
      firebaseRef: 'staff-Kx9ulSTaBDn7xSezPOn'
    }, {
      id: 2,
      userName: 'Teacher',
      firebaseRef: 'staff-KxZWsgH5yuRhPJnVCM7'
    }, {
      id: 3,
      userName: 'Administrator',
      firebaseRef: 'not real'
    }, {
      id: 4,
      userName: 'Parent',
      firebaseRef: 'not real'
    }, {
      id: 5,
      userName: 'Student',
      firebaseRef: 'not real'
    }, {
      id: 6,
      userName: 'Administrator Personal',
      firebaseRef: 'staff-Kx9ds272Q93POGwf5Sq'
    }],

    actions: {
      refreshClass: function refreshClass(theClass, highlighted) {
        var classService = this.get('connectedUser');
        theClass.actions.select(theClass.highlighted);
        classService.setProperties({ currentClass: theClass.highlighted });
        classService.changeClass(theClass.highlighted);
      },
      refreshPeriod: function refreshPeriod(thePeriod, highlighted) {
        var classService = this.get('connectedUser');
        thePeriod.actions.select(thePeriod.highlighted);
        classService.changePeriod(thePeriod.highlighted);
        classService.changeClass(null);
        this.setProperties({ selectedClass: null });
      },
      refreshYear: function refreshYear(theYear, highlighted) {
        console.log(theYear);
        var classService = this.get('connectedUser');
        classService.changeYear(theYear);
        classService.changeClass(null);
        classService.changePeriod(null);
        this.setProperties({ selectedClass: null, selectedPeriod: null });
      },
      logOut: function logOut(session) {
        if (confirm('Do you wish to logout?') == true) {
          var route = this.get('router');
          session.close().then(function () {
            route.transitionTo('dashboard.account');
          });
        } else {
          return;
        }
      },
      reflection: function reflection(role) {
        var staff = this.get('connectedUser.account');
        var _this = this;
        var decode = this.get('roleDecode');
        var roleValue = decode.getNumber(role.roleName);
        staff.set('role', roleValue);
        staff.save().then(function () {
          _this.set('selectedOption', role);
        });
      },
      assassin: function assassin(role) {
        //this should change user defaultAccount to a test account
      }
    }
  });
});