define('sokol-today/models/relationship', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        relation: _emberData.default.belongsTo('relationList', { async: true }),
        guardian: _emberData.default.belongsTo('guardian', { async: true, inverse: null }),
        student: _emberData.default.belongsTo('student', { inverse: null }),
        homeFull: _emberData.default.attr('boolean'),
        homePart: _emberData.default.attr('boolean')
    });
});