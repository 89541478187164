define('sokol-today/components/dashboard/students/student-display', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var RSVP = Ember.RSVP;
  exports.default = Ember.Component.extend({
    assignmentsByTodo: Ember.computed.sort('student.assignments', 'todosSortingDesc').property('student.assignments.@each.classAssignment.dueDate'),
    assignmentsDone: Ember.computed.filterBy('assignmentsByTodo', 'complete', true),
    assignmentsTodo: Ember.computed.filterBy('assignmentsByTodo', 'complete', false),
    todosSortingDesc: ['complete:asc', 'classAssignment.dueDate:asc'],
    actions: {
      saveTodo: function saveTodo(assignment) {
        assignment.toggleProperty('complete');
        assignment.save();
      }
    }
  });
});