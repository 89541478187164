define('sokol-today/routes/dashboard/students/edit/step-one', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		model: function model(params) {
			return this.store.findRecord('student', params.student_id);
		}
	});
});