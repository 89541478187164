define('sokol-today/models/diciplinary', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    catagory: _emberData.default.belongsTo('catagory'),
    student: _emberData.default.belongsTo('student', { async: true }),
    administered: _emberData.default.belongsTo('staff', { async: true }),
    dateAdministered: _emberData.default.attr('date'),
    dateStart: _emberData.default.attr('date'),
    dateEnd: _emberData.default.attr('date'),
    notes: _emberData.default.attr('string')
  });
});