define('sokol-today/models/demo/absence', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    absent: _emberData.default.attr('boolean', { defaultValue: false }),
    date: _emberData.default.attr('string'),
    school: _emberData.default.belongsTo('demo/school', { async: true }),
    student: _emberData.default.belongsTo('demo/student', { async: true }),
    suspension: _emberData.default.attr('boolean', { defaultValue: false }),
    tardy: _emberData.default.attr('boolean', { defaultValue: false }),
    dismissal: _emberData.default.attr('string'),
    teacher: _emberData.default.belongsTo('demo/teacher'),
    year: _emberData.default.belongsTo('demo/year', { async: true }),
    period: _emberData.default.belongsTo('demo/period', { async: true }),
    class: _emberData.default.belongsTo('demo/class', { async: true }),
    // timeAttended: Ember.computed('tardy', 'dismissal', 'date', function(){
    // 	var tardy=this.get('tardy');
    // 	var dismissal=this.get('dismissal');
    // 	var date=this.get('date');

    // 	if (tardy){
    // 		var timeAtSchool = moment('3:00 pm').subtract(date).format('HH:mm:ss');
    // 		return timeAtSchool;
    // 	} else if (dismissal){
    // 		var timeAtSchool = moment(dismissal).subtract(date).format('HH:mm:ss');
    // 		return timeAtSchool;
    // 	}
    // }),
    notes: _emberData.default.attr('string')

  });
});