define('sokol-today/services/student-info', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Service.extend({
		connectedUser: Ember.inject.service(),
		store: Ember.inject.service(),

		getAbsences: Ember.computed('connectedUser.student', function () {
			var _this = this;
			var store = this.get('store');
			var student = this.get('connectedUser.student');
			var absences = store.query('absence', {
				orderBy: 'student',
				equalTo: student.get('id')
			}).then(function (absenceData) {
				// console.log(absenceData);
				console.log(absenceData.toArray().length);
				return absenceData.toArray();
			}).catch(function (error) {
				console.log(error);
			});

			return absences;
		}).property('connectedUser.currentYear', 'connectedUser.student')
	});
});