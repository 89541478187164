define('sokol-today/components/dashboard/observations/new-observation', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        store: Ember.inject.service(),
        firebaseApp: Ember.inject.service(),
        classNames: ['cd-timeline-block', 'tooltip'],

        ///from webserver

        // didInsertElement() {
        //     var menuHeight = $('#obs-demo1').height();
        //     var height = (menuHeight);
        //     console.log(height);
        //     $('.demo-obs').height(height);
        // },

        click: function click() {
            var firebaseRef = this.get('firebaseApp').database();
            var store = this.get('store');
            var model = this.get('model');
            var date = new Date();
            var obsRef = firebaseRef.push();
            var newObsRef = 'observation' + obsRef.key;
            var newObservation = store.createRecord('observation', {
                id: newObsRef,
                timestamp: date
            });
            newObservation.save().then(function (newObs) {
                var obsBlock = store.findRecord('observation-block', model.get('id'));

                obsBlock.then(function (result) {

                    result.get('observations').addObject(newObs);
                    result.save().then(function () {}, function (error) {
                        console.log('Error : ' + error);
                    });
                }, function (error) {
                    console.log('Error : ' + error);
                });
                // obsBlock.get('observations').addObject('newObs');
                console.log('save successful');
            }, function (error) {
                console.log(error);
            });
        },


        actions: {}
    });
});