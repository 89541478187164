define('sokol-today/components/dashboard/staff/edit/step-two', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        newUser: Ember.inject.service(),
        connectedUser: Ember.inject.service(),
        initial: true,
        didInsertElement: function didInsertElement() {
            var newUser = this.get('newUser');
            newUser.setProperties({ hasUser: true, hasRole: false });
        },
        transition: function transition(model) {
            var self = this;
            this.setProperties({ successMessage: 'Saved Permission Successfully' });
            setTimeout(function () {
                self.get('transitionToStepThree')(model);
            }, 1000);
        },


        actions: {
            roleChange: function roleChange(role) {
                var self = this;
                switch (role) {
                    case "supportStaff":
                        self.setProperties({ initial: false, supportStaff: true, teachingStaff: false, adminStaff: false });
                        self.setProperties({ custodialStaff: false, athleticCoach: false });
                        self.setProperties({ substitute: false, assistant: false, teacher: false });
                        break;
                    case "teachingStaff":
                        self.setProperties({ initial: false, supportStaff: false, teachingStaff: true, adminStaff: false });
                        self.setProperties({ custodialStaff: false, athleticCoach: false });
                        self.setProperties({ substitute: false, assistant: false, teacher: false });
                        break;
                    case "adminStaff":
                        self.setProperties({ initial: false, supportStaff: false, teachingStaff: false, adminStaff: true });
                        self.setProperties({ custodialStaff: false, athleticCoach: false });
                        self.setProperties({ substitute: false, assistant: false, teacher: false });
                        break;
                    default:
                        self.setProperties({ initial: false, supportStaff: false, teachingStaff: false, adminStaff: false });
                        break;
                }
            },
            subRole: function subRole(_subRole) {
                var self = this;
                switch (_subRole) {
                    case "custodialStaff":
                        self.setProperties({ custodialStaff: true, athleticCoach: false, assistant: false });
                        break;
                    case "assistant":
                        self.setProperties({ custodialStaff: false, athleticCoach: false, assistant: true });
                        break;
                    case "athleticCoach":
                        self.setProperties({ custodialStaff: false, athleticCoach: true, assistant: false });
                        break;
                    case "substitute":
                        self.setProperties({ substitute: true, teacher: false });
                        break;
                    case "teacher":
                        self.setProperties({ substitute: false, teacher: true });
                        break;
                    case "frontOffice":
                        self.setProperties({ frontOffice: true, admin: false });
                        break;
                    case "admin":
                        self.setProperties({ frontOffice: false, admin: true });
                        break;
                    default:
                        self.setProperties({ custodialStaff: false, athleticCoach: false });
                        self.setProperties({ substitute: false, assistant: false, teacher: false });
                        break;
                }
            },
            saveStaffRole: function saveStaffRole(staff, supportStaff, custodialStaff, athleticCoach, teachingStaff, substitute, assistant, teacher, adminStaff, frontOffice, admin) {
                var self = this;
                if (supportStaff) {
                    if (custodialStaff) {
                        staff.set('role', 70);
                        staff.save().then(function (savedStaff) {
                            self.transition(savedStaff);
                        });
                    } else if (assistant) {
                        staff.set('role', 71);
                        staff.save().then(function (savedStaff) {
                            self.transition(savedStaff);
                        });
                    } else if (athleticCoach) {
                        staff.set('role', 72);
                        staff.save().then(function (savedStaff) {
                            self.transition(savedStaff);
                        });
                    }
                    // End Support Staff
                } else if (teachingStaff) {
                    if (substitute) {
                        staff.set('role', 74);
                        staff.save().then(function (savedStaff) {
                            self.transition(savedStaff);
                        });
                    } else if (teacher) {
                        staff.set('role', 87);
                        staff.save().then(function (savedStaff) {
                            self.transition(savedStaff);
                        });
                    }
                    // End Teaching Staff
                } else if (adminStaff) {
                    if (frontOffice) {
                        staff.set('role', 90);
                        staff.save().then(function (savedStaff) {
                            self.transition(savedStaff);
                        });
                    } else if (admin) {
                        staff.set('role', 92);
                        staff.save().then(function (savedStaff) {
                            self.transition(savedStaff);
                        });
                    }
                }
                // End Admin Staff
            }
        }
    });
});