define('sokol-today/components/dashboard/observations/observation-icon', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['draggableItem', 'cd-timeline-img', 'cd-notes'],
        attributeBindings: ['draggable'],
        draggable: 'true',

        dragStart: function dragStart(event) {},
        dragEnd: function dragEnd(event) {
            var data = this.get('data');
            data.destroyRecord();
        },
        click: function click(data) {
            this.toggleProperty('showRubricCodes');
            // var data = this.get('data');
            // console.log(data.get('observation'));
            // if (data.get('observation') == false) {
            //     data.set('observation', true);
            //     data.save();
            //     return
            // } else {
            //     data.set('observation', false);
            //     data.save();
            //     return
            // }
        }
    });
});