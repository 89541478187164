define('sokol-today/components/cards/card-course', ['exports'], function (exports) {
		'use strict';

		Object.defineProperty(exports, "__esModule", {
				value: true
		});
		exports.default = Ember.Component.extend({
				connectedUser: Ember.inject.service(),
				router: Ember.inject.service(),
				store: Ember.inject.service(),
				cardFlip: false,

				classDescriptionBlurb: Ember.computed('course.courseDesc', function () {
						var string = this.get('course.courseDesc');
						if (!string) {
								return '<red>No Course Description</red>';
						}
						if (string.length < 240) {
								return string;
						}

						var i = 0;
						do {
								if (string.substring(0, 240 + i + 1).endsWith(' ')) {
										return string.substring(0, 240 + i) + '...';
								}
								i++;
						} while (!string.substring(0, 240 + i).endsWith(' '));
				}),

				// courseYears: Ember.computed('course.years', function(){
				// 	return this.get('course.years');
				// }),

				click: function click() {
						this.toggleProperty('cardFlip');
				},


				actions: {
						getStaffInfo: function getStaffInfo(staff) {
								console.log(staff);
								var router = this.get('router');
								router.transitionTo('dashboard.staff.info');
						},
						showCourseDesc: function showCourseDesc() {
								this.toggleProperty('cardFlip');
								this.toggleProperty('showCourseDesc');
						},
						toggleActivity: function toggleActivity(year, active, model) {
								this.toggleProperty('cardFlip');
								var confirmed = year.get('coursesConfirmed');
								if (confirmed) {
										alert('Courses have already been confirmed and changes to courses is forbidden');
										return;
								}

								var firstDay = moment(year.get('firstDay'));
								var today = moment();

								if (today > firstDay) {
										alert('School has started and changes to courses is forbidden');
										return;
								}
								console.log(year.get('firstDay'));
								if (active) {
										model.get('years').popObject(year);
										year.get('courses').popObject(model);
										model.set('status', 'disabled');
										model.save().then(function (savedModel) {
												year.save();
												// model.reload().then(function(){
												// 	console.log('turn disabled');
												// });
										});
								} else {
										model.get('years').addObject(year);
										year.get('courses').addObject(model);
										model.set('status', 'active');
										model.save().then(function (savedModel) {
												year.save();
												// model.reload().then(function(){
												// 	console.log('turn active');
												// });
										});
								}
						}
				}

		});
});