define('sokol-today/components/helper/image-component', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		tagName: 'image-component',
		store: Ember.inject.service(),
		// classNames:'rounded margin-v-10',
		classNameBindings: ['mouseOver'],
		mouseOver: false,
		mouseEnter: function mouseEnter() {
			this.set('mouseOver', true);
		},
		mouseLeave: function mouseLeave() {
			this.set('mouseOver', false);
		},


		liked: Ember.computed('photo', 'user', function () {
			var photo = this.get('photo');
			var user = this.get('user.id');
			var bool = false;
			var favorites = photo.get('favorites').toArray();
			favorites.forEach(function (object) {
				if (object.id == user) {
					bool = true;
				}
			});
			return bool;
		}),

		saved: Ember.computed('photo', 'user', function () {
			var photo = this.get('photo');
			var user = this.get('user.id');
			var bool = false;
			var saves = photo.get('saves').toArray();
			saves.forEach(function (object) {
				if (object.id == user) {
					console.log('found saved photo');
					bool = true;
				}
			});
			return bool;
		}),

		addLikedPhoto: function addLikedPhoto(user, photo, student) {
			var store = this.get('store');
			var userModel = store.peekRecord('user', user.get('id'));
			var userPhotoLibrary = userModel.get('photoLibrary');
			return userPhotoLibrary.then(function (userPhotoLibraryRef) {
				userPhotoLibraryRef.get('favorites').addObject(photo);
				photo.get('favorites').addObject(userModel);
				//add any liked photos to student profile if student info present
				if (student) {
					console.log(student);
					student.get('photos').addObject(photo);
					student.save().then(function () {}).catch(function (error) {
						console.log('student info present but error saving photo to student model:');
						console.log(error);
						return { error: error };
					});
				}
				return photo.save().then(function (savedPhoto) {
					console.log('successful in saving photo with user filed under the favorites');
					return userPhotoLibraryRef.save().then(function (savedPhotoLibraryModel) {
						console.log('successful in saving photoLibrary to firebase');
						console.log('successful in saving user with photolibrary reference');
						return { message: 'successfully saved liked photo' };
					}).catch(function (error) {
						console.log('error saving photoLibrary');
						console.log(error);
					});
				}).catch(function (error) {
					console.log('error saving photo');
					console.log(error);
					photo.rollbackAttributes();
					// savedPhotoLibraryModel.destroyRecord();
				});
			}).catch(function (error) {
				console.log('error finding user');
				console.log(error);
			});
		},
		removeLikedPhoto: function removeLikedPhoto(user, photo, student) {
			var store = this.get('store');
			var userModel = store.peekRecord('user', user.get('id'));
			var userPhotoLibrary = userModel.get('photoLibrary');
			return userPhotoLibrary.then(function (userPhotoLibraryRef) {
				console.log(userModel);
				userPhotoLibraryRef.get('favorites').removeObject(photo);
				photo.get('favorites').removeObject(userModel);
				if (student) {
					student.get('photos').removeObject(photo);
					student.save().then(function () {}).catch(function (error) {
						console.log('student info present but error removing photo from student model:');
						console.log(error);
						return { error: error };
					});
				}
				return photo.save().then(function () {
					console.log('photo had favorited user removed');
					return userPhotoLibraryRef.save().then(function () {
						console.log('user removed photo from favorited list');
						return { message: 'successfully removed' };
					}).catch(function (error) {
						console.log('error saving userPhotoLibraryRef');
						console.log(error);
					});
				}).catch(function (error) {
					console.log('error saving photo');
					console.log(error);
				});
			}).catch(function (error) {
				console.log('error finding user Library');
				console.log(error);
			});
		},

		actions: {
			//user is passed down into component from parent component/view
			like: function like(photo, user, userPhotoLibrary, liked) {
				var _this = this;
				var store = _this.get('store');
				var userModel = store.peekRecord('user', user.get('id'));
				// let user = this.get('user');
				// let requestPhotoLibrary = userModel.get('photoLibrary');
				// requestPhotoLibrary
				userPhotoLibrary.then(function (photoLibrary) {
					// if photo already liked, remove from user photo library and remove from photo stats
					if (liked) {
						// remove photo from favorites library
						_this.removeLikedPhoto(user, photo).then(function (result) {
							console.log(result);
							_this.notifyPropertyChange('liked');
						}).catch(function (error) {
							console.log(error);
						});
						return;
					} else {
						// else check if photo library exists 
						//If yes add  user to photo likes and photo to user library favorites						
						if (photoLibrary !== undefined && photoLibrary !== null) {
							console.log('found photoLibrary');
							if (userModel) {
								_this.addLikedPhoto(userModel, photo).then(function (result) {
									console.log(result);
									_this.notifyPropertyChange('liked');
								}).catch(function (error) {
									console.log(error);
								});
							} else {
								console.log('no user');
							}
							// if it doesn't exist create library, add photo to library favorites and add user to photo favorites
						} else {
							//Must be first time interacting with photos, so create model and relationship						
							console.log('must not exist');
							var photoLibraryModel = store.createRecord('photo-library');
							if (userModel) {
								console.log('user model:');
								console.log(userModel);
								// add new photoLibrary id to create relation to user model
								userModel.set('photoLibrary', photoLibraryModel);
								userModel.save().then(function (savedUserModel) {
									_this.addLikedPhoto(savedUserModel, photo).then(function (result) {
										console.log(result);
										_this.notifyPropertyChange('liked');
									}).catch(function (error) {
										console.log(error);
									});
								}).catch(function (error) {
									console.log('error saving user model with new library id:');
									console.log(error);
								});
							} else {
								console.log('no user');
							}
						}
					}
				}).catch(function (error) {
					console.log(error);
				});
			},
			downloadPhoto: function downloadPhoto(filePath) {
				$.get({
					url: "https://us-central1-scope-95d64.cloudfunctions.net/photoDownloadLink?filePath=" + "asdfasdfsadf",
					objectMode: true
				}).done(function (data) {
					console.log(data);
				}).fail(function (error) {
					console.log(error);
				});
			}
		}
	});
});