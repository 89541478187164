define('sokol-today/helpers/index-correct', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.indexCorrect = indexCorrect;
    function indexCorrect(params) {
        var trueIndex = parseFloat(params) + 1;
        return trueIndex;
    }

    exports.default = Ember.Helper.helper(indexCorrect);
});