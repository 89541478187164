define('sokol-today/components/d3/bar-chart-stacked', ['exports', 'd3-collection', 'd3-selection', 'd3-transition', 'd3-ease', 'd3-scale', 'd3-array', 'd3-axis'], function (exports, _d3Collection, _d3Selection, _d3Transition, _d3Ease, _d3Scale, _d3Array, _d3Axis) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var run = Ember.run,
      get = Ember.get;
  exports.default = Ember.Component.extend({
    tagName: 'svg',
    classNames: ['awesome-d3-widget'],
    data: [],
    width: 720,
    height: 300,

    didReceiveAttrs: function didReceiveAttrs() {
      // Schedule a call to our `drawCircles` method on Ember's "render" queue, which will
      // happen after the component has been placed in the DOM, and subsequently
      // each time data is changed.
      run.scheduleOnce('render', this, this.drawGraph);
    },
    drawGraph: function drawGraph() {
      var _this = this;
      var svg = (0, _d3Selection.select)(this.element);
      svg.attr('width', get(this, 'width')).attr('height', get(this, 'height'));

      var margin = { top: 20, right: 20, bottom: 30, left: 40 },
          width = +svg.attr('width') - margin.left - margin.right,
          height = +svg.attr('height') - margin.top - margin.bottom,
          data = get(this, 'data');
      var x = (0, _d3Scale.scaleBand)().range([0, width]).padding(0.1),
          y = (0, _d3Scale.scaleLinear)().range([height, 0]);

      var g = svg.append("g").attr("transform", "translate(" + margin.left + "," + margin.top + ")");

      var div = (0, _d3Selection.select)('body').append("div").attr("class", "d3-tooltip").style("opacity", 0);

      // let data = theData.get('content');    
      if (!data) {
        return;
      }
      data.then(function (myData) {
        if (myData.length < 1) {
          console.log('no data');
          svg.selectAll('g', 'text').remove();
          svg.append('text').text('No Data Available').attr('x', width / 4).attr('y', height / 2).attr('font-size', 25);
          return;
        }

        var keys = myData.columns.slice(1);

        console.log(myData);
        svg.selectAll('text').remove();
        x.domain(myData.map(function (d) {
          return d.key;
        }));
        y.domain([0, (0, _d3Array.max)(myData, function (d) {
          return d.value;
        })]);

        g.append("g").attr("class", "axis axis--x").attr("transform", "translate(0," + height + ")").call((0, _d3Axis.axisBottom)(x));

        g.append("g").attr("class", "axis axis--y").call((0, _d3Axis.axisLeft)(y).ticks(10)).append("text").attr("transform", "rotate(-90)").attr("y", 6).attr("dy", "0.71em").attr("text-anchor", "end").text("Absences");

        g.selectAll(".d3-bar").data(stack().keys(keys)(myData)).enter().append("g").attr("fill", function (d) {
          return z(d.key);
        }).enter().append("rect").attr("class", "d3-bar").attr("x", function (d) {
          return x(d.key);
        }).attr("y", function (d) {
          return y(d.value);
        }).attr("width", x.bandwidth()).attr("height", function (d) {
          return height - y(d.value);
        });

        g.selectAll('.d3-bar2').data(myData).enter().append('text').attr('class', 'd3-tool').attr('font-size', 20).style("text-anchor", "middle").attr("x", function (d) {
          console.log((0, _d3Selection.select)(this).node().offsetWidth);return x(d.key) + x.bandwidth() / 2;
        }).attr("y", function (d) {
          return y(d.value) + 25;
        }).append('tspan').html(function (d) {
          return d.value;
        });
      });
    }
  });
});