define('sokol-today/router', ['exports', 'sokol-today/config/environment'], function (exports, _environment) {
        'use strict';

        Object.defineProperty(exports, "__esModule", {
                value: true
        });


        var Router = Ember.Router.extend({
                location: _environment.default.locationType,
                rootURL: _environment.default.rootURL
        });

        Router.map(function () {
                this.route('website', { path: '//' }, function () {
                        this.route('home', { path: '/home' });
                        this.route('story', { path: '/story' });
                        this.route('pricing', function () {
                                this.route('teachers');
                                this.route('administrators');
                        });
                        this.route('beta');
                        this.route('subscribe', { path: ':random_info' });
                        this.route('qa', { path: 'Q&A' });
                });
                this.route('account', function () {
                        this.route('sign-in');
                        this.route('profile', { path: 'profile/:user_id' });
                        this.route('user-create', function () {
                                this.route('step-one', { path: '/1' });
                                this.route('step-two', { path: '/2' });
                                this.route('step-three', { path: '/3' });
                                this.route('step-four', function () {
                                        this.route('personal', { path: '/personal/4' });
                                });
                        });
                        this.route('user-create-with-pin', function () {
                                this.route('step-one', { path: '/1' });
                                this.route('step-two', { path: '/2' });
                                this.route('step-three', { path: '/3' });
                                this.route('step-four', { path: '/:staff_id/4' });
                        });
                });
                this.route('dashboard', function () {
                        this.route('classes', function () {
                                this.route('add', function () {
                                        this.route('step-one', { path: '/1' });
                                        this.route('step-two', { path: '/:class_id/2' });
                                });
                                this.route('edit', { path: 'edit/:class_id' });
                                this.route('classroom', { path: 'classroom/:class_id' }, function () {
                                        this.route('home');
                                        this.route('report-cards', { path: '/report-cards' });
                                        this.route('photos', { path: '/photos' }, function () {
                                                this.route('add-photo');
                                        });
                                });
                                this.route('copy', function () {
                                        this.route('batch', { path: 'batchCopy/:classlist' });
                                });
                        });
                        this.route('observations', function () {
                                this.route('edit', { path: 'edit/:observationBlock_id' });
                                this.route('new');
                                this.route('observation', { path: '/:obs_block_id' }, function () {
                                        this.route('edit');
                                });
                        });
                        this.route('loading');
                        this.route('students', function () {
                                this.route('add', function () {
                                        this.route('step-one', { path: '/1' });
                                        this.route('step-two', { path: '/:student_id/2' });
                                });
                                this.route('edit', function () {
                                        this.route('step-one', { path: ':student_id/1' });
                                        this.route('step-two', { path: ':student_id/2' }, function () {
                                                this.route('edit-guardian', { path: '/:guardian_id' });
                                        });
                                });
                                this.route('student', { path: ':student_id' }, function () {
                                        this.route('add', function () {
                                                this.route('classes');
                                        });
                                });
                        });
                        this.route('staff', function () {
                                this.route('edit', function () {
                                        this.route('step-one', { path: '/:staff_id/1' });
                                        this.route('step-two', { path: '/:staff_id/2' });
                                        this.route('step-three', { path: '/:staff_id/3' });
                                });
                                this.route('add', function () {
                                        this.route('step-one', { path: '/1' });
                                        this.route('step-two', { path: '/:staff_id/2' });
                                        this.route('step-three', { path: '/:staff_id/1' });
                                });
                                this.route('staff', { path: '/:staff_id' });
                        });
                        this.route('setup', function () {
                                this.route('step-one');
                        });
                        this.route('assignments', function () {
                                this.route('add', function () {
                                        this.route('step-one');
                                });
                                this.route('edit', function () {
                                        this.route('step-one', { path: '/:assign_id' });
                                });
                        });
                        this.route('rubrics');
                        this.route('relationships', function () {
                                this.route('add', function () {
                                        this.route('step-one', { path: '1/:relation_id' });
                                        this.route('step-two', { path: '2/:relation2_id' });
                                });
                                this.route('edit', { path: '/:relationship_id/edit' });
                                this.route('relationship', { path: '/:relation_id' });
                        });
                        this.route('years', function () {
                                this.route('add', function () {
                                        this.route('step-one');
                                        this.route('step-two');
                                });
                                this.route('year', { path: '/:year_id' });
                                this.route('edit', function () {
                                        this.route('step-one', { path: 'edit/:year_id' });
                                });
                        });
                        this.route('cloud-files', function () {});
                        this.route('courses', function () {
                                this.route('course');
                                this.route('add', { path: 'add/' }, function () {
                                        this.route('step-one', { path: '1' });
                                        this.route('step-two', { path: '2' });
                                        this.route('step-three', { path: '3' });
                                        this.route('step-four');
                                });
                        });
                        this.route('settings', function () {
                                this.route('user', { path: 'user/:staff_id' });
                                this.route('school', { path: 'school/:school_id' });
                                this.route('billing', { path: 'billing/:staff_id' }, function () {
                                        this.route('subscriptions', function () {
                                                this.route('subscription', { path: 'subscription/:sub_id' }, function () {
                                                        this.route('cancel');
                                                        this.route('reactivate');

                                                        this.route('add', function () {
                                                                this.route('staff');
                                                        });
                                                });
                                                this.route('add-subscription', function () {
                                                        this.route('step-one');
                                                        this.route('step-two');
                                                        this.route('step-three');
                                                });
                                        });
                                        this.route('usage');
                                        this.route('invoices', { path: 'invoices/:cus_id' });
                                });
                        });
                        this.route('statistics', { path: 'statistics/:staff_id' });
                });
                this.route('BETA');
                this.route('test-route');
                this.route('loading');
                this.route('billing', { path: 'billing/:user_id' }, function () {
                        this.route('subscriptions', function () {
                                this.route('subscription', { path: 'subscription/:sub_id' });
                        });
                });
        });

        exports.default = Router;
});