define('sokol-today/components/dashboard/classes/add/step-two', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        newUser: Ember.inject.service(),
        router: Ember.inject.service('-routing'),
        store: Ember.inject.service(),
        connectedUser: Ember.inject.service(),

        didInsertElement: function didInsertElement() {
            var newUser = this.get('newUser');
            newUser.setProperties({ hasClass: true });
        },


        actions: {
            addStudents: function addStudents(students) {
                var store = this.get('store');
                var theClass = this.get('model');
                var length = students.get('length');
                var router = this.get('router');

                if (!length) {
                    router.transitionTo('dashboard.classes');
                } else {
                    theClass.save().then(function (savedClass) {
                        for (var i = length - 1; i >= 0; i--) {

                            var me = students.objectAt(i);
                            me.get('classes').addObject(savedClass);
                            savedClass.get('year').then(function (foundYear) {
                                me.get('years').addObject(foundYear);
                                me.save().then(function () {
                                    router.transitionTo('dashboard.classes');
                                }, function (error) {
                                    console.log('Error : ' + error);
                                });
                            }, function (error) {
                                console.log('Error : ' + error);
                            });
                        }
                    }, function (error) {
                        console.log('Error : ' + error);
                    });
                }
            }
        }
    });
});