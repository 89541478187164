define('sokol-today/components/helper/card-student', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		cardFlip: false,
		connectedUser: Ember.inject.service(),
		router: Ember.inject.service('-routing'),

		click: function click() {
			this.toggleProperty('cardFlip');
		},


		actions: {
			getClass: function getClass(theClass) {
				var classInfo = this.get('connectedUser');
				var router = this.get('router');
				classInfo.changeClass(theClass);
				this.set('selectedClass', theClass);
				// classInfo.setProperties({currentYear:theClass.year, currentPeriod: theClass.period, currentClass:theClass });
				router.transitionTo('dashboard.classes');
			}
		}
	});
});