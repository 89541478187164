define('sokol-today/components/account/user/user-create-with-pin/step-one', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        store: Ember.inject.service(),
        connectedUser: Ember.inject.service(),
        firebaseApp: Ember.inject.service(),
        newUser: Ember.inject.service(),
        router: Ember.inject.service('-routing'),
        session: Ember.inject.service(),

        didInsertElement: function didInsertElement() {
            var newUser = this.get('newUser');
            newUser.setProperties({ hasUser: false, haveAccountType: false, havePin: false, haveStaffConfirm: false });
        },


        notFilledOut: Ember.computed('email', 'password', 'passwordConfirm', function () {
            var email = this.get('email');
            var passphrase = this.get('password');
            var confirmed = this.get('passwordConfirm');

            if (email && passphrase && confirmed && passphrase === confirmed) {
                return false;
            } else {
                return true;
            }
        }),

        match: Ember.computed('password', 'passwordConfirm', function () {
            var passphrase = this.get('password');
            var confirmed = this.get('passwordConfirm');

            if (passphrase === confirmed || typeof confirmed === 'undefined' || confirmed === null || String(confirmed).length < 9) {
                return true;
            } else {
                return false;
            }
        }),

        actions: {
            saveInfo: function saveInfo(emailCreate, passwordCreate, passwordCreateConfirm, firstName, lastName) {
                var torii = this.get('session');
                var connectedUser = this.get('connectedUser');
                var self = this;
                var newUser = this.get('newUser');
                var firebaseRef = this.get('firebaseApp').database();
                if (typeof passwordCreate === 'undefined' || typeof passwordCreateConfirm === 'undefined') {
                    self.setProperties({ passwordError: 'must have a password' });
                    return;
                }
                if (passwordCreate !== passwordCreateConfirm) {
                    self.setProperties({ passwordError: 'passwords do not match' });
                    return;
                }
                if (typeof emailCreate === 'undefined') {
                    self.setProperties({ emailError: 'most provide an email' });
                    return;
                }
                var text = "";
                var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

                for (var i = 0; i < 10; i++) {
                    text += possible.charAt(Math.floor(Math.random() * possible.length));
                }

                var auth = this.get('firebaseApp').auth();
                auth.createUserWithEmailAndPassword(emailCreate, passwordCreate).then(function (d) {
                    var store = self.get('store');
                    //create user
                    var newUser = store.createRecord('user', {
                        id: d.uid,
                        email: emailCreate,
                        pin: text,
                        firstName: firstName,
                        lastName: lastName,
                        betaTester: true,
                        isNew: 'account.user-create-with-pin.step-two'
                    });
                    //create main storage folder
                    var folderRef = firebaseRef.push();
                    var newFolderRef = 'folder' + folderRef.key;
                    var userStorage = store.createRecord('folder', {
                        id: newFolderRef,
                        user: newUser,
                        folderName: 'Personal Files'
                    });
                    //create assignment folder
                    var assignmentRef = firebaseRef.push();
                    var newAssignmentRef = 'assignment_folder' + assignmentRef.key;
                    var userAssignments = store.createRecord('folder', {
                        id: newAssignmentRef,
                        user: newUser,
                        folderName: 'Assignments'
                    });
                    //add assignment to main folder tree
                    userStorage.get('folders').addObject(userAssignments);
                    //add storage location to user
                    userAssignments.save().then(function () {
                        userStorage.save().then(function (savedStorage) {
                            newUser.set('cloudStorage', savedStorage);
                            newUser.save().then(function () {
                                torii.open('firebase', {
                                    provider: 'password',
                                    email: emailCreate,
                                    password: passwordCreate
                                }).then(function (d) {
                                    var store = self.get('store');
                                    var user = store.findRecord('user', d.uid);
                                    newUser.setProperties({ hasUser: true });
                                    self.get('router').transitionTo('account.user-create.step-two');
                                    return user;
                                }, function (error) {
                                    self.setProperties({ errorMessage: error.message, login: true, displayChoice: false });
                                });
                            }, function (error) {
                                self.setProperties({ errorMessageCreate: error.message });
                            });
                        }, function (error) {
                            self.setProperties({ errorMessageCreate: error.message });
                            console.log('Error : ' + error);
                        });
                    }, function (error) {
                        self.setProperties({ errorMessageCreate: error.message });
                        console.log('Error : ' + error);
                    });
                }, function (error) {
                    self.setProperties({ errorMessageCreate: error.message });
                });
            },
            logout: function logout() {
                var router = this.get('router');
                var store = this.get('store');
                var session = this.get('session');
                var user = store.peekRecord('user', session.content.uid);
                session.close();
                store.unloadRecord(user);
                this.set('connectedUser.user', false);
            }
        }
    });
});