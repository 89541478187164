define('sokol-today/components/dashboard/classes/classroom/attendance-list', ['exports'], function (exports) {
		'use strict';

		Object.defineProperty(exports, "__esModule", {
				value: true
		});
		exports.default = Ember.Component.extend({
				store: Ember.inject.service(),
				connectedUser: Ember.inject.service(),
				absenceInfo: null,

				didInsertElement: function didInsertElement() {
						var student = this.get('student');
						this.getStudentAbsences(student);
				},


				watchAttendanceDate: Ember.computed('attendanceDate', function () {
						var student = this.get('student');
						this.getStudentAbsences(student);
				}).property('attendanceDate'),

				getStudentAbsences: function (student) {
						var student = this.get('student.id');
						var store = this.get('store');
						var that = this;
						var studentAbsences = store.query('absence', {
								orderBy: 'student',
								equalTo: student
						});
						var date = that.get('attendanceDate');
						var beginDate = moment(date).startOf('day');
						var endDate = moment(date).endOf('day');
						var presentClass = this.get('class.id');

						studentAbsences.then(function (absenceList) {
								that.setProperties({ isAbsent: false });
								var length = absenceList.get('length');

								for (var i = length - 1; i >= 0; i--) {
										if (that.get('isAbsent')) {
												return;
										}

										var absenceDate = absenceList.objectAt(i).get('date');

										if (moment(absenceDate).isBetween(beginDate, endDate) && absenceList.objectAt(i).get('class.id') == presentClass) {
												that.setProperties({ isAbsent: true, absenceInfo: absenceList.objectAt(i) });
										} else {
												that.setProperties({ isAbsent: false, absenceInfo: null });
										}
								}
						});
				}.observes('attendanceDate'),

				actions: {
						addPresent: function addPresent(student, absentInfo, staff) {
								var store = this.get('store');
								var that = this;
								var classInfo = this.get('class');
								var currentDate = moment(that.get('attendanceDate')).format();
								//get school and find absence list to add new absence
								var school = store.findRecord('school', classInfo.get('school.id'));
								if (absentInfo !== null) {
										school.then(function (theSchool) {
												store.findRecord('absence-list', theSchool.get('absenceList.id')).then(function (list) {
														list.get('absences').removeObject(absentInfo);
														list.save();
												});
										});
										store.findRecord('absence', absentInfo.id).then(function (theAbsence) {
												theAbsence.destroyRecord();
										});
										that.setProperties({ isAbsent: false, absenceInfo: null });
								} else {
										school.then(function (theSchool) {
												var absentRecord = store.createRecord('absence', {
														present: 'present',
														type: 'present',
														date: currentDate,
														student: student,
														school: theSchool,
														staff: staff,
														year: classInfo.get('year'),
														period: classInfo.get('period'),
														class: classInfo,
														byYear: classInfo.get('year.year'),
														byClass: classInfo.get('id')
												});
												absentRecord.save().then(function (savedRecord) {
														var absenceListId = theSchool.get('absenceList.id');

														var getAbsenceList = store.findRecord('absence-list', absenceListId);

														getAbsenceList.then(function (theList) {
																theList.get('absences').addObject(savedRecord);
																student.get('absences').addObject(savedRecord);
																theList.save();
																student.save().then(function (savedStudent) {
																		that.getStudentAbsences(savedStudent);
																});
														}, function (error) {
																console.log('Error : ' + error);
														});
												}, function (error) {
														console.log('Error : ' + error);
												});
										}, function (error) {
												console.log('Error : ' + error);
										});
								}
						},
						addAbsent: function addAbsent(student, absentInfo, staff) {
								var store = this.get('store');
								var that = this;
								var classInfo = this.get('class');
								var currentDate = moment(that.get('attendanceDate')).format();
								console.log(currentDate);
								// get school and find absence list to add new absence
								var school = store.findRecord('school', classInfo.get('school.id'));
								if (absentInfo !== null) {
										school.then(function (theSchool) {
												store.findRecord('absence-list', theSchool.get('absenceList.id')).then(function (list) {
														list.get('absences').removeObject(absentInfo);
														list.save();
												});
										});
										store.findRecord('absence', absentInfo.id).then(function (theAbsence) {
												theAbsence.destroyRecord();
										});
										that.setProperties({ isAbsent: false, absenceInfo: null });
								} else {
										school.then(function (theSchool) {
												var absentRecord = store.createRecord('absence', {
														absent: 'absent',
														type: 'absent',
														date: currentDate,
														student: student,
														school: theSchool,
														staff: staff,
														year: classInfo.get('year'),
														period: classInfo.get('period'),
														class: classInfo,
														byYear: classInfo.get('year.year'),
														byClass: classInfo.get('id')
												});
												absentRecord.save().then(function (savedRecord) {
														var getAbsenceList = store.findRecord('absence-list', theSchool.get('absenceList.id'));

														getAbsenceList.then(function (theList) {
																theList.get('absences').addObject(savedRecord);
																student.get('absences').addObject(savedRecord);
																theList.save();
																student.save().then(function (savedStudent) {
																		that.getStudentAbsences(savedStudent);
																});
														}, function (error) {
																console.log('Error : ' + error);
														});
												}, function (error) {
														console.log('Error : ' + error);
												});
										}, function (error) {
												console.log('Error : ' + error);
										});
								}
						},
						addTardy: function addTardy(student, absentInfo, staff) {
								var store = this.get('store');
								var classInfo = this.get('class');
								var that = this;
								var currentDate = moment(that.get('attendanceDate')).format();

								var school = store.findRecord('school', classInfo.get('school.id'));

								if (absentInfo !== null && !absentInfo.get('tardy')) {

										store.findRecord('absence', absentInfo.id).then(function (theAbsence) {
												theAbsence.setProperties({ absent: false, tardy: true, date: currentDate });
												theAbsence.save();
										});
								} else if (absentInfo !== null && absentInfo.get('tardy')) {
										school.then(function (theSchool) {
												store.findRecord('absence-list', theSchool.get('absenceList.id')).then(function (list) {
														list.get('absences').removeObject(absentInfo);
														list.save();
												});
										});
										store.findRecord('absence', absentInfo.id).then(function (theAbsence) {
												theAbsence.destroyRecord();
										});
										that.setProperties({ isAbsent: false, absenceInfo: null });
								} else {
										school.then(function (theSchool) {
												var currentDate = moment(that.get('attendanceDate')).format();
												var absentRecord = store.createRecord('absence', {
														tardy: 'tardy',
														type: 'tardy',
														date: currentDate,
														student: student,
														school: theSchool,
														staff: staff,
														year: classInfo.get('year'),
														period: classInfo.get('period'),
														class: classInfo,
														byYear: classInfo.get('year.year'),
														byClass: classInfo.get('id')
												});
												absentRecord.save().then(function (savedRecord) {
														var getAbsenceList = store.findRecord('absence-list', theSchool.get('absenceList.id'));

														getAbsenceList.then(function (theList) {
																theList.get('absences').addObject(savedRecord);
																student.get('absences').addObject(savedRecord);
																theList.save();
																student.save().then(function (savedStudent) {
																		that.getStudentAbsences(savedStudent);
																});
														}, function (error) {
																console.log('Error : ' + error);
														});
												}, function (error) {
														console.log('Error : ' + error);
												});
										}, function (error) {
												console.log('Error : ' + error);
										});
								}
						},
						addDismiss: function addDismiss(student, absentInfo, staff) {
								var store = this.get('store');
								var classInfo = this.get('class');
								var that = this;
								var now = moment();
								var currentDate = moment(that.get('attendanceDate')).format();
								var school = store.findRecord('school', classInfo.get('school.id'));

								if (absentInfo !== null && !absentInfo.get('dismissal')) {
										store.findRecord('absence', absentInfo.id).then(function (theAbsence) {
												theAbsence.setProperties({ absent: false, dismissal: now });
												theAbsence.save();
										});
								} else if (absentInfo !== null && absentInfo.get('dismissal')) {
										school.then(function (theSchool) {
												store.findRecord('absence-list', theSchool.get('absenceList.id')).then(function (list) {
														list.get('absences').removeObject(absentInfo);
														list.save();
												});
										});
										store.findRecord('absence', absentInfo.id).then(function (theAbsence) {
												theAbsence.destroyRecord();
										});
										that.setProperties({ isAbsent: false, absenceInfo: null });
								} else {
										school.then(function (theSchool) {
												var currentDate = moment(that.get('attendanceDate')).format();
												var absentRecord = store.createRecord('absence', {
														dismissal: now,
														type: 'dismissal',
														date: currentDate,
														student: student,
														school: theSchool,
														staff: staff,
														year: classInfo.get('year'),
														period: classInfo.get('period'),
														class: classInfo,
														byYear: classInfo.get('year.year'),
														byClass: classInfo.get('id')
												});
												absentRecord.save().then(function (savedRecord) {
														var getAbsenceList = store.findRecord('absence-list', theSchool.get('absenceList.id'));

														getAbsenceList.then(function (theList) {
																theList.get('absences').addObject(savedRecord);
																student.get('absences').addObject(savedRecord);
																theList.save();
																student.save().then(function (savedStudent) {
																		that.getStudentAbsences(savedStudent);
																});
														}, function (error) {
																console.log('Error : ' + error);
														});
												}, function (error) {
														console.log('Error : ' + error);
												});
										}, function (error) {
												console.log('Error : ' + error);
										});
								}
						}
				}
		});
});