define('sokol-today/models/demo/grade', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		grade: _emberData.default.attr('string'),
		//duration is to correspound to how the school hands out grades, quarterly, semester, yearly, by class etc
		duration: _emberData.default.attr('string'),
		yearReceived: _emberData.default.attr('date'),
		//class the student received the grade for
		class: _emberData.default.attr('string'),
		teacher: _emberData.default.attr('string'),
		student: _emberData.default.attr('string'),
		school: _emberData.default.attr('string'),
		district: _emberData.default.attr('string')
	});
});