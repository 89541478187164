define('sokol-today/components/helper/image-preview-picker', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        rawSize: '',
        image: false,

        newImage: function newImage(input) {
            // this.setProperties({image:'',imageLandscape:'',imagePortrait:''});
            var _URL = window.URL || window.webkitURL;
            var self = this;

            var fileToUpload = input.files[0];
            this.set('rawSize', fileToUpload.size);

            if (!input) {
                alert("Um, couldn't find the fileinput element.");
            } else if (!input.files) {
                alert("This browser doesn't seem to support the `files` property of file inputs.");
            } else if (!input.files[0]) {
                alert("Please select a file before clicking 'Load'");
            } else if (self.rawSize > 1000000) {
                alert("Please use a smaller picture (200px by 200px");
            } else {
                var file = input.files[0];
                var img = new Image();
                img.onload = function () {
                    if (this.width / this.height > 1.3) {
                        var fr = new FileReader();
                        fr.onload = function (e) {
                            console.log(e);
                            self.setProperties({ image: e.target.result, imageLandscape: true, imagePortrait: false });
                        };
                        fr.readAsDataURL(file);
                    } else if (this.height / this.width > 1.3) {
                        var fr = new FileReader();
                        fr.onload = function (e) {
                            console.log(e);
                            self.setProperties({ image: e.target.result, imagePortrait: true, imageLandscape: false });
                        };
                        fr.readAsDataURL(file);
                    } else {
                        var fr = new FileReader();
                        fr.onload = function (e) {
                            console.log(e);
                            self.setProperties({ image: e.target.result, imageLandscape: false, imagePortrait: false });
                        };
                        fr.readAsDataURL(file);
                    }
                };

                img.src = _URL.createObjectURL(file);
            }
        },


        click: function click(event) {
            Ember.$("input[type='file']").click();
        },

        didInsertElement: function didInsertElement() {
            var self = this;
            Ember.$("input[type='file']").click(function (event) {
                event.stopPropagation();
            });
            $("#profileImage").on('change', function (e) {
                console.log(e);
                self.newImage(e.target);
            });
        }
    });
});