define('sokol-today/components/dashboard/relationships/add/step-one', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    stepIndicator: Ember.inject.service(),
    connectedUser: Ember.inject.service(),

    didInsertElement: function didInsertElement() {
      this.get('stepIndicator').setProperties({ stepOne: false, stepTwo: false });
    },


    parentTime: '',

    relationOptions: Ember.computed('', function () {
      return this.get('store').findAll('relation-list');
    }),

    updated: Ember.computed('relationship.relation', 'relationship.student', function () {
      var _this = this;
      var json = this.get('relationship').toJSON();
      var updated = false;
      console.log(json);
      console.log('checking Dirty');
      if (json['relation'] && json['student']) {
        updated = true;
      }
      return updated;
    }),

    actions: {
      goStepTwo: function goStepTwo(studentRel, relation, parentTime, relationList) {
        if (!studentRel.get('id')) {
          this.set('errorMessage', 'Student must be selected');
          return;
        }
        if (!relation.get('relation')) {
          this.set('errorMessage', 'Relation type must be selected');
          return;
        }
        var _this = this;
        var router = this.get('router');
        var store = this.get('store');
        store.findRecord('student', studentRel.get('id')).then(function (student) {
          student.get('relationships').addObject(relation);
          console.log(parentTime);
          switch (parentTime) {
            case "full":
              relation.set('homeFull', true);
              break;
            case "part":
              relation.set('homePart', true);
              break;
            default:
              break;
          }
          student.save().then(function () {
            relation.save().then(function (savedRelation) {
              store.findRecord('relationship-list', relationList.get('id')).then(function (relationshipList) {
                relationshipList.get('relationships').addObject(savedRelation);
                relationshipList.save().then(function () {
                  router.transitionTo('dashboard.relationships.add.step-two', savedRelation);
                }).catch(function (error) {
                  console.log(error);
                });
              }).catch(function (error) {
                console.log(error);
              });
            }).catch(function (error) {
              console.log(error);
            });
          }).catch(function (error) {
            console.log(error);
          });
        }).catch(function (error) {
          _this.set('errorMessage', 'Student cannot be blank');
        });
      },
      cancel: function cancel(relationship, relationList_id, student_id) {
        var store = this.get('store');
        var router = this.get('router');
        if (relationship.get('dirtyType') == 'created') {
          relationship.deleteRecord();
          router.transitionTo('dashboard.relationships');
          return;
        }
        store.findRecord('relationship-list', relationList_id).then(function (relationshipList) {
          relationshipList.get('relationships').removeObject(relationship);
          relationshipList.save().then(function () {
            store.findRecord('student', student_id).then(function (theStudent) {
              theStudent.get('relationships').removeObject(relationship);
              theStudent.save().then(function () {
                relationship.destroyRecord().then(function () {
                  router.transitionTo('dashboard.relationships');
                }).catch(function (error) {
                  console.log('error destroying relationship');
                  console.log(error);
                });
              }).catch(function (error) {
                console.log('error saving student after deleting relationship');
                console.log(error);
              });
            }).catch(function (error) {
              console.log('error finding student');
              console.log(error);
            });
          }).catch(function (error) {
            console.log('error saving relationshiplist after removing relationship');
            console.log(error);
          });
        }).catch(function (error) {
          console.log('error finding relationship list');
          console.log(error);
        });
      }
    }
  });
});