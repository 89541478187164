define('sokol-today/controllers/dashboard/staff/staff', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		actions: {
			goToClass: function goToClass(classroom) {
				this.transitionToRoute('dashboard.classes.class', classroom);
			}
		}
	});
});