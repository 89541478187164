define('sokol-today/models/demo/teacher', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    firstName: _emberData.default.attr('string'),
    lastName: _emberData.default.attr('string'),
    birthday: _emberData.default.attr('date'),
    observed: _emberData.default.hasMany('demo/observation-block', { async: true }),
    started: _emberData.default.attr('date'),
    room: _emberData.default.belongsTo('demo/room', { async: true }),
    pin: _emberData.default.attr('string'),
    // students: DS.hasMany('student', {async:true}),
    classes: _emberData.default.hasMany('demo/class', { async: true }),
    school: _emberData.default.belongsTo('demo/school', { async: true }),
    profession: _emberData.default.belongsTo('demo/profession', { async: true }),
    avatar: _emberData.default.belongsTo('demo/avatar', { async: true }),
    myAssignments: _emberData.default.hasMany('demo/myAssignment'),
    user: _emberData.default.belongsTo('demo/user', { async: true })
  });
});