define('sokol-today/components/account/user/user-create-with-pin/progress-page', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		newUser: Ember.inject.service()

	});
});