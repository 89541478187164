define('sokol-today/components/dashboard/relationships/display-relationships', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),

    sortByCorrected: Ember.computed('sortBy', function () {
      var string = this.get('sortBy');
      switch (string) {
        case "guardian.lastName":
          return 'Name';
          break;
        case "guardian.homePhone":
          return 'Home Phone';
          break;
        case "guardian.emailPersonal":
          return "Email";
          break;
        default:
          return string;
          break;
      }
    }),
    sortedRelationships: Ember.computed.sort('connectedUser.school.relationshipList.relationships', 'sortDefinition'),
    sortBy: 'guardian.lastName',
    reverseSort: false,
    sortDefinition: Ember.computed('sortBy', 'reverseSort', function () {
      var sortOrder = this.get('reverseSort') ? 'desc' : 'asc';
      return [this.get('sortBy') + ':' + sortOrder];
    }),
    actions: {
      toggleSortMenu: function toggleSortMenu() {
        this.toggleProperty('sortMenu');
      },
      toggleFilterMenu: function toggleFilterMenu() {
        this.toggleProperty('showFilterMenu');
      }
    }
  });
});