define('sokol-today/components/dashboard/students/add/step-one', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        store: Ember.inject.service(),
        firebaseApp: Ember.inject.service(),
        newUser: Ember.inject.service(),
        session: Ember.inject.service(),
        raceCode: Ember.inject.service(),
        connectedUser: Ember.inject.service(),
        router: Ember.inject.service(),
        errorMessage: '',
        successMessage: '',
        studentRace: null,
        // classes: '',
        // isHispanic: false,
        raceArray: [],
        classOfArray: [],
        didInsertElement: function didInsertElement() {
            var newUser = this.get('newUser');
            newUser.set('haveStudent', false);
        },


        allergies: Ember.computed('', function () {
            return this.get('store').findAll('allergy');
        }),

        classOf: Ember.computed('', function () {
            var myArray = this.get('classOfArray');
            // let year = moment().format('YYYY');
            for (var i = 14 - 1; i >= 0; i--) {
                var nextYear = moment().add(i, 'year').format('YYYY');
                myArray.pushObject(nextYear);
            }
        }),

        classes: Ember.computed('connectedUser.currentClass', function () {
            return this.get('connectedUser.currentClass');
        }),

        getRaceCode: function getRaceCode(raceService, isHispanic, array) {
            var code = raceService.getCode(isHispanic, array);
            return code;
        },
        saveStudent: function saveStudent(first, last, theClass, allergies, bDay, sDay, classOf, SASID) {
            if (!first) {
                this.setProperties({ errorMessage: 'Please enter a first name' });
                return;
            } else if (!last) {
                this.setProperties({ errorMessage: 'Please enter a last name' });
                return;
            } else if (!sDay) {
                console.log(sDay);
                this.setProperties({ errorMessage: 'Please enter the day the student will start or first day of school' });
                return;
            } else {}
            var router = this.get('router');
            var store = this.get('store');
            var school = this.get('connectedUser.account.school');
            var that = this;
            var imageLandscape = this.get('imageLandscape');
            var imagePortrait = this.get('imagePortrait');
            var image = this.get('image');
            var race = this.get('studentRace');
            var year = this.get('connectedUser.currentYear');

            var text = "";
            var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_";

            for (var i = 0; i < 10; i++) {
                text += possible.charAt(Math.floor(Math.random() * possible.length));
            }

            // newStudent.get('classes').addObject(theClass);

            var ref = this.get('firebaseApp').database().ref('students/');
            var newRef = ref.push();
            var realRef = "student" + newRef.key;
            return school.then(function (schoolLink) {
                var newStudent = store.createRecord('student', {
                    id: realRef,
                    firstName: first.capitalize(),
                    lastName: last.capitalize(),
                    birthday: bDay,
                    started: sDay,
                    raceCode: race,
                    pin: text,
                    SASID: SASID,
                    classOf: classOf,
                    school: schoolLink
                });
                newStudent.get('years').addObject(year);
                var avatarRef = ref.push();
                var newAvatarRef = 'avatar' + avatarRef.key;
                if (imageLandscape) {
                    var avatar = store.createRecord('avatar', {
                        id: newAvatarRef,
                        imageLandscape: true,
                        image: image
                    });

                    avatar.save().then(function (newAvatar) {
                        newStudent.set('avatar', newAvatar);
                    });
                } else if (imagePortrait) {
                    var avatar = store.createRecord('avatar', {
                        id: newAvatarRef,
                        imagePortrait: true,
                        image: image
                    });

                    avatar.save().then(function (newAvatar) {
                        newStudent.set('avatar', newAvatar);
                    });
                } else if (image) {
                    var avatar = store.createRecord('avatar', {
                        id: newAvatarRef,
                        image: image
                    });

                    avatar.save().then(function (newAvatar) {
                        newStudent.set('avatar', newAvatar);
                    });
                } else {}
                if (theClass) {
                    for (var i = theClass.length - 1; i >= 0; i--) {

                        theClass[i].get('students').addObject(newStudent);
                        newStudent.get('classes').addObject(theClass[i]);
                        theClass[i].save().then(function () {}, function (error) {
                            console.log('Error : ' + error);
                        });
                        theClass[i].get('year').then(function (yearObject) {
                            newStudent.get('years').addObject(yearObject);
                        });
                    }
                }
                if (typeof allergies !== 'undefined') {
                    if (allergies.length > 1) {
                        newStudent.get('allergies').addObjects(allergies);
                    } else {
                        newStudent.get('allergies').addObjects(allergies);
                    }
                }

                that.setProperties({ successMessage: 'Saved Successful' });
                that.setProperties({ firstName: null, lastName: null, selectedStudent: null, birthDate: null, startDate: null });
                var studentList = store.findRecord('student-list', schoolLink.get('studentList.id'));
                return studentList.then(function (theList) {
                    theList.get('students').addObject(newStudent);
                    return theList.save().then(function () {
                        return newStudent.save().then(function (savedClass) {
                            // that.get('connectedUser').changeYear(null);
                            return savedClass;
                            // that.get('transitionToStepTwo')(savedClass);
                        }, function (error) {
                            console.log('Error : ' + error);
                        });
                    }, function (error) {
                        console.log('Error : ' + error);
                    });
                }, function (error) {
                    console.log('Error : ' + error);
                });
                // theClass.get('students').addObject(newStudent);
                // theClass.save();
            }, function (error) {
                console.log('Error : ' + error);
            });
        },


        actions: {
            saveStudentGoRelation: function saveStudentGoRelation(first, last, theClass, allergies, bDay, sDay, classOf, SASID) {
                var self = this;
                var connectedUser = this.get('connectedUser');
                var router = this.get('router');
                self.saveStudent(first, last, theClass, allergies, bDay, sDay, classOf, SASID).then(function (model) {
                    self.setProperties({ successMessage: "Student Info Saved", errorMessage: false });
                    connectedUser.changeYear();
                    setTimeout(function () {
                        self.get('transitionToStepTwo')(model);
                    }, 1000);
                }, function (error) {
                    self.set('errorMessage', error);
                });
            },
            save: function save(first, last, theClass, allergies, bDay, sDay, classOf, SASID) {
                var _this = this;
                console.log(bDay + ';;;;' + sDay);
                var connectedUser = this.get('connectedUser');
                var router = this.get('router');
                _this.saveStudent(first, last, theClass, allergies, bDay, sDay, classOf, SASID).then(function (model) {
                    _this.setProperties({ successMessage: "Student Info Saved", errorMessage: false });
                    connectedUser.changeYear(connectedUser.get('currentYear'));
                    setTimeout(function () {
                        router.transitionTo('dashboard.students');
                    }, 1000);
                }, function (error) {
                    _this.set('errorMessage', error);
                });
            },
            mut: function mut(val, date) {
                console.log(date);
                console.log(val);
                this.set(val, date);
            },
            toggleRace: function toggleRace(race) {
                var _this = this;
                var raceService = this.get('raceCode');
                var getRaceCode = this.get('getRaceCode');
                var array = this.get('raceArray');
                switch (race) {
                    case "isOne":
                        if (_this.get('isOne')) {
                            array.removeObject(1);
                            array.sort();
                        } else {
                            array.pushObject(1);
                            array.sort();
                        }
                        break;
                    case "isTwo":
                        if (_this.get('isTwo')) {
                            array.removeObject(2);
                            array.sort();
                        } else {
                            array.pushObject(2);
                            array.sort();
                        }
                        break;
                    case "isFour":
                        if (_this.get('isFour')) {
                            array.removeObject(4);
                            array.sort();
                        } else {
                            array.pushObject(4);
                            array.sort();
                        }
                        break;
                    case "isFive":
                        if (_this.get('isFive')) {
                            array.removeObject(5);
                            array.sort();
                        } else {
                            array.pushObject(5);
                            array.sort();
                        }
                        break;
                    case "isSix":
                        if (_this.get('isSix')) {
                            array.removeObject(6);
                            array.sort();
                        } else {
                            array.pushObject(6);
                            array.sort();
                        }
                        break;
                    default:
                        break;
                }

                this.toggleProperty(race);
                var isHispanic = this.get('isHispanic');
                this.setProperties({ studentRace: getRaceCode(raceService, isHispanic, array) });
            }
        }
    });
});