define('sokol-today/models/folder', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        user: _emberData.default.belongsTo('user', { async: true, inverse: null }),
        school: _emberData.default.belongsTo('school', { async: true, inverse: null }),
        files: _emberData.default.hasMany('file'),
        folders: _emberData.default.hasMany('folder', { async: true, inverse: null }),
        folderName: _emberData.default.attr('string'),
        assignmentInfo: _emberData.default.belongsTo('my-assignment', { async: true, inverse: 'folder' })
    });
});