define('sokol-today/components/dashboard/classes/classroom/user-teacher', ['exports'], function (exports) {
		'use strict';

		Object.defineProperty(exports, "__esModule", {
				value: true
		});
		exports.default = Ember.Component.extend({
				connectedUser: Ember.inject.service(),

				sortedStudents: Ember.computed.sort('class.students', 'sortDefinition'),

				content: Ember.computed('class.students', function () {
						var store = this.get('store');
						var students = this.get('class.students');
						if (!students) {
								console.log('no students');
								return;
						}
						return students.then(function (theStudents) {
								return theStudents;
						}).catch(function (error) {
								console.log(error);
						});
				}),

				sortBy: 'firstName',
				reverseSort: false,
				sortDefinition: Ember.computed('sortBy', 'reverseSort', function () {
						var sortOrder = this.get('reverseSort') ? 'desc' : 'asc';
						return [this.get('sortBy') + ':' + sortOrder];
				}),

				actions: {
						attendanceDateSelected: function attendanceDateSelected(attendanceDate) {
								var today = moment();
								var adjust = moment.duration(2, 'h');
								var adjustedToday = today.add(adjust);
								//add two hours onto old dates that start at 0hrs and compare
								if (moment(attendanceDate).isBefore(adjustedToday)) {
										var time = moment.duration(8, 'h');
										var correctedTime = moment(attendanceDate).add(time);
										this.setProperties({ attendanceDate: correctedTime });
								} else {
										this.setProperties({ attendanceDate: attendanceDate });
								}
						},
						toggleAssignmentAddDisplay: function toggleAssignmentAddDisplay() {
								this.toggleProperty('addAssignment');
						}
				}
		});
});