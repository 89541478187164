define('sokol-today/components/dashboard/settings/billing/subscriptions/add-subscription', ['exports', 'accounting/format-money'], function (exports, _formatMoney) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		store: Ember.inject.service(),
		router: Ember.inject.service(),
		routeHistory: Ember.inject.service(),
		stepIndicator: Ember.inject.service(),

		actions: {
			previousStep: function previousStep() {
				var route = this.get('routeHistory.previousRoute');
				var router = this.get('router');
				router.transitionTo(route);
			},
			nextStep: function nextStep() {
				var route = this.get('routeHistory.nextRoute');
				var router = this.get('router');
				router.transitionTo(route);
			}
		}

	});
});