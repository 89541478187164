define('sokol-today/routes/dashboard/classes/copy/batch', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model: function model(params) {
            // console.log(params.classlist.split(','));
            return params.classlist.split(',');
        },
        afterModel: function afterModel(model) {
            var self = this;
            if (!model.get('firstObject.id')) {
                self.transitionTo('dashboard.classes.index');
            }
        },

        serialize: function serialize(models) {
            // console.log(models);
            return { classlist: models.join(',') };
        }
    });
});