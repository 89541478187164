define('sokol-today/components/dashboard/assignments/display-page', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		connectedUser: Ember.inject.service()

		// assignments:Ember.computed('connectedUser.user.cloudStorage')
	});
});