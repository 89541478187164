define('sokol-today/models/demo/guardian', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        avatar: _emberData.default.belongsTo('demo/avatar', { async: true }),
        firstName: _emberData.default.attr('string'),
        lastName: _emberData.default.attr('string'),
        pin: _emberData.default.attr('string'),
        students: _emberData.default.hasMany('demo/student', { async: true }),
        cellPhone: _emberData.default.attr('string'),
        homePhone: _emberData.default.attr('string'),
        email: _emberData.default.attr('string'),
        addressStreet: _emberData.default.attr('string'),
        addressStreetNum: _emberData.default.attr('string'),
        addressState: _emberData.default.attr('string'),
        addressZip: _emberData.default.attr('string'),
        addressTown: _emberData.default.attr('string')
    });
});