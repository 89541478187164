define('sokol-today/components/dashboard/settings/index/admin-display', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    router: Ember.inject.service(),

    //This redirects to the user route, allowing the menu settings button to remain lit
    didInsertElement: function didInsertElement() {
      var router = this.get('router');
      var staff = this.get('connectedUser.account');
      router.transitionTo('dashboard.settings.user', staff);
    }
  });
});