define('sokol-today/controllers/website', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		actions: {
			clickedMenu: function clickedMenu() {
				this.toggleProperty('hideNavBar');
			}
		}
	});
});