define("sokol-today/components/dashboard/settings/billing/subscriptions/subscription/reactivate-sub", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      reactivateSub: function reactivateSub(sub_id) {
        var body = { "sub_id": sub_id };
        $.get({
          url: "https://us-central1-scope-95d64.cloudfunctions.net/reactivateStripeSubscription?sub_id=" + sub_id,
          data: JSON.stringify(body),
          json: true
        }).done(function (data) {
          console.log(data);
          if (data.error) {
            self.setProperties({ errorMessage: data.error });
          } else if (data.success) {}
        });
      }
    }
  });
});