define('sokol-today/components/dashboard/settings/billing/subscriptions/subscription/cancel-sub', ['exports'], function (exports) {
		'use strict';

		Object.defineProperty(exports, "__esModule", {
				value: true
		});
		exports.default = Ember.Component.extend({
				router: Ember.inject.service(),
				connectedUser: Ember.inject.service(),
				actions: {
						cancelSub: function cancelSub(sub) {
								var router = this.get('router');
								var staff = this.get('connectedUser.staff');
								var sub_id = sub.get('id');
								var cus_id = sub.get('customer');
								var _this = this;
								cus_id.then(function (customer) {
										$.get({
												url: "https://us-central1-scope-95d64.cloudfunctions.net/cancelStripeSubscription?sub_id=" + sub_id
										}).done(function (data) {
												if (data.error) {
														self.setProperties({ errorMessage: data.error });
												} else if (data.success) {
														_this.setProperties({ successMessage: data.success });
														$.get({
																url: "https://us-central1-scope-95d64.cloudfunctions.net/retrieveUpcomingStripeInvoice?cus_id=" + customer.get('id') + '&sub_id=' + sub_id
														}).done(function (data) {
																if (data.error) {
																		console.log('error with upcoming Invoice');
																		_this.setProperties({ errorMessage: data.error });
																} else if (data.success) {
																		console.log('received upcoming invoice');
																		_this.setProperties({ successMessage: data.success });
																		router.transitionTo('dashboard.settings.billing.subscriptions.subscription', staff, sub_id);
																}
														});
												}
										});
								});
						},
						listSub: function listSub(sub_id) {
								var body = { "sub_id": sub_id };
								$.get({
										url: "https://us-central1-scope-95d64.cloudfunctions.net/listStripeSubscription?sub_id=" + sub_id,
										data: JSON.stringify(body),
										json: true
								}).done(function (data) {
										console.log(data);
										if (data.error) {
												self.setProperties({ errorMessage: data.error });
										} else if (data.success) {
												store.findRecord('user', staff.get('user.id')).then(function (theUser) {
														theUser.set('isNew', 'account.user-create.step-four.personal');
														theUser.save().then(function () {
																self.setProperties({ errorMessage: '', successMessage: data.success });
																setTimeout(function () {
																		router.transitionTo('account.user-create.step-four.personal');
																		self.setProperties({ successMessage: '' });
																}, 1500);
														}, function (error) {
																console.log('Error : ' + error);
														});
												});
										}
								});
						}
				}
		});
});