define('sokol-today/models/assignment', ['exports', 'ember-data'], function (exports, _emberData) {
		'use strict';

		Object.defineProperty(exports, "__esModule", {
				value: true
		});
		exports.default = _emberData.default.Model.extend({
				subject: _emberData.default.attr('string'),
				type: _emberData.default.attr('string'), //Classwork, Homework, Test, Final
				grade: _emberData.default.attr('number', { defaultValue: 0 }),
				completed: _emberData.default.attr('date'),
				maxScore: _emberData.default.attr('number'),
				//myAssignment is a directory for each teacher to keep/store/distribute assignments to students
				classAssignment: _emberData.default.belongsTo('class-assignment', { async: true, inverse: null }),
				student: _emberData.default.belongsTo('student', { async: true }),
				class: _emberData.default.belongsTo('class', { async: true, inverse: null }), //Need to add this as part of save so we can query assignments by class
				report_card_id: _emberData.default.attr('string'),
				complete: _emberData.default.attr('boolean')
		});
});