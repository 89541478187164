define('sokol-today/models/subscription', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        cancel_at_period_end: _emberData.default.attr('boolean'),
        created: _emberData.default.attr('date'), //Unix Epoch Seconds
        current_period_end: _emberData.default.attr('date'), //Unix Epoch Seconds
        current_period_start: _emberData.default.attr('date'), //Unix Epoch Seconds
        customer: _emberData.default.belongsTo('account-info', { async: true, inverse: null }),
        // id: DS.attr('string'), //Subscription id
        items: _emberData.default.attr(),
        livemode: _emberData.default.attr('boolean'),
        object: _emberData.default.attr('string'),
        plan: _emberData.default.belongsTo('plan'),
        quantity: _emberData.default.attr('number'), //How many subscriptions to hand out (STRIPE property)
        used: _emberData.default.attr('number'), //How many subscriptions have been handed out (SOKOOL property should never be updated by stripe)
        start: _emberData.default.attr('date'), //Unix Epoch Seconds
        status: _emberData.default.attr('string'),
        trial_end: _emberData.default.attr('date'), //Unix Epoch Seconds
        trial_start: _emberData.default.attr('date'), //Unic Epoch Seconds
        users: _emberData.default.hasMany('staff', { async: true }), //keep track of staff using certain subscriptions (SOKOOL property should never be updated by stripe)
        invoices: _emberData.default.hasMany('invoice', { async: true, inverse: 'subscription' }), //Invoices from stripe
        upcoming_payment_amount: _emberData.default.attr('number'), //Total amount in pennies going to be charged
        upcoming_payment_attempt: _emberData.default.attr('date'), //Unix Epocj Seconds
        upcoming_item_count: _emberData.default.attr('number') //Items in bill
    });
});