define('sokol-today/components/helper/student-class-grade', ['exports'], function (exports) {
		'use strict';

		Object.defineProperty(exports, "__esModule", {
				value: true
		});
		exports.default = Ember.Component.extend({
				store: Ember.inject.service(),
				grade: null,
				classAbsences: null,
				classTardies: null,
				classDismissals: null,

				gradeAvg: Ember.computed('student.assignments', function () {
						var _this = this;
						var theClass = this.get('class');
						var theStudent = this.get('student');
						var store = this.get('store');
						return store.query('assignment', {
								orderBy: 'class',
								equalTo: theClass.get('id')
						}).then(function (classAssignments) {
								var gradeArray = [];
								var adjArray = [];
								//Check and see if teacher created a weighting for the class, if not use sokool progressive
								if (!theClass.get('isWeighted')) {
										// console.log('no weight for class using default progressive');
										return store.find('class-grade-weight', '-alsdjkfh').then(function (defaultWeightModel) {
												for (var i = classAssignments.get('length') - 1; i >= 0; i--) {
														var assignment = classAssignments.objectAt(i);
														switch (assignment.get('type')) {
																case "classwork":
																		var adj1 = Math.pow(defaultWeightModel.get('classwork'), Math.sqrt((i + 1) * defaultWeightModel.get('classwork')));
																		var adjGrade = adj1 * assignment.get('grade');
																		gradeArray.pushObject(adjGrade);
																		adjArray.pushObject(adj1);
																		break;
																case "homework":
																		var adj1 = Math.pow(defaultWeightModel.get('homework'), Math.sqrt((i + 1) * defaultWeightModel.get('homework')));
																		var adjGrade = adj1 * assignment.get('grade');
																		gradeArray.pushObject(adjGrade);
																		adjArray.pushObject(adj1);
																		break;
																case "test":
																		var adj1 = Math.pow(defaultWeightModel.get('test'), Math.sqrt((i + 1) * defaultWeightModel.get('test')));
																		var adjGrade = adj1 * assignment.get('grade');
																		gradeArray.pushObject(adjGrade);
																		adjArray.pushObject(adj1);
																		break;
																case "final":
																		var adj1 = Math.pow(defaultWeightModel.get('final'), Math.sqrt((i + 1) * defaultWeightModel.get('final')));
																		var adjGrade = adj1 * assignment.get('grade');
																		gradeArray.pushObject(adjGrade);
																		adjArray.pushObject(adj1);
																		break;
														}
												}
												var totalGrades = gradeArray.reduce(function (sum, value) {
														return sum + value;
												});
												var totalAdj = adjArray.reduce(function (sum, value) {
														return sum + value;
												});
												_this.set('grade', Math.round(totalGrades / totalAdj));
												return Math.round(totalGrades / totalAdj);
										}).catch(function (error) {
												console.log(error);
										});
								} else {
										//NEED TO ADD LOGIC FOR USING A USERS WEIGHT
										console.log('there is a grade weight...wtf?');
								}
						}).catch(function (error) {
								console.log(error);
						});
				}),
				absences: Ember.computed('student.absences', function () {
						var _this = this;
						var theClass = this.get('class');
						var theStudent = this.get('student');
						var store = this.get('store');
						return store.query('absence', {
								orderBy: 'student',
								equalTo: theStudent.get('id')
						}).then(function (allAbsences) {
								var theClassAbsences = allAbsences.filter(function (absence) {
										if (absence.get('class.id') == theClass.get('id') && absence.get('absent')) {
												return true;
										} else {
												return false;
										}
								});
								var theClassTardies = allAbsences.filter(function (absence) {
										if (absence.get('class.id') == theClass.get('id') && absence.get('tardy')) {
												return true;
										} else {
												return false;
										}
								});
								var theClassDismissals = allAbsences.filter(function (absence) {
										if (absence.get('class.id') == theClass.get('id') && absence.get('dismissal')) {
												return true;
										} else {
												return false;
										}
								});
								_this.set('classTardies', theClassTardies);
								_this.set('classAbsences', theClassAbsences);
								_this.set('classDismissals', theClassDismissals);
						}).catch(function (error) {
								console.log(error);
						});
				})
		});
});