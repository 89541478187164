define('sokol-today/components/cards/report-card', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    assignments: [],

    classAssignments: Ember.computed('student.assignments.[]', function () {
      var _this = this;
      var store = this.get('store');
      var student_id = this.get('student.id');
      var class_id = this.get('class.id');
      var uniq_id = student_id + class_id;
      return store.query('assignment', {
        orderBy: 'report_card_id',
        equalTo: uniq_id
      }).then(function (assignmentList) {
        _this.set('assignments', assignmentList.toArray());
        return assignmentList.toArray();
      }).catch(function (error) {
        console.log(error);
      });
    }),

    sortedByDateAssignments: Ember.computed.sort('assignments', 'sortDefinition'),

    sortBy: 'completed',
    reverseSort: false,
    sortDefinition: Ember.computed('sortBy', 'reverseSort', function () {
      var sortOrder = this.get('reverseSort') ? 'desc' : 'asc';
      return [this.get('sortBy') + ':' + sortOrder];
    }),

    actions: {
      doubleCheck: function doubleCheck() {
        console.log(this.get('finalize'));
      }
    }

  });
});