define('sokol-today/routes/dashboard/staff/staff', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		model: function model(params) {
			return this.store.find('staff', params.staff_id);
		},

		actions: {
			goToClass: function goToClass(classroom) {
				this.transitionTo('dashboard.staff', classroom);
			}
		}
	});
});