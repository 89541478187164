define('sokol-today/components/dashboard/students/edit/step-two', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        store: Ember.inject.service(),
        connectedUser: Ember.inject.service(),
        router: Ember.inject.service('-routing'),
        relations: Ember.computed('store', function () {
            return this.get('store').findAll('relationList');
        }),
        actions: {
            removeTime: function removeTime() {
                var relationship = this.get('model');
                relationship.setProperties({ homeFull: false, homePart: false });
            },
            addTime: function addTime(button) {
                var relationship = this.get('model');
                switch (button) {
                    case "Full":
                        relationship.setProperties({ homeFull: true, homePart: false });
                        break;
                    case "Part":
                        relationship.setProperties({ homeFull: false, homePart: true });
                }
            },
            saveGuardian: function saveGuardian(model, guardian) {
                var store = this.get('store');
                var self = this;
                var connectedUser = this.get('connectedUser');
                var router = this.get('router');
                store.findRecord('guardian', guardian.get('id')).then(function (foundGuardian) {
                    foundGuardian.save().then(function () {
                        model.save().then(function () {
                            self.setProperties({ successMessage: "Guardian Info Saved", errorMessage: false });
                            connectedUser.changeYear();
                            setTimeout(function () {
                                router.transitionTo('dashboard.students');
                            }, 1000);
                        });
                    }, function (error) {
                        console.log('Error : ' + error);
                    });
                }, function (error) {
                    console.log('Error : ' + error);
                });
            }
        }
    });
});