define('sokol-today/models/staff', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        firstName: _emberData.default.attr('string'),
        lastName: _emberData.default.attr('string'),
        email: _emberData.default.attr('string'),
        birthday: _emberData.default.attr('date'),
        connection: _emberData.default.hasMany('connection'),
        observed: _emberData.default.hasMany('observation-block', { async: true }),
        started: _emberData.default.attr('date'),
        deactivated: _emberData.default.attr('date'),
        room: _emberData.default.belongsTo('room', { async: true }),
        pin: _emberData.default.attr('string'),
        ext: _emberData.default.attr('string'),
        lastOnline: _emberData.default.attr('date'),
        classes: _emberData.default.hasMany('class', { async: true, inverse: 'staff' }),
        school: _emberData.default.belongsTo('school', { async: true, inverse: null }),
        profession: _emberData.default.belongsTo('profession', { async: true }),
        avatar: _emberData.default.belongsTo('avatar', { async: true }),
        myAssignments: _emberData.default.hasMany('myAssignment'),
        user: _emberData.default.belongsTo('user', { inverse: null }),
        accountInfo: _emberData.default.belongsTo('account-info', { async: true, inverse: null }),
        districtInfo: _emberData.default.belongsTo('account-info', { async: true, inverse: null }),
        allergies: _emberData.default.hasMany('allergy', { async: true }),
        role: _emberData.default.attr('number'),
        waiting: Ember.computed('role', function () {
            var role = this.get('role');
            switch (role) {
                case 0:
                    return true;
                default:
                    return false;
            }
        }).property('role'),
        student: Ember.computed('role', function () {
            var role = this.get('role');
            switch (role) {
                case 30:
                    return true;
                default:
                    return false;
            }
        }).property('role'),
        //check parent role privledges
        parent: Ember.computed('role', function () {
            var role = this.get('role');
            switch (role) {
                case 40:
                    return true;
                default:
                    return false;
            }
        }).property('role'),
        //check teacher role privledges
        support: Ember.computed('role', function () {
            var role = this.get('role');
            switch (role) {
                case 70: //custodial
                case 71:
                case 72: //althetic coach
                case 73:
                case 74: //substitute
                case 75:
                case 76:
                case 77:
                case 78:
                case 79:
                    return true;
                default:
                    return false;
            }
            this.notifyPropertyChange('role');
        }).property('role'),
        //check teacher role privledges
        teacher: Ember.computed('role', function () {
            var role = this.get('role');
            switch (role) {
                case 80:
                case 81:
                case 82:
                case 83:
                case 84:
                case 85: //assistant
                case 86:
                case 87: //teacher
                case 88:
                case 89:
                    //teacher personal
                    return true;
                default:
                    return false;
            }
            this.notifyPropertyChange('role');
        }).property('role'),
        teacherPersonal: Ember.computed('role', function () {
            var role = this.get('role');
            switch (role) {
                case 89:
                    return true;
                default:
                    return false;
            }
            this.notifyPropertyChange('role');
        }).property('role'),
        admin: Ember.computed('role', function () {
            var role = this.get('role');
            switch (role) {
                case 89: //teacher personal
                case 90: //admin personal
                case 91: //front office staff
                case 92: //admin - principle/assistant principle
                case 93:
                case 94:
                case 95:
                case 96:
                case 97:
                case 98:
                case 99:
                    return true;
                default:
                    return false;
            }
            this.notifyPropertyChange('role');
        }).property('role'),
        adminPersonal: Ember.computed('role', function () {
            var role = this.get('role');
            switch (role) {
                case 90:
                    return true;
                default:
                    return false;
            }
            this.notifyPropertyChange('role');
        }).property('role'),
        superUser: Ember.computed('role', function () {
            var role = this.get('role');
            switch (role) {
                case 100:
                    return true;
                default:
                    return false;
            }
            this.notifyPropertyChange('role');
        }).property('role')
    });
});