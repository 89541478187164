define('sokol-today/routes/dashboard/relationships/index', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		// connectedUser:Ember.inject.service(),
		// store:Ember.inject.service(),

		// model(){
		// 	let connectedUser = this.get('connectedUser.user');
		// 	let store = this.get('store');
		// 	return connectedUser.then(function(user){
		// 		return store.findRecord('school', user.get('school')).then(function(school){
		// 			let relationLink = school.get('relationshipList');
		// 			return relationLink.get('relationships');
		// 		},function(error) {
		// 			console.log('Error : ' + error);
		// 		});
		// 	},function(error) {
		// 		console.log('Error : ' + error);
		// 	}); 
		// }
	});
});