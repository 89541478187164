define('sokol-today/components/dashboard/courses/add/step-one', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    stepIndicator: Ember.inject.service(),
    connectedUser: Ember.inject.service(),
    router: Ember.inject.service('-routing'),
    store: Ember.inject.service(),
    exampleID: null,
    revealPriorityHelper: false,

    courseLengthCollege: Ember.computed('store', function () {
      var store = this.get('store');
      return store.query('course-length', {
        orderBy: 'schoolType',
        equalTo: 'college'
      });
    }),
    //Priority check handled in add controller for data down to card and step-one component
    priorityCheck: Ember.computed('model.priority', function () {
      console.log('controller priority check');
      var _this = this;
      var model = this.get('model');
      var priority = model.get('priority');
      if (!priority) {
        return;
      }
      switch (priority) {
        case "1":
          // _this.updateCourseID(subject, priority, count);
          return "State Required";
          break;
        case "2":
          // _this.updateCourseID(subject, priority, count);
          return "State Required Second Level";
          break;
        case "3":
          // _this.updateCourseID(subject, priority, count);
          return "Higher Learning";
          break;
        case "4":
          // _this.updateCourseID(subject, priority, count);
          return "Continued Learning";
          break;
        case "5":
          // _this.updateCourseID(subject, priority, count);
          return "Non-Essential Learning";
          break;
        case "6":
          // _this.updateCourseID(subject, priority, count);
          return "Basket Weaving...";
          break;
        default:
          // _this.updateCourseID(subject, priority, count);
          return "";
          break;
      }
    }),

    updateCourseID: function updateCourseID(subject, priority, count) {
      console.log(subject + ' ' + priority + ' ' + count);
      var idString = null;
      var _this = this;
      switch (subject) {
        case 'Mathematics':
          idString = 'MA' + (1000 * priority + count);
          _this.set('model.courseID', idString);
          _this.set('exampleID', idString);
          break;
        case 'English':
          idString = 'EN' + (1000 * priority + count);
          _this.set('model.courseID', idString);
          _this.set('exampleID', idString);
          break;
        case 'Life Studies':
          idString = 'LS' + (1000 * priority + count);
          _this.set('model.courseID', idString);
          _this.set('exampleID', idString);
          break;
        case 'Science':
          idString = 'SC' + (1000 * priority + count);
          _this.set('model.courseID', idString);
          _this.set('exampleID', idString);
          break;
        case 'Social Studies':
          idString = 'SS' + (1000 * priority + count);
          _this.set('model.courseID', idString);
          _this.set('exampleID', idString);
          break;
        case 'Technology':
          idString = 'TE' + (1000 * priority + count);
          _this.set('model.courseID', idString);
          _this.set('exampleID', idString);
          break;
        case 'Physical & Health':
          idString = 'PE' + (1000 * priority + count);
          _this.set('model.courseID', idString);
          _this.set('exampleID', idString);
          break;
        case 'World Languages':
          idString = 'WL' + (1000 * priority + count);
          _this.set('model.courseID', idString);
          _this.set('exampleID', idString);
          break;
        case 'Miscellaneous Offering':
          idString = 'MO' + (1000 * priority + count);
          _this.set('model.courseID', idString);
          _this.set('exampleID', idString);
          break;
        case 'Business':
          idString = 'BU' + (1000 * priority + count);
          _this.set('model.courseID', idString);
          _this.set('exampleID', idString);
          break;
        case 'The Arts':
          idString = 'AR' + (1000 * priority + count);
          _this.set('model.courseID', idString);
          _this.set('exampleID', idString);
          break;
        case 'Performing Arts':
          idString = 'MU' + (1000 * priority + count);
          _this.set('model.courseID', idString);
          _this.set('exampleID', idString);
          break;
        case 'Digital Education':
          idString = 'DE' + (1000 * priority + count);
          _this.set('model.courseID', idString);
          _this.set('exampleID', idString);
          break;
        case 'Wellness':
          idString = 'WE' + (1000 * priority + count);
          _this.set('model.courseID', idString);
          _this.set('exampleID', idString);
          break;
        default:
          return;
      }
    },
    didInsertElement: function didInsertElement() {
      var step = this.get('stepIndicator');
      step.setProperties({ stepOne: false, stepTwo: false, stepThree: false });
    },


    courseSubjects: Ember.computed('connectedUser.school', function () {
      var school = this.get('connectedUser.school');
      return school;
    }),

    actions: {
      next: function next() {
        var router = this.get('router');
        router.transitionTo('dashboard.courses.add.step-two');
      },
      cancel: function cancel(model) {
        var router = this.get('router');
        model.destroyRecord().then(function () {
          router.transitionTo('dashboard.courses.index');
        }, function (error) {
          console.log('Error : ' + error);
        });
      },
      createID: function createID(subjectModel, object) {
        // console.log(object);
        var _this = this;
        var subject = object.highlighted;
        // let string = 'div[data-ebd-id="'+object.uniqueId +'-trigger"]';
        if (!subject) {
          this.set('model.subject', null);
          console.log('no subject: ' + subject);
          // $(string).attr('data-really', '0');
          return;
        }
        this.set('model.subject', subject);

        var name = subject.get('name');
        var count = subject.get('courses.length');
        var priority = this.get('model.priority');
        if (!priority) {
          priority = 1;
        }
        var idString = null;
        this.updateCourseID(name, priority, count);
      }
    }
  });
});