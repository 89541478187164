define('sokol-today/models/demo/observation-block', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    observations: _emberData.default.hasMany('demo/observation', { async: true }),
    finalized: _emberData.default.attr('boolean', { defaultValue: false }),
    teacher: _emberData.default.belongsTo('demo/teacher', { async: true }),
    school: _emberData.default.belongsTo('demo/school', { async: true }),
    timestampStart: _emberData.default.attr('string'),
    timestampEnd: _emberData.default.attr('string'),
    room: _emberData.default.belongsTo('demo/room'),
    observer: _emberData.default.belongsTo('demo/user'),
    observationType: _emberData.default.belongsTo('demo/observeType'),
    points: _emberData.default.hasMany('demo/point')
  });
});