define('sokol-today/transitions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () {

    ///ACCOUNT TRANSITIONS
    this.transition(this.fromRoute('account.index'), this.toRoute('account.user-create'), this.use('toLeft', { duration: 1000 }), this.reverse('toRight', { duration: 1000 }));

    this.transition(this.fromRoute('account.index'), this.toRoute('account.sign-in'), this.use('toLeft', { duration: 1000 }), this.reverse('toRight', { duration: 1000 }));
    ///WEBSITE TRANSITIONS
    this.transition(this.fromRoute('website.pricing.teachers'), this.toRoute('website.pricing.administrators'), this.use('toUp', { duration: 1000 }), this.reverse('toDown', { duration: 1000 }));

    this.transition(this.fromRoute('website'), this.toRoute('account'), this.use('toUp', { duration: 1000 }), this.reverse('toDown', { duration: 1000 }));

    this.transition(this.fromRoute('website'), this.toRoute('dashboard'), this.use('toUp', { duration: 1000 }), this.reverse('toDown', { duration: 1000 }));

    this.transition(this.fromRoute('website.home'), this.toRoute('website.story'), this.use('toLeft', { duration: 1000 }), this.reverse('toRight', { duration: 1000 }));

    this.transition(this.fromRoute('website.story'), this.toRoute('website.pricing'), this.use('toLeft', { duration: 1000 }), this.reverse('toRight', { duration: 1000 }));

    this.transition(this.fromRoute('website.home'), this.toRoute('website.pricing'), this.use('toLeft', { duration: 1000 }), this.reverse('toRight', { duration: 1000 }));

    this.transition(this.fromRoute('website.home'), this.toRoute('website.subscribe'), this.use('toLeft', { duration: 1000 }), this.reverse('toRight', { duration: 1000 }));
    this.transition(this.fromRoute('website.story'), this.toRoute('website.subscribe'), this.use('toLeft', { duration: 1000 }), this.reverse('toRight', { duration: 1000 }));
    this.transition(this.fromRoute('website.pricing'), this.toRoute('website.subscribe'), this.use('toLeft', { duration: 1000 }), this.reverse('toRight', { duration: 1000 }));
    ///DASHBOARD TRANSITIONS
    this.transition(this.fromRoute('dashboard'), this.toRoute('billing'), this.use('toLeft', { duration: 1000 }), this.reverse('toRight', { duration: 1000 }));

    this.transition(this.fromRoute('dashboard.index'), this.toRoute('dashboard.assignments'), this.use('toLeft', { duration: 1000 }), this.reverse('toRight', { duration: 1000 }));

    this.transition(this.fromRoute('dashboard.assignments.index'), this.toRoute('dashboard.assignments.edit'), this.use('toLeft', { duration: 1000 }), this.reverse('toRight', { duration: 1000 }));

    this.transition(this.fromRoute('dashboard.assignments.index'), this.toRoute('dashboard.assignments.add'), this.use('toLeft', { duration: 1000 }), this.reverse('toRight', { duration: 1000 }));

    this.transition(this.fromRoute('dashboard.index'), this.toRoute('dashboard.observations'), this.use('toLeft', { duration: 1000 }), this.reverse('toRight', { duration: 1000 }));

    this.transition(this.fromRoute('dashboard.observations.index'), this.toRoute('dashboard.observations.new'), this.use('toLeft', { duration: 1000 }), this.reverse('toRight', { duration: 1000 }));

    this.transition(this.fromRoute('dashboard.index'), this.toRoute('dashboard.staff'), this.use('toLeft', { duration: 1000 }), this.reverse('toRight', { duration: 1000 }));

    this.transition(this.fromRoute('dashboard.classes.index'), this.toRoute('dashboard.classes.add'), this.use('toLeft', { duration: 1000 }), this.reverse('toRight', { duration: 1000 }));

    this.transition(this.fromRoute('dashboard.classes'), this.toRoute('dashboard.students'), this.use('toLeft', { duration: 1000 }), this.reverse('toRight', { duration: 1000 }));

    this.transition(this.fromRoute('dashboard.classes'), this.toRoute('dashboard.observations'), this.use('toUp', { duration: 1000 }), this.reverse('toDown', { duration: 1000 }));

    this.transition(this.fromRoute('dashboard.staff'), this.toRoute('dashboard.observations'), this.use('toUp', { duration: 1000 }), this.reverse('toDown', { duration: 1000 }));

    // this.transition(
    //   this.fromRoute('dashboard.staff'),
    //   this.toRoute('dashboard.courses'),
    //   this.use('toLeft', { duration: 1000 }),
    //   this.reverse('toRight', { duration: 1000 })
    // );

    this.transition(this.fromRoute('dashboard.students'), this.toRoute('dashboard.observations'), this.use('toUp', { duration: 1000 }), this.reverse('toDown', { duration: 1000 }));

    // this.transition(
    //   this.fromRoute('dashboard.classes'),
    //   this.toRoute('dashboard.courses'),
    //   this.use('toLeft', { duration: 1000 }),
    //   this.reverse('toRight', { duration: 1000 })
    // );

    this.transition(
    // this.fromRoute('dashboard.students'),
    this.toRoute('dashboard.courses'), this.use('toLeft', { duration: 1000 }), this.reverse('toRight', { duration: 1000 }));

    this.transition(this.toRoute('dashboard.classes.classroom.index'), this.use('toLeft', { duration: 1000 }), this.reverse('toRight', { duration: 1000 }));
    this.transition(this.toRoute('dashboard.classes.classroom'), this.use('toLeft', { duration: 1000 }), this.reverse('toRight', { duration: 1000 }));
    this.transition(
    // this.fromRoute('dashboard.classes'),
    this.toRoute('dashboard.classes'), this.use('toLeft', { duration: 1000 }), this.reverse('toRight', { duration: 1000 }));

    this.transition(
    // this.fromRoute('dashboard.classes'),
    this.toRoute('dashboard.relationships'), this.use('toLeft', { duration: 1000 }), this.reverse('toRight', { duration: 1000 }));

    this.transition(this.toRoute('dashboard.relationships.add.step-one'), this.use('toRight', { duration: 1000 }), this.reverse('toLeft', { duration: 1000 }));

    this.transition(this.fromRoute('dashboard.classes'), this.toRoute('dashboard.assignments'), this.use('toLeft', { duration: 1000 }), this.reverse('toRight', { duration: 1000 }));

    this.transition(this.fromRoute('dashboard.classes'), this.toRoute('dashboard.staff'), this.use('toLeft', { duration: 1000 }), this.reverse('toRight', { duration: 1000 }));

    this.transition(this.fromRoute('dashboard.students'), this.toRoute('dashboard.staff'), this.use('toLeft', { duration: 1000 }), this.reverse('toRight', { duration: 1000 }));

    this.transition(this.fromRoute('dashboard.classes.index'), this.toRoute('dashboard.classes.edit'), this.use('toLeft', { duration: 1000 }), this.reverse('toRight', { duration: 1000 }));

    this.transition(this.fromRoute('dashboard.students.index'), this.toRoute('dashboard.students.add'), this.use('toLeft', { duration: 1000 }), this.reverse('toRight', { duration: 1000 }));

    this.transition(this.fromRoute('dashboard.staff.index'), this.toRoute('dashboard.staff.add'), this.use('toLeft', { duration: 1000 }), this.reverse('toRight', { duration: 1000 }));

    this.transition(this.fromRoute('dashboard.staff.index'), this.toRoute('dashboard.staff.edit'), this.use('toLeft', { duration: 1000 }), this.reverse('toRight', { duration: 1000 }));

    this.transition(this.toRoute('dashboard.staff.staff'), this.use('toLeft', { duration: 1000 }), this.reverse('toRight', { duration: 1000 }));

    this.transition(this.fromRoute('dashboard.students.index'), this.toRoute('dashboard.students.edit'), this.use('toLeft', { duration: 1000 }), this.reverse('toRight', { duration: 1000 }));

    this.transition(this.fromRoute('dashboard.years.index'), this.toRoute('dashboard.years.year'), this.use('toLeft', { duration: 1000 }), this.reverse('toRight', { duration: 1000 }));

    this.transition(this.toRoute('dashboard.relationships.add'), this.use('toLeft', { duration: 1000 }), this.reverse('toRight', { duration: 1000 }));

    this.transition(this.fromRoute('dashboard.years'), this.toRoute('dashboard.classes'), this.use('toLeft', { duration: 1000 }), this.reverse('toRight', { duration: 1000 }));

    this.transition(this.fromRoute('dashboard.years'), this.toRoute('dashboard.courses'), this.use('toLeft', { duration: 1000 }), this.reverse('toRight', { duration: 1000 }));

    this.transition(this.fromRoute('dashboard.years'), this.toRoute('dashboard.students'), this.use('toLeft', { duration: 1000 }), this.reverse('toRight', { duration: 1000 }));
    this.transition(this.fromRoute('dashboard.years'), this.toRoute('dashboard.staff'), this.use('toLeft', { duration: 1000 }), this.reverse('toRight', { duration: 1000 }));
    this.transition(this.fromRoute('dashboard.years'), this.toRoute('dashboard.observations'), this.use('toLeft', { duration: 1000 }), this.reverse('toRight', { duration: 1000 }));

    this.transition(this.fromRoute('dashboard.years.index'), this.toRoute('dashboard.years.add'), this.use('toLeft', { duration: 1000 }), this.reverse('toRight', { duration: 1000 }));
    this.transition(this.fromRoute('dashboard.years.index'), this.toRoute('dashboard.years.edit'), this.use('toLeft', { duration: 1000 }), this.reverse('toRight', { duration: 1000 }));
    this.transition(this.fromRoute('dashboard.classes.copy'), this.toRoute('dashboard.classes.index'), this.use('toLeft', { duration: 1000 }), this.reverse('toRight', { duration: 1000 }));
    this.transition(this.fromRoute('dashboard.courses.add.step-one'), this.toRoute('dashboard.courses.add.step-two'), this.use('toLeft', { duration: 1000 }), this.reverse('toRight', { duration: 1000 }));
    this.transition(this.fromRoute('dashboard.courses.add.step-two'), this.toRoute('dashboard.courses.add.step-three'), this.use('toLeft', { duration: 1000 }), this.reverse('toRight', { duration: 1000 }));
    this.transition(this.fromRoute('dashboard.courses.index'), this.toRoute('dashboard.courses.add'), this.use('toLeft', { duration: 1000 }), this.reverse('toRight', { duration: 1000 }));

    this.transition(this.fromRoute('dashboard.assignments'), this.toRoute('dashboard.courses'), this.use('toLeft', { duration: 1000 }), this.reverse('toRight', { duration: 1000 }));
    this.transition(this.fromRoute('dashboard.assignments'), this.toRoute('dashboard.years'), this.use('toLeft', { duration: 1000 }), this.reverse('toRight', { duration: 1000 }));
    this.transition(this.fromRoute('dashboard.assignments'), this.toRoute('dashboard.classes'), this.use('toLeft', { duration: 1000 }), this.reverse('toRight', { duration: 1000 }));
    this.transition(this.fromRoute('dashboard.assignments'), this.toRoute('dashboard.staff'), this.use('toLeft', { duration: 1000 }), this.reverse('toRight', { duration: 1000 }));

    // IF TRANSITIONS

    this.transition(this.hasClass('staff'), this.toValue(true), this.use('toDown', { duration: 1000 }), this.reverse('toUp', { duration: 1000 }));

    this.transition(this.hasClass('toDown'), this.toValue(true), this.use('toDown', { duration: 1000 }), this.reverse('toUp', { duration: 1000 }));

    this.transition(this.hasClass('toLeft'), this.toValue(true), this.use('toLeft', { duration: 1000 }), this.reverse('toRight', { duration: 1000 }));

    this.transition(this.hasClass('fade'), this.toValue(true), this.use('fade', { duration: 300 }), this.reverse('fade', { duration: 300 }));

    this.transition(this.hasClass('toRight'), this.toValue(true), this.use('toRight', { duration: 1000 }), this.reverse('toLeft', { duration: 1000 }));

    this.transition(this.hasClass('toRightLong'), this.toValue(true), this.use('toRight', { duration: 1000 }), this.reverse('toLeft', { duration: 1000 }));

    this.transition(this.hasClass('toUp'), this.toValue(true), this.use('toUp', { duration: 1000 }), this.reverse('toDown', { duration: 1000 }));

    this.transition(this.hasClass('header'), this.toValue(true), this.use('toLeft', { duration: 1000 }), this.reverse('toRight', { duration: 1000 }));
  };

  ;
});