define('sokol-today/controllers/dashboard/students/edit/step-one', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		actions: {
			transitionToGuardian: function transitionToGuardian(theStudent) {
				this.transitionToRoute('dashboard.students.add.step-two', theStudent.id);
			}
		}
	});
});