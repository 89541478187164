define("sokol-today/helpers/compare-by", ["exports"], function (exports) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.compareBy = compareBy;
	function compareBy(params) {
		store: Ember.inject.service();
		var a = params[0]; //First compare variable or array
		var method = params[1]; //Method of comparison
		var b = params[2]; //Second compare variable
		var count = params[3]; //Return count = true, or return model = model, or leave blank for true false
		var test = params[4]; //If compare to multiple models test = key of location of test for variable A
		var c = params[5]; //Third variable for between test
		var format = params[6]; //not sure this is a thing

		if (b == "now") {
			// this.set(b, moment());
			b = moment();
		}
		if (!count && count !== "model") {
			switch (method) {
				case ">=":
					if (a >= b) {
						return true;
					} else {
						return false;
					}
					break;
				case "==":
					// console.log(a + ':::' + b);
					if (a == b) {
						return true;
					} else {
						return false;
					}
					break;
				case "<=":
					if (a <= b) {
						return true;
					} else {
						return false;
					}
					break;
				case "<":
					if (a < b) {
						return true;
					} else {
						return false;
					}
					break;
				case ">":
					if (a > b) {
						return true;
					} else {
						return false;
					}
					break;
				case "!=":
					if (a != b) {
						return true;
					} else {
						return false;
					}
					break;
				default:
					return false;
			}
		} else {
			var myArray = [];
			if (!a) {
				return 0;
			}
			if (!test) {
				switch (method) {
					case ">=":
						if (a >= b) {
							myArray.pushObject(a);
						}
						break;
					case "==":
						if (a == b) {
							myArray.pushObject(a);
						}
						break;
					case "<=":
						if (a <= b) {
							myArray.pushObject(a);
						}
						break;
					case "<":
						if (a < b) {
							myArray.pushObject(a);
						}
						break;
					case ">":
						if (a > b) {
							myArray.pushObject(a);
						}
						break;
					case "between":
						if (a > b && a < c) {
							myArray.pushObject(a);
						}
						break;
					default:
						break;
				}

				if (count == "model") {
					return myArray;
				} else {
					return myArray.length;
				}
			} else {
				console.log('least in right spot');
				for (var i = a.get('length') - 1; i >= 0; i--) {
					var aB = a.objectAt(i);
					if (!aB) {
						console.log(aB);
						return;
					}
					var testObject = aB.get(test);
					switch (method) {
						case ">=":
							if (testObject >= b) {
								myArray.pushObject(a.objectAt(i));
							}
							break;
						case "==":
							if (testObject == b) {
								myArray.pushObject(a.objectAt(i));
							}
							break;
						case "<=":
							if (testObject <= b) {
								myArray.pushObject(a.objectAt(i));
							}
							break;
						case "<":
							if (testObject < b) {
								myArray.pushObject(a.objectAt(i));
							}
							break;
						case ">":
							if (testObject > b) {
								myArray.pushObject(a.objectAt(i));
							}
							break;
						case "between":
							if (testObject > b && testObject < c) {
								myArray.pushObject(a.objectAt(i));
							}
							break;
						default:
							break;
					}
				}
				if (count == "model") {
					return myArray;
				} else if (count == "bool") {
					if (myArray.length > 0) {
						return true;
					} else {
						return false;
					}
				} else {
					return myArray.length;
				}
			}
		}
	}

	exports.default = Ember.Helper.helper(compareBy);
});