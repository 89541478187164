define('sokol-today/services/role-decode', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    getName: function getName(number) {
      switch (number) {
        case 40:
          return 'Parent';
        case 30:
          return 'Student';
        case 70: //custodial
        case 71:
        case 72: //althetic coach
        case 73:
        case 74: //substitute
        case 75:
        case 76:
        case 77:
        case 78:
        case 79:
          return 'Support';
        case 80:
        case 81:
        case 82:
        case 83:
        case 84:
        case 85: //assistant
        case 86:
          return 'Assisting Staff';
        case 87: //teacher
        case 88:
          return 'Teacher';
        case 89:
          return 'Teacher Personal';
        case 90:
          return 'Admin Personal';
        case 91:
          //
          return 'Front Office';
        case 92: //admin - principle/assistant principle
        case 93:
        case 94:
        case 95:
        case 96:
        case 97:
        case 98:
        case 99:
          return 'Administrator';
        case 100:
          return 'Super User';
        default:
          return null;
          break;
      }
    },
    getNumber: function getNumber(name) {
      switch (name) {
        case "Teacher":
          return 88;
        case "Teacher Personal":
          return 89;
        case "Administrator":
          return 92;
        case "Administrator Personal":
          return 90;
        case "Parent":
          return 40;
        case "Student":
          return 30;
        case "Support":
          return 79;
        default:
          return 40;
      }
    }
  });
});