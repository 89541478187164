define('sokol-today/components/dashboard/courses/display-page', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    query: null,

    sortByCorrected: Ember.computed('sortBy', function () {
      var string = this.get('sortBy');
      switch (string) {
        case "subject.name":
          return 'Subject Name';
          break;
        case 'courseID':
          return 'Course ID';
          break;
        default:
          return string;
          break;
      }
    }),

    sortedCourses: Ember.computed.sort('connectedUser.school.courseList.courses', 'sortDefinition'),
    sortBy: 'name',
    reverseSort: false,
    sortDefinition: Ember.computed('sortBy', 'reverseSort', function () {
      var sortOrder = this.get('reverseSort') ? 'desc' : 'asc';
      return [this.get('sortBy') + ':' + sortOrder];
    }),

    actions: {
      toggleCopyCoursesFrom: function toggleCopyCoursesFrom() {
        this.toggleProperty('copyCoursesFrom');
      },
      toggleFilterMenu: function toggleFilterMenu() {
        this.toggleProperty('showFilterMenu');
      },
      confirmCourses: function confirmCourses(year) {
        // var message = confirm('This will lock the courses for the school year and allow students to start choosing classes. Do you want to continue?');
        // if (message == true) {
        //   year.set('coursesConfirmed', true);
        // } else {
        //   console.log('clicked cancel');
        // }
        if (!year) {
          this.toggleProperty('confirmCoursesMessage');
          return;
        } else {
          year.set('coursesConfirmed', true);
        }
      }
    }
  });
});