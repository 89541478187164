define('sokol-today/components/dashboard/observations/recent-observations-list', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        obsList: null,
        didInsertElement: function didInsertElement() {
            this.get('obsSearch');
        },


        obsSearch: Ember.computed('data', 'user', function () {
            var data = this.get('data');

            var that = this;
            var showOnly = this.get('showOnly');

            if (showOnly && Number.isInteger(parseInt(showOnly)) && typeof data !== 'undefined') {
                var length = data.get('length');
                var obs = data.slice(length - parseInt(showOnly), length);
                that.set('obsList', obs);
                return data.slice(length - parseInt(showOnly), length);
            }
        }).property('data', 'user')
    });
});