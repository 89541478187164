define('sokol-today/components/account/user/user-create/display-page', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		session: Ember.inject.service(),
		router: Ember.inject.service('-routing'),

		didInsertElement: function didInsertElement() {
			var user = this.get('user');
			var router = this.get('router');
			if (user) {
				router.transitionTo(user);
			}
		}
	});
});