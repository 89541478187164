define('sokol-today/components/helper/log-out', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        session: Ember.inject.service(),
        store: Ember.inject.service(),
        router: Ember.inject.service('-routing'),
        actions: {
            signOut: function signOut() {
                var session = this.get('session');
                var router = this.get('router');
                var store = this.get('store');
                // store.unloadAll('user');
                store.peekAll('user').forEach(function (r) {
                    return r.unloadRecord();
                });
                session.close();
                router.transitionTo('website.home');
            }
        }
    });
});