define('sokol-today/components/dashboard/assignments/edit/step-one', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _Ember$Component$exte;

    function _defineProperty(obj, key, value) {
        if (key in obj) {
            Object.defineProperty(obj, key, {
                value: value,
                enumerable: true,
                configurable: true,
                writable: true
            });
        } else {
            obj[key] = value;
        }

        return obj;
    }

    exports.default = Ember.Component.extend((_Ember$Component$exte = {

        connectedUser: Ember.inject.service(),
        router: Ember.inject.service('-routing'),
        store: Ember.inject.service(),
        firebaseApp: Ember.inject.service()
    }, _defineProperty(_Ember$Component$exte, 'router', Ember.inject.service('-routing')), _defineProperty(_Ember$Component$exte, 'subjectOptions', Ember.computed(function () {
        var store = this.get('store');
        return store.findAll('subject');
    })), _defineProperty(_Ember$Component$exte, 'typeOptions', ['homework', 'classwork', 'test', 'final']), _defineProperty(_Ember$Component$exte, 'actions', {
        backToAssignments: function backToAssignments(model, documents) {
            var _this = this;
            var router = this.get('router');
            if (model.get('hasDirtyAttributes')) {
                console.log(model.changedAttributes());
                model.rollbackAttributes();
            }
            var files = model.get('documents');
            for (var i = files.get('length') - 1; i >= 0; i--) {
                console.log(i);
                var file = files.objectAt(i);
                if (file.get('hasDirtyAttributes')) {
                    console.log('found dirty file');
                    file.rollbackAttributes();
                    var folderFiles = model.get('folder').then(function (folder) {
                        folder.rollbackAttributes();
                        _this.setProperties({ errorMessage: '', successMessage: 'Any changes reversed' });
                        router.transitionTo('dashboard.assignments');
                    }, function (error) {
                        _this.setProperties({ errorMessage: error });
                        console.log('Error : ' + error);
                    });
                } else {
                    router.transitionTo('dashboard.assignments');
                }
            }
            // let file = model.get('documents').objectAt(index);
        },
        removeFileModel: function removeFileModel(index, document, model) {
            var _this = this;
            var file = model.get('documents').objectAt(index);
            file.get('assignments').set(model.id, null);
            model.get('documents').set(document.id, null);
            var folderFiles = model.get('folder').then(function (folder) {
                folder.get('files').set(document.id, null);
            }, function (error) {
                _this.setProperties({ errorMessage: error });
                console.log('Error : ' + error);
            });
        },
        removeFile: function removeFile(index) {
            var fileInfo = this.get('fileInfo');
            fileInfo.removeAt(index);
        },
        saveAssignment: function saveAssignment(model, files) {
            if (!model.get('name')) {
                this.setProperties({ errorMessage: 'Assignment needs to have a name' });
                return;
            }
            var user = this.get('connectedUser.user');
            var store = this.get('store');
            var storage = this.get('firebaseApp').storage();
            var self = this;
            var router = this.get('router');
            user.then(function (theUser) {
                var cloudStorageLoc = theUser.get('cloudStorage');
                cloudStorageLoc.then(function (theCloudStorageFolder) {
                    var assignmentLoc = theCloudStorageFolder.get('folders.firstObject');
                    var storageRef = storage.ref('users/' + theUser.id + '/Assignments/' + model.get('name'));
                    if (!files) {
                        model.save().then(function (savedAssignment) {
                            self.setProperties({ errorMessage: '', successMessage: 'Assignment saved!' });
                            setTimeout(function () {
                                router.transitionTo('dashboard.assignments');
                            }, 1000);
                        }, function (error) {
                            self.setProperties({ errorMessage: error });
                            model.rollbackAttributes();
                            model.save();
                        });
                    } else {
                        var assignmentFolder = model.get('folder');
                        assignmentFolder.then(function (theFolder) {
                            for (var i = files.length - 1; i >= 0; i--) {
                                var file = files[i];
                                var metadata = {
                                    name: file.metadata.name,
                                    size: file.metadata.size,
                                    updated: file.metadata.updated
                                };
                                storageRef.child(file.metadata.name).put(file.file, metadata).then(function (fileShot) {
                                    var path = fileShot.metadata.downloadURLs[0];
                                    var fileModel = store.createRecord('file', {
                                        name: fileShot.metadata.name,
                                        filePath: path,
                                        saveDate: fileShot.metadata.updated,
                                        fileType: fileShot.metadata.contentType
                                    });
                                    theFolder.set('assignmentInfo', model);
                                    theFolder.get('files').addObject(fileModel);
                                    model.get('documents').addObject(fileModel);
                                    fileModel.get('assignments').addObject(model);
                                    fileModel.save().then(function () {
                                        model.save().then(function (savedAssignment) {
                                            theFolder.save().then(function () {
                                                assignmentLoc.save().then(function () {}, function (error) {
                                                    console.log('Error : ' + error);
                                                });
                                            }, function (error) {
                                                console.log('Error : ' + error);
                                            });
                                        }, function (error) {
                                            self.setProperties({ errorMessage: error });
                                            model.rollbackAttributes();
                                        });
                                    }, function (error) {
                                        self.setProperties({ errorMessage: error });
                                        fileModel.rollbackAttributes();
                                        console.log('Error : ' + error);
                                    });
                                }, function (error) {
                                    console.log('Error : ' + error);
                                });
                            }
                        }, function (error) {
                            console.log('Error : ' + error);
                        });
                    }
                    self.setProperties({ errorMessage: '', suucessMessage: 'Assignment saved!' });
                }, function (error) {
                    console.log('Error : ' + error);
                });
            }, function (error) {
                console.log('Error : ' + error);
            });
        }
    }), _Ember$Component$exte));
});