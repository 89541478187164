define('sokol-today/components/dashboard/settings/billing/subscriptions/subscription/add/staff-sub', ['exports'], function (exports) {
		'use strict';

		Object.defineProperty(exports, "__esModule", {
				value: true
		});
		exports.default = Ember.Component.extend({
				connectedUser: Ember.inject.service(),
				router: Ember.inject.service(),

				actions: {
						addToSub: function addToSub(staff, sub, accountInfo_id) {
								var _this = this;
								var sub_id = sub.get('id');
								var sub_quantity = sub.get('quantity') + staff.get('length');
								var cust_id = sub.get('customer');
								var staffArray = [];
								var staffObject = {};
								var user_id = this.get('connectedUser.staff.id');
								for (var i = staff.get('length') - 1; i >= 0; i--) {
										var obj = {};
										var staffId = staff.objectAt(i).get('id');
										staffObject[staffId] = true;
								}
								cust_id.then(function (customer) {
										_this.setProperties({ fetching: true, errorMessage: null, successMessage: null });
										$.get({
												url: "https://us-central1-scope-95d64.cloudfunctions.net/addMemberToStripeSubscription?sub_id=" + sub_id + '&members=' + JSON.stringify(staffObject) + '&accountInfoId=' + accountInfo_id + '&cus_id=' + accountInfo_id,
												data: JSON.stringify(staffArray),
												json: true,
												objectMode: true
										}).done(function (data) {
												_this.setProperties({ fetching: false, successMessage: data, selectedOption: null });
												setTimeout(function () {
														_this.get('router').transitionTo('dashboard.settings.billing.subscriptions.subscription', user_id, sub_id);
												}, 3000);
												// $.get({
												// 		url: "https://us-central1-scope-95d64.cloudfunctions.net/retrieveUpcomingStripeInvoice?cus_id=" + customer.get('id') + '&sub_id=' + sub_id,
												// }).done(function(data) {
												// 		console.log(data);
												// 		if (data.error) {
												// 				console.log('error with upcoming Invoice');
												// 				_this.setProperties({ errorMessage: data.error });
												// 		} else if (data.success) {
												// 				console.log('received upcoming invoice');
												// 				_this.setProperties({ successMessage: data.success });
												// 		}
												// });												
										}).fail(function (error) {
												console.log(error);
												_this.setProperties({ errorMessage: error });
										});
								});
						}
				}
		});
});