define('sokol-today/components/dashboard/classes/classroom/grades/grade-info', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        // tagName: 'td',
        classNames: ['flex-grow1'],

        watchEdit: Ember.observer('editMode', function () {
            var editMode = this.get('editMode');
            if (editMode) {
                return;
            }
            var assignment = this.get('theStudent');
            assignment.save().then(function () {}, function (error) {
                console.log('Error : ' + error);
            });
        }),
        keyDown: function keyDown(evt) {
            evt.bubbles = false;
            console.log('keyDown');
            console.log(evt.key);
        },
        keyPress: function keyPress(evt) {
            console.log('keyPress');
            console.log(evt);
        },

        actions: {
            personalSave: function personalSave(model) {
                var self = this;
                model.save().then(function () {
                    self.toggleProperty('editModeSingle');
                }, function (error) {
                    console.log('Error : ' + error);
                });
            },
            personalEdit: function personalEdit() {
                console.log('toggle edit peronal');
                this.toggleProperty('editModeSingle');
            },
            assignmentReceived: function assignmentReceived(event) {
                var assignment = this.get('theStudent');
                assignment.set('completed', event);
                console.log(event);
            }
        }
    });
});