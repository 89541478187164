define('sokol-today/components/billing/index-page', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    router: Ember.inject.service(),

    didInsertElement: function didInsertElement() {
      var router = this.get('router');
      router.transitionTo('dashboard.settings.billing.subscriptions');
    }
  });
});