define('sokol-today/components/account/user/user-create-with-pin/step-four', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        newUser: Ember.inject.service(),
        connectedUser: Ember.inject.service(),
        store: Ember.inject.service(),
        router: Ember.inject.service('-routing'),
        error: false,
        errorMessage: '',
        didInsertElement: function didInsertElement() {
            var newUser = this.get('newUser');
            newUser.setProperties({ hasUser: true, haveAccountType: true, havePin: true, haveStaffConfirm: false });
            var user = this.get('connectedUser.user');
            var router = this.get('router');
            if (!user) {
                router.transitionTo('account.user-create-with-pin.step-one');
            }
        },

        actions: {
            combineWithStaff: function combineWithStaff(staff) {
                var self = this;
                var router = this.get('router');
                var store = this.get('store');
                var school = staff.get('school');
                var modelSchool = store.findRecord('school', school.get('id'));
                var user = this.get('connectedUser.user');
                user.then(function (theUser) {
                    modelSchool.then(function (foundSchool) {
                        theUser.get('staff').addObject(staff);
                        theUser.set('defaultStaff', staff);
                        theUser.get('schools').addObject(foundSchool);
                        theUser.set('isNew', null);
                        theUser.save().then(function () {
                            staff.set('user', theUser);
                            staff.save().then(function () {
                                self.setProperties({ successMessage: 'Staff file found', errorMessage: '' });
                                setTimeout(function () {
                                    router.transitionTo('dashboard');
                                }, 1000);
                            }, function (error) {
                                console.log('Error : ' + error);
                            });
                        }, function (error) {
                            theUser.rollbackAttributes().save();
                            staff.rollbackAttributes().save();
                            console.log('Error : ' + error);
                        });
                    }, function (error) {
                        console.log('Error : ' + error);
                    });
                }, function (error) {
                    console.log('Error : ' + error);
                });
            }
        }
    });
});