define('sokol-today/routes/dashboard/students/index', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        connectedUser: Ember.inject.service(),
        beforeModel: function beforeModel() {
            var self = this;
            var role = this.get('connectedUser.user');
            role.then(function (staffRole) {
                staffRole.get('defaultStaff').then(function (hm) {
                    if (hm.get('role') < 70) {
                        self.transitionTo('dashboard');
                    }
                });
            });
        }
    });
});