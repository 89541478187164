define('sokol-today/components/website/beta-user', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        connectedUser: Ember.inject.service(),
        store: Ember.inject.service(),
        router: Ember.inject.service('-routing'),
        errorMessage: '',

        actions: {
            betaTester: function betaTester(email, pin) {
                var store = this.get('store');
                var router = this.get('router');
                if (!email) {
                    this.setProperties({ errorMessage: 'We need a valid email' });
                } else if (!pin) {
                    this.setProperties({ errorMessage: "We need a valid pin" });
                } else {
                    var newTester = store.createRecord('beta-tester', {
                        email: email,
                        pin: pin
                    });

                    newTester.save().then(function (tester) {
                        router.transitionTo('website.home');
                    }, function (error) {
                        console.log('Error : ' + error);
                    });
                }
            }
        }
    });
});