define('sokol-today/services/race-code', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        getCode: function getCode(isHispanic, selectedRaces) {
            console.log(selectedRaces);
            if (isHispanic) {
                switch (selectedRaces.join(',')) {
                    case "1":
                        return 34;
                        break;
                    case "2":
                        return 35;
                        break;
                    case "4":
                        return 36;
                        break;
                    case "5":
                        return 3;
                        break;
                    case "6":
                        return 37;
                        break;
                    case "1,2":
                        return 38;
                        break;
                    case "1,4":
                        return 39;
                        break;
                    case "1,5":
                        return 40;
                        break;
                    case "1,6":
                        return 41;
                        break;
                    case "2,4":
                        return 42;
                        break;
                    case "2,5":
                        return 43;
                        break;
                    case "2,6":
                        return 44;
                        break;
                    case "4,5":
                        return 45;
                        break;
                    case "4,6":
                        return 46;
                        break;
                    case "5,6":
                        return 47;
                        break;
                    case "1,2,4":
                        return 48;
                        break;
                    case "1,2,5":
                        return 49;
                        break;
                    case "1,2,6":
                        return 50;
                        break;
                    case "1,4,5":
                        return 51;
                        break;
                    case "1,4,6":
                        return 52;
                        break;
                    case "1,5,6":
                        return 53;
                        break;
                    case "2,4,6":
                        return 54;
                        break;
                    case "2,4,5":
                        return 55;
                        break;
                    case "2,5,6":
                        return 56;
                        break;
                    case "4,5,6":
                        return 57;
                        break;
                    case "1,2,4,5":
                        return 58;
                        break;
                    case "1,2,5,6":
                        return 59;
                        break;
                    case "1,4,5,6":
                        return 60;
                        break;
                    case "1,2,4,6":
                        return 61;
                        break;
                    case "2,4,5,6":
                        return 62;
                        break;
                    case "1,2,4,5,6":
                        return 63;
                        break;
                    default:
                        return 0;
                }
            } else {
                switch (selectedRaces.join(',')) {
                    case "1":
                        return 1;
                        break;
                    case "2":
                        return 2;
                        break;
                    case "4":
                        return 4;
                        break;
                    case "5":
                        return 5;
                        break;
                    case "6":
                        return 6;
                        break;
                    case "1,2":
                        return 7;
                        break;
                    case "1,4":
                        return 8;
                        break;
                    case "1,5":
                        return 10;
                        break;
                    case "1,6":
                        return 11;
                        break;
                    case "2,4":
                        return 12;
                        break;
                    case "2,5":
                        return 13;
                        break;
                    case "2,6":
                        return 14;
                        break;
                    case "4,5":
                        return 15;
                        break;
                    case "4,6":
                        return 16;
                        break;
                    case "5,6":
                        return 17;
                        break;
                    case "1,2,4":
                        return 18;
                        break;
                    case "1,2,5":
                        return 19;
                        break;
                    case "1,2,6":
                        return 20;
                        break;
                    case "1,4,5":
                        return 21;
                        break;
                    case "1,4,6":
                        return 22;
                        break;
                    case "1,5,6":
                        return 23;
                        break;
                    case "2,4,6":
                        return 24;
                        break;
                    case "2,4,5":
                        return 25;
                        break;
                    case "2,5,6":
                        return 26;
                        break;
                    case "4,5,6":
                        return 27;
                        break;
                    case "1,2,4,5":
                        return 28;
                        break;
                    case "1,2,5,6":
                        return 29;
                        break;
                    case "1,4,5,6":
                        return 30;
                        break;
                    case "1,2,4,6":
                        return 31;
                        break;
                    case "2,4,5,6":
                        return 32;
                        break;
                    case "1,2,4,5,6":
                        return 33;
                        break;
                    default:
                        return 0;
                }
            }
            console.log(selectedRaces.join(','));
        },
        getArray: function getArray(raceCode) {
            switch (raceCode) {
                case 1:
                    return [1];
                    break;
                case 2:
                    return [2];
                    break;
                case 4:
                    return [4];
                    break;
                case 5:
                    return [5];
                    break;
                case 6:
                    return [6];
                    break;
                case 7:
                    return [1, 2];
                    break;
                case 8:
                    return [1, 4];
                    break;
                case 10:
                    return [1, 5];
                    break;
                case 11:
                    return [1, 6];
                    break;
                case 12:
                    return [2, 4];
                    break;
                case 13:
                    return [2, 5];
                    break;
                case 14:
                    return [2, 6];
                    break;
                case 15:
                    return [4, 5];
                    break;
                case 16:
                    return [4, 6];
                    break;
                case 17:
                    return [5, 6];
                    break;
                case 18:
                    return [1, 2, 4];
                    break;
                case 19:
                    return [1, 2, 5];
                    break;
                case 20:
                    return [1, 2, 6];
                    break;
                case 21:
                    return [1, 4, 5];
                    break;
                case 22:
                    return [1, 4, 6];
                    break;
                case 23:
                    return [1, 5, 6];
                    break;
                case 24:
                    return [2, 4, 6];
                    break;
                case 25:
                    return [2, 4, 5];
                    break;
                case 26:
                    return [2, 5, 6];
                    break;
                case 27:
                    return [4, 5, 6];
                    break;
                case 28:
                    return [1, 2, 4, 5];
                    break;
                case 29:
                    return [1, 2, 5, 6];
                    break;
                case 30:
                    return [1, 4, 5, 6];
                    break;
                case 31:
                    return [1, 2, 4, 6];
                    break;
                case 32:
                    return [2, 4, 5, 6];
                    break;
                case 33:
                    return [1, 2, 4, 5, 6];
                    break;
                //AND HISPANIC
                case 34:
                    return [1, 10];
                    break;
                case 35:
                    return [2, 10];
                    break;
                case 36:
                    return [4, 10];
                    break;
                case 3:
                    return [5, 10];
                    break;
                case 37:
                    return [6, 10];
                    break;
                case 38:
                    return [1, 2, 10];
                    break;
                case 39:
                    return [1, 4, 10];
                    break;
                case 40:
                    return [1, 5, 10];
                    break;
                case 41:
                    return [1, 6, 10];
                    break;
                case 42:
                    return [2, 4, 10];
                    break;
                case 43:
                    return [2, 5, 10];
                    break;
                case 44:
                    return [2, 6, 10];
                    break;
                case 45:
                    return [4, 5, 10];
                    break;
                case 46:
                    return [4, 6, 10];
                    break;
                case 47:
                    return [5, 6, 10];
                    break;
                case 48:
                    return [1, 2, 4, 10];
                    break;
                case 49:
                    return [1, 2, 5, 10];
                    break;
                case 50:
                    return [1, 2, 6, 10];
                    break;
                case 51:
                    return [1, 4, 5, 10];
                    break;
                case 52:
                    return [1, 4, 6, 10];
                    break;
                case 53:
                    return [1, 5, 6, 10];
                    break;
                case 54:
                    return [2, 4, 6, 10];
                    break;
                case 55:
                    return [2, 4, 5, 10];
                    break;
                case 56:
                    return [2, 5, 6, 10];
                    break;
                case 57:
                    return [4, 5, 6, 10];
                    break;
                case 58:
                    return [1, 2, 4, 5, 10];
                    break;
                case 59:
                    return [1, 2, 5, 6, 10];
                    break;
                case 60:
                    return [1, 4, 5, 6, 10];
                    break;
                case 61:
                    return [1, 2, 4, 6, 10];
                    break;
                case 62:
                    return [2, 4, 5, 6, 10];
                    break;
                case 63:
                    return [1, 2, 4, 5, 6, 10];
                    break;
                default:
                    return 0;
            }
        }
    });
});