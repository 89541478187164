define('sokol-today/components/dashboard/relationships/add/step-two', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    stepIndicator: Ember.inject.service(),
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    connectedUser: Ember.inject.service(),

    didInsertElement: function didInsertElement() {
      this.get('stepIndicator').set('stepOne', true);
    },

    actions: {
      cancel: function cancel(relationship, relationList_id, student_id) {
        var store = this.get('store');
        var router = this.get('router');
        store.findRecord('relationship-list', relationList_id).then(function (relationshipList) {
          relationshipList.get('relationships').removeObject(relationship);
          relationshipList.save().then(function () {
            store.findRecord('student', student_id).then(function (theStudent) {
              theStudent.get('relationships').removeObject(relationship);
              theStudent.save().then(function () {
                relationship.destroyRecord().then(function () {
                  router.transitionTo('dashboard.relationships');
                }).catch(function (error) {
                  console.log('error destroying relationship');
                  console.log(error);
                });
              }).catch(function (error) {
                console.log('error saving student after deleting relationship');
                console.log(error);
              });
            }).catch(function (error) {
              console.log('error finding student');
              console.log(error);
            });
          }).catch(function (error) {
            console.log('error saving relationshiplist after removing relationship');
            console.log(error);
          });
        }).catch(function (error) {
          console.log('error finding relationship list');
          console.log(error);
        });
      }
    }
  });
});