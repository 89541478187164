define('sokol-today/models/demo/class', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    students: _emberData.default.hasMany('demo/student', { async: true }),
    assignments: _emberData.default.hasMany('demo/assignment', { async: true }),
    year: _emberData.default.belongsTo('demo/year', { async: true }),
    name: _emberData.default.belongsTo('demo/class-name', { async: true }),
    startTime: _emberData.default.belongsTo('demo/time', { async: true }),
    room: _emberData.default.belongsTo('demo/room', { async: true }),
    endTime: _emberData.default.belongsTo('demo/time', { async: true }),
    school: _emberData.default.belongsTo('demo/school'),
    teacher: _emberData.default.belongsTo('demo/teacher', { async: true }),
    subject: _emberData.default.belongsTo('demo/subject', { async: true }),
    period: _emberData.default.belongsTo('demo/period', { async: true, inverse: null }),
    stringName: Ember.computed('name.name', function () {
      var name = this.get('name.name');
      if (name) {
        return name.slice(0, 1);
      }
    }),
    stringTeacher: Ember.computed('teacher.firstName', function () {
      var name = this.get('teacher.firstName');
      if (name) {
        return name.slice(0, 1);
      }
    })
  });
});