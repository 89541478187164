define('sokol-today/components/dashboard/classes/copy/batch-copy', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        store: Ember.inject.service(),
        connectedUser: Ember.inject.service(),

        years: Ember.computed('connectedUser.currentYear', function () {
            var school = this.get('connectedUser.school');

            return this.get('store').query('yearList', {
                orderBy: 'school',
                equalTo: school.get('id')
            }).then(function (theList) {
                return theList.get('firstObject.years');
            });
        }),

        batchCopy: function batchCopy(classes, year, periods) {
            var store = this.get('store');

            var _loop = function _loop() {
                var currentClass = classes[i];

                var _loop2 = function _loop2() {
                    var currentPeriod = periods[j];
                    var classCopy = currentClass.toJSON();
                    var periodID = currentPeriod.get('id');
                    classCopy.period = periodID;
                    classCopy.year = year.get('id');
                    // classCopy.students = [];
                    // classCopy.assignments = [];
                    var newClass = store.createRecord('class', {
                        period: store.peekRecord('period', classCopy.period),
                        year: store.peekRecord('year', classCopy.year),
                        name: currentClass.get('name'),
                        startTime: currentClass.get('startTime'),
                        room: currentClass.get('room'),
                        endTime: currentClass.get('endTime'),
                        school: currentClass.get('school'),
                        days: classCopy.days,
                        subject: currentClass.get('subject')
                    });

                    newClass.get('staff').addObjects(currentClass.get('staff'));

                    newClass.save().then(function (savedCopy) {
                        currentPeriod.get('classes').addObject(savedCopy);
                        currentPeriod.save();
                        currentClass.get('school.classList').then(function (classList) {
                            classList.get('classes').addObject(savedCopy);
                            classList.save().then(function () {
                                store.findRecord('room', classCopy.room).then(function (theRoom) {
                                    theRoom.get('classes').addObject(savedCopy);
                                    theRoom.save().then(function () {
                                        for (var h = classCopy.staff.length - 1; h >= 0; h--) {
                                            store.findRecord('staff', classCopy.staff[h]).then(function (foundStaff) {
                                                foundStaff.get('classes').addObject(savedCopy);
                                                foundStaff.save();
                                            }, function (error) {
                                                return { error: true, errorMessage: error };
                                                console.log('Error : ' + error);
                                            });
                                        }
                                    });
                                }, function (error) {
                                    return { error: true, errorMessage: error };
                                    console.log('Error : ' + error);
                                });
                            });
                        });
                    }, function (error) {
                        return { error: true, errorMessage: error };
                        console.log('Error : ' + error);
                    });
                };

                for (j = periods.length - 1; j >= 0; j--) {
                    _loop2();
                }
            };

            for (var i = classes.length - 1; i >= 0; i--) {
                var j;

                _loop();
            }
            self.setProperties({ successMessage: 'Batch copy complete' });
            setTimeout(function () {
                self.setProperties({ successMessage: null, haveChoice: false });
            }, 1000);
        },


        actions: {
            yearBatch: function yearBatch() {
                this.setProperties({ haveChoice: true, yearBatch: true, termBatch: false });
            },
            termBatch: function termBatch() {
                this.setProperties({ haveChoice: true, termBatch: true, yearBatch: false });
            },
            changeChoice: function changeChoice() {
                this.setProperties({ haveChoice: false });
            },
            copy: function copy(classes, year, selectionPeriods, periods) {
                var self = this;
                // let periods = this.get('selectedPeriod');
                if (!year) {
                    return;
                }
                if (selectionPeriods) {
                    self.batchCopy(classes, year, selectionPeriods);
                } else {
                    var allPeriods = [];
                    for (var i = periods.get('length') - 1; i >= 0; i--) {
                        allPeriods.push(periods.objectAt(i));
                    }
                    self.batchCopy(classes, year, allPeriods);
                }
            }
        }
    });
});