define('sokol-today/models/my-assignment', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		//path to firebase storage documents
		documents: _emberData.default.hasMany('file'),
		creationDate: _emberData.default.attr('date', {
			defaultValue: function defaultValue() {
				return new Date();
			}
		}),
		desc: _emberData.default.attr('string'),
		type: _emberData.default.attr('string'),
		//math/english/science/etc
		subject: _emberData.default.belongsTo('subject', { async: true }),
		//appropriate grade for assignment
		maxScore: _emberData.default.attr('string'),
		minScore: _emberData.default.attr('string'),
		steps: _emberData.default.attr('number'),
		// class: DS.attr('string'),
		name: _emberData.default.attr('string'),
		folder: _emberData.default.belongsTo('folder', { async: true, inverse: null })
	});
});