define('sokol-today/controllers/dashboard/staff/add/step-two', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		actions: {
			transitionToStepThree: function transitionToStepThree(theStaff) {
				console.log(theStaff);
				this.transitionToRoute('dashboard.staff.add.step-three', theStaff.id);
			}
		}
	});
});