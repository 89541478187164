define('sokol-today/components/dashboard/observations/observation/display-page', ['exports'], function (exports) {
				'use strict';

				Object.defineProperty(exports, "__esModule", {
								value: true
				});
				exports.default = Ember.Component.extend({
								timePeriods: [],

								teacherPosition: Ember.computed.filterBy('model.points', 'teacher', true),

								studentParticipation: Ember.computed.filterBy('model.points', 'handup', true),

								studentCalledOn: Ember.computed.filterBy('model.points', 'calledOn', true),

								studentsOnTask: Ember.computed.filterBy('model.points', 'onTask', true),

								studentsOffTask: Ember.computed.filterBy('model.points', 'offTask', true),

								blockLayout: Ember.computed('model', 'model.observations', 'model.points', function () {
												var self = this;
												var model = this.get('model');
												var observations = this.get('model.observations');
												var points = this.get('model.points');
												var elapsedTime = this.get('model.momentElapsed');

												var breakUps = Math.ceil(elapsedTime / 5);
												this.set('timePeriods', []);

												for (var i = breakUps - 1; i >= 0; i--) {
																//add five minutes increments of amount of available breakUps
																var beginPeriod = moment(model.get('timestampStart')).add(i * 5, 'm');
																//add five minutes to new start time
																var endPeriod = moment(beginPeriod).add(5, 'm');

																self.get('timePeriods').pushObject({ begin: beginPeriod, end: endPeriod });
												}
												self.get('timePeriods').reverseObjects();
								}),

								// willRender(){
								// 	this.set('timePeriods', null);
								// },
								actions: {
												printCard: function printCard(staff, schoolName) {
																this.toggleProperty('print');
												},
												print: function print() {
																window.print();
												}
								}
				});
});