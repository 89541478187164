define('sokol-today/components/dashboard/classes/classroom/photos/add-photo-img-preview', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		didInsertElement: function didInsertElement() {
			console.log(this.get('image.data'));
		}
	});
});