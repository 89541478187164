define('sokol-today/components/account/user/user-create/step-three', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        connectedUser: Ember.inject.service(),
        connectionStatus: Ember.inject.service(),
        newUser: Ember.inject.service(),
        session: Ember.inject.service(),
        store: Ember.inject.service(),
        router: Ember.inject.service('-routing'),

        didInsertElement: function didInsertElement() {
            var newUser = this.get('newUser');
            newUser.setProperties({ hasUser: true, hasType: true });
        },


        actions: {
            hasPin: function hasPin() {},
            changePlan: function changePlan(staff) {
                var store = this.get('store');
                var accountInfoId = staff.get('accountInfo');
                var accountInfo = store.findRecord('accountInfo', accountInfoId.get('id'));

                accountInfo.then(function (theAccount) {
                    theAccount.set('accountType', null);
                    // theAccount.save().then(function(){}, function(error) {
                    //     console.log('Error : ' + error);
                    // });
                }, function (error) {
                    console.log('Error : ' + error);
                });
            },

            // saveAccountType(accountType, aUser, cost) {
            //     var hasAccountAlready = aUser.get('accountInfo.id');
            //     var store = this.get('store');
            //     var route = this.get('router');
            //     var getUser = store.findRecord('user', aUser.get('id'));
            //     getUser.then(function(user) {
            //         if (typeof(hasAccountAlready) === 'undefined') {
            //             var endPeriod = Date.now();
            //             var accountInfoStore = store.createRecord('account-info', {
            //                 user: user,
            //                 accountType: accountType,
            //                 accountCost: cost,
            //                 endTrialPeriod: endPeriod
            //             });

            //             accountInfoStore.save().then(function() {
            //                 switch (accountType) {
            //                     case "Community Teacher":
            //                     case "Community Admin":
            //                     case "Personal Teacher":
            //                     case "Personal Admin":
            //                         user.set('isNew', 'account.user-create.step-four.personal');
            //                         user.save().then(function() {
            //                             route.transitionTo('account.user-create.step-four.personal');
            //                         }, function(error) {
            //                             console.log('Error : ' + error);
            //                         });
            //                         break;
            //                     case "Lucky Teacher":
            //                     case "Lucky Admin":
            //                         user.set('isNew', 'account.user-create.step-four.group');
            //                         user.save().then(function() {
            //                             route.transitionTo('account.user-create.step-four.group');
            //                         }, function(error) {
            //                             console.log('Error : ' + error);
            //                         });
            //                         break;
            //                     default:
            //                         break;
            //                 }
            //             }, function(error) {
            //                 console.log(error);
            //             });
            //         } else {
            //             var accountInfo = store.findRecord('accountInfo', hasAccountAlready);

            //             accountInfo.then(function(theAccount) {
            //                 theAccount.set('accountType', accountType);
            //                 theAccount.save().then(function() {
            //                         switch (accountType) {
            //                             case "Community Admin":
            //                             case "Personal Teacher":
            //                             case "Community Teacher":
            //                             case "Personal Admin":
            //                                 user.set('isNew', 'account.user-create.step-four.personal');
            //                                 user.save().then(function() {
            //                                     route.transitionTo('account.user-create.step-four.personal');
            //                                 }, function(error) {
            //                                     console.log('Error : ' + error);
            //                                 });
            //                                 break;
            //                             case "Lucky Teacher":
            //                             case "Lucky Admin":
            //                                 user.set('isNew', 'account.user-create.step-four.group');
            //                                 user.save().then(function() {
            //                                     route.transitionTo('account.user-create.step-four.group');
            //                                 }, function(error) {
            //                                     console.log('Error : ' + error);
            //                                 });
            //                                 break;
            //                             default:
            //                                 break;
            //                         }
            //                     },
            //                     function(error) {
            //                         console.log(error);
            //                     });

            //             }, function(error) {
            //                 console.log('Error : ' + error);
            //             });
            //         }
            //     });
            // },
            keepPlan: function keepPlan(user) {
                var route = this.get('router');
                route.transitionTo(user.get('isNew'));
            }
        }
    });
});