define('sokol-today/models/rubric-component', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    unsatisfactory: _emberData.default.belongsTo('rubric-ranking', { async: true }),
    basic: _emberData.default.belongsTo('rubric-ranking', { async: true }),
    proficient: _emberData.default.belongsTo('rubric-ranking', { async: true }),
    distinguished: _emberData.default.belongsTo('rubric-ranking', { async: true })

  });
});