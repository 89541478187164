define('sokol-today/helpers/school-days', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.schoolDays = schoolDays;
    function schoolDays(params /*, hash*/) {
        var startDate = params[1];
        var endDate = params[2];
        // currentYearDays(startDate, endDate) {
        if (params[0] == "total") {
            var start = moment(startDate);
            var end = moment(endDate);

            var first = start.clone().endOf('week'); // end of first week
            var last = end.clone().startOf('week'); // start of last week
            var days = last.diff(first, 'days') * 5 / 7; // this will always multiply of 7
            var wfirst = first.day() - start.day(); // check first week
            if (start.day() == 0) --wfirst; // -1 if start with sunday 
            var wlast = end.day() - last.day(); // check last week
            if (end.day() == 6) --wlast; // -1 if end with saturday
            var daysTotal = wfirst + days + wlast; // get the total
            return daysTotal;
        } else if (params[0] == "left") {

            // daysLeft(startDate, endDate) {
            var start = moment(startDate);
            var today = moment();
            var end = moment(endDate);

            if (today.isBefore(startDate)) {
                var first = start.clone().endOf('week'); // end of first week
                var last = end.clone().startOf('week'); // start of last week
                var days = last.diff(first, 'days') * 5 / 7; // this will always multiply of 7
                var wfirst = first.day() - start.day(); // check first week
                if (start.day() == 0) --wfirst; // -1 if start with sunday 
                var wlast = end.day() - last.day(); // check last week
                if (end.day() == 6) --wlast; // -1 if end with saturday
                var daysLeft = wfirst + days + wlast; // get the total

                return daysLeft;
            } else {
                var first = today.clone().endOf('week'); // end of first week
                var last = end.clone().startOf('week'); // start of last week
                var days = last.diff(first, 'days') * 5 / 7; // this will always multiply of 7
                var wfirst = first.day() - today.day(); // check first week
                if (today.day() == 0) --wfirst; // -1 if start with sunday 
                var wlast = end.day() - last.day(); // check last week
                if (end.day() == 6) --wlast; // -1 if end with saturday
                var daysLeft = wfirst + days + wlast; // get the total
                if (Math.floor(daysLeft) < 0) {
                    return 0;
                }
                return Math.floor(daysLeft);
            }
        }
    }

    exports.default = Ember.Helper.helper(schoolDays);
});