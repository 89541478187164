define('sokol-today/components/dashboard/display/main/main-teacher', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        connectedUser: Ember.inject.service(),
        assignmentArray: [],

        assignmentSorting: ['dueDate:desc'],
        sortedAssignments: Ember.computed.sort('assignmentArray', function (a, b) {
            if (moment(Ember.get(a, 'dueDate')) > moment(Ember.get(b, 'dueDate'))) {
                //returns morning timings before afternoon timings
                //api doc says return 1 when 'a' should come after 'b'
                //http://emberjs.com/api/#method_computed_sort
                return 1;
            } else if (moment(Ember.get(a, 'dueDate')) < moment(Ember.get(b, 'dueDate'))) {
                //returns afternoon timings before morning timings
                //api docs says return -1, when 'a' should come before 'b'
                return -1;
            }
            return 0;
        }),

        classAssignments: Ember.computed('connectedUser.account', function () {
            var assignmentArray = this.get('assignmentArray');
            var classes = this.get('connectedUser.account.classes');
            var self = this;
            assignmentArray.clear();
            return classes.then(function (staffClasses) {
                for (var i = staffClasses.get('length') - 1; i >= 0; i--) {
                    var assignments = staffClasses.objectAt(i).get('classAssignments');
                    assignments.then(function (classAssignments) {
                        for (var j = classAssignments.get('length') - 1; j >= 0; j--) {
                            var classAssignment = classAssignments.objectAt(j);
                            if (moment(classAssignment.get('dueDate')).unix() >= moment().unix() - 172800) {
                                self.get('assignmentArray').pushObject(classAssignments.objectAt(j));
                            }
                        }
                    }, function (error) {
                        console.log('Error : ' + error);
                    });
                }
            }, function (error) {
                console.log('Error : ' + error);
            });
        })
    });
});