define('sokol-today/models/demo/my-assignment', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		//path to firebase storage documents
		documents: _emberData.default.attr('string'),
		creationDate: _emberData.default.attr('date'),
		desc: _emberData.default.attr('string'),
		//math/english/science/etc
		subject: _emberData.default.attr('string'),
		//appropriate grade for assignment
		class: _emberData.default.attr('string')
	});
});