define('sokol-today/routes/dashboard/relationships/add', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    firebaseApp: Ember.inject.service(),
    model: function model() {
      var ref = this.get('firebaseApp').database().ref('null/').push();
      var relationID = 'relation' + ref.key;
      return this.get('store').createRecord('relationship', {
        id: relationID
      });
    },
    redirect: function redirect(model, transition) {
      if (model) {
        this.transitionTo('dashboard.relationships.add.step-one', model);
      }
    }
  });
});