define('sokol-today/routes/dashboard/settings/billing/invoices', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		model: function model(params) {
			var customerId = params.cus_id;
			console.log(customerId);
			return this.store.query('invoice', {
				orderBy: 'customer',
				equalTo: customerId
			});
		}
	});
});