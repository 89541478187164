define('sokol-today/components/dashboard/display/main/main-student', ['exports'], function (exports) {
		'use strict';

		Object.defineProperty(exports, "__esModule", {
				value: true
		});
		exports.default = Ember.Component.extend({
				connectedUser: Ember.inject.service(),
				studentInfo: Ember.inject.service(),
				store: Ember.inject.service(),
				assignmentArray: [],
				absences: [],

				assignmentSorting: ['dueDate:desc'],
				sortedAssignments: Ember.computed.sort('assignmentArray', function (a, b) {
						if (moment(Ember.get(a, 'dueDate')) > moment(Ember.get(b, 'dueDate'))) {
								//returns morning timings before afternoon timings
								//api doc says return 1 when 'a' should come after 'b'
								//http://emberjs.com/api/#method_computed_sort
								return 1;
						} else if (moment(Ember.get(a, 'dueDate')) < moment(Ember.get(b, 'dueDate'))) {
								//returns afternoon timings before morning timings
								//api docs says return -1, when 'a' should come before 'b'
								return -1;
						}
						return 0;
				}),
				classAssignments: Ember.computed('student.assignments', function () {
						var assignmentArray = this.get('assignmentArray');
						var classes = this.get('student.classes');
						var self = this;
						assignmentArray.clear();
						return classes.then(function (studentClasses) {
								for (var i = studentClasses.get('length') - 1; i >= 0; i--) {
										var assignments = studentClasses.objectAt(i).get('classAssignments');
										assignments.then(function (classAssignments) {
												for (var j = classAssignments.get('length') - 1; j >= 0; j--) {
														var classAssignment = classAssignments.objectAt(j);
														if (moment(classAssignment.get('dueDate')).unix() >= moment().unix() - 172800) {
																self.get('assignmentArray').pushObject(classAssignments.objectAt(j));
														}
												}
										}, function (error) {
												console.log('Error : ' + error);
										});
								}
						}, function (error) {
								console.log('Error : ' + error);
						});
				}),

				absenceData: Ember.computed('student.absences', function () {
						var _this = this;
						var store = this.get('store');
						var absenceArray = this.get('absences');
						absenceArray.clear();
						return this.get('student.absences').then(function (absenceList) {
								var absences = absenceList.filter(function (absence) {
										if (absence.get('absent')) {
												// _this.get('absences').pushObject(absence);
												return true;
										} else {
												return false;
										}
								});
								console.log(absences);
								_this.get('absences').pushObjects(absences);
								// _this.set('absences', absences);				
						}).catch(function (error) {
								console.log(error);
						});
				})

		});
});