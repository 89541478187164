define('sokol-today/models/absence', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    absent: _emberData.default.attr('string'),
    present: _emberData.default.attr('string'),
    type: _emberData.default.attr('string'),
    date: _emberData.default.attr('string'),
    school: _emberData.default.belongsTo('school', { async: true }),
    student: _emberData.default.belongsTo('student', { async: true }),
    suspension: _emberData.default.attr('boolean', { defaultValue: false }),
    tardy: _emberData.default.attr('string'),
    dismissal: _emberData.default.attr('string'),
    staff: _emberData.default.belongsTo('staff'),
    year: _emberData.default.belongsTo('year', { async: true }),
    period: _emberData.default.belongsTo('period', { async: true }),
    class: _emberData.default.belongsTo('class', { async: true }),
    byYear: _emberData.default.attr('string'),
    byClass: _emberData.default.attr('string'),
    // timeAttended: Ember.computed('tardy', 'dismissal', 'date', function(){
    // 	var tardy=this.get('tardy');
    // 	var dismissal=this.get('dismissal');
    // 	var date=this.get('date');
    theYear: Ember.computed('year', function () {
      return this.get('year').then(function (result) {
        return result.get('year');
      });
    }),
    byWeek: Ember.computed('date', function () {
      return moment(this.get('date')).format('YYYY - w');
    }),
    byMonth: Ember.computed('date', function () {
      return moment(this.get('date')).format("MMM 'YY");
    }),
    // 	if (tardy){
    // 		var timeAtSchool = moment('3:00 pm').subtract(date).format('HH:mm:ss');
    // 		return timeAtSchool;
    // 	} else if (dismissal){
    // 		var timeAtSchool = moment(dismissal).subtract(date).format('HH:mm:ss');
    // 		return timeAtSchool;
    // 	}
    // }),
    notes: _emberData.default.attr('string')

  });
});