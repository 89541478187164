define("sokol-today/controllers/test-route", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        email: "chad.roberge@gmail.com",
        uid: "bV1XV53dDzZlw8UtPLXEJWywhcu1",

        actions: {
            toggleStripe: function toggleStripe() {
                this.toggleProperty('showStripe');
            },
            processStripeToken: function processStripeToken(plan, token) {
                console.log(token);
                console.log(plan);
            },
            SubmitDay: function SubmitDay(full, short, num) {
                var store = this.get('store');
                var model = store.createRecord('day-list', {
                    fullName: full,
                    shortName: short,
                    numeric: num
                });
                model.save().then(function (savedModel) {
                    alert('model saved successfully');
                }, function (error) {
                    alert('Error : ' + error);
                });
            },
            createCustomer: function createCustomer(email, uid) {
                if (!email && !uid) {
                    return;
                }
                $.get({
                    url: "https://us-central1-scope-95d64.cloudfunctions.net/createStripeCustomerHttp?email=" + email + '&uid=' + uid
                }).done(function (data) {
                    console.log(data);
                    if (data.error) {
                        self.setProperties({ errorMessage: data.error });
                    } else if (data.success) {
                        self.setProperties({ errorMessage: '', successMessage: 'Customer created ' + data.success });
                        setTimeout(function () {
                            self.setProperties({ successMessage: '' });
                        }, 1500);
                    }
                });
            }
        }
    });
});