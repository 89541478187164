define('sokol-today/routes/dashboard/index', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model: function model() {
            return Ember.$.getJSON('http://beta.newsapi.org/v2/top-headlines?sources=associated-press&apiKey=e30350bf279d4cfbb5c972fb5cb57130');
        }
    });
});