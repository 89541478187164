define('sokol-today/services/step-indicator', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        stepOne: false,
        stepTwo: false,
        stepThree: false,
        stepFour: false
    });
});