define('sokol-today/components/icons/hand-shake-no', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		classNameBindings: ['hovered:active'],
		hovered: false,

		mouseEnter: function mouseEnter(event) {
			this.set('hovered', true);
		},
		mouseLeave: function mouseLeave(event) {
			this.set('hovered', false);
		}
	});
});