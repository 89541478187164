define('sokol-today/components/dashboard/classes/display-student', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    content: [],

    didInsertElement: function didInsertElement() {
      var height = this.$(window).height() * 0.78;
      this.$('section').height(height);
    },


    classList: Ember.computed('connectedUser.currentYear', function () {
      var _this = this;
      var student = this.get('student');
      var year = this.get('connectedUser.currentYear');
      var array = this.get('content');
      array.clear();
      if (!year || !student) {
        return false;
      }
      return student.get('classes').then(function (theClassList) {
        var _loop = function _loop() {
          var theClass = theClassList.objectAt(i);
          var classYear = theClass.get('year');
          classYear.then(function (theYear) {
            if (!theYear) {
              return;
            }
            if (theYear.get('year') === year.get('year')) {
              array.pushObject(theClass);
            }
          }).catch(function (error) {
            console.log(error);
          });
        };

        for (var i = theClassList.get('length') - 1; i >= 0; i--) {
          _loop();
        }
        return true;
      }).catch(function (error) {
        console.log(error);
      });
    }),

    sortByCorrected: Ember.computed('sortBy', function () {
      var string = this.get('sortBy');
      switch (string) {
        case "staff.name":
          return 'Staff Name';
        case 'startTime.time':
          return 'Start Time';
        default:
          return string;
      }
    }),

    sortBy: 'name',
    reverseSort: false,
    sortDefinition: Ember.computed('sortBy', 'reverseSort', function () {
      var sortOrder = this.get('reverseSort') ? 'desc' : 'asc';
      return [this.get('sortBy') + ':' + sortOrder];
    }),

    actions: {
      toggleSortMenu: function toggleSortMenu() {
        this.toggleProperty('sortMenu');
      }
    }
  });
});