define('sokol-today/components/account/user/account-upgrade', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        connectedUser: Ember.inject.service(),
        store: Ember.inject.service(),
        upgradeHidden: true,

        accountType: Ember.computed('account.accountType', function () {
            var account = this.get('account.accountType');

            if (account) {
                switch (account) {
                    case "Personal Admin":
                        return { personalAdmin: true };
                        break;
                    case "Personal Teacher":
                        return { personalTeacher: true };
                        break;
                    default:
                        return { unknown: true };
                        break;
                }
            }
        }),

        actions: {
            toggleUserAccountUpgrade: function toggleUserAccountUpgrade() {
                this.toggleProperty('upgradeHidden');
            }
        }
    });
});