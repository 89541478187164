define('sokol-today/models/feedback', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    account: _emberData.default.belongsTo('staff'),
    timestamp: _emberData.default.attr('date'),
    feedback: _emberData.default.attr('string'),
    addressed: _emberData.default.attr('boolean'),
    addressedBy: _emberData.default.belongsTo('user'),
    addressedOn: _emberData.default.attr('date'),
    url: _emberData.default.attr('string'),
    chat_room: _emberData.default.belongsTo('chat-room')
  });
});