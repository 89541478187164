define('sokol-today/components/dashboard/classes/classroom/grades/report-cards', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _Ember$Component$exte;

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  exports.default = Ember.Component.extend((_Ember$Component$exte = {
    connectedUser: Ember.inject.service(),
    store: Ember.inject.service(),
    router: Ember.inject.service('-routing'),
    finalize: false,

    sortedStudents: Ember.computed.sort('class.students', 'sortDefinition'),

    sortBy: 'firstName',
    reverseSort: false,
    sortDefinition: Ember.computed('sortBy', 'reverseSort', function () {
      var sortOrder = this.get('reverseSort') ? 'desc' : 'asc';
      return [this.get('sortBy') + ':' + sortOrder];
    }),

    sortByCorrected: Ember.computed('sortBy', function () {
      var string = this.get('sortBy');
      switch (string) {
        case "name.name":
          return 'Class Name';
          break;
        case "staff.firstObject.firstName":
          return 'Staff Name';
          break;
        case "room.name":
          return 'Room';
          break;
        case 'startTime.time':
          return 'Start Time';
          break;
        default:
          return string;
          break;
      }
    })

  }, _defineProperty(_Ember$Component$exte, 'sortBy', 'name.name'), _defineProperty(_Ember$Component$exte, 'reverseSort', false), _defineProperty(_Ember$Component$exte, 'sortDefinition', Ember.computed('sortBy', 'reverseSort', function () {
    var sortOrder = this.get('reverseSort') ? 'desc' : 'asc';
    return [this.get('sortBy') + ':' + sortOrder];
  })), _defineProperty(_Ember$Component$exte, 'actions', {
    toggleFilterMenu: function toggleFilterMenu() {
      this.toggleProperty('showFilterMenu');
    },
    finalizeGrades: function finalizeGrades(theClass, students, yearID, schoolID) {
      var _this = this;
      var studentsArray = [];
      students.forEach(function (student) {
        studentsArray.push(student.get('id'));
      }).catch(function (error) {
        console.log(error);
      });
      console.log(JSON.stringify(studentsArray));
      $.get({
        url: "https://us-central1-scope-95d64.cloudfunctions.net/finalizeClassGradesHttp?theClass=" + theClass + '&theStudents=' + JSON.stringify(studentsArray) + '&year_id=' + yearID + '&school_id=' + schoolID,
        json: true,
        objectMode: true
      }).done(function (data) {
        console.log(data);
        _this.setProperties({ successMessage: data.success });
      }).fail(function (error) {
        console.log(error);
        _this.setProperties({ errorMessage: error });
      });
    }
  }), _Ember$Component$exte));
});