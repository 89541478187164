define('sokol-today/models/demo/point', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    teacher: _emberData.default.attr('boolean'),
    handup: _emberData.default.attr('boolean'),
    calledOn: _emberData.default.attr('boolean'),
    onTask: _emberData.default.attr('boolean'),
    offTask: _emberData.default.attr('boolean'),
    cx: _emberData.default.attr('string'),
    cy: _emberData.default.attr('string'),
    timeStamp: _emberData.default.attr('date')
  });
});