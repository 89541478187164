define('sokol-today/components/dashboard/classes/classroom/photos/add-photo', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		store: Ember.inject.service(),
		router: Ember.inject.service(),
		firebaseApp: Ember.inject.service(),
		connectedUser: Ember.inject.service(),
		images: [],
		fileInfo: Ember.A(),

		actions: {
			save: function save(files, classroom) {
				//Need to add blob file to firebase filestorage and add link to photo model
				var user = this.get('connectedUser.user');
				var staff = this.get('connectedUser.account');
				var school = this.get('connectedUser.school');
				var store = this.get('store');
				var router = this.get('router');
				var storage = this.get('firebaseApp').storage();
				var firebase = this.get('firebaseApp');
				var _this = this;
				user.then(function (theUser) {
					var _loop = function _loop() {
						var storageRef = storage.ref('users/' + theUser.id + '/Photos/');
						var file = files.objectAt(i);
						var fileData = file.data;
						var date = new Date();
						var metadata = {};
						for (key in fileData) {
							if (fileData.hasOwnProperty(key) && fileData[key] !== undefined) {
								metadata[key] = fileData[key];
							}
						}
						var newImage = store.createRecord('photo', {
							name: metadata.name,
							metadata: metadata,
							owner: staff,
							school: school,
							year: moment(metadata.origDate).format('YYYY')
						});
						metadata['photoID'] = newImage.id;
						storageRef.child(metadata.name).put(file.file, metadata).then(function (fileShot) {
							var path = fileShot.metadata.downloadURLs[0];
							// fileShot.fullPath.updateMetadata(metadata);
							newImage.get('classes').addObject(classroom);
							newImage.setProperties({ filePath: path, fileType: fileShot.metadata.contentType });
							newImage.save().then(function (savedImage) {
								classroom.get('photos').addObject(savedImage);
								classroom.save().then(function (savedClass) {
									_this.setProperties({ errorMessage: '', successMessage: 'Assignment saved!' });
									setTimeout(function () {
										router.transitionTo('dashboard.classes.classroom', classroom);
									}, 1000);
								}).catch(function (error) {
									_this.setProperties({ errorMessage: error });
									newImage.roleBackChanges();
									classroom.roleBackChanges();
									console.log('Error : ' + error);
								});
							}, function (error) {
								_this.setProperties({ errorMessage: error });
								newImage.roleBackChanges();
								console.log('Error : ' + error);
							});
						}, function (error) {
							console.log('Error : ' + error);
						});
					};

					for (var i = files.length - 1; i >= 0; i--) {
						var key;

						_loop();
					}
				});
			}
		}
	});
});